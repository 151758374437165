import {RestaurantBusinessTypeName, RestaurantFromSearch} from '~/shared/store/models';
import {CollectionOrderTypeViewTags} from '~/shared/store/models/Carousel';

export enum TagsTypes {
  RATING = 'rating',
  DELIVERY_TIME = 'delivery_time',
  DELIVERY_FEE = 'delivery_fee',
  DISTANCE = 'distance',
  MINIMUM_ORDER = 'minimum_order',
  MINIMUM_POOLED_ORDER = 'minimum_pooled_order',
  KOSHER = 'kosher',
  IS_NEW = 'new',
  POOLED_ORDER = 'pooled_order',
  ADDRESS = 'address',
  CUISINE_TYPE = 'cuisine_type'
}

export type TagType = EnumValueType<typeof TagsTypes>;

export type RestaurantPropsType = RestaurantFromSearch & {
  deliveryMethod?: string;
  promotedTag?: boolean;
  distanceNumeric?: number;
};

export type RestaurantItemTagsProps = {
  isPooledOrderRestaurant: RestaurantFromSearch['isPooledOrderRestaurant'];
  restaurantAddress: RestaurantFromSearch['restaurantAddress'];
  numOfReviews: RestaurantFromSearch['numOfReviews'];
  estimatedNumberOfOrdersInPoolOrder: RestaurantFromSearch['estimatedNumberOfOrdersInPoolOrder'];
  reviewsRankDecimal: RestaurantFromSearch['reviewsRankDecimal'];
  distance: string;
  distanceNumeric: RestaurantPropsType['distanceNumeric'];
  deliveryMethod: RestaurantPropsType['deliveryMethod'];
  deliveryFee: RestaurantFromSearch['deliveryFee'];
  deliveryTimeInMinutes: RestaurantFromSearch['deliveryTimeInMinutes'];
  minimumPriceForOrder: RestaurantFromSearch['minimumPriceForOrder'];
  isKosher: RestaurantFromSearch['isKosher'];
  tags: RestaurantFromSearch['tags'];
  promotedTag: RestaurantPropsType['promotedTag'];
  isEnvironmentFriendly: RestaurantFromSearch['isEnvironmentFriendly'];
  isClosedWithFutureOrder: boolean;
  deliveryPriceBeforeCompanyDiscount: RestaurantFromSearch['deliveryPriceBeforeCompanyDiscount'];
  pooledOrderSum: RestaurantFromSearch['pooledOrderSum'];
  groupId: string;
  minimumForPooledOrder: number;
  isOnlyAsap: boolean;
  isOnlyPooled: boolean;
  isOpenNow: boolean;
  isSDFO: boolean;
  orderTypeViewTags: CollectionOrderTypeViewTags;
  isFutureOrderAvailable?: boolean;
  isVoucherRestaurant?: boolean;
  isAsapAvailable?: boolean;
  hasActivePoolRule: boolean;
  isScoober: boolean;
  businessTypeName: RestaurantBusinessTypeName;
  showTagsLogo: boolean;
  withPickupTags?: boolean;
  userHasAddress: boolean;
  cuisineTypes?: string;
};

export type RenderingType = Partial<
  Record<
    TagType,
    {
      renderPlaceholder: boolean | ((tagProps: RestaurantItemTagsProps) => boolean);
      condition: (tagProps: RestaurantItemTagsProps) => boolean;
    }
  >
>;

export type OrderType =
  | TagType[]
  | ((tagProps: RestaurantItemTagsProps, isMinOrderBeforeDeliveryFee: boolean) => TagType[]);

export type RowsType = TagsRowsDefinition | ((tagsProps: RestaurantItemTagsProps, tags: TagsArray) => Result);

type TagsRowsDefinition = {
  firstRow: {start: number; end: number};
  secondRow: {start: number; end: number};
};

type Result = {firstRowTags?: TagType[]; secondRowTags?: TagType[]};

export type TagsConfiguration = {
  rendering: RenderingType;
  order: OrderType;
  rows: RowsType;
  isMinOrderBeforeDeliveryFee: boolean;
};

export type TagsArray = {tag: TagType; isPlaceholder: boolean}[];

const shouldRenderPlaceholder = (
  renderPlaceholder: boolean | ((tagProps: RestaurantItemTagsProps) => boolean),
  tagsProps: RestaurantItemTagsProps,
) => (typeof renderPlaceholder === 'function' ? renderPlaceholder(tagsProps) : renderPlaceholder);

// returns a list of tag elements sorted by the order of items in order array
export const getTagsComponentsToDisplay = (
  tagsProps: RestaurantItemTagsProps,
  rendering: RenderingType,
  order: {
    firstRow: TagType[];
    secondRow: TagType[];
  },
): {firstRow: TagsArray; secondRow: TagsArray} => {
  const mapTags = (tag: TagType) => {
    // need to verify that the tag element should be returned
    if (rendering[tag]?.condition(tagsProps)) {
      return {
        tag,
        isPlaceholder: false,
      };
    }

    if (shouldRenderPlaceholder(rendering[tag]?.renderPlaceholder || false, tagsProps)) {
      return {
        tag: '',
        isPlaceholder: true,
      };
    }

    return undefined;
  };

  const result = {
    firstRow: order.firstRow.map(mapTags).filter(Boolean) as TagsArray,
    secondRow: order.secondRow.map(mapTags).filter(Boolean) as TagsArray,
  };

  return result;
};

export const formatTags = (tags: TagsArray): TagType[] => tags.map(({tag}) => tag);
