import {memo} from 'react';

import {attachModal} from '~/shared/components/ReduxModal';
import {emailValidator} from '~/shared/utils/validators';
import {isNumeric} from '~/shared/utils/general';
import {getLocalizationService} from '~/shared/services/localisationService';

import SendTransferForm from './SendTransferForm';
import {Root, Title, FormWrapper} from './styled';

const validate = ({transferReceiverEmail, amount, total}) => {
  const errors = {};

  if (!transferReceiverEmail || emailValidator(transferReceiverEmail) === 'invalid_email') {
    errors.transferReceiverEmail = 'the_internet_doesnt_let_us_to_accept_this_email';
  }

  const numberAmount = Number(amount);
  if (!(numberAmount > 0 && numberAmount <= total)) {
    errors.amount = 'amount_is_above_the_total_or_under_0';
  }

  if (!isNumeric(amount)) {
    errors.amount = 'amount_with_positive_numbers_only';
  }

  return errors;
};

const SendDebitTransferRequestModal = ({
  args: {
    order: {total},
    onSubmit,
  },

  closeModal,
}) => {
  const {t} = getLocalizationService();

  const onSubmitClick = async formProps => {
    await onSubmit(formProps, total);
    closeModal();
  };

  const onCancelClick = () => {
    closeModal();
  };

  return (
    <Root>
      <Title id="modal-title">{t('request_for_debit_transfer')}</Title>
      <FormWrapper>
        <SendTransferForm {...{onSubmitClick, onCancelClick, validate, total}} />
      </FormWrapper>
    </Root>
  );
};

const EnhanceSendDebitTransferRequestModal = memo(SendDebitTransferRequestModal);

attachModal('sendDebitTransferRequestModal', EnhanceSendDebitTransferRequestModal);
