import {memo, useCallback, useEffect, useMemo, useState} from 'react';

import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';

import {trackEvent} from '~/shared/services/analytics';
import {PaymentRequestStatusTypes} from '~/shared/consts/checkoutConsts';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {media} from '~/shared/theme/utils';
import {selectAvailablePayments, selectLinkMoneycardRequests, selectCheckoutPayments, selectUserData} from '~/shared/store/selectors';
import BasicSearchInput from '~/shared/components/BasicSearchInput';
import {CentralLoading} from '~/shared/components/Loaders';
import {ActionButton} from '~/shared/components/Button';
import {LayoutWithHeader} from '~/shared/components/LayoutWithHeader';
import {getLocalizationService} from '~/shared/services/localisationService';
import {SubPageTitle} from '~/shared/components/styled';
import {addPayment, getLinkMoneycardToUserRequests} from '~/shared/store/actions';
import {filterPayments, sortPayments} from '~/shared/utils/paymentMethodsUtils';
import usePollingMoneyCard from '~/shared/hooks/usePollingMoneyCard';

import PaymentsList from './PaymentsList';
import AddPaymentsButtons from './AddPaymentsButtons';

const Root = styled.div`
  margin-top: 10px;
  padding: 10px 10px;
  ${media.minMobile`
    margin-top: 0;
    margin-bottom: 30px;
  `}
  ${media.minDesktop`
    padding: 10px 20px 0 20px;
  `}
`;

const SubmitButton = styled(ActionButton)`
  border-radius: 0;
  margin: -5px 0;
  box-shadow: none;
`;

const AvailablePaymentsSection = ({onClose, addBillingPermission, addCreditCard}) => {
  const dispatch = useDispatch();
  const {t} = getLocalizationService();

  const userData = useSelector(selectUserData);
  const availablePayments = useSelector(state => selectAvailablePayments(state) || []);
  const checkoutPayments = useSelector(selectCheckoutPayments);
  const linkMoneycardRequests = useSelector(selectLinkMoneycardRequests);

  const [searchValue, setSearchValue] = useState('');
  const [paymentsToAdd, setPaymentsToAdd] = useState([]);
  const isLoading = useMemo(() => isEmpty(availablePayments), [availablePayments]);
  const titleKey = userData?.isCompanyUser ? 'add_a_payment_method' : 'change_payment_method';
  const submitButtonTranslationKey = userData?.isCompanyUser ? 'add' : 'change';

  const addMoneyCardsToPaymentsList = useCallback(() => {
    if (!paymentsToAdd?.length) {
      onClose();
      return;
    }

    trackEvent('hasAddedPaymentOption');

    paymentsToAdd.forEach(payment => {
      dispatch(addPayment(payment));
    });

    onClose();
  }, [dispatch, onClose, paymentsToAdd]);

  const onPaymentChange = useCallback(
    payment => {
      if (!userData.isCompanyUser) {
        setPaymentsToAdd([{...payment, assigned: true}]);
        return;
      }

      const paymentExists = paymentsToAdd.find(p => p.cardId === payment.cardId);
      const updatedPaymentsToAdd = paymentExists
        ? paymentsToAdd.filter(p => p.cardId !== payment.cardId)
        : [...paymentsToAdd, {...payment, assigned: true}];

      setPaymentsToAdd(updatedPaymentsToAdd);
    },
    [paymentsToAdd, userData],
  );

  const showPaypalButton = useMemo(
    () =>
      !availablePayments.some(
        p => p.paymentMethod.toLocaleLowerCase() === PaymentMethodTypes.PAYPAL.toLocaleLowerCase(),
      ),
    [availablePayments],
  );

  const paymentsList = useMemo(() => {
    const payments = [
      ...(linkMoneycardRequests
        ? linkMoneycardRequests
          .filter(({status}) => status === PaymentRequestStatusTypes.REQUESTED)
          .map(p => ({...p, dimmed: true}))
        : []),
      ...availablePayments.filter(
        ({cardId: availablePaymentCardId}) =>
          !checkoutPayments.some(({cardId: checkoutPaymentCardId}) => availablePaymentCardId === checkoutPaymentCardId),
      ),
    ].map(payment => ({...payment, assigned: false}));

    const filteredPayments = filterPayments(payments, searchValue);
    const sortedPayments = sortPayments(filteredPayments);

    return sortedPayments.map(payment => {
      const assigned = paymentsToAdd.find(assignedPayment => assignedPayment.cardId === payment.cardId);
      return assigned ? {...payment, assigned: true} : payment;
    });
  }, [availablePayments, checkoutPayments, linkMoneycardRequests, paymentsToAdd, searchValue]);

  useEffect(() => {
    dispatch(getLinkMoneycardToUserRequests());
  }, [dispatch]);

  usePollingMoneyCard();

  if (isLoading) {
    return <CentralLoading />;
  }

  return (
    <LayoutWithHeader onClick={onClose} titleKey={titleKey} showBackArrow titleComponent={SubPageTitle}>
      <Root>
        <BasicSearchInput
          value={searchValue}
          onChange={e => setSearchValue(e.target.value)}
          ariaLabel={t('search_payment_method')}
        />

        <PaymentsList
          paymentsList={paymentsList}
          onPaymentChange={onPaymentChange}
          isSinglePaymentAllowed={!userData.isCompanyUser}
        />

        <AddPaymentsButtons
          addCreditCard={addCreditCard}
          addBillingPermission={addBillingPermission}
          showPaypalButton={showPaypalButton}
          onClose={onClose}
        />

        <SubmitButton full onClick={addMoneyCardsToPaymentsList} disabled={!paymentsToAdd?.length}>
          {t(submitButtonTranslationKey)}
        </SubmitButton>
      </Root>
    </LayoutWithHeader>
  );
};

export default memo(AvailablePaymentsSection);
