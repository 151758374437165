import {memo} from 'react';

import {attachModal} from '~/shared/components/ReduxModal';

import {ErrorModal} from './ErrorModal';

const args = {
  title: 'the_link_expired',
  text: 'sorry_the_link_to_reset_your_password_expired_please_reset_your_password_again',
  btnText: 'confirm',
};

const ForgotPasswordTokenExpiredModal = memo(({closeModal}) => {
  return (
    <ErrorModal {...{args, closeModal}}/>
  );
});

attachModal('forgotPasswordTokenExpiredModal', ForgotPasswordTokenExpiredModal);
