import {memo} from 'react';

import {AgeConfirm} from '~/ageRestriction';
import {attachModal} from '~/shared/components/ReduxModal';

const AgeConfirmModal = ({closeModal, args}) => {
  const {onConfirm} = args;
  const onApprove = () => {
    closeModal();
    onConfirm();
  };

  const onCancel = () => {
    closeModal();
  };

  return <AgeConfirm onApprove={onApprove} onCancel={onCancel} />;
};

export default attachModal('age_confirm_modal', memo(AgeConfirmModal));
