import {AxiosResponse} from 'axios';

import {IMarketingBanner, RestaurantCategories, RestaurantGroupRestaurants} from '~/shared/store/models';
import {OrderSuccessData} from '~/shared/store/models/Order';

export interface IMarketingBannerRes {
  marketingBanners: IMarketingBanner[];
}

export interface IUpdateUserRes {
  redirectToOtl: boolean;
}

export interface IRestaurantGroupsRes {
  restaurantGroupList: Partial<RestaurantGroupRestaurants>[];
}

export interface IRestaurantMenuRes {
  categories: RestaurantCategories[];
}

export type IOrderRes = OrderSuccessData & {
  dishes?: OrderSuccessData['dishes'];
  orderStatus: string;
};

export type IVoucherCardRes = {
  id: number;
  cardId: string;
  cardPin: string;
  balance: number;
  logo: string;
  image: string;
  description: string; // html
};

export type IRefreshTokenRes =
  | {
      accessToken: string;
      refreshToken: string;
    }
  | {
      description: string;
      code: string;
      traceId: string;
      details: {
        description: string;
        code: string;
      }[];
    };

interface LoadTenbisCreditResponse {
  code: string;
  description: string;
  details: string[];
  traceId: string;
}

export type ILoadTenbisCreditResponse = AxiosResponse | LoadTenbisCreditResponse;

export type IFetchBrandingPageDataResponse = {
  imageUrl: string;
  title: string;
  subTitle: string;
};

interface TimeRange {
  startTime: string;
  endTime: string;
}

interface ActivityTime {
  dayOfWeek: number;
  ranges: TimeRange[];
}

export interface IDiscountResponse {
  activityTimes: ActivityTime[];
  details: string;
  expirationDate: string;
}

export interface ApplePayMerchantSessionResponse extends MerchantSession {}

export const isAxiosResponse = <ApiResponse>(response: ApiResponse | AxiosResponse<ApiResponse>): response is AxiosResponse<ApiResponse> => {
  if (!response) {
    return false;
  }
  return (
    typeof response === 'object' && 'data' in response && 'statusText' in response && 'status' in response
  );
};
