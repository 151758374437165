import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {Link} from '~/shared/router';
import {getLocalizationService} from '~/shared/services/localisationService';
import {getLegacySiteUrl} from '~/shared/services/urlService';
import {selectUserData, selectUserDevice} from '~/shared/store/selectors';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import {flipOnLTR, rotateOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {body14Normal} from '~/shared/theme/typography';
import ScooberLogoIcon from '~/assets/images/scoober-logo-icon.svg?react';

const StyledScooberLogoIcon = styled(ScooberLogoIcon).attrs({
  className: 'avoid-fill-stroke',
})`
  width: 28px;
  height: 28px;
  ${flipOnLTR`margin-left: 5px;`}
  ${rotateOnLTR}
`;

const ActionMenuItem = styled.div`
  ${flexCenterVertically};
  color: ${({theme}) => theme.header.actionMenu.dropDown.textColor};
  background-color: ${({theme}) => theme.header.actionMenu.dropDown.backgroundColor};
  width: 100%;
  height: 48px;
  display: ${({isVisibleOnMobile}) => (isVisibleOnMobile ? 'flex' : 'none')};
  padding: 0 16px;
  cursor: pointer;

  &:hover {
    background-color: ${({theme}) => theme.header.actionMenu.dropDown.hover.backgroundColor};
    color: ${({theme}) => theme.colors.secondaryText};
  }

  &:last-child {
    border-bottom: none;
    ${({extraBottom}) => extraBottom && 'margin-bottom: 54px'};
    ${media.minTablet`
      margin-bottom: unset;  
    `};
  }

  ${media.minTablet`
    display: ${props => (props.showOnMobileOnly ? 'none' : 'flex')};
    margin: 0;
  `};
  ${media.maxLargeTablet`
    display: ${props => props.isVisibleOnTablet && 'flex'};
  `}
`;

const ActionMenuText = styled.div`
  ${body14Normal};
  display: ${props => (props.isVisibleOnMobile ? 'flex' : 'none')};
  min-width: 150px;
  font-weight: ${props => (props.isHighlighted ? 'bold' : 'normal')};
  color: inherit;
  ${media.minTablet`
    ${flipOnLTR`
      padding-right:20px;
    `};
      display: flex;
  `};
`;

// fix outline around action menu
const MenuLink = styled(Link)`
  margin: 1px;
  color: inherit;
  height: 100%;
  width: 100%;
  ${flexCenterVertically};
  border-bottom: solid 1px ${({theme}) => theme.colors.background};

  &:hover {
    border-bottom: solid 1px transparent;
  }
`;

const ActionMenuLinks = ({links, onClick}) => {
  const {t} = getLocalizationService();
  const userDevice = useSelector(selectUserDevice);
  const user = useSelector(selectUserData);

  const getMenuLinkProps = link =>
    (link.url
      ? {
          target: link.openInNewWindow ? '_blank' : '',
          href: link.useLegacy ? getLegacySiteUrl(`${link.url}`) : link.url,
          hard: link.useLegacy,
        }
      : {
          'links-test': `action-menu-link-${link.textKey}`,
          onClick: () => onClick(link),
        });

  return links
    .filter(
      link =>
        link.isAvailable &&
        ((link.loggedInUserOnly && user) || !link.loggedInUserOnly) &&
        ((link.loggedOutUserOnly && !user) || !link.loggedOutUserOnly) &&
        ((link.companyUserOnly && user && user.isCompanyUser) || !link.companyUserOnly) &&
        ((link.compAdminOnly && user && user.isCompanyAdmin) || !link.compAdminOnly),
    )
    .map(link => (
      <ActionMenuItem
        key={link.textKey}
        extraBottom={userDevice.isIOS}
        showOnMobileOnly={link.showOnMobileOnly}
        isVisibleOnTablet={link.isVisibleOnTablet}
        isVisibleOnMobile={link.isVisibleOnMobile}
      >
        <MenuLink {...getMenuLinkProps(link)}>
          <ActionMenuText isHighlighted={link.isHighlighted} isVisibleOnMobile={link.isVisibleOnMobile}>
            {link.textKey === 'become_a_courier' && <StyledScooberLogoIcon />}
            {t(link.textKey)}
          </ActionMenuText>
        </MenuLink>
      </ActionMenuItem>
    ));
};

export default ActionMenuLinks;
