import {isEmpty} from 'lodash';

import {ICheckoutError, OrderFailure} from '~/common/types/checkout';
import {OrderSubmitError} from '~/shared/store/models';

export const shouldShowErrorBoxOnCheckout = ({
  isThreeDSFailed,
  showPaymentsRemarksError,
  preSubmitErrors,
  orderFailure,
  orderErrors,
}: {
  isThreeDSFailed?: boolean;
  showPaymentsRemarksError?: boolean;
  preSubmitErrors?: ICheckoutError;
  orderFailure?: OrderFailure;
  orderErrors?: OrderSubmitError;
}) => isThreeDSFailed ||
  showPaymentsRemarksError ||
  (!isEmpty(preSubmitErrors || orderFailure || orderErrors) && !orderErrors?.data.threeDsChallengeUrl);
