import styled from 'styled-components';

import {Link} from '~/shared/router';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {body18Normal} from '~/shared/theme/typography';

const Header = styled.header`
  ${body18Normal};
  color: ${({theme}) => theme.colors.secondary};
  font-family: TakeawaySans, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 16px;
  ${flipOnLTR`
    padding-right: 16px;
  `}
  ${media.minLargeTablet`
    align-items: center;
    min-height: 71px;
    line-height: 71px;
    border-bottom: 1px solid ${({theme}) => theme.colors.darkBackground};
    margin-bottom: 0;
  `}
`;

const RestaurantLink = styled(Link)`
  margin-left: 5px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  font-weight: bold;
`;

type RestaurantHeaderProps = {
  restaurantUrl?: string;
  restaurantName?: string;
};

const RestaurantHeader = ({restaurantUrl, restaurantName}: RestaurantHeaderProps) => {
  const {t} = getLocalizationService();
  return (
    <Header as="h1">
      {t('your_order_from')}
      <RestaurantLink href={restaurantUrl}>{restaurantName}</RestaurantLink>
    </Header>
  );
};

export default RestaurantHeader;
