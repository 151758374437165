import {RestaurantFromSearch} from '../Restaurant';

export enum CollectionContent {
  Unknown,
  Businesses,
  Items,
  Categories,
  MarketingBanners
}

export enum CollectionOrderTypeViewTags {
  Unknown = 'Unknown',
  AsapPooled = 'AsapPooled',
  Pooled = 'Pooled',
  Asap = 'Asap'
}

type HighlightInfo = {
  title: string;
  iconUrl: string;
};

type StringOrEmptyString = string | '';

type Carousel = {
  id: string;
  name: string; // collection name page (used by query ?collectionName=name in the restList)
  contentType: CollectionContent; // irrelevant.
  iconUrl: StringOrEmptyString; // ex: https://cdn.10bis.co.il/collections/Pooled.svg
  miniFeedDesc: StringOrEmptyString;
  miniFeedUrlImage: StringOrEmptyString; // irrelevant
  orderTypeViewTags: CollectionOrderTypeViewTags;
  resIds: Array<RestaurantFromSearch['restaurantId']>;
  subtitle: StringOrEmptyString; // irrelevant
  title: string;
  highlightInfo: HighlightInfo | null;
};

export default Carousel;
