import {css} from 'styled-components';

import {flipOnLTR} from '~/shared/theme/flipOnLTR';

const rSelectStyleOverride = css`
  .r-select-container {
    width: 100%;
    font-size: 13px;
    ${flipOnLTR`
      text-align: right;
    `}
  }

  .r-select__menu {
    z-index: 2;
  }

  .r-select__single-value {
    width: 100%;
  }

  .r-select__indicator-separator {
    display: none !important;
  }

  .r-select__dropdown-indicator {
    color: ${({theme}) => theme.colors.primary} !important;
  }

  .r-select__control--is-focused {
    border-color: ${({theme}) => theme.colors.gray100};
    box-shadow: none !important;

    &:hover {
      border-color: ${({theme}) => theme.colors.gray100};
    }
  }

  .r-select__option--is-selected {
    background-color: ${({theme}) => theme.colors.primary};
  }
`;

export default rSelectStyleOverride;
