import {uniqBy} from 'lodash';
import {createSelector} from 'reselect';

import {EMPTY_ARRAY} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

const selectCoupons = (state: AppState) => state.coupons;

export const selectCurrentCoupon = createSelector(selectCoupons, coupons => coupons.currentCoupon);

const selectAvailableCouponsRoot = createSelector(selectCoupons, coupons => coupons.availableCoupons);
const selectAvailableCouponsData = createSelector(
  selectAvailableCouponsRoot,
  availableCoupons => availableCoupons.data || EMPTY_ARRAY,
);
export const selectAllCouponsLoading = createSelector(
  selectAvailableCouponsRoot,
  availableCoupons => availableCoupons.loading,
);

export const selectAllCoupons = createSelector(
  selectAvailableCouponsData,
  selectCurrentCoupon,
  (availableCoupons, currentCoupon) => {
    return uniqBy(
      [
        {
          code: null,
          description: 'without_a_coupon',
        },
        currentCoupon,
        ...availableCoupons,
        // hardcoded zero coupon
      ].filter(Boolean),
      item => (item?.code || '0').toLowerCase(),
    );
  },
);

export const selectIsRestaurantCouponAvailable = createSelector(
  selectAvailableCouponsData,
  availableCoupons => availableCoupons.length === 1 && availableCoupons[0].couponSource === 'Restaurant',
);

export const selectIsCouponAutoChanged = createSelector(
  selectCoupons,
  couponsState => couponsState.isCouponAutoChanged,
);
