const MILLISECONDS_PER_HOUR = 1000 * 60 * 60;
const MILLISECONDS_PER_DAY = 24 * MILLISECONDS_PER_HOUR;

export const checkTimePast = ({storedDate, days}: {storedDate: string | null; days: number}) => {
  const currentDate = Date.now();
  const timeToCheck = MILLISECONDS_PER_DAY * days;

  if (!storedDate) {
    return true;
  }
  const delta = Number(storedDate) - currentDate;

  return delta > timeToCheck;
};

export const checkTimePastWithHours = ({
  storedDate,
  days = 0,
  hours = 0,
}: {
  storedDate: string | null;
  days?: number;
  hours?: number;
}) => {
  if (!storedDate) {
    return true;
  }
  const currentDate = Date.now();

  const timeToCheck = (days * MILLISECONDS_PER_DAY) + (hours * MILLISECONDS_PER_HOUR);
  const storedDateTime = new Date(storedDate).getTime();

  const delta = Math.abs(currentDate - storedDateTime);

  return delta > timeToCheck;
};
