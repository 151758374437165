import {useContext, useEffect, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import styled, {css} from 'styled-components';

import TimeSelectionErrorIcon from '~/assets/images/icons/time-error.svg?react';
import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import BottomSheet from '~/shared/components/BottomSheet';
import {
  clearCurrentOrderDateAndTime,
  setDeliveryRule,
  setIsOrderTypeMenuOpen,
  setMenuLoading,
  setShowSelectTimeErrorMessage as setShowSelectTimeErrorMessageAction,
} from '~/shared/store/actions';
import {
  selectCurrentDeliveryMethod,
  selectCurrentRestaurant,
  selectCurrentRestaurantAvailableDeliveryRules,
  selectCurrentRestaurantShoppingCartDeliveryType,
  selectFutureOrderAvailableDatesAndTimes,
  selectIsOrderTypeMenuOpen,
  selectOrderDeliveryRule,
  selectShowSelectTimeErrorMessage,
} from '~/shared/store/selectors';
import {getFormatedDate, getOrderTypeString} from '~/shared/services/analytics/utils';
import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import Button from '~/shared/components/Button';
import {DELIVERY_RULES, DeliveryMethod, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {trackEvent} from '~/shared/services/analytics';
import SameDayFutureOrder from '~/shared/components/ShoppingCart/components/ShoppingCartHeader/SameDayFutureOrder';
import {FutureOrderContext} from '~/shared/components/FutureOrder/context';
import {OnSelectTimeProps} from '~/common/FutureOrderContextProvider';
import {getAsapDeliveryTimeRanges, getEstimatedPooledDeliveryTimeRangeString} from '~/shared/utils/restaurants';
import {getLocalizationService} from '~/shared/services/localisationService';
import {ETAInfoBox} from '~/shared/components/ETAInfoBox';
import useWebpWithFallback from '~/shared/hooks/useWebpWithFallback';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {body14Normal, body16Bold, body18Bold} from '~/shared/theme/typography';
import FutureOrder from '~/shared/components/FutureOrder';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {getDateOptionLabel} from '~/shared/components/FutureOrder/FutureOrder';
import {getTimeRangeLabel, isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';
import {flipOnLTR} from '~/shared/theme/utils';
import whiteLeftArrowUrl from '~/assets/images/icons/checkmark-blue.svg';
import {isCurrentTimeKeyAsap as isTimeKeyAsap} from '~/shared/utils/general';
import shouldChangeOrder from '~/shared/components/MwOrderTypeMenu/shouldChangeOrder';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';

import DeliveryMethodPicker from './DeliveryMethodPicker';
import {
  getInitialDeliveryMethod,
  getInitialDeliveryRule,
  getNewDeliveryPrice,
  getSDFOInitialDayAndTime,
  removeAsapFromFutureOrderTimes,
} from './utils';
import SDFOBoxMw from './components/SDFOBoxMw';

export type FORM_ORDER_TYPE = EnumValueType<typeof DELIVERY_RULES> | DeliveryMethods.PICKUP;

const SubmitButton = styled(Button)`
  ${flexCenter}
  ${body16Bold}
  padding: 12px 16px;
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  width: 100%;
  line-height: 20px;
`;

const DeliveryMethodPickerContainer = styled.div`
  border-bottom: 1px solid ${({theme}) => theme.colors.gray800};
  padding: 16px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 9004;
  flex: 1;
`;

const OrderTypeContainer = styled.div<{isSelected: boolean; isDisabled?: boolean}>`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  color: ${({theme}) => theme.colors.text2};
  align-items: flex-start;
  border: ${({isSelected, isDisabled, theme}) => (isSelected && !isDisabled ? `solid 2px ${theme.colors.surfaceTitle}` : `solid 2px ${theme.colors.gray800}`)};
  filter: grayscale(${({isDisabled}) => (isDisabled ? 1 : 0)});
  ${({isSelected}) => isSelected && css`
    &::after {
      content: '';
      position: absolute;
      top: 21px;
      width: 22px;
      height: 17px;
      background-image: url(${whiteLeftArrowUrl});
      ${flipOnLTR`left: 12px;`}
    }
  `}
`;

const OrderTypesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  ${({theme}) => convertGapToMargin('16px 0', theme.isLTR)}
`;

const OrderTypeHeader = styled.div`
  ${body18Bold}
`;

const ETAInfoBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
`;

const FutureOrderWrapper = styled.div`
  padding: 12px 12px 16px;
  width: 100%;
`;

const SelectTimeErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme.colors.error};
  ${body14Normal}
  margin-top: 16px;
  ${({theme}) => convertGapToMargin('0 4px', theme.isLTR)}
`;

const MwOrderTypeMenu = ({isCheckout}: {
  isCheckout?: boolean;
}) => {
  const dispatch = useDispatch();
  const {t} = getLocalizationService();
  const isMinLargeTablet = useIsMinLargeTablet();

  const isOpen = useSelector(selectIsOrderTypeMenuOpen);
  const showSelectTimeErrorMessage = useSelector(selectShowSelectTimeErrorMessage);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const currentDeliveryRule = useSelector(selectOrderDeliveryRule);
  const currentDeliveryMethod = useSelector(selectCurrentDeliveryMethod);
  const availableDeliveryRules = useSelector(selectCurrentRestaurantAvailableDeliveryRules);
  const shoppingCartDeliveryType = useSelector(selectCurrentRestaurantShoppingCartDeliveryType);
  const availableFutureDatesAndTimes = useSelector(selectFutureOrderAvailableDatesAndTimes);

  const setShowSelectTimeErrorMessage = (show: boolean) => dispatch(setShowSelectTimeErrorMessageAction(show));

  const {
    onSelectDateAndTime: contextOnSelectDateAndTime,
    futureOrderAvailableDatesAndTimes: contextFutureOrderAvailableDatesAndTimes,
    currentOrderDateAndTime,
    currentDeliveryFee,
    isASAP: isCurrentTimeKeyAsap,
    isInitialOrder,
    trackUserFirstDateTimeSelection,
  } = useContext(FutureOrderContext);

  const isSDFOAvailable = shoppingCartDeliveryType === SHOPPING_CART_DELIVERY_TYPE.SDFO;

  const isAsapAvailable = Boolean(availableDeliveryRules.Asap);
  const isPooledAvailable = Boolean(availableDeliveryRules.Pool);
  const isFutureAvailable = Boolean(availableDeliveryRules.Future);
  const isPickupEnabled = Boolean(availableDeliveryRules.pickup);
  const isDeliveryEnabled = isAsapAvailable || isPooledAvailable || isFutureAvailable;

  const initialDeliveryMethod = getInitialDeliveryMethod(currentDeliveryMethod, isPickupEnabled, isDeliveryEnabled);

  const initialOrderType = initialDeliveryMethod === DeliveryMethods.PICKUP ?
    DeliveryMethods.PICKUP :
    currentDeliveryRule || getInitialDeliveryRule(isFutureAvailable, isPooledAvailable, isAsapAvailable);
  
  const initialSDFODateAndTime = useMemo(() => {
    const dayAndTime = getSDFOInitialDayAndTime(isCurrentTimeKeyAsap, currentOrderDateAndTime, availableFutureDatesAndTimes);
    const rule = availableDeliveryRules?.Future?.find(deliveryRule => deliveryRule.ruleId === dayAndTime?.orderTime.ruleId);
    
    if (dayAndTime) {
      return {
        ...dayAndTime,
        formDeliveryFee: (isInitialOrder && !isSDFOAvailable) ? undefined : rule?.deliveryFee,
        deliveryFeeBeforeDiscount: (isInitialOrder && !isSDFOAvailable) ? undefined : rule?.deliveryFeeBeforeDiscount,
      };
    }
    
    return {...currentOrderDateAndTime, deliveryFeeBeforeDiscount: rule?.deliveryFeeBeforeDiscount, formDeliveryFee: currentDeliveryFee};
  }, [
    isCurrentTimeKeyAsap,
    currentOrderDateAndTime,
    availableFutureDatesAndTimes,
    availableDeliveryRules?.Future,
    currentDeliveryFee,
    isInitialOrder,
    isSDFOAvailable,
  ]);

  const [formOrderType, setFormOrderType] = useState<FORM_ORDER_TYPE>(initialOrderType);
  const [isLoading, setIsLoading] = useState(false);
  const [formDeliveryMethod, setFormDeliveryMethod] = useState(initialDeliveryMethod);
  const [formDateAndTime, setFormDateAndTime] = useState(initialSDFODateAndTime);
  const [formIsTimeSelectionRequired, setFormIsTimeSelectionRequired] = useState(
    isInitialOrder && !isCheckout && !isSDFOAvailable,
  );

  const isDeliveryMethodPickerEnabled = !isCheckout &&
    (
      (isPickupEnabled && isDeliveryEnabled) ||
      (initialDeliveryMethod === DeliveryMethods.DELIVERY && isPickupEnabled) ||
      (initialDeliveryMethod === DeliveryMethods.PICKUP && isDeliveryEnabled)
    );

  const pickupImgPath = useWebpWithFallback('asap_and_pooled/pickup.svg') as string;
  const asapImgPath = useWebpWithFallback('asap_and_pooled/asap.svg') as string;
  const pooledImgPath = useWebpWithFallback('asap_and_pooled/pooled.svg') as string;

  const deliveryTimeRange = t('delivered_in_xxx', getAsapDeliveryTimeRanges(currentRestaurant?.asapDeliveryTimeInMinutes || 0));
  const estimatedPoolDeliveryTime = t('delivered_between_xxx', {
    estimatedPoolDeliveryTime: currentRestaurant?.poolDeliveryTime
      ? getEstimatedPooledDeliveryTimeRangeString(currentRestaurant?.poolDeliveryTime)
      : '',
  });

  const sdfoTimeRange = isValidTimeRangeString(formDateAndTime?.orderTime?.timeRange) ? getTimeRangeLabel(formDateAndTime?.orderTime?.timeRange) : '';
  const sdfoTitle = formOrderType === DELIVERY_RULES.FUTURE ? sdfoTimeRange : t('schedule_delivery');
  const futureOrderTimeLabel = formIsTimeSelectionRequired ?
    getDateOptionLabel({formatedDate: formDateAndTime?.orderDate?.formatedDate || ''}) :
    `${getDateOptionLabel({formatedDate: formDateAndTime?.orderDate?.formatedDate || ''})}, ${formDateAndTime?.orderTime?.timeKeyLabel}`;
  const futureOrderTitle = formOrderType === DELIVERY_RULES.FUTURE ? futureOrderTimeLabel : t('future_delivery');

  const closeBottomSheet = () => dispatch(setIsOrderTypeMenuOpen(false));
  
  const onOrderTypeChange = (newOrderType: FORM_ORDER_TYPE) => {
    if (formOrderType === newOrderType) {
      return;
    }
    setFormOrderType(newOrderType);
  };
  
  const onDeliveryMethodChange = (newDeliveryMethod: DeliveryMethod) => {
    if (newDeliveryMethod === formDeliveryMethod) {
      return;
    }
    setFormDeliveryMethod(newDeliveryMethod);
    
    if (newDeliveryMethod === DeliveryMethods.PICKUP) {
      onOrderTypeChange(DeliveryMethods.PICKUP);
    }

    if (newDeliveryMethod === DeliveryMethods.DELIVERY) {
      onOrderTypeChange(getInitialDeliveryRule(isFutureAvailable, isPooledAvailable, isAsapAvailable));
    }
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      if (!currentRestaurant) {
        closeBottomSheet();
        return;
      }

      if (formIsTimeSelectionRequired && formOrderType === DELIVERY_RULES.FUTURE) {
        setShowSelectTimeErrorMessage(true);
        return;
      }

      // removes future order choose time placeholder (if the user chooses the first available time, we wont dispatch
      // setTime action because it is already updated in the redux, we need just to remove the placeholder)
      trackUserFirstDateTimeSelection();

      const shouldChangeDeliveryMethod = formDeliveryMethod !== currentDeliveryMethod;
      const shouldChangeOrderType = (formOrderType !== initialOrderType) && formOrderType !== DeliveryMethods.PICKUP;
      const isSelectedSameDate = formDateAndTime.orderDate?.date === currentOrderDateAndTime.orderDate?.date;
      const isSelectedSameTime =
        (isTimeKeyAsap(formDateAndTime.orderTime?.timeKey) && isTimeKeyAsap(currentOrderDateAndTime.orderTime?.timeKey)) ||
        formDateAndTime.orderTime?.timeKey === currentOrderDateAndTime.orderTime?.timeKey;

      if (!shouldChangeOrder({
        shouldChangeDeliveryMethod,
        isSelectedSameDate,
        isSelectedSameTime,
        formOrderType,
        initialOrderType,
      })) {
        closeBottomSheet();
        return;
      }
      
      // enables loader for the page including OrderTypeButton until the menu isnt refreshed
      dispatch(setMenuLoading(true));

      if (shouldChangeDeliveryMethod) {
        await ManagerProvider.changeDeliveryMethod({deliveryMethod: formDeliveryMethod, reChangeRestaurant: false});
        trackEvent('hasSwitchedDeliveryType', {deliveryMethod: formDeliveryMethod});
      }

      if (shouldChangeOrderType) {
        dispatch(setDeliveryRule(formOrderType));
        await ManagerProvider.switchDeliveryRuleType({restaurantId: currentRestaurant?.id, deliveryRuleType: formOrderType});
      }

      if (formOrderType === DELIVERY_RULES.FUTURE) {
        contextOnSelectDateAndTime({
          orderDate: formDateAndTime.orderDate,
          orderTime: {
            props: formDateAndTime.orderTime,
            newDeliveryFee: formDateAndTime.formDeliveryFee,
            deliveryTimeRange,
          },
          skipRuleSwitch: true,
        });
      }

      // Backend resets the current order date and time if the current order type is not future, therefore
      // it should be also erased from redux (exception: ASAP type with SDFO restaurant)
      if (formOrderType !== DELIVERY_RULES.FUTURE && !(formOrderType === DELIVERY_RULES.ASAP && isFutureAvailable)) {
        dispatch(clearCurrentOrderDateAndTime());
      }

      if (formOrderType === DELIVERY_RULES.ASAP && isFutureAvailable) {
        const asapTime = contextFutureOrderAvailableDatesAndTimes?.[0]?.times?.[0];
        if (asapTime) {
          const {timeKey, timeRange, ruleId} = asapTime;
          contextOnSelectDateAndTime({
            orderDate: formDateAndTime.orderDate,
            orderTime: {
              props: {
                timeKey,
                timeKeyLabel: deliveryTimeRange,
                timeRange,
                ruleId,
              },
              newDeliveryFee: availableDeliveryRules?.Asap?.[0]?.deliveryFee,
              deliveryTimeRange,
            },
            skipRuleSwitch: true,
          });
        }
      }

      trackEvent('nlxOrderType_select', {
        selected_orderType: getOrderTypeString(formDeliveryMethod, formDeliveryMethod === DeliveryMethods.PICKUP ? null : formOrderType as DELIVERY_RULES),
        original_orderType: getOrderTypeString(currentDeliveryMethod, currentDeliveryRule),
        pageSource: isCheckout ? 'checkout' : 'menu',
        futureOrderTime: formOrderType === DELIVERY_RULES.FUTURE ? getFormatedDate(formDateAndTime.orderDate, formDateAndTime.orderTime) : undefined,
        delivery_price: getNewDeliveryPrice(formOrderType, availableDeliveryRules, formDateAndTime.formDeliveryFee),
      });

      closeBottomSheet();
    } finally {
      // currently no menu request is sent on transition to pickup or between asap and pool
      // in these cases the menu loader should be disabled manually
      if (formDeliveryMethod === DeliveryMethods.PICKUP || !isFutureAvailable) {
        dispatch(setMenuLoading(false));
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setFormDeliveryMethod(initialDeliveryMethod);
    setFormOrderType(initialOrderType);
    setFormDateAndTime(initialSDFODateAndTime);
    setFormIsTimeSelectionRequired(isInitialOrder && !isCheckout && !isSDFOAvailable);
  }, [initialOrderType, isCheckout, initialDeliveryMethod, initialSDFODateAndTime, isInitialOrder, isSDFOAvailable, isOpen]);

  const onTimeSelect = (selectedTime: OnSelectTimeProps) => {
    const rule = availableDeliveryRules?.Future?.find(deliveryRule => deliveryRule.ruleId === selectedTime?.props?.ruleId);
    setFormDateAndTime(prevState => {
      return {
        ...prevState,
        orderTime: selectedTime.props,
        formDeliveryFee: rule?.deliveryFee,
        deliveryFeeBeforeDiscount: rule?.deliveryFeeBeforeDiscount,
      };
    });
  };

  const userTimeInputHandler = (isTimeSelectionRequired?: boolean, isShowSelectTimeErrorMessage?: boolean) => {
    if (isTimeSelectionRequired) {
      setFormIsTimeSelectionRequired(false);
    }
    
    if (isShowSelectTimeErrorMessage) {
      setShowSelectTimeErrorMessage(false);
    }
  };

  const onFutureTimeSelect = (selectedTime: OnSelectTimeProps) => {
    userTimeInputHandler(formIsTimeSelectionRequired, showSelectTimeErrorMessage);
    
    onTimeSelect(selectedTime);
  };

  const onFutureDateSelect = (selectedDate: MutatedFutureOrderAvailableDatesAndTimes) => {
    userTimeInputHandler(formIsTimeSelectionRequired, showSelectTimeErrorMessage);
    const rule = availableDeliveryRules?.Future?.find(deliveryRule => deliveryRule.ruleId === selectedDate.ruleId);

    setFormDateAndTime({
      orderDate: selectedDate,
      orderTime: selectedDate.times[0],
      formDeliveryFee: rule?.deliveryFee,
      deliveryFeeBeforeDiscount: rule?.deliveryFeeBeforeDiscount,
    });
  };
  
  const futureOrderAvailableDatesAndTimes = removeAsapFromFutureOrderTimes(t('asap'), contextFutureOrderAvailableDatesAndTimes);

  const currentOrderDate = removeAsapFromFutureOrderTimes(t('asap'), [formDateAndTime?.orderDate])?.[0];
  const currentFutureOrderDateAndTime = {...formDateAndTime, orderDate: currentOrderDate || formDateAndTime.orderDate};
  
  if (isMinLargeTablet) {
    return null;
  }

  return (
    <BottomSheet
      isOpen={isOpen}
      onClose={closeBottomSheet}
    >
      <Content>
        {isDeliveryMethodPickerEnabled && (
          <DeliveryMethodPickerContainer>
            <DeliveryMethodPicker deliveryMethod={formDeliveryMethod as DeliveryMethods.DELIVERY | DeliveryMethods.PICKUP} onChange={onDeliveryMethodChange} />
          </DeliveryMethodPickerContainer>
        )}
        <OrderTypesWrapper>
          {formDeliveryMethod === DeliveryMethods.DELIVERY ? (
            <>
              <OrderTypeHeader>{t('time_to_deliver_2')}</OrderTypeHeader>
              {isAsapAvailable && (
                <OrderTypeContainer isSelected={formOrderType === DELIVERY_RULES.ASAP} onClick={() => onOrderTypeChange(DELIVERY_RULES.ASAP)}>
                  {/* ASAP */}
                  <ETAInfoBoxWrapper>
                    <ETAInfoBox
                      title={deliveryTimeRange}
                      isAsap
                      imagePath={asapImgPath}
                      hidePickupDisclaimer
                      inlineRow
                      deliveryFee={availableDeliveryRules.Asap?.[0]?.deliveryFee}
                      deliveryFeeBeforeDiscount={availableDeliveryRules.Asap?.[0]?.deliveryFeeBeforeDiscount}
                    />
                  </ETAInfoBoxWrapper>
                </OrderTypeContainer>
              )}
              {isPooledAvailable && (
                <OrderTypeContainer isSelected={formOrderType === DELIVERY_RULES.POOL} onClick={() => onOrderTypeChange(DELIVERY_RULES.POOL)}>
                  {/* Pooled */}
                  <ETAInfoBoxWrapper>
                    <ETAInfoBox
                      title={estimatedPoolDeliveryTime}
                      imagePath={pooledImgPath}
                      hidePickupDisclaimer
                      inlineRow
                      deliveryFee={availableDeliveryRules.Pool?.[0].deliveryFee}
                    />
                  </ETAInfoBoxWrapper>
                </OrderTypeContainer>
              )}
              {isFutureAvailable && (
                <OrderTypeContainer isSelected={formOrderType === DELIVERY_RULES.FUTURE} onClick={() => onOrderTypeChange(DELIVERY_RULES.FUTURE)} as={formOrderType === DELIVERY_RULES.FUTURE ? 'div' : 'button'}>
                  {/* FUTURE */}
                  <ETAInfoBoxWrapper>
                    <ETAInfoBox
                      title={isSDFOAvailable ? sdfoTitle : futureOrderTitle}
                      imagePath={pooledImgPath}
                      hidePickupDisclaimer
                      inlineRow
                      deliveryFee={formOrderType === DELIVERY_RULES.FUTURE ? formDateAndTime.formDeliveryFee : undefined}
                      deliveryFeeBeforeDiscount={formOrderType === DELIVERY_RULES.FUTURE ? formDateAndTime.deliveryFeeBeforeDiscount : undefined}
                    />
                  </ETAInfoBoxWrapper>
                  {/* SDFO */}
                  {(formOrderType === DELIVERY_RULES.FUTURE && isSDFOAvailable) && (
                    <SameDayFutureOrder
                      openToTop
                      hideTitle
                      initiateWith={{
                        onSelectTime: onTimeSelect,
                        orderDateAndTime: formDateAndTime,
                        futureOrderAvailableDatesAndTimes,
                        RuleBoxComponent: SDFOBoxMw,
                        disuseAnalytics: true,
                      }}
                    />
                  )}
                  {/* Future Order */}
                  {(formOrderType === DELIVERY_RULES.FUTURE && !isSDFOAvailable) && (
                    <FutureOrderWrapper>
                      <FutureOrder
                        openToTop
                        isCheckout={false}
                        futureOnly
                        customOptions={{
                          disuseAnalytics: true,
                          isInitialOrder: formIsTimeSelectionRequired && !isCheckout,
                          shouldShowDisclaimers: false,
                          currentOrderDateAndTime: currentFutureOrderDateAndTime,
                          onSelectDate: onFutureDateSelect,
                          onSelectTime: onFutureTimeSelect,
                          futureOrderAvailableDatesAndTimes,
                          isASAP: isTimeKeyAsap(formDateAndTime?.orderTime?.timeKey),
                        }}
                      />
                      {showSelectTimeErrorMessage && (
                        <SelectTimeErrorMessage>
                          <TimeSelectionErrorIcon/>
                          <div>{t('choose_delivery_hour_to_continue')}</div>
                        </SelectTimeErrorMessage>
                      )}
                    </FutureOrderWrapper>
                  )}
                </OrderTypeContainer>
              )}
            </>
          ) : (
            <>
              {/* Pickup */}
              <OrderTypeHeader>{t('pickup_order')}</OrderTypeHeader>
              <OrderTypeContainer
                isSelected={formOrderType === DeliveryMethods.PICKUP}
                onClick={() => availableDeliveryRules.pickup?.isActiveNow && onOrderTypeChange(DeliveryMethods.PICKUP)}
                isDisabled={!availableDeliveryRules.pickup?.isActiveNow}
              >
                <ETAInfoBoxWrapper>
                  <ETAInfoBox title={t('pickup_time_disclaimer')} isPickup imagePath={pickupImgPath} hidePickupDisclaimer inlineRow/>
                </ETAInfoBoxWrapper>
              </OrderTypeContainer>
            </>
          )}
        </OrderTypesWrapper>
        <SubmitButton disabled={isLoading} onClick={onSubmit}>{t('select')}</SubmitButton>
      </Content>
    </BottomSheet>
  );
};

export default MwOrderTypeMenu;
