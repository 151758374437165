import moment from 'moment-timezone';

import {Address, LocalAddress, RestaurantFromGet, RestaurantFromSearch} from '~/shared/store/models';
import {isRestaurantOpen} from '~/shared/utils/restaurants/restaurantStatus';
import {
  getMutatedFutureOrderAvailableDatesAndTimes,
} from '~/shared/utils/restaurants/getMutatedFutureOrderAvailableDatesAndTimes';
import {isRestaurantFromGet} from '~/shared/utils/restaurant';
import {getActiveDeliveryRules} from '~/shared/utils/restaurants/getActiveDeliveryRules';
import {createLogger} from '~/shared/logging';

const logger = createLogger('isSDFORestaurant');

export const isToday = (dateString: string) => {
  const date = moment(dateString, 'DD/MM/YYYY');

  return date.isSame(moment(), 'day');
};

const isSDFORestaurant = (restaurant: RestaurantFromSearch | RestaurantFromGet, address?: Address | LocalAddress | undefined) => {
  if (!isRestaurantOpen(restaurant)) {
    return false;
  }

  if (isRestaurantFromGet(restaurant)) {
    if (!address) {
      logger.warn('No address was passed. When using RestaurantFromGet you should also pass the current address');
      return false;
    }

    const activeDeliveryRules = getActiveDeliveryRules(restaurant, address);

    if (!activeDeliveryRules?.Future) {
      return false;
    }

    const mutatedFutureOrderTimes = getMutatedFutureOrderAvailableDatesAndTimes(restaurant);

    if (!mutatedFutureOrderTimes || mutatedFutureOrderTimes.length === 0) {
      return false;
    }

    return mutatedFutureOrderTimes.every(({date}) => isToday(date));
  }

  return Boolean(restaurant.futureOrderTodayBeginTime);
};

export default isSDFORestaurant;
