import Bowser from 'bowser';

import {createLogger} from '~/shared/logging';

const logger = createLogger('userAgent');

export function getUserDevice() {
  try {
    const bowserResult = Bowser.parse(navigator?.userAgent);
    if (!bowserResult) {
      return {};
    }

    const {name: browserName, version: browserVersion} = bowserResult.browser;
    return {
      isMobileDevice: bowserResult.platform.type === 'mobile',
      isTabletDevice: bowserResult.platform.type === 'tablet',
      isAndroid: bowserResult.os.name === 'Android',
      isIOS: bowserResult.os.name === 'iOS',
      isChrome: browserName === 'Chrome',
      isSafari: browserName === 'Safari',
      isEdge: browserName === 'Microsoft Edge',
      isFirefox: browserName === 'Firefox',
      browserVersion,
      platformVendor: bowserResult.platform.vendor,
    };
  } catch (e) {
    logger.error('No user agent was provided.');
    return {};
  }
}
