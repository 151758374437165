import {useCallback, useMemo, useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import {Form} from 'react-final-form';

import {trackEvent} from '~/shared/services/analytics';
import {
  selectCurrentAddressKey,
  selectCurrentRestaurantDishesByDishId,
  selectDishAssignedUsers,
  selectJustAddedDishToShoppingCart,
  selectShoppingCartDishesByDishId,
  selectUserData,
  selectCategoriesListOfCurrentRestaurant,
  selectIsShoppingCartHasAgeRestrictionDishOrSub,
  selectShouldOpenModal,
} from '~/shared/store/selectors';
import {media} from '~/shared/theme/media';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import actions from '~/shared/store/actions';
import {useIsMaxMobile} from '~/shared/hooks/deviceInfo';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {AddressViewValuesMap} from '~/shared/consts/addressConsts';
import {getDishTotalPrice} from '~/shared/utils/billingLinesCalculation';
import {AGE_RESTRICTED_ANALYTICS} from '~/shared/consts/restaurantConsts';
import {selectIsAgeConfirmed} from '~/shared/store/storeModules/ageRestriction/ageRestrictionSelectors';
import store from '~/shared/store';
import {getCurrentLocation, pushRoute} from '~/shared/router';
import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';
import {routeNames} from '~/shared/routes';

import {DishType, EncodedChoicesType} from '../../modals/DishType';
import {decodeChoices, encodeChoices, validateForceChoice} from '../../utils/choices';

import Header from './Header';
import PickingOptions from './PickingOptions';
import Footer from './Footer';
import DishNotFound from './DishNotFound';

const ModalRoot = styled.div`
  height: 100%;
  max-height: 100%;
  width: 100%;
  background-color: ${({theme}) => theme.loginModal.backgroundColor};
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
  ${flexColumn};
  ${media.minTablet`
		height: auto;
	`}

  &&& {
    background: ${({theme}) => theme.colors.surface};
  }
`;

const EMPTY_DISH = {};

const Dish = ({
  dishId,
  shoppingCartDishId,
  onDone,
}: {
  dishId?: number;
  shoppingCartDishId?: number;
  onDone: () => void;
}) => {
  const dispatch = useDispatch();
  const shoppingCartDishesById = useSelector(selectShoppingCartDishesByDishId);
  const restaurantDishedById = useSelector(selectCurrentRestaurantDishesByDishId);
  const assignedUsers = useSelector(selectDishAssignedUsers);
  const userData = useSelector(selectUserData);
  const currentAddressKey = useSelector(selectCurrentAddressKey);
  const justAddedDishToShoppingCart = useSelector(selectJustAddedDishToShoppingCart);
  const shouldOpenModal = useSelector(selectShouldOpenModal);

  const isMaxMobile = useIsMaxMobile();

  const dish = useMemo((): DishType => {
    if (!dishId || !restaurantDishedById?.[dishId]) {
      return EMPTY_DISH;
    }

    if (shoppingCartDishId) {
      return {
        ...(shoppingCartDishesById[shoppingCartDishId] || {}),
        ...(restaurantDishedById[shoppingCartDishesById[shoppingCartDishId].dishId] || {}),
        selectedChoices: (shoppingCartDishesById[shoppingCartDishId] || {}).choices || [],
      };
    }
    return {
      ...restaurantDishedById[dishId],
      selectedChoices: (restaurantDishedById[dishId]?.choices || [])
        .filter(({isForced, maxPicksAllowed}) => isForced && maxPicksAllowed === 1)
        .map(choice => ({...choice, subsChosen: [choice.subs?.[0]]})),
    };
  }, [dishId, restaurantDishedById, shoppingCartDishId, shoppingCartDishesById]);

  const categoriesList = useSelector(selectCategoriesListOfCurrentRestaurant);
  const category = categoriesList.find(item => item.categoryId === dish.categoryId);

  const dishDataForAnalytics = useMemo(() => {
    const data = {
      productID: dish.dishId,
      productPrice: dish.dishPrice,
      productName: dish.dishName,
      productCategory: category?.categoryName,
      productQuantity: dish.quantity,
      productVariant: dish.ageRestricted ? AGE_RESTRICTED_ANALYTICS : '',
    };
    return data;
  }, [dish.dishId, dish.dishPrice, dish.dishName, category?.categoryName, dish.quantity, dish.ageRestricted]);

  useEffect(() => {
    if (dish === EMPTY_DISH) {
      return;
    }

    trackEvent('hasViewedProductDetail', dishDataForAnalytics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isEditMode = !!(shoppingCartDishId && shoppingCartDishesById[shoppingCartDishId]);

  const initialValues = useMemo(() => {
    const selectedUserId = assignedUsers.find(user => user.isSelected)?.id;
    const loggedInUserId = userData?.userId;

    return {
      quantity: 1,
      dishNotes: null,
      assignedUserId: selectedUserId || loggedInUserId || assignedUsers?.[0]?.id,
      ...dish,
      selectedChoices: encodeChoices(dish.selectedChoices || []),
    };
  }, [assignedUsers, dish, userData?.userId]);

  const onSubmit = useCallback(
    (values: {
      dishNotes: string;
      quantity: number;
      assignedUserId: number;
      selectedChoices: EncodedChoicesType;
      choices: DishType['choices'];
    }) => {
      if (!currentAddressKey) {
        onDone();
        dispatch(actions.setAddressView(AddressViewValuesMap.ADD));
        return;
      }

      if (justAddedDishToShoppingCart && isMaxMobile) {
        return;
      }
     
      const decodedChoices = decodeChoices(values.selectedChoices || {});

      ManagerProvider.upsertDish({
        dishId: dishId!,
        shoppingCartDishId: shoppingCartDishId!,
        quantity: Number(values.quantity),
        assignedUserId: values.assignedUserId,
        choices: decodedChoices,
        dishNotes: values.dishNotes,
        categoryId: dish.categoryId,
      });

      if (!shoppingCartDishId) {
        dispatch(actions.setJustAddedDishToShoppingCart(true));
      }

      const state = store.getState();
      const isShoppingCartHasAgeRestrictionDishOrSub = selectIsShoppingCartHasAgeRestrictionDishOrSub(state);
      const isAgeConfirmed = selectIsAgeConfirmed(state);
      const isCheckoutPage = getCurrentLocation().routeName === routeNames.checkout;
      
      if (isCheckoutPage && isEditMode && isShoppingCartHasAgeRestrictionDishOrSub && !isAgeConfirmed) {
        if (!shouldOpenModal) {
          dispatch(actions.setActionMadeFrom(ACTION_MADE_FROM_ENUM.CHECKOUT));
          pushRoute('/confirm-age');
          return;
        }
        dispatch(actions.setActionMadeFrom(ACTION_MADE_FROM_ENUM.MENU));
        dispatch(
          actions.setCurrentModal('age_confirm_modal', {
            onConfirm: () => {
              actions.setIsAgeConfirmed(true);
              onDone();
            },
          }),
        );
        return;
      }

      onDone();

      (function analyticsAddAndRemoveFromCart() {
        const dishForPrice = {
          choices: dish.choices,
          dishPrice: dish.dishPrice,
        };

        const singleDishWithSubsPrice = getDishTotalPrice(dishForPrice, decodedChoices, 1);
        dishDataForAnalytics.productPrice = singleDishWithSubsPrice;
        const prevSingleDishWithSubsPrice = getDishTotalPrice(
          dishForPrice,
          decodeChoices(initialValues.selectedChoices || {}),
          1,
        );
        if (isEditMode) {
          const initialQuantity = dishDataForAnalytics.productQuantity ? dishDataForAnalytics.productQuantity : 0;
          const currentQuantity = values.quantity;
          if (currentQuantity > initialQuantity) {
            dispatch(actions.setIsReorder(false));
            const dishAddData = {...dishDataForAnalytics, productQuantity: currentQuantity - initialQuantity};
            trackEvent('hasAddedToCart', dishAddData);
          }
          if (currentQuantity < initialQuantity) {
            dispatch(actions.setIsReorder(false));
            const dishRemoveData = {...dishDataForAnalytics, productQuantity: initialQuantity - currentQuantity};
            trackEvent('hasRemovedFromCart', dishRemoveData);
          }
          if (currentQuantity === initialQuantity) {
            const dishDataWithSubs = {...dishDataForAnalytics, productQuantity: currentQuantity};
            dispatch(actions.setIsReorder(false));
            if (singleDishWithSubsPrice > prevSingleDishWithSubsPrice) {
              trackEvent('hasAddedToCart', dishDataWithSubs);
            }
            if (singleDishWithSubsPrice < prevSingleDishWithSubsPrice) {
              dispatch(actions.setIsReorder(false));
              trackEvent('hasRemovedFromCart', dishDataWithSubs);
            }
          }
        } else {
          const dishDataForAddProduct = {...dishDataForAnalytics, productQuantity: values.quantity};
          dispatch(actions.setIsReorder(false));
          trackEvent('hasAddedToCart', dishDataForAddProduct);
        }
      })();
    },
    [
      currentAddressKey,
      dish.categoryId,
      dishId,
      dispatch,
      isMaxMobile,
      justAddedDishToShoppingCart,
      onDone,
      shoppingCartDishId,
      dishDataForAnalytics,
      isEditMode,
      dish.dishPrice,
      dish.choices,
      initialValues.selectedChoices,
      shouldOpenModal,
    ],
  );

  const onRemoveDish = useCallback(() => {
    ManagerProvider.removeDish(shoppingCartDishId!);
    onDone();
    const dishDataForRemoveProduct = {...dishDataForAnalytics, productQuantity: dish.quantity};
    trackEvent('hasRemovedFromCart', dishDataForRemoveProduct);
  }, [onDone, shoppingCartDishId, dishDataForAnalytics, dish.quantity]);

  if (dish === EMPTY_DISH) {
    return <DishNotFound onClose={onDone} />;
  }

  return (
    <Form
      initialValues={initialValues}
      validate={({choices, selectedChoices}) => validateForceChoice(choices, selectedChoices)}
      onSubmit={onSubmit}
      render={({handleSubmit: onAddToCart}) => (
        <ModalRoot>
          <Header
            name={dish.dishName}
            description={dish.dishDescription}
            image={dish.dishImageUrl}
            isEditMode={isEditMode}
            ageRestricted={dish.ageRestricted}
            pricePerMeasurementUnit={dish.pricePerMeasurementUnit}
            measurementUnitScale={dish.measurementUnitScale}
            measurementUnit={dish.measurementUnit}
          />

          <PickingOptions choices={dish.choices} selectedChoices={dish.selectedChoices} />

          <Footer
            enableComment={dish.enableComment}
            isEditMode={isEditMode}
            onAddToCart={onAddToCart}
            onRemoveDish={onRemoveDish}
          />
        </ModalRoot>
      )}
    />
  );
};

export default Dish;
