import evaluateIdHashLogic from './evaluateIdHashLogic';
import {logger} from './logger';
import {FeatureConfig} from './config';
import {Context} from './settings';

export const determineVariant = (feature: FeatureConfig, context: Context) => {
  if (!feature.evaluationLogic) return null;

  try {
    const {type} = feature.evaluationLogic;
    if (type === 'IdHash') return evaluateIdHashLogic(feature.evaluationLogic.detail, context);
  } catch (error: unknown) {
    const message = error instanceof Error ? error.message : 'Unknown error';
    logger.logError(message, {error});
  }
  return null;
};
