import BarCodeCouponComponent from '~/common/components/CouponBarCodeComponent';
import {InfoModal} from '~/shared/components/Modals';
import {attachModal} from '~/shared/components/ReduxModal';

const ShowBarCodeAvoucherModal = ({args}) => {
  const {data, restaurantName} = args;
  const barCodeData = data?.vouchers[0];
  return (
    <InfoModal header="">
      <BarCodeCouponComponent restaurantName={restaurantName} barCode={barCodeData} />
    </InfoModal>
  );
};

attachModal('showBarCodeAvoucherModal', ShowBarCodeAvoucherModal);
