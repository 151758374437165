import styled from 'styled-components';

import {Link} from '~/shared/router';
import {body14Normal} from '~/shared/theme/typography';
import {clickOnEnterProps} from '~/shared/utils/general';

const linkTextAttrs = () => ({tabIndex: 0, ...clickOnEnterProps});
export const LinkText = styled(Link).attrs(linkTextAttrs)`
  ${body14Normal}
  line-height: 1.25em;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  cursor: pointer;
`;

export const TextWithPreBullet = styled.div<{preBullet?: boolean}>`
  ${({preBullet, theme}) => preBullet && `
    &:before {
      content: '•';
      width: 2px;
      height: 2px;
      margin: 0 4px;
      color: ${theme.colors.gray300};
    }
  `}
`;
