import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {createLogger} from '~/shared/logging';
import actions from '~/shared/store/actions';
import store from '~/shared/store';
import apiService, {ApiResponse} from '~/shared/services/apiService';
import {selectAvailablePayments, selectUserData} from '~/shared/store/selectors';
import {getLocalizationService} from '~/shared/services/localisationService';
import {is401Error} from '~/shared/services/apiErrorService';

import {handleRefreshToken} from './auth';

const logger = createLogger('payPalHelper');
const {t} = getLocalizationService();

export const onPayPalAuthorized = async ({payload, deviceData, onError, onSuccess}: OnPayPalAuthorizedPayload) => {
  const {firstName, lastName, email, payerId} = payload.details;
  const {nonce} = payload;

  logger.log('paypal authorization completed', {payload, deviceData});

  try {
    await apiService.insertPayPalUserToken({
      deviceData,
      paymentMethodNonce: nonce,
      payPalFirstName: firstName,
      payPalLastName: lastName,
      payPalPayerId: payerId,
      payPalUserEmail: email,
    });

    await store.dispatch(actions.getAvailablePayments());

    const newAvailablePayments = selectAvailablePayments(store.getState());
    const userData = selectUserData(store.getState());

    const availablePaypalPayment = newAvailablePayments?.find(
      p => p.paymentMethod.toLocaleLowerCase() === PaymentMethodTypes.PAYPAL.toLocaleLowerCase(),
    );

    if (availablePaypalPayment) {
      if (!userData?.isCompanyUser) {
        const paymentsToRemove = newAvailablePayments?.filter(({isTenbisCredit}) => !isTenbisCredit);
        paymentsToRemove?.forEach(paymentToRemove => {
          store.dispatch(actions.removePayment(paymentToRemove.cardId));
        });
      }

      store.dispatch(
        actions.addPayment({...availablePaypalPayment, assigned: true, isDisabled: false, editMode: false}),
      );
    }

    onSuccess?.();
  } catch (error: any) {
    if (is401Error(error)) {
      await handleRefreshToken(error, onPayPalAuthorized, {payload, deviceData, onError, onSuccess});
      return;
    }
    
    onError?.(error?.message || t('paypal_error'));
  }
};

interface OnPayPalAuthorizedPayload {
  payload: {
    details: {
      firstName: string;
      lastName: string;
      email: string;
      payerId: string;
      countryCode: string;
    };
    nonce: string;
  };
  deviceData: string;
  onError?: (error: ApiResponse<string> | string) => void;
  onSuccess?: () => void;
}
