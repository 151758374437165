import type {FeatureManager} from './featureManager';
import {Features} from './config';

export class ExperimentObserver {
  private readonly fm: FeatureManager;

  constructor(featureManager: FeatureManager) {
    this.fm = featureManager;
  }

  peekExperiments(): Features {
    const results: Features = [];

    Object.keys(this.fm.featureLookup.getFeatureKeys())
      .sort()
      .forEach(qualifiedKey => {
        const keyWithoutScope = qualifiedKey.replace(`${this.fm.settings.keyPrefix}::`, '');
        results.push({key: qualifiedKey, value: this.fm.getValue(keyWithoutScope, [], false)});
      });

    return results;
  }
}
