import {useState, useCallback} from 'react';

import {isFunction} from 'lodash';

const useToggle = (value?: boolean) => {
  const [state, setState] = useState(isFunction(value) ? () => value() : value);
  const toggle = useCallback(() => setState((currentState: boolean) => !currentState), []);
  return [state, toggle];
};

export default useToggle;
