import {useSelector} from 'react-redux';

import {selectUserDevice} from '~/shared/store/selectors';
import {UserDeviceState} from '~/shared/store/storeModules/userDevice/userDeviceReducer';

const useUserDeviceInfo = <K extends keyof UserDeviceState>(info: K) => useSelector(selectUserDevice)[info];

export const useIsMinLargeMobile = () => useUserDeviceInfo('isMinLargeMobile');
export const useIsMaxLargeMobile = () => useUserDeviceInfo('isMaxLargeMobile');

export const useIsMinLargeDesktop = () => useUserDeviceInfo('isMinLargeDesktop');
export const useIsMaxLargeDesktop = () => useUserDeviceInfo('isMaxLargeDesktop');

export const useIsMinSmallMobile = () => useUserDeviceInfo('isMinSmallMobile');
export const useIsMaxSmallMobile = () => useUserDeviceInfo('isMaxSmallMobile');

export const useIsMinMobile = () => useUserDeviceInfo('isMinMobile');
export const useIsMaxMobile = () => useUserDeviceInfo('isMaxMobile');

export const useIsMaxTablet = () => useUserDeviceInfo('isMaxTablet');
export const useIsMinTablet = () => useUserDeviceInfo('isMinTablet');

export const useIsMinLargeTablet = () => useUserDeviceInfo('isMinLargeTablet');
export const useIsMaxLargeTablet = () => useUserDeviceInfo('isMaxLargeTablet');

export const useIsMinDesktop = () => useUserDeviceInfo('isMinDesktop');
export const useIsMaxDesktop = () => useUserDeviceInfo('isMaxDesktop');

export const useIsEdge = () => useUserDeviceInfo('isEdge');
export const useIsSafari = () => useUserDeviceInfo('isSafari');

export const useIsIOS = () => useUserDeviceInfo('isIOS');
export const useIsAndroid = () => useUserDeviceInfo('isAndroid');
export const useIsFireFox = () => useUserDeviceInfo('isFirefox');
export const useIsMobileDevice = () => useUserDeviceInfo('isMobileDevice');
