import {forwardRef, HTMLAttributes, memo, MutableRefObject} from 'react';

import styled from 'styled-components';

import {createLogger} from '~/shared/logging';

const logger = createLogger('Image');

const minimalGifSrc = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

// eslint-disable-next-line no-restricted-properties
const Img = styled.img<{ref: MutableRefObject<unknown>}>``;

type ImageWithAltProps = AltOptions & {
  src?: string;
  loading?: HTMLImageElement['loading'];
  displayName?: string;
  width?: number;
  height?: number;
} & Partial<HTMLAttributes<HTMLImageElement>>;
type AltOptions = {noAlt: true; alt?: never} | {alt: string; noAlt?: never};

const ImageWithAlt = forwardRef(
  ({src, alt, noAlt, displayName, ...otherProps}: Readonly<ImageWithAltProps>, ref) => {
    if (!alt && !noAlt) {
      logger.errorDialog('no alt was provided to ImageWithAlt, use noAlt attr incase of decorative images.', {
        src,
        alt,
        noAlt,
        displayName,
        ...otherProps,
      });
    }

    return <Img {...otherProps} alt={noAlt ? '' : alt} src={src || minimalGifSrc} ref={ref as MutableRefObject<any>} />;
  },
);

export default memo(ImageWithAlt);
