import {ThreeDSMessage} from '~/common/types/checkout';
import {ThreeDsAnalyticsPayload} from '~/shared/services/analytics/analyticsModels';
import {trackEvent} from '~/shared/services/analytics';
import {Payment, RestaurantFromGet} from '~/shared/store/models';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';

export const THREE_DS_EVENTS_MAP = {
  success: (payload: ThreeDsAnalyticsPayload) => trackEvent('dsChallenge_success', payload),
  failure: (payload: ThreeDsAnalyticsPayload) => trackEvent('dsChallenge_failed', payload),
  fired: (payload: ThreeDsAnalyticsPayload) => trackEvent('dsChallenge_received', payload),
};

type GetThreeDsAnalyticsPayloadArgs = {
  currentRestaurant?: RestaurantFromGet;
  payments: Payment[];
  threeDsResponse?: ThreeDSMessage;
  customError?: string;
};

const getThreeDsAnalyticsPayload = ({
  currentRestaurant,
  payments,
  threeDsResponse,
  customError,
}: GetThreeDsAnalyticsPayloadArgs): ThreeDsAnalyticsPayload => ({
  businessType: currentRestaurant?.businessType,
  transactionAmount: payments.find(payment => payment.paymentMethod === PaymentMethodTypes.CREDIT_CARD)?.sum,
  errorMessage: [threeDsResponse?.cardHolderInfo, threeDsResponse?.z3, customError].filter(Boolean) as string[],
});

const trackThreeDsEvent = (event: keyof typeof THREE_DS_EVENTS_MAP,
  {customError, threeDsResponse, currentRestaurant, orderFailurePayments}: {
    customError?: string;
    threeDsResponse?: ThreeDSMessage;
    currentRestaurant?: RestaurantFromGet;
    orderFailurePayments: Payment[];
  },
) => {
  const analyticsMetaData = getThreeDsAnalyticsPayload({
    customError,
    currentRestaurant,
    payments: orderFailurePayments,
    threeDsResponse,
  });
  THREE_DS_EVENTS_MAP[event](analyticsMetaData);
};

export default trackThreeDsEvent;
