import store from '~/shared/store/initStore';
import initLocalisationService, {getLocalizationService} from '~/shared/services/localisationService';
import {init as initRouter} from '~/shared/router';
import {setCurrentLanguageCookie} from '~/shared/utils/cookies';
import {createLogger, initLogging} from '~/shared/logging';
import * as epics from '~/shared/epics';
import {getUserDevice} from '~/shared/utils/userAgent';
import actions from '~/shared/store/actions';

const logger = createLogger('init');

initLogging();

const i18n = initLocalisationService();

const {currentCulture, currentLanguageKey} = getLocalizationService();
setCurrentLanguageCookie({culture: currentCulture, languageKey: currentLanguageKey});
logger.verbose('selected initial language', {language: currentLanguageKey, currentCulture});

initRouter({currentLanguageKey});

Object.values(epics).forEach(initEpic => initEpic());

const initList = [
  store.dispatch(actions.getCuisines()),
  new Promise(resolve => i18n.on('loaded', resolve)),
  new Promise(resolve => {
    const userDevice = getUserDevice();
    store.dispatch(actions.setUserDevice(userDevice));
    resolve();
  }),
];

export const onInitPromise = Promise.all(initList);
