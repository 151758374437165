import {memo} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {flexCenterHorizontally} from '~/shared/theme/FlexLayout';
import {InnerWidthContainer} from '~/shared/components/styled';
import {media} from '~/shared/theme/utils';
import {selectIsActiveBannersShown} from '~/shared/store/selectors';

import FooterSection from './FooterSection';
import footerSectionData from './footerSectionData.json';

const Root = styled.footer.attrs({
  className: 'darkCover',
})`
  padding: 16px 4px;
  width: 100%;
  display: flex;
  background-color: ${({theme}) => theme.footer.color.backgroundColor};
  z-index: 1;
  min-height: ${({theme}) => theme.footer.height}px;
  ${({isBannersShown}) => isBannersShown && 'padding-bottom: 80px;'}
  ${media.minDesktop`
    ${flexCenterHorizontally};
    display: block;
    ${({isBannersShown}) => isBannersShown && 'padding-bottom: 68px;'}
  `}
`;

const FooterSectionWrapper = styled.section`
  display: flex;
  flex: 1;
  min-width: 40%;
  ${media.minLargeTablet`
    min-width: ${({minWidth}) => (minWidth ? `${minWidth}px` : 'auto')};
  `}
`;

const Spacer = styled.div`
  display: none;
  width: 1px;
  min-width: 1px;
  height: 100%;
  background-color: ${({theme}) => theme.colors.surface};
  margin: 0 16px;
  ${media.minLargeTablet`
    display: block;
  `}
`;

const FooterInnerWidthContainer = styled(InnerWidthContainer)`
  margin: 0 auto;
  overflow-y: visible;
  flex-wrap: wrap;
  padding: 0 16px;
  justify-content: space-between;
  ${media.minLargeTablet`
    padding: 0;
    flex-wrap: nowrap;
  `}
`;

const PageFooter = () => {
  const isBannersShown = useSelector(selectIsActiveBannersShown);
  return (
    <Root isBannersShown={isBannersShown}>
      <FooterInnerWidthContainer>
        {footerSectionData.map((section, idx) => (
          <FooterSectionWrapper key={section.title} minWidth={section.minWidth}>
            <FooterSection {...section} />
            {idx !== footerSectionData.length - 1 && <Spacer />}
          </FooterSectionWrapper>
        ))}
      </FooterInnerWidthContainer>
    </Root>
  );
};

export default memo(PageFooter);
