import moment from 'moment-timezone';

import {PoolDeliveryTime} from '~/shared/store/models/Restaurant/RestaurantFromGet';
import {getPooledEstimationTimes} from '~/shared/utils/pooledGroupTimesInfo';

export const getAsapDeliveryTimeRanges = (deliveryTimeInMinutes: number): {
  timeFrom: number;
  timeTo: number;
} => ({
  timeFrom: deliveryTimeInMinutes - 10,
  timeTo: deliveryTimeInMinutes,
});

export const getEstimatedPooledDeliveryTimeRangeString = (
  estimatedPooledDelivery: PoolDeliveryTime,
): string => {
  const pooledDeliveryTime = moment().hour(estimatedPooledDelivery.hours).minute(estimatedPooledDelivery.minutes);
  return getPooledEstimationTimes(pooledDeliveryTime).estimatedPoolDeliveryTime;
};
