import {useEffect, useState, memo, useCallback} from 'react';

import {useDispatch} from 'react-redux';

import {LoginBody} from '~/shared/components/loginStyled';
import {getLocalizationService} from '~/shared/services/localisationService';
import VerificationForm from '~/shared/components/VerificationForm';
import {VerificationFormContextProvider} from '~/common/contextProviders/VerificationFormContextProvider';
import {clearUserErrors} from '~/shared/store/actions';

import {
  onForgotPassword,
  onSubmitPassword,
  onSubmitSignUpVerification,
  onSubmitVerification,
  onSignUpSubmit,
  toStepVerificationMode,
} from './utils/facebookLoginOrSignUpHelper';
import SignUpForm from './SignUpForm';
import {LoginProvider, AuthFlowStep, ViewModes} from './consts';
import PasswordForm from './PasswordForm';
import LoginForm from './LoginForm';

const ConnectProviderTo10bis = props => {
  const {email} = props;
  return <LoginForm {...props} initialValues={{email}} />;
};

const ProviderLogin = ({
  authFlowStep,
  setAuthFlowStep,
  verificationDetails,
  emailEntry,
  onSubmit,
  facebookUserAccessToken,
  facebookUserId,
  googleUserId,
  googleCode,
  setResErrors,
  resErrors,
  dispatch,
  setEmailEntry,
  setVerificationDetails,
  pageView,
}) => {
  const {t} = getLocalizationService();

  const headerText = verificationDetails?.isPhoneMethod
    ? t('we_sent_you_an_sms_with_code_to_this_number_xxx_withPrefix', {
      lastFourPhoneDigits: verificationDetails?.lastFourPhoneDigits,
    })
    : t('we_sent_you_an_email_with_code_to_verify');
  return (
    <>
      {authFlowStep === AuthFlowStep.OTP && (
        <VerificationFormContextProvider>
          <VerificationForm
            verificationDetails={verificationDetails}
            onReSendCode={() =>
              toStepVerificationMode({
                email: emailEntry,
                setEmailEntry,
                setVerificationDetails,
                setAuthFlowStep,
                setResErrors,
              })}
            onSubmit={({authenticationCode}) =>
              onSubmitVerification({
                onSubmit,
                authenticationCode,
                email: emailEntry,
                facebookUserAccessToken,
                facebookUserId,
                googleUserId,
                googleCode,
                authenticationToken: verificationDetails.authenticationToken,
              })}
            email={emailEntry}
            headerText={headerText}
            t={t}
            setResErrors={setResErrors}
            resErrors={resErrors}
          />
        </VerificationFormContextProvider>
      )}
      {authFlowStep === AuthFlowStep.PASSWORD && (
        <PasswordForm
          onBackClick={() => setAuthFlowStep(AuthFlowStep.OTP)}
          onSubmitLoginWithEmailAndPassword={({password}) =>
            onSubmitPassword({email: emailEntry, facebookUserAccessToken, facebookUserId, password, onSubmit})}
          emailEntry={emailEntry}
          onForgotPassword={() => onForgotPassword({email: emailEntry, dispatch})}
          t={t}
          pageView={pageView}
        />
      )}
      {authFlowStep === AuthFlowStep.INITIAL && (
        <>
          <ConnectProviderTo10bis
            t={t}
            resErrors={resErrors}
            setResErrors={setResErrors}
            email={emailEntry}
            onSubmitEmail={({email}) =>
              toStepVerificationMode({
                email,
                setAuthFlowStep,
                setEmailEntry,
                setVerificationDetails,
                setResErrors,
                t,
              })}
          />
        </>
      )}
    </>
  );
};

const ProviderSignUp = ({
  authFlowStep,
  setAuthFlowStep,
  verificationDetails,
  setVerificationDetails,
  signUpDetails,
  setSignUpDetails,
  setResErrors,
  closeModal,
  dispatch,
  resErrors,
  facebookUserAccessToken,
  facebookUserId,
  googleCode,
  openLoginScreen,
  emailEntry,
  fullNameEntry,
  isFixedEmail,
}) => {
  const {t} = getLocalizationService();

  return (
    <>
      {authFlowStep === AuthFlowStep.OTP ? (
        <VerificationFormContextProvider>
          <VerificationForm
            verificationDetails={verificationDetails}
            onReSendCode={() =>
              onSignUpSubmit({entries: signUpDetails, setSignUpDetails, setAuthFlowStep, setResErrors})}
            onSubmit={({authenticationCode}) =>
              onSubmitSignUpVerification({
                authenticationCode,
                setResErrors,
                signUpDetails: {...signUpDetails, googleCode},
                closeModal,
                dispatch,
              })}
            email={signUpDetails?.email}
            headerText={t('we_sent_you_an_sms_with_code_to_this_number_xxx', {phoneNumber: signUpDetails?.cellPhone})}
            setResErrors={setResErrors}
            resErrors={resErrors}
            toUpdatePhoneNumberScreen={() => {
              dispatch(clearUserErrors());
              setResErrors(null);
              setAuthFlowStep(AuthFlowStep.INITIAL);
            }}
          />
        </VerificationFormContextProvider>
      ) : (
        <>
          <SignUpForm
            onSubmit={entries => onSignUpSubmit({entries, setSignUpDetails, setAuthFlowStep, setResErrors})}
            sendActivationCodeError={resErrors}
            initialValues={{
              facebookUserAccessToken,
              facebookUserId,
              cellPhone: signUpDetails?.cellPhone,
              wantPromotion: true,
              email: emailEntry,
              fullName: fullNameEntry,
            }}
            existingEmailAction={email => openLoginScreen({
              email,
              setVerificationDetails,
            })}
            isFixedEmail={isFixedEmail}
          />
        </>
      )}
    </>
  );
};

const ProviderLoginOrSignUp = ({
  providerViewMode,
  facebookUserAccessToken,
  facebookUserId,
  googleCode,
  openLoginScreen,
  onSubmit,
  closeModal,
  pageView,
  email,
  fullName,
  loginProvider,
  authFlowStep,
  setAuthFlowStep,
  isRedirectedToStepRef,
  verificationDetails,
  setVerificationDetails,
}) => {
  const [emailEntry, setEmailEntry] = useState(email || '');
  const [resErrors, setResErrors] = useState();
  const [signUpDetails, setSignUpDetails] = useState();
  const dispatch = useDispatch();

  const isFixedEmail = loginProvider === LoginProvider.GOOGLE;

  const clearSteps = useCallback(() => {
    setAuthFlowStep(AuthFlowStep.INITIAL);
  }, [setAuthFlowStep]);

  const isLoginMode = providerViewMode === ViewModes.SIGN_IN_MODE;

  useEffect(() => {
    if (isRedirectedToStepRef.current) {
      isRedirectedToStepRef.current = false;
      return;
    }

    setResErrors(null);
    clearSteps();
  }, [isLoginMode, clearSteps, isRedirectedToStepRef]);

  return (
    <LoginBody pageView={pageView}>
      {isLoginMode ? (
        <ProviderLogin
          {...{
            verificationDetails,
            emailEntry,
            onSubmit,
            facebookUserAccessToken,
            facebookUserId,
            googleCode,
            setResErrors,
            resErrors,
            authFlowStep,
            setAuthFlowStep,
            dispatch,
            setEmailEntry,
            setVerificationDetails,
            pageView,
          }}
        />
      ) : (
        <ProviderSignUp
          {...{
            authFlowStep,
            setAuthFlowStep,
            verificationDetails,
            setVerificationDetails,
            signUpDetails,
            setSignUpDetails,
            setResErrors,
            closeModal,
            dispatch,
            resErrors,
            facebookUserAccessToken,
            facebookUserId,
            googleCode,
            openLoginScreen,
            emailEntry,
            fullNameEntry: fullName,
            isFixedEmail,
          }}
        />
      )}
    </LoginBody>
  );
};

export default memo(ProviderLoginOrSignUp);
