import styled from 'styled-components';

import {media} from '~/shared/theme/utils';
import Input from '~/common/components/Input';
import {Fieldset, SharedCheckoutCloseButton} from '~/shared/components/styled';
import {body11Normal, body14Normal} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {Link} from '~/shared/router';

export const CloseButton = styled(SharedCheckoutCloseButton)`
  height: 20px;
`;

export const RootFieldset = styled(Fieldset)`
  ${flexColumn};
  margin-top: 4px;
`;

export const RulesLink = styled(Link)`
  ${body11Normal};
  line-height: 16px;
`;

export const TipInput = styled(Input)`
  display: flex;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  border-radius: 0;
  text-align: center;
  padding: 0;
  height: ${({theme}) => theme.checkout.elements.tipInput.height};
  width: 134px;
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    height: 32px;
  `}

  &&& {
    ${body14Normal};
  }

  ::placeholder {
    text-align: center;
    color: ${({theme}) => theme.colors.text};
  }

  &:focus {
    border: 1px solid ${({theme}) => theme.colors.text};
  }

  ${media.minLargeTablet`
    padding: 0 8px;  
    text-align: center;
  `}

  ${media.maxMobile`
    width: 73px;
  `}
`;
