import {throttle, get} from 'lodash';
import moment from 'moment-timezone';
import {Middleware} from 'redux';

import {createLogger} from '~/shared/logging';

import {AppState} from '../configStore';

import persistStoreConfig, {PersistentStoreSavedScopes} from './persistStoreConfig';
import {mergeStorePersistDataAction} from './persistStore';

const logger = createLogger('persistStoreMiddleware');

const {localStorageKey, scopes} = persistStoreConfig;

const latestPersistStore: PersistentStoreSavedScopes = {};

try {
  Object.assign(latestPersistStore, JSON.parse(window.localStorage.getItem(localStorageKey) as string));
} catch (e) {
  logger.warn('persist storage error', {e});
}

const scopesArray = Object.entries(scopes).map(([scopeKey, scope]) => {
  return {
    scope,
    scopeKey,
  };
});

const dumpPersistStoreThrottled = throttle(() => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(latestPersistStore));
}, 1000);

window.addEventListener('unload', () => {
  dumpPersistStoreThrottled.flush();
});

const persistStoreMiddleware: Middleware<never, AppState> =
  ({getState}) =>
    next =>
      action => {
        if (action.type === mergeStorePersistDataAction.TYPE) {
          return next(action);
        }

        const stateBeforeAction = getState();
        const result = next(action);
        const stateAfterAction = getState();

        scopesArray.forEach(({scopeKey, scope: {path: scopePath}}) => {
          const oldData = get(stateBeforeAction, scopePath);
          const newData = get(stateAfterAction, scopePath);

          if (oldData !== newData) {
            latestPersistStore[scopeKey] = {
              timeStamp: moment().utc().format(),
              data: newData,
            };
            dumpPersistStoreThrottled();
          }
        });

        return result;
      };

export default persistStoreMiddleware;
