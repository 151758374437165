import {HTMLAttributes, useCallback, useEffect, useRef, useState} from 'react';

import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import TriangleIcon from '~/assets/images/svgIconsAsText/triangle.svg?react';
import {flexBottomVertically, FlexCenter} from '~/shared/theme/FlexLayout';

import {body9Normal} from '../theme/typography';

const Root = styled.div<{hasError: boolean}>`
  position: relative;
  height: 48px;
  min-height: 48px;
  background-color: ${({theme}) => theme.colors.surface};
  ${flexBottomVertically};
  ${({hasError, theme}) =>
    hasError &&
    `
    border: solid 1px ${theme.colors.error};
  `}
`;

const InnerSelect = styled.select<{innerSelectPaddingTop: number}>`
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-top: ${({innerSelectPaddingTop}) => `${innerSelectPaddingTop}px`};
  padding-bottom: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: ${({theme}) => theme.colors.surface};
  ${flipOnLTR`
    padding-right: 8px;
    padding-left: 30px;
    text-align: right;
  `};

  &&& {
    color: ${({theme}) => theme.colors.secondary};
    font-size: 13px;
    line-height: 13px;
  }
`;

const SelectPlaceholder = styled.div<{hasValue: boolean}>`
  position: absolute;
  bottom: auto;
  top: 5px;
  z-index: 1;
  display: ${({hasValue}) => (hasValue ? 'inherit' : 'none')};
  pointer-events: none;
  ${body9Normal};
  line-height: 13px;
  color: ${({theme}) => theme.colors.text};
  ${flipOnLTR`
    right: 8px;
    text-align: right;
  `}
`;

const TriangleWrapper = styled(FlexCenter)`
  background-color: ${({theme}) => theme.colors.surface};
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 30px;
  ${flipOnLTR`
    left: 0;
  `}
`;

const Triangle = styled(TriangleIcon)`
  width: 8px;
  height: 4px;
`;

export interface ISelect {
  hasError: boolean;
  options: {value: string; text: string}[];
  value: any;
  placeholder: string;
  name: string;
  onChange: (value: string) => void;
  required: boolean;
  rest?: HTMLAttributes<HTMLSelectElement>[];
}

const Select = ({options, value, placeholder, name, onChange, hasError, required, ...rest}: ISelect) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const [innerSelectPaddingTop, setInnerSelectPaddingTop] = useState(0);
  const label = `${required ? '* ' : ''}${placeholder || name}`;

  const getRootHeight = useCallback(() => {
    const {height}: {height?: number} = rootRef.current ? rootRef.current.getBoundingClientRect() : {};
    return height;
  }, [rootRef]);

  useEffect(() => {
    const rootHeight: number | undefined = getRootHeight();
    if (rootHeight) {
      setInnerSelectPaddingTop(rootHeight - 13 - 8);
    }
  }, [getRootHeight]);

  return (
    <Root ref={rootRef} hasError={hasError}>
      <InnerSelect {...{value, innerSelectPaddingTop, required, ...rest}} onChange={e => onChange(e.target.value)}>
        <option value="initial-disabled" hidden>
          {label}
        </option>
        {options?.map(({value: optionValue, text: optionLabel}: {value: string; text: string}) => {
          return (
            <option key={optionValue} value={optionValue}>
              {optionLabel}
            </option>
          );
        })}
      </InnerSelect>
      <SelectPlaceholder hasValue={!!value}>{label}</SelectPlaceholder>
      <TriangleWrapper>
        <Triangle />
      </TriangleWrapper>
    </Root>
  );
};

export default Select;
