import styled from 'styled-components';

import {flipOnLTR, media} from '~/shared/theme/utils';
import {Link} from '~/shared/router';
import Button from '~/shared/components/Button';
import FormField from '~/shared/components/FormField';
import ErrorBox from '~/shared/components/ErrorBox';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {body10Bold, body13Normal, body14Bold} from '~/shared/theme/typography';

export const ResetPasswordErrorBox = styled(ErrorBox)`
  margin-bottom: 20px;
`;

export const SubmitButton = styled(Button)`
  ${body14Bold};
  height: 48px;
  min-height: 48px;
  margin-bottom: 10px;
  width: 100%;
  background-color: ${({theme}) => theme.actionButton.enabled.backgroundColor};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  ${media.minLargeTablet`
    width: 300px;
    margin: 0 auto;
  `}
`;

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  margin-top: 100px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  padding: 10px;
`;

export const InputField = styled(FormField)<{hidden?: boolean}>`
  ${({hidden}) => hidden && 'display: none;'}
  ${media.minMobile`
    flex-basis: calc(50% - 15px);
    flex-grow: 0;
    ${flipOnLTR`
      margin-left: 15px;
    `}
  `}
  height: auto;
`;

export const InputsContainer = styled.div`
  ${flexColumn};
  height: 100%;
  ${media.minMobile`
    flex-flow: row wrap;
  `}
`;

export const FormContainer = styled.form`
  ${flexColumn};
  height: 100%;
  margin-bottom: 10px;
`;

export const RequiredFieldInfo = styled.p`
  ${body10Bold};
  line-height: 18px;
  color: ${({theme}) => theme.modal.textColor};
  margin: 5px 0;
  ${flipOnLTR`
    text-align: right;
  `}
`;

export const LogoutLink = styled(Link)`
  cursor: pointer;
  ${body13Normal};
  line-height: 22px;
  text-align: center;
  text-decoration: underline;
  color: ${({theme}) => theme.updateUserDetails.logoutTextColor};
  margin-top: 25px;
`;

export const MessageContainer = styled.div`
  ${flipOnLTR`
    text-align: right;
  `}
  margin-bottom: 20px;
`;

export const MessageHeader = styled.div`
  font-weight: bold;
`;

export const MessageText = styled.div`
  overflow: auto;
  ${media.minLargeTablet`
    height: 100px;
  `}
`;

export const VerificationWrapper = styled.div`
  margin: 30px auto;
  width: 100%;
`;
