import {ComponentType, ReactNode, memo, useContext, useState} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import Button from '~/shared/components/Button';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {FutureOrderContext} from '~/shared/components/FutureOrder/context';
import {getAsapDeliveryTimeRanges} from '~/shared/utils/restaurants';
import {MutatedFutureOrderAvailableDatesAndTimes, OrderDateAndTime} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {OnSelectTimeProps} from '~/common/FutureOrderContextProvider';
import {trackEvent} from '~/shared/services/analytics';
import {getOrderTypeString, getFormatedDate} from '~/shared/services/analytics/utils';

import {StyledArrow} from './Shared';
import SingleRuleETABox from './SingleRuleETABox';
import TimeDropdown from './TimeDropdown';

const Root = styled.div`
  width: 100%;
  position: relative;
`;

const ContentLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapseButton = styled(Button)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 35px;
`;

const Label = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.surfaceTitle};
`;

const ArrowContainer = styled.div<{showTimeDropDown: boolean}>`
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.25s linear;
  transform: rotate(${({showTimeDropDown}) => (showTimeDropDown ? 0 : 180)}deg);
  ${flipOnLTR`
    margin-right: 6px;
  `}
`;

const StyledTimeDropdown = styled(TimeDropdown)<{openToTop?: boolean}>`
  ${({openToTop}) => (openToTop ? 'bottom: 100%;' : 'top: calc(100%-16px);')}
`;

export interface SDFOBoxMwProps {
  isActive?: boolean;
  children: ReactNode;
  onClick: () => void;
  disabled?: boolean;
}

const SameDayFutureOrder = ({hideTitle, isCheckout, initiateWith, openToTop, inline}: {
  inline?: boolean;
  isCheckout?: boolean;
  hideTitle?: boolean;
  openToTop?: boolean;
  initiateWith?: {
    onSelectTime?: (args: OnSelectTimeProps) => void;
    orderDateAndTime?: OrderDateAndTime;
    futureOrderAvailableDatesAndTimes?: MutatedFutureOrderAvailableDatesAndTimes[];
    RuleBoxComponent?: ComponentType<SDFOBoxMwProps>;
    disuseAnalytics?: boolean;
  };
}) => {
  const [showTimeDropDown, setShowTimeDropdown] = useState(false);
  const {t} = getLocalizationService();
  const {
    onSelectTime: contextOnSelectTime,
    futureOrderAvailableDatesAndTimes: contextFutureOrderAvailableDatesAndTimes,
    currentRestaurant,
    currentOrderDateAndTime: contextOrderDateAndTime,
    currentDeliveryRule,
    currentDeliveryMethod,
    isASAP,
    isBenefitsHighlightEnabled,
  } = useContext(FutureOrderContext);

  const onSelectTime = initiateWith?.onSelectTime || contextOnSelectTime;
  const currentOrderDateAndTime = initiateWith?.orderDateAndTime || contextOrderDateAndTime;
  const futureOrderAvailableDatesAndTimes = initiateWith?.futureOrderAvailableDatesAndTimes || contextFutureOrderAvailableDatesAndTimes;
  const deliveryTimeRange = t('delivered_in_xxx', getAsapDeliveryTimeRanges(currentRestaurant?.asapDeliveryTimeInMinutes || 0));

  const handleCollapeClick = () => {
    if (inline) {
      return;
    }

    setShowTimeDropdown(prevState => {
      if (!prevState && !initiateWith?.disuseAnalytics) {
        trackEvent('nlxOrderType_click', {
          pageSource: isCheckout ? 'checkout' : 'menu',
          original_orderType: getOrderTypeString(currentDeliveryMethod, currentDeliveryRule),
        });
      }
      return !prevState;
    });
  };

  const closeDropdown = () => {
    if (showTimeDropDown) {
      setShowTimeDropdown(false);
    }
  };

  const handleTimeSelection = (props: MutatedFutureOrderAvailableDatesAndTimes['times'][number], newDeliveryFee?: number) => {
    setShowTimeDropdown(false);
    onSelectTime({props, deliveryTimeRange, newDeliveryFee});

    if (!initiateWith?.disuseAnalytics) {
      const isAsapSelected = (props.timeKey === t('asap') || props.timeKey === 'ASAP');
      trackEvent('nlxOrderType_select', {
        selected_orderType: isAsapSelected ? 'asap' : 'future',
        original_orderType: isASAP ? 'asap' : 'future',
        pageSource: isCheckout ? 'checkout' : 'menu',
        futureOrderTime: isAsapSelected ? undefined : getFormatedDate(currentOrderDateAndTime.orderDate, props),
        delivery_price: newDeliveryFee?.toString(),
      });
    }
  };

  if (!futureOrderAvailableDatesAndTimes?.[0]?.times?.[0]) {
    return null;
  }

  return (
    <Root>
      {initiateWith?.RuleBoxComponent ? (
        <initiateWith.RuleBoxComponent isActive={showTimeDropDown} onClick={handleCollapeClick} disabled={Boolean((futureOrderAvailableDatesAndTimes?.[0]?.times.length || 0) <= 1)}>
          {initiateWith?.orderDateAndTime?.orderTime?.timeKeyLabel}
        </initiateWith.RuleBoxComponent>
      ) : (
        <ContentLine>
          <SingleRuleETABox
            inline={inline}
            isCheckout={isCheckout}
            hideTitle={hideTitle}
          />
          <CollapseButton onClick={handleCollapeClick} className="toggle_sdfo_dropdown">
            {!inline && (
            <Label className="toggle_sdfo_dropdown">
              {showTimeDropDown ? t('done') : t('schedule')}
            </Label>
          )}
            <ArrowContainer showTimeDropDown={!showTimeDropDown} className="toggle_sdfo_dropdown">
              <StyledArrow inline={inline} className="toggle_sdfo_dropdown"/>
            </ArrowContainer>
          </CollapseButton>
        </ContentLine>
      )}
      {showTimeDropDown && (
        <StyledTimeDropdown
          openToTop={openToTop}
          deliveryTimeRange={deliveryTimeRange}
          currentTimeKey={currentOrderDateAndTime?.orderTime?.timeKey}
          futureOrderAvailableDatesAndTimes={futureOrderAvailableDatesAndTimes}
          deliveryRules={currentRestaurant?.deliveryRules}
          onClick={handleTimeSelection}
          onClickOutside={closeDropdown}
          isBenefitsHighlightEnabled={isBenefitsHighlightEnabled}
        />
      )}
    </Root>
  );
};

export default memo(SameDayFutureOrder);
