import styled, {css} from 'styled-components';

export const flexColumnCenterHorizontally = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlexColumnCenterHorizontally = styled.div`
  ${flexColumnCenterHorizontally};
`;
