import {RestaurantsFiltersSortOptionsType} from '~/shared/consts/restaurantConsts';

import {Address} from '../../models';
import {makeActionCreator} from '../../redux-toolbelt';

import {UiState} from './uiReducer';

export const showOrderHistoryToggle = makeActionCreator<'showOrderHistoryToggle', boolean>('showOrderHistoryToggle');

export const toggleRestaurantsFiltersFullScreen = makeActionCreator<'toggleRestaurantsFiltersFullScreen', never>(
  'toggleRestaurantsFiltersFullScreen',
);
export const setEditedAddress = makeActionCreator<'setEditedAddress', Address>('setEditedAddress');

export const setAddressView =
  makeActionCreator<'setAddressView', UiState['addressDropdown']['addressView']>('setAddressView');
export const setSearchMode = makeActionCreator<'setSearchMode', boolean>('setSearchMode');

export const setRestaurantsFilter =
  makeActionCreator<'setRestaurantsFilter', SetRestaurantsFilterPayload>('setRestaurantsFilter');
export const resetRestaurantsFilter = makeActionCreator<'resetRestaurantsFilter', void>('resetRestaurantsFilter');

export const setCuisineFilter = makeActionCreator<'setCuisineFilter', string>('setCuisineFilter');
export const setCuisinesFilter = makeActionCreator<'setCuisinesFilter', string[]>('setCuisinesFilter');
export const setShowStoresMobileFilter = makeActionCreator<'setShowStoresMobileFilter', boolean>('setShowStoresMobileFilter');

export const setMobileMiniFeedFilterEnabled = makeActionCreator<'setMobileMiniFeedFilterEnabled', boolean>('setMobileMiniFeedFilterEnabled');

export const setPreferencesFilter =
  makeActionCreator<'setPreferencesFilter', keyof UiStateRestaurantsFilter>('setPreferencesFilter');
export const setRestaurantsSortBy =
  makeActionCreator<'setRestaurantsSortBy', RestaurantsFiltersSortOptionsType>('setRestaurantsSortBy');

export const setIsOrderTypeMenuOpen = makeActionCreator<'setIsOrderTypeMenuOpen', boolean>('setIsOrderTypeMenuOpen');
export const setShowSelectTimeErrorMessage = makeActionCreator<'setShowSelectTimeErrorMessage', boolean>('setShowSelectTimeErrorMessage');

type SetRestaurantsFilterPayload = UiStateRestaurantsFilter &
  Partial<{
    toggleFilterName: boolean;
    lastFilterTypeSelected: string;
    override: boolean;
  }>;

export const setIsActiveOrderBannerShown = makeActionCreator<
  'setIsActiveOrderBannerShown',
  UiState['isActiveOrderBannerShown']
>('setIsActiveOrderBannerShown');

type UiStateRestaurantsFilter = UiState['restaurants']['filter'];
