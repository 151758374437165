import {addClass, removeClass} from '~/shared/utils/general';
import store from '~/shared/store';
import {A11Y_LOCALSTORAGE_KEY_NAME} from '~/shared/consts/localStorageConsts';

export default function a11yEpic() {
  store.observe(
    {
      selector: state => state.a11y,
      runOnInitialState: false,
    },
    ({contrast, outline}) => {
      const bodyEl = document.body;

      if (contrast) {
        addClass(bodyEl, 'contrast-filter');
      } else {
        removeClass(bodyEl, 'contrast-filter');
      }

      if (outline) {
        addClass(bodyEl, 'outline-filter');
      } else {
        removeClass(bodyEl, 'outline-filter');
      }

      localStorage.setItem(
        A11Y_LOCALSTORAGE_KEY_NAME,
        JSON.stringify({constrastEnabled: contrast, outlineEnabled: outline}),
      );
    },
  );
}
