import {memo, useCallback, useEffect, useRef, useState} from 'react';

import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';

import {CentralLoading} from '~/shared/components/Loaders';
import AddCreditCard from '~/shared/components/AddCreditCard';
import apiService from '~/shared/services/apiService';
import {trackEvent} from '~/shared/services/analytics';
import {handleRefreshToken} from '~/shared/services/auth';
import {MoneycardOtlRuleManagement} from '~/shared/store/models';
import {AddCreditCardModes} from '~/shared/consts/checkoutConsts';
import {getAvailablePayments} from '~/shared/store/storeModules/payments/paymentsActions';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import {getMoneyCardAndCreditCardFromPayments, isAvailablePayments} from '~/shared/utils/payments';
import {MoneycardAndCreditcard, PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {createLogger} from '~/shared/logging';
import {is401Error} from '~/shared/services/apiErrorService';

import OtlRuleSettings from './OtlRuleSettings';

const logger = createLogger('OtlSectionsContainer');

enum OtlMode {
  ADD_CREDIT_CARD = 'addCreditCard',
  RULES_SETTINGS = 'ruleSettings'
}

const OTL_STEP_MODES = {
  [OtlMode.RULES_SETTINGS]: OtlRuleSettings,
  [OtlMode.ADD_CREDIT_CARD]: AddCreditCard,
};

const BACK_LINK_CLICK_HANDLER: Record<OtlMode, (options: {
  hasVisitedOtlRules: boolean;
  setOtlMode: (mode: OtlMode) => void;
  onClose?: () => void;
}) => void> = {
  [OtlMode.ADD_CREDIT_CARD]: ({hasVisitedOtlRules, setOtlMode, onClose}) => {
    if (hasVisitedOtlRules) {
      setOtlMode(OtlMode.RULES_SETTINGS);
    } else {
      if (onClose) {
        onClose();
      }
    }
  },
  [OtlMode.RULES_SETTINGS]: ({onClose}) => {
    if (onClose) {
      onClose();
    }
  },
};

type OtlSectionsContainerProps = {
  onClose?: () => void;
  onOtlSuccess?: () => void;
  orderCardId?: number;
  withoutHeader?: boolean;
  withoutCreditCardsList?: boolean;
  hideLimitations?: boolean;
};

const OtlSectionsContainer = ({
  onClose,
  onOtlSuccess,
  orderCardId,
  withoutHeader,
  withoutCreditCardsList,
  hideLimitations,
}: OtlSectionsContainerProps) => {
  const dispatch = useDispatch();
  const [otlMode, setOtlMode] = useState(OtlMode.RULES_SETTINGS);
  const [hasVisitedOtlRules, setHasVisitedOtlRules] = useState(false);
  const [otlData, setOtlData] = useState<MoneycardOtlRuleManagement | null>(null);
  const [selectedCreditCard, setSelectedCreditCard] = useState<number | null>(null);

  const [cardId, setCardId] = useState<number | undefined>(orderCardId);

  const paymentsRequestControlRef = useRef<{hasSentRequest: boolean}>({hasSentRequest: false});

  const getPayments = useCallback(async () => {
    const payments = await dispatch(getAvailablePayments());
    if (!isAvailablePayments(payments)) {
      logger.error('Unexpected error');
      navigateToDefaultStartPage();
      return;
    }

    const {Moneycard: moneycard, Creditcard: creditcard} = payments?.availablePayments.reduce<MoneycardAndCreditcard>(
      getMoneyCardAndCreditCardFromPayments,
      {[PaymentMethodTypes.MONEY_CARD]: null, [PaymentMethodTypes.CREDIT_CARD]: null},
    );

    if (!moneycard || !creditcard) {
      navigateToDefaultStartPage();
      return;
    }

    setCardId(moneycard.cardId);
  }, [dispatch]);

  const onAddCreditCardSuccess = async () => {
    try {
      const {data} = await apiService.getMoneycardOtlRuleManagement({moneycardId: cardId});
      const sortedCards = data?.userCreditcardList?.sort((a, b) => b.creditcardId - a.creditcardId);
      if (sortedCards) {
        setSelectedCreditCard(sortedCards[0].creditcardId);
      }
      setHasVisitedOtlRules(true);
      setOtlMode(OtlMode.RULES_SETTINGS);
      setOtlData(data);
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, onAddCreditCardSuccess);
      }
    }
  };

  const addCreditCard = useCallback(() => {
    setOtlMode(OtlMode.ADD_CREDIT_CARD);
    trackEvent('hasClickedAddCreditCard', {linkType: 'Payments Modal'});
  }, [setOtlMode]);

  const onBackLinkClick = useCallback(() => {
    BACK_LINK_CLICK_HANDLER[otlMode]({hasVisitedOtlRules, onClose, setOtlMode});
  }, [otlMode, hasVisitedOtlRules, onClose]);

  const ModeComponent = OTL_STEP_MODES[otlMode];
  const onSuccess = otlMode === OtlMode.RULES_SETTINGS ? onOtlSuccess : onAddCreditCardSuccess;

  useEffect(() => {
    if (!cardId) {
      if (!paymentsRequestControlRef.current.hasSentRequest) {
        paymentsRequestControlRef.current.hasSentRequest = true;
        getPayments();
      }
      return;
    }

    (async () => {
      const getMoneycardOtlRuleManagementData = async (): Promise<MoneycardOtlRuleManagement | null> => {
        try {
          const {data} = await apiService.getMoneycardOtlRuleManagement({moneycardId: cardId});
          return data;
        } catch (error) {
          if (is401Error(error)) {
            return handleRefreshToken(error, getMoneycardOtlRuleManagementData);
          }

          return null;
        }
      };

      const moneycardOtlRuleManagementData = await getMoneycardOtlRuleManagementData();

      if (moneycardOtlRuleManagementData) {
        let mode = OtlMode.ADD_CREDIT_CARD;
        if (!isEmpty(moneycardOtlRuleManagementData.userCreditcardList)) {
          mode = OtlMode.RULES_SETTINGS;
          setHasVisitedOtlRules(true);
        }
        setOtlMode(mode);
        setOtlData(moneycardOtlRuleManagementData);

        const {otlRuleData, userCreditcardList} = moneycardOtlRuleManagementData;
        const sortedCards = userCreditcardList?.sort((a, b) => b.creditcardId - a.creditcardId);
        const creditCard =
            otlRuleData?.ruleActive && otlRuleData?.creditcardId
              ? otlRuleData?.creditcardId
              : sortedCards && sortedCards[0]?.creditcardId;

        setSelectedCreditCard(creditCard);
      }
    })();
  }, [cardId, getPayments]);

  if (isEmpty(otlData)) {
    return <CentralLoading />;
  }
  return (
    <ModeComponent
      otlData={otlData}
      addCreditCard={addCreditCard}
      selectedCreditCard={selectedCreditCard}
      setSelectedCreditCard={setSelectedCreditCard}
      onClose={onClose}
      onSuccess={onSuccess}
      onBackLinkClick={onBackLinkClick}
      withoutHeader={withoutHeader}
      mode={AddCreditCardModes.MINIMAL}
      withoutCreditCardsList={withoutCreditCardsList}
      hideLimitations={hideLimitations}
    />
  );
};
export default memo(OtlSectionsContainer);
