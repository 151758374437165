import {createSelector} from 'reselect';

import {EMPTY_ARRAY} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

const selectMarketingBannersBase = (state: AppState) => state.marketingBanners;

export const selectMarketingBanners = createSelector(selectMarketingBannersBase, ({loading, data}) =>
  (loading || !data ? EMPTY_ARRAY : data),
);

export const selectMarketingBannersLoading = createSelector(
  selectMarketingBannersBase,
  marketingBanners => marketingBanners.loading,
);
export const selectMarketingBannersLoaded = createSelector(
  selectMarketingBannersBase,
  marketingBanners => marketingBanners.loaded,
);
