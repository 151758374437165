import {memo} from 'react';

import styled, {css} from 'styled-components';

import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {body16Bold} from '~/shared/theme/typography';
import BagIconComponent from '~/shared/components/DeliveryMethodPicker/assets/bagIcon.svg?react';
import BikeIconComponent from '~/shared/components/DeliveryMethodPicker/assets/bikeIcon.svg?react';
import {Theme} from '~/shared/theme';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR, rotateOnLTR} from '~/shared/theme/flipOnLTR';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';

const sharedIconStyles = css<{$isActive: boolean}>`
  path {
    fill: ${({theme, $isActive}: {theme: Theme; $isActive: boolean}) => ($isActive ? theme.colors.secondary : theme.colors.gray200)};
  }
`;

const StyledBagIconComponent = styled(BagIconComponent)<{$isActive: boolean}>`
  ${sharedIconStyles}
`;

const StyledBikeIconComponent = styled(BikeIconComponent)<{$isActive: boolean}>`
  ${rotateOnLTR}
  ${sharedIconStyles}
`;

const Root = styled.div`
  width: 100%;
  height: 36px;
  background-color: ${({theme}) => theme.colors.darkBackground};
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
`;

const Option = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  ${body16Bold}
`;

const Label = styled.span<{$isActive: boolean}>`
  color: ${({theme, $isActive}) => ($isActive ? theme.colors.secondary : theme.colors.text)};
`;

const HoverBubble = styled.div.attrs({className: 'white-focus'})<{$isDeliveryMethod: boolean}>`
  position: absolute;
  ${flipOnLTR`
    left: ${({$isDeliveryMethod}) => ($isDeliveryMethod ? '50%' : 0)};
  `}
  top: 0;
  width: calc(100% / 2);
  height: 100%;
  border-radius: 24px;
  transition: all 0.3s ease;
  box-shadow: ${({theme}) => theme.shadows.shadow3};
`;

const Container = styled.div<{$isActive: boolean}>`
  width: 100%;
  height: 100%;
  background-color: ${({$isActive, theme}) => ($isActive ? theme.colors.surface : 'transparent')};
  display: flex;
  ${({theme}) => convertGapToMargin('0 4px', theme.isLTR)}
  flex: 1;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  transition: all 0.5s ease;
`;

type PickerDeliveryMethods = DeliveryMethods.PICKUP | DeliveryMethods.DELIVERY;
// SERVING MW ONLY. SKIPPING A11Y RULES.

const DeliveryMethodPicker = (
  {deliveryMethod, onChange}:
  {
    deliveryMethod: PickerDeliveryMethods;
    onChange: (newDeliveryMethod: DeliveryMethods) => void;
  },
) => {
  const {t} = getLocalizationService();

  const isDeliveryActive = deliveryMethod === DeliveryMethods.DELIVERY;

  return (
    <Root>
      <Option onClick={() => onChange(DeliveryMethods.DELIVERY)}>
        <Container $isActive={isDeliveryActive}>
          <StyledBikeIconComponent $isActive={isDeliveryActive} />
          <Label $isActive={isDeliveryActive}>{t('delivery')}</Label>
        </Container>
      </Option>
      <Option onClick={() => onChange(DeliveryMethods.PICKUP)}>
        <Container $isActive={!isDeliveryActive}>
          <StyledBagIconComponent $isActive={!isDeliveryActive} />
          <Label $isActive={!isDeliveryActive}>{t('pickup')}</Label>
        </Container>
      </Option>
      <HoverBubble $isDeliveryMethod={isDeliveryActive} />
    </Root>
  );
};

export default memo(DeliveryMethodPicker);
