import {RestaurantFromGet, RestaurantFromSearch} from '~/shared/store/models';

export type DeliveryMessage = {
  menuCover: string;
  restListBox: string;
};

export type ClosedReasonsMap = Record<string, Record<string, DeliveryMessage>>;

export type GetClosedRestaurantMessageForDeliveryMode = (
  restaurant?: RestaurantFromSearch | RestaurantFromGet,
) => DeliveryMessage;

export const isRestaurantFromSearch = (
  restaurant: RestaurantFromGet | RestaurantFromSearch,
): restaurant is RestaurantFromSearch => 'isOpenNow' in restaurant;
