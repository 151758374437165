import {useEffect, useRef} from 'react';

export type IntervalProps = {
  callback: () => void;
  delay?: number | null;
};

const useInterval = ({callback, delay}: IntervalProps) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    if (!callback) {
      return;
    }
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay) {
      return;
    }
    const id = setInterval(() => callbackRef.current(), delay);
    return () => clearInterval(id);
  }, [delay]);
};

export default useInterval;
