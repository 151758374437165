import {useSelector} from 'react-redux';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {selectIsTrackerUrlEmpty, selectOrderSuccessData, selectUserLastOrderScooberJob} from '~/shared/store/selectors';

import useIsShowMap from './useIsShowMap';

export default function useIsShowScooberTrackerOrLink({isUsingEmptyUrl = false} = {}) {
  const {trackerUrl} = useSelector(selectUserLastOrderScooberJob) || EMPTY_OBJECT;
  const {restaurant} = useSelector(selectOrderSuccessData) || EMPTY_OBJECT;
  const isTrackerUrlEmpty = useSelector(selectIsTrackerUrlEmpty);

  const isShowMap = useIsShowMap();

  const isTrackingLinkSection = trackerUrl && !isShowMap && restaurant?.isScoober;

  if (!isUsingEmptyUrl) {
    return {isTracking: isTrackingLinkSection, trackerUrl};
  }
  return {isTracking: isTrackingLinkSection && !isTrackerUrlEmpty, trackerUrl};
}
