/* eslint-disable no-console */
import {isError} from 'lodash';

import store from '~/shared/store';
import {actions as errorDialogDataActions} from '~/shared/store/storeModules/errorDialogData';

import {log as newRelicLog, logError as newRelicLogError} from './newrelic';
import {log as sentryLog, logError as sentryLogError, init as sentryInit} from './sentry';
import {initialPrints} from './consoleFilterMessage';

const IS_PRODUCTION = process.env.NODE_ENV === 'production';

export function initLogging() {
  initialPrints();
  sentryInit();
}

enum LogLevels {
  VERBOSE = 'verbose',
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
  ERROR_DIALOG = 'errorDialog',
}

const loggerLevelToConsoleLevel = {
  [LogLevels.VERBOSE]: 'info',
  [LogLevels.ERROR_DIALOG]: LogLevels.ERROR,
} as const;

const loggerLevelsToTrackAsErrors = {
  [LogLevels.ERROR]: true,
  [LogLevels.ERROR_DIALOG]: true,
};

const allowConsoleLogs = !IS_PRODUCTION || localStorage.allowConsoleLogs === 'true';

function isErrorLevel(logLevel: LogLevels): logLevel is keyof typeof loggerLevelsToTrackAsErrors {
  return Object.keys(loggerLevelsToTrackAsErrors).includes(logLevel);
}

function isNotConsoleLevel(logLevel: LogLevels): logLevel is keyof typeof loggerLevelToConsoleLevel {
  return Object.keys(loggerLevelToConsoleLevel).includes(logLevel);
}

function trackError({
  loggerName,
  errorOrMessage,
  args,
}: {
  loggerName: string;
  errorOrMessage: string | Error;
  args: any;
}) {
  const error = isError(errorOrMessage) ? errorOrMessage : new Error(errorOrMessage);

  try {
    if (args?.find((item: any) => item?.status === 401)) {
      return;
    }

    sentryLogError({loggerName, error, args});
    newRelicLogError({loggerName, error, args});
  } catch (err) {
    console.error(new Error('trackError in logging errored'), {error: err});
  }
}

function trackInfo({
  loggerName,
  logLevel,
  errorOrMessage,
  args,
}: {
  loggerName: string;
  logLevel: string;
  errorOrMessage: string | Error;
  args: any;
}) {
  try {
    sentryLog({loggerName, logLevel, errorOrMessage, args});
    newRelicLog({loggerName, logLevel, errorOrMessage, args});
  } catch (err) {
    console.error(new Error('trackInfo in logging errored'), {error: err});
  }
}

function loggerFn(logLevel: LogLevels, loggerName: string, errorOrMessage: string | Error, ...args: any) {
  try {
    if (isErrorLevel(logLevel) && loggerLevelsToTrackAsErrors[logLevel]) {
      trackError({loggerName, errorOrMessage, args});
    } else {
      trackInfo({loggerName, logLevel, errorOrMessage, args});
    }
    if (allowConsoleLogs) {
      const consoleLevel = isNotConsoleLevel(logLevel)
        ? (loggerLevelToConsoleLevel[
            logLevel
          ] as typeof loggerLevelToConsoleLevel[keyof typeof loggerLevelToConsoleLevel])
        : logLevel;
      console[consoleLevel](`$[${loggerName}]: ${errorOrMessage}`, ...args);
    }
  } catch (e) {
    console.error(new Error('Failed to log an error using the logger.'), {logLevel, loggerName, errorOrMessage, args});
  }
}

export function createLogger(loggerName: string) {
  // repeating code for the sake of intellisense
  return {
    [LogLevels.VERBOSE]: (errorOrMessage: string | Error, ...args: any) =>
      loggerFn(LogLevels.VERBOSE, loggerName, errorOrMessage, ...args),

    [LogLevels.LOG]: (errorOrMessage: string | Error, ...args: any) =>
      loggerFn(LogLevels.LOG, loggerName, errorOrMessage, ...args),

    [LogLevels.WARN]: (errorOrMessage: string | Error, ...args: any) =>
      loggerFn(LogLevels.WARN, loggerName, errorOrMessage, ...args),

    [LogLevels.ERROR]: (errorOrMessage: string | Error, ...args: any) =>
      loggerFn(LogLevels.ERROR, loggerName, errorOrMessage, ...args),

    [LogLevels.ERROR_DIALOG]: (errorOrMessage: string | Error, ...args: any) => {
      if (!IS_PRODUCTION) {
        store.dispatch(errorDialogDataActions.addErrorsToDialog({errorOrMessage, args}));
      }

      return loggerFn(LogLevels.ERROR_DIALOG, loggerName, errorOrMessage, ...args);
    },
  };
}
