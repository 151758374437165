import {memo} from 'react';

import styled from 'styled-components';

import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {attachModal} from '~/shared/components/ReduxModal';
import {media} from '~/shared/theme/media';
import {CancelOrderWarning} from '~/cancelOrderWarning';
import {pushRoute} from '~/shared/router';

const Root = styled(ClickOutsideDiv)`
  &&& {
    background-color: ${({theme}) => theme.colors.surface};
    width: 100vw;
  }

  border-radius: 2px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  ${media.minLargeTablet`
  &&& {
      background-color: ${({theme}) => theme.colors.surface};
      width: 600px;
  }
      text-align: center;
      padding: 1px 0 40px;
     
  `}
`;

const CancelActiveOrderModal = ({closeModal}) => {
  const handleCloseModal = e => {
    e?.preventDefault();
    closeModal();
  };

  const handleApprove = () => {
    pushRoute('/user-report', {hard: true, keepAllQueries: false});
  };

  return (
    <Root onClickOutside={handleCloseModal}>
      <CancelOrderWarning onApprove={handleApprove} onCancel={closeModal} />
    </Root>
  );
};

const EnhancedCancelOrderModal = memo(CancelActiveOrderModal);
EnhancedCancelOrderModal.hideCloseButton = true;

attachModal('cancel_active_order_modal', EnhancedCancelOrderModal);
