import styled, {keyframes, Keyframes} from 'styled-components';

import {FlexColumnCenter} from '~/shared/theme/FlexLayout';
import {body15Normal, subheader24Bold, body20Bold} from '~/shared/theme/typography';
import {media} from '~/shared/theme/utils';

export const ANIMATION_SECONDS = 2;

export const OrderCompleteWrapper = styled(FlexColumnCenter)<{keyFrame: Keyframes | null}>`
  width: 100%;
  animation-duration: ${ANIMATION_SECONDS}s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  animation-name: ${({keyFrame}) => keyFrame || 'none'};
`;

export const OrderCompleteHeader = styled.div<{showTracker?: boolean; alignTextCenter?: boolean}>`
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  ${({showTracker}) => showTracker && 'padding-top: 46px;'}
  margin-bottom: 8px;
  ${({alignTextCenter}) => alignTextCenter && 'text-align: center;'}
  ${body20Bold}
  ${media.minMobile`
    ${subheader24Bold}
  `}
  line-height: 32px;
`;

export const OrderCompleteSubheader = styled.div<{alignTextCenter?: boolean}>`
  ${body15Normal}
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  line-height: 19px;
  ${({alignTextCenter}) => alignTextCenter && 'text-align: center;'}
`;

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
 
  100% {
    opacity: 1;
  }
`;

export const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
 
  100% {
    opacity: 0;
  }
`;
