import {isEmpty, pickBy} from 'lodash';
import numeral from 'numeral';

import {
  addressKeyFromParts,
  AddressPartsFromKey,
  getBestMatchAddress,
  mapAddressFromServerOrCookies,
} from '~/shared/utils/address';
import actions from '~/shared/store/actions';
import store from '~/shared/store';
import {
  GeolocationPlace,
  getCurrentGeolocationPlace,
  getExtraGeoCoderDataForAddress,
  MapGeoCoderResult,
} from '~/shared/services/geocoder';
import {LocationTypes} from '~/shared/consts/addressConsts';
import apiService, {ApiResponse} from '~/shared/services/apiService';
import {getTenBisAddressLocation} from '~/shared/services/getTenBisAddressLocation';
import {getLocalizationService} from '~/shared/services/localisationService';
import {createLogger} from '~/shared/logging';

import {
  selectCurrentAddressKey,
  selectLocalAddress,
  selectRemoteAddresses,
  selectUsersCompanyAddresses,
} from '../store/selectors';
import {Address, isRemoteAddress, LocalAddress} from '../store/models';
import {SupportedLanguageType} from '../consts/commonConsts';

const logger = createLogger('addressHelper');

const airportCityNames = ['2 נגב איר פורט סיטי, ישראל', 'קרית שדה התעופה', 'איירפורט סיטי'];

export const formatAddress = ({address, lng}: {address: Address | LocalAddress; lng: SupportedLanguageType['key']}) => {
  if (!address) {
    return null;
  }

  const {houseNumber, streetName, cityName} = address;
  const entrance = isRemoteAddress(address) ? address.entrance : undefined;
  const separator = houseNumber || streetName ? ', ' : '';

  if (lng === 'en') {
    return `${houseNumber || ''} ${streetName || ''}${separator}${cityName || ''} ${entrance || ''}`;
  }

  return `${streetName || ''} ${houseNumber || ''}${separator}${cityName || ''} ${entrance || ''}`;
};

export function mapGooglePlaceToRawAddress(place: GeolocationPlace) {
  if (isEmpty(place.address_components)) {
    return null;
  }

  const address = {} as RawAddress;

  if (airportCityNames.includes(place.name)) {
    address.cityName = 'איירפורט סיטי';
    address.streetName = 'גולן';
    address.houseNumber = '1';
  } else if (place.name === 'מחוז תל אביב') {
    address.cityName = 'תל אביב יפו';
  } else {
    place.address_components.forEach(item => {
      switch (item.types[0]) {
        case 'street_number': {
          const [houseNumber] = (item.long_name || '').split('-');
          address.houseNumber = houseNumber;
          break;
        }
        case 'route':
          address.streetName = item.long_name;
          break;
        case 'locality':
          address.cityName = item.long_name;
          break;
        default:
          break;
      }
    });
  }

  const {lat, lng} = place.geometry.location;
  address.latitude = typeof lat === 'function' ? lat() : lat;
  address.longitude = typeof lng === 'function' ? lng() : lng;

  return address;
}

export const getCurrentAddress = async ({extraPlaceData = {}} = {}) => {
  const place = await getCurrentGeolocationPlace();
  if (!place) {
    throw new Error('cant get current place from geocoder');
  }

  const rawAddress = mapGooglePlaceToRawAddress({
    ...place,
    ...extraPlaceData,
  });
  if (!rawAddress) {
    throw new Error('cant get address from geocoder place');
  }

  const {cityName, streetName, houseNumber, latitude, longitude} = rawAddress;

  const address = await getTenBisAddressLocation({
    cityName,
    streetName,
    houseNumber,
    latitude,
    longitude,
  });

  if (!address.cityId || !address.streetId || !address.houseNumber) {
    throw new Error('cant get address location');
  }

  return address;
};

export const isGetAddressDetailsSucceed = (addressParts: AddressPartsFromKey, result: ApiResponse<Address>) => {
  // we can't compare address's ids as apiService.getAddressDetails will always return addressId: 0 (confirmed with dror).
  if (!result.success) {
    return false;
  }

  return (
    String(result.data.cityId) === String(addressParts.cityId) &&
    String(result.data.streetId) === String(addressParts.streetId)
  );
};

export async function validateAndExpandAddress(
  address: Address | LocalAddress | AddressPartsFromKey,
): Promise<Address | LocalAddress> {
  const state = store.getState();

  const equivalentExistingMatchingAddress = getBestMatchAddress({
    addressKey: address.addressKey,
    localAddress: selectLocalAddress(state),
    remoteAddresses: selectRemoteAddresses(state),
  });
  if (equivalentExistingMatchingAddress) {
    return equivalentExistingMatchingAddress;
  }

  const result = await apiService.getAddressDetails(address);

  if (!isGetAddressDetailsSucceed(address, result)) {
    throw new Error('Address doesn\'t exists');
  }

  const responseAddress = result.data && mapAddressFromServerOrCookies(pickBy(result.data, Boolean));

  const initialNewAddress = {
    ...address,
    ...responseAddress as typeof result.data,
  };

  let extraGeoCoderData: MapGeoCoderResult | undefined;
  try {
    if (!initialNewAddress.latitude && !initialNewAddress.longitude) {
      extraGeoCoderData = await getExtraGeoCoderDataForAddress(initialNewAddress);
    }
  } catch (e) {
    logger.warn('couldnt get geocode data for address', {address});
  }

  const localAddress: LocalAddress = {
    ...initialNewAddress,
    ...extraGeoCoderData,
    addressKey: addressKeyFromParts(initialNewAddress),
    locationType: initialNewAddress.locationType || LocationTypes.RESIDENTIAL,
    isBigCity: 'isBigCity' in initialNewAddress ? initialNewAddress.isBigCity : false,
  };

  store.dispatch(actions.setLocalAddress(localAddress));

  return localAddress;
}

export const formatFloor = (floorNumber: string) => {
  const {t, currentLanguageKey} = getLocalizationService();
  return currentLanguageKey === 'en'
    ? `${numeral(floorNumber).format('Oo')} ${t('floor')}`
    : `${t('floor')} ${floorNumber}`;
};

export const getUserFormattedAddress = (selectedAddress: Address | LocalAddress) => {
  const {t, currentLanguageKey} = getLocalizationService();
  const state = store.getState();
  const companyAddresses = selectUsersCompanyAddresses(state);
  const companyAddressInCompanyAddresses =
    isRemoteAddress(selectedAddress) &&
    companyAddresses.some(
      address => address.addressId === selectedAddress.addressId && selectedAddress.isCompanyAddress,
    );

  if (companyAddressInCompanyAddresses) {
    if (companyAddresses.length === 1) {
      return t('office');
    }

    if (companyAddresses.length === 2) {
      const isOfficesInDifferentCities = companyAddresses[0].cityId !== companyAddresses[1].cityId;
      if (isOfficesInDifferentCities) {
        return `${t('office')}, ${selectedAddress.cityName}`;
      }
      // checking by street name because several streets has more than one "virtual id"
      const isOfficesOnDifferentStreets = companyAddresses[0].streetName !== companyAddresses[1].streetName;
      if (isOfficesOnDifferentStreets) {
        return `${t('office')}, ${selectedAddress.streetName}`;
      }

      const isOfficesOnDifferentHouseNumber = companyAddresses[0].houseNumber !== companyAddresses[1].houseNumber;
      if (isOfficesOnDifferentHouseNumber) {
        return `${t('office')}, ${selectedAddress.houseNumber}`;
      }
      const isOfficesOnDifferentFloor = companyAddresses[0].floor !== companyAddresses[1].floor;
      if (isRemoteAddress(selectedAddress) && isOfficesOnDifferentFloor) {
        return `${t('office')}, ${formatFloor(selectedAddress.floor)}`;
      }
    }

    if (companyAddresses.length > 2) {
      const isOfficeCityIsUnique =
        companyAddresses.filter(address => address.cityId === selectedAddress.cityId).length === 1;
      if (isOfficeCityIsUnique) {
        return `${t('office')}, ${selectedAddress.cityName}`;
      }
      // checking by street name because several streets has more than one "virtual id"
      const isStreetNameIsUnique =
        companyAddresses.filter(address => address.streetName === selectedAddress.streetName).length === 1;
      if (isStreetNameIsUnique) {
        return `${t('office')}, ${selectedAddress.streetName}, ${selectedAddress.cityName}`;
      }

      const isHouseNumberIsUnique =
        companyAddresses.filter(address => address.houseNumber === selectedAddress.houseNumber).length === 1;
      if (isHouseNumberIsUnique) {
        return `${t('office')}, ${selectedAddress.streetName}, ${selectedAddress.houseNumber}  ${
          selectedAddress.cityName
        }`;
      }

      const isFloorNumberIsUnique =
        isRemoteAddress(selectedAddress) &&
        companyAddresses.filter(address => address.floor === selectedAddress.floor).length === 1;
      if (isRemoteAddress(selectedAddress) && isFloorNumberIsUnique) {
        return `${t('office')}, ${selectedAddress.streetName}, ${selectedAddress.houseNumber}, ${formatFloor(
          selectedAddress.floor,
        )} ${selectedAddress.cityName}`;
      }
    }
  }
  return formatAddress({address: selectedAddress, lng: currentLanguageKey});
};

export const getDefaultAddress = () => {
  const state = store.getState();
  const remoteAddresses = selectRemoteAddresses(state);
  const localAddress = selectLocalAddress(state);
  const currentAddressKey = selectCurrentAddressKey(state);

  // if current address is present, it means that the address got set
  // from the persisten store to support refreshes, and was not expired.
  if (currentAddressKey) {
    // this might convert a local address like 11-12-13 to 11-12-13-400
    // in case the user has an equivalent remote address to the selected local one
    const bestMatchAddress = getBestMatchAddress({
      addressKey: currentAddressKey,
      remoteAddresses,
      localAddress,
    });
    if (bestMatchAddress) {
      return bestMatchAddress;
    }
  }

  // if the server returned any addresses, then we use it
  // because there's a logic calculating the default address
  // for the user based on the hour of day and more factors
  if (remoteAddresses[0]) {
    return remoteAddresses[0];
  }

  // any other address
  // if (localAddress[0]) {
  //   return localAddress[0];
  // }

  return localAddress;
};

export interface RawAddress {
  cityName: string;
  streetName: string;
  houseNumber: string;
  latitude: number;
  longitude: number;
}
