import {memo} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {attachModal} from '~/shared/components/ReduxModal';
import DiagonalHeaderView from '~/shared/components/DiagonalHeaderView';
import {media} from '~/shared/theme/utils';
import circleImageUrl from '~/assets/images/green_smiley.png';
import {ModalContent, LongButton, PopupModalBase} from '~/shared/components/Modals';
import {body13Normal} from '~/shared/theme/typography';

export const Root = styled(PopupModalBase)`
  ${media.minLargeTablet`
    &&& {
      height: 475px; 
    }
  `}
`;

const SuccessButton = styled(LongButton)`
  width: 85%;
  margin-bottom: 105px;
  margin-top: 40px;
  border-radius: 2px;
  ${media.minMobile`  
    width: ${({theme}) => theme.successModal.buttonWidth};
  `}
`;

const ContentText = styled.p`
  color: ${({theme}) => theme.modal.textColor};
  ${body13Normal};
  line-height: 24px;
  margin: 40px 0;
  ${media.minMobile`
    margin-top: 0;
  `}
`;

export const SuccessModal = ({
  args: {title, text, btnText, shouldShowCircle = true, useTranslate = true, onSuccess, disableCloseModal},
  closeModal,
}) => {
  const {t} = getLocalizationService();

  const handleClose = () => {
    if (onSuccess) {
      onSuccess();
    }
    if (!disableCloseModal) {
      closeModal();
    }
  };

  return (
    <Root>
      <DiagonalHeaderView
        circleImageProps={shouldShowCircle && {imageUrl: circleImageUrl, noAlt: true}}
        isSuccessModal
        title={t(title)}
        titleId="modal-title"
      >
        <ModalContent>
          <ContentText id="modal-content">{useTranslate ? t(text) : text}</ContentText>
          <SuccessButton onClick={handleClose}>{t(btnText)}</SuccessButton>
        </ModalContent>
      </DiagonalHeaderView>
    </Root>
  );
};

const EnhanceSuccessModal = memo(SuccessModal);
EnhanceSuccessModal.hideCloseButton = true;

attachModal('successModal', EnhanceSuccessModal);
