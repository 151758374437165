import {createSelector} from 'reselect';

import {EMPTY_ARRAY} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

const selectPayments = (state: AppState) => state.payments;

export const selectCheckoutPayments = createSelector(
  selectPayments,
  payments => payments.checkoutPayments || EMPTY_ARRAY,
);

const selectAvailablePaymentsBase = createSelector(selectPayments, payments => payments.availablePayments);

export const selectAvailablePayments = createSelector(
  selectAvailablePaymentsBase,
  availablePayments => availablePayments.data,
);
export const selectIsAvailablePaymentsLoaded = createSelector(
  selectAvailablePaymentsBase,
  availablePayments => availablePayments.loaded,
);

export const selectIsAvailablePaymentsLoading = createSelector(
  selectAvailablePaymentsBase,
  availablePayments => availablePayments.loading,
);

export const selectOrderPayments = createSelector(selectPayments, payments => payments.orderPayments || EMPTY_ARRAY);
export const selectLinkMoneycardRequests = createSelector(
  selectPayments,
  payments => payments.linkMoneycardRequests.data || EMPTY_ARRAY,
);
export const selectCheckoutPaymentsIfExists = createSelector(selectPayments, payments => payments.checkoutPayments);

export const selectCheckoutCashPayment = createSelector(selectCheckoutPayments, checkoutPayments =>
  checkoutPayments?.find(payment => payment.paymentMethod === 'Cash'),
);

export const selectShouldAvoidPaymentsRefetch = createSelector(selectPayments, state => state.shouldAvoidPaymentsRefetch);
