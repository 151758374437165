import {memo, useEffect, useMemo, useState} from 'react';

import styled, {CSSProperties} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/media';
import {FlexColumn} from '~/shared/theme/FlexLayout';
import apiService from '~/shared/services/apiService';
import DropDown from '~/shared/components/DropDown';
import {setDiningRoomBuilding, setDiningRoomFloor, setOrderRemarks, setRemarksToPresent} from '~/shared/store/actions';
import {selectDiningRoomBuilding, selectDiningRoomFloor, selectOrderRemarks, selectRemarksToPresent} from '~/shared/store/selectors';

const Wrapper = styled.div`
  padding: 0 16px;
  ${media.minLargeTablet`
    padding: unset;
  `}
`;

const StyledDropdown = styled(DropDown)``;

const MarginedDropdown = styled(StyledDropdown).attrs(() => ({
  styles: {
    option: (provided: CSSProperties) => ({
      ...provided,
      paddingLeft: 10,
      paddingRight: 10,
    }),
    singleValue: (provided: CSSProperties) => ({
      ...provided,
      maxWidth: 'none',
    }),
  },
}))`
  width: calc(50% - 8px);
`;

const LTRMarginDropdown = styled(MarginedDropdown).attrs(() => ({
  styles: {
    option: (provided: CSSProperties) => ({
      ...provided,
      direction: 'ltr',
    }),
    singleValue: (provided: CSSProperties) => ({
      ...provided,
      direction: 'ltr',
    }),
  },
}))``;

const Label = styled.label`
  display: block;
  margin: 16px 0;
  color: ${({theme}) => theme.colors.secondary};
  font-weight: bold;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 16px;
`;

type Structure = Record<string, Record<string, string[]>>;

type OptionType = {
  id: string;
  label: string;
};

const useFetchRoomsJson = () => {
  const [response, setResponse] = useState<Structure>();

  useEffect(() => {
    (async () => {
      const jsonRes: Structure = await apiService.fetchCdnDiningRooms();
      // in js object with numbered keys can't be sorted correctly (with negative numbers)
      // thats why we add 'a' to the key if it's not negative
      // and remove it in the render afterwords
      setResponse(jsonRes && Object.keys(jsonRes).sort().reduce((acc: Structure, building) => {
        acc[building] = Object.keys(jsonRes[building]).sort((a, b) => Number(a) - Number(b)).reduce((accInner: Record<string, Array<string>>, key) => {
          accInner[`${(!key.includes('-') ? 'a' : '') + key}`] = jsonRes[building][key];
          return accInner;
        }, {});
        return acc;
      }, {}));
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return response;
};

const optionsMapper = (str: string | null) => (str ? ({id: str, label: str}) : str);
const labelMapper = (opt: OptionType) => opt.id;

const DiningRoomSelection = () => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const roomsList = useFetchRoomsJson();

  const selectedBuilding = useSelector(selectDiningRoomBuilding);
  const selectedFloor = useSelector(selectDiningRoomFloor);
  const selectedRoom = useSelector(selectOrderRemarks);
  const selectedModifiedRoom = useSelector(selectRemarksToPresent);

  const setSelectedBuilding = (building: OptionType | null) => dispatch(setDiningRoomBuilding(building ? building.id : ''));
  const setSelectedFloor = (floor: OptionType | null) => dispatch(setDiningRoomFloor(floor ? floor.id : ''));
  const setSelectedRoom = (room: string | null) => dispatch(setOrderRemarks(room));
  const setModifiedRoomName = (room: string | null) => dispatch(setRemarksToPresent(room));

  const buildings = useMemo(() => roomsList && Object.keys(roomsList).map(optionsMapper), [roomsList]);
  const floors = useMemo(() => (roomsList && selectedBuilding && Object.keys(roomsList[selectedBuilding]).map(optionsMapper)) || [], [roomsList, selectedBuilding]);
  const rooms = useMemo(() => (roomsList && selectedBuilding && selectedFloor && roomsList[selectedBuilding][selectedFloor].map(optionsMapper)) || null, [roomsList, selectedBuilding, selectedFloor]);

  const clearSelectedStates = () => {
    [setSelectedBuilding, setSelectedFloor, setSelectedRoom, setModifiedRoomName].forEach(setter => setter(null));
  };

  const onBuildingSelect = (building: OptionType) => {
    clearSelectedStates();
    setSelectedBuilding(building);
  };

  const onFloorSelect = (floor: OptionType) => {
    if (selectedRoom) {
      setSelectedRoom(null);
      setModifiedRoomName(null);
    }

    setSelectedFloor(floor);
  };

  const onRoomSelect = (room: OptionType) => {
    setSelectedRoom(`${t('dining_building')} ${selectedBuilding} ${t('dining_floor')} ${selectedFloor.replace('a', '') === '-1' ? '1-' : selectedFloor.replace('a', '')} - ${room.id}`);
    setModifiedRoomName(room.id);
  };

  if (!roomsList) {
    return null;
  }

  return (
    <Wrapper>
      <Label htmlFor="remarks-textarea">
        {t('select_dining_room')}
      </Label>
      <FlexColumn>
        <FlexRow>
          <MarginedDropdown
            name="dining-room-building-selector"
            value={optionsMapper(selectedBuilding)}
            isSearchable={false}
            options={buildings}
            placeholder={t('dining_building')}
            onChange={onBuildingSelect}
            getOptionValue={labelMapper}
            getOptionLabel={labelMapper}
          />

          <LTRMarginDropdown
            name="dining-room-floor-selector"
            value={optionsMapper(selectedFloor)}
            isSearchable={false}
            options={floors}
            placeholder={t('dining_floor')}
            onChange={onFloorSelect}
            isDisabled={!selectedBuilding}
            getOptionValue={labelMapper}
            getOptionLabel={(opt: OptionType) => (opt.id.includes('-') ? opt.id : opt.id.split('a')[1])}
          />
        </FlexRow>

        <StyledDropdown
          name="dining-room--selector"
          value={optionsMapper(selectedModifiedRoom)}
          isSearchable={false}
          options={rooms}
          placeholder={t('dining_room')}
          isDisabled={!selectedFloor}
          onChange={onRoomSelect}
          getOptionValue={labelMapper}
          getOptionLabel={labelMapper}
        />
      </FlexColumn>
    </Wrapper>
  );
};

export default memo(DiningRoomSelection);
