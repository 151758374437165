import {createLogger} from '~/shared/logging';
import {validateAndExpandAddress, getDefaultAddress} from '~/shared/services/addressHelper';
import store from '~/shared/store';
import {setLocalAddress} from '~/shared/store/actions';
import {Address, LocalAddress, isRemoteAddress} from '~/shared/store/models';
import {
  selectUserData,
  selectCurrentAddress,
  selectRemoteAddresses,
  selectLocalAddress,
} from '~/shared/store/selectors';
import {
  AddressPartsFromKey,
  addressKeyFromParts,
  isAddressKeyContainAddressId,
  removeAddressIdFromAddressKey,
  addressPartsFromKey,
  areAddressesEqual,
} from '~/shared/utils/address';

const logger = createLogger('AddressManagerHelper');

const AddressManagerHelper = {
  getFinalNewAddress: async (
    newAddress: Address | LocalAddress | AddressPartsFromKey | undefined,
    retryNum = 0,
  ): Promise<Address | LocalAddress | AddressPartsFromKey | undefined> => {
    if (!newAddress) {
      logger.warn('changeAddress expects newAddress.');
      return;
    }

    const initialState = store.getState();
    const user = selectUserData(initialState);
    const currentAddress = selectCurrentAddress(initialState);
    const newAddressKey = addressKeyFromParts(newAddress);

    let finalNewAddress = newAddress;
    if (isAddressKeyContainAddressId(newAddressKey)) {
      if (!user) {
        const equivalentLocalAddressKey = removeAddressIdFromAddressKey(newAddressKey);
        logger.verbose('since there is no user, changing to the equivalent local address key:', {
          newAddressKey,
          equivalentLocalAddressKey,
        });
        const equivalentLocalAddress = addressPartsFromKey(equivalentLocalAddressKey);
        return AddressManagerHelper.getFinalNewAddress(equivalentLocalAddress);
      }
    }

    try {
      finalNewAddress = await validateAndExpandAddress(finalNewAddress);
      if ('addressId' in finalNewAddress && finalNewAddress.addressId === 0) {
        throw new Error('invalid address with addressId "0".');
      }
    } catch (error) {
      if (retryNum < 3) {
        logger.error(new Error('there was a problem with "newAddress" when validated remotely. retrying...'), {
          newAddress,
          retryNum,
          error,
        });
        return AddressManagerHelper.getFinalNewAddress(newAddress, retryNum + 1);
      }

      logger.error(new Error('there was a problem with "newAddress" when validated remotely. retries failed.'), {
        newAddress,
        error,
      });

      const defaultAddress = getDefaultAddress();
      if (
        !defaultAddress ||
        !('addressId' in defaultAddress && defaultAddress.addressId) ||
        defaultAddress === currentAddress
      ) {
        logger.error(new Error('could not detect the default address to use different then the current.'), {
          newAddress,
          retryNum,
          error,
        });

        // navigateToHomePage(); //Todo: revisit if necessary

        return;
      }

      return AddressManagerHelper.getFinalNewAddress(defaultAddress);
    }

    return finalNewAddress;
  },

  // eslint-disable-next-line class-methods-use-this
  clearLocalAddressIfEquivalentRemoteExist: (finalNewAddress: Address | LocalAddress | AddressPartsFromKey) => {
    if (isRemoteAddress(finalNewAddress)) {
      const state = store.getState();
      const remoteAddresses = selectRemoteAddresses(state);
      const localAddress = selectLocalAddress(state);

      const shouldRemoveLocalAddress =
        localAddress &&
        remoteAddresses.some(safeRemoteAddress => {
          const keyWithoutAddressId = removeAddressIdFromAddressKey(safeRemoteAddress.addressKey);
          return (
            keyWithoutAddressId === localAddress.addressKey || areAddressesEqual(localAddress, safeRemoteAddress, true)
          );
        });

      if (shouldRemoveLocalAddress) {
        store.dispatch(setLocalAddress(null));
      }
    }
  },
};

export default AddressManagerHelper;
