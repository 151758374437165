import {
  Context,
  memo,
  MemoExoticComponent,
  Provider,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import styled, {CSSProperties} from 'styled-components';

import ClockAlert from '~/assets/images/icons/clock_alert.svg?react';
import ClockIcon from '~/assets/images/asap_and_pooled/pooled.svg?react';
import {getLocalizationService} from '~/shared/services/localisationService';
import DropDown, {DropDownValueContainer} from '~/shared/components/DropDown';
import {flexSpace} from '~/shared/theme/FlexLayout';
import {MutatedFutureOrderAvailableDatesAndTimes, OrderDateAndTime} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {getAsapDeliveryTimeRanges} from '~/shared/utils/restaurants';
import {media} from '~/shared/theme/media';
import {body14Bold, body14Normal} from '~/shared/theme/typography';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import {OnSelectTimeProps} from '~/common/FutureOrderContextProvider';
import {trackEvent} from '~/shared/services/analytics';
import {getFormatedDate as getFormatedDateForAnalytics} from '~/shared/services/analytics/utils';

import {DEFAULT_LANGUAGE_KEY} from '../../consts/commonConsts';

import {FutureOrderContext, IFutureOrderContext} from './context';
import FutureOrderDisclaimer from './FutureOrderDisclaimer';

const SPACE_BETWEEN_DROPDOWNS_IN_PIXELS = 12;
const DROPDOWN_SMALL_BREAKPOINT_WIDTH_PERCENT_FIX = 2;

const Container = styled.div`
  color: ${({theme}) => theme.checkout.textColor};
  ${flexSpace}
  width: 100%;
`;

const DropdownContainer = styled.div`
  ${flexSpace}
  width: 100%;
  z-index: 2;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  overflow: hidden;
  margin-bottom: 12px;

  svg {
    ${flipOnLTR`
      margin-left: 10px;
    `}
    height: 40px;
    width: 40px;

    path {
      transform: scale(1.3);
    }
  }
`;

const HeaderLabel = styled.div<{isCheckout?: boolean}>`
  color: ${({theme}) => theme.colors.secondary};
  line-height: 20px;
  ${body14Bold}
`;

const DropdownWrapper = styled(DropDown).attrs(() => ({
  styles: {
    option: (provided: CSSProperties) => ({
      ...provided,
      paddingLeft: 10,
      paddingRight: 10,
    }),
    singleValue: (provided: CSSProperties) => ({
      ...provided,
      maxWidth: 'none',
    }),
  },
}))<{growOnSmallResolution?: boolean; isCheckout?: boolean}>`
  width: calc(50% - ${SPACE_BETWEEN_DROPDOWNS_IN_PIXELS / 2}px);
  ${({growOnSmallResolution, isCheckout}) => (!isCheckout ? media.minTablet`
    width: ${growOnSmallResolution ?
      `calc(${50 + DROPDOWN_SMALL_BREAKPOINT_WIDTH_PERCENT_FIX}% - ${SPACE_BETWEEN_DROPDOWNS_IN_PIXELS / 4}px);` :
      `calc(${50 - DROPDOWN_SMALL_BREAKPOINT_WIDTH_PERCENT_FIX}% - ${SPACE_BETWEEN_DROPDOWNS_IN_PIXELS / 4}px);`}
  ` : '')}
  ${media.minLargeTablet`
    width: calc(50% - ${SPACE_BETWEEN_DROPDOWNS_IN_PIXELS / 2}px);
  `}
`;

const Value = styled.div`
  flex: 1;
`;

const ValidationWrapper = styled.div`
  margin-top: 2px;
  display: flex;
  align-items: center;
`;

const ValidationError = styled.div`
  ${body14Normal}
  color: ${({theme}) => theme.colors.error};
  ${flipOnLTR`
    margin-right: 8px;
  `}
`;

const ValueContainer = ({children, ...props}: any) => (
  <DropDownValueContainer {...props}>
    <Value>{children}</Value>
  </DropDownValueContainer>
);

const getFormatedDate = (sourceDate: string) => {
  const {currentLanguageKey} = getLocalizationService();
  if (sourceDate.split(' ').length < 2) {
    return sourceDate;
  }
  const datePart = sourceDate.split(' ')[0];
  const dayPart = sourceDate.split(' ')[1];
  const dayMonth = datePart.split('/')[0].concat('/', datePart.split('/')[1]);
  if (currentLanguageKey === DEFAULT_LANGUAGE_KEY) {
    return `יום ${dayPart}, ${dayMonth} `;
  } return `${dayMonth}, ${dayPart}`;
};

const getDateOptionValue = ({date}: {date: MutatedFutureOrderAvailableDatesAndTimes['date']}) => date;
export const getDateOptionLabel = ({formatedDate}: {formatedDate: MutatedFutureOrderAvailableDatesAndTimes['formatedDate']}) =>
  getFormatedDate(formatedDate);

interface IFutureOrder {
  shouldShowHeader?: boolean;
  futureOnly?: boolean;
  hideCancelDisclaimer?: boolean;
  setIsFutureOrderTimesNotSelected?: (value: boolean) => void;
  isCheckout: boolean;
  showFutureOrderValidationError?: boolean;
  openToTop?: boolean;
  showForm?: boolean;
  customOptions?: {
    shouldShowDisclaimers?: boolean;
    onSelectDate?: (orderDate: MutatedFutureOrderAvailableDatesAndTimes) => void;
    onSelectTime?: (args: OnSelectTimeProps) => void;
    currentOrderDateAndTime?: OrderDateAndTime;
    isInitialOrder?: boolean;
    isASAP?: boolean;
    futureOrderAvailableDatesAndTimes?: MutatedFutureOrderAvailableDatesAndTimes[];
    disuseAnalytics?: boolean;
  };
}

const FutureOrder = ({
  shouldShowHeader,
  futureOnly,
  hideCancelDisclaimer,
  setIsFutureOrderTimesNotSelected,
  isCheckout,
  showFutureOrderValidationError,
  openToTop,
  customOptions,
}: IFutureOrder) => {

  const {
    onSelectDate: contaxtOnSelectDate,
    onSelectTime: contaxtOnSelectTime,
    currentOrderDateAndTime: contaxtOrderDateAndTime,
    futureOrderAvailableDatesAndTimes: contextFutureOrderAvailableDatesAndTimes,
    isFutureOrderEnabled,
    futureOrderAvailableDatesAndTimesAvailable,
    currentRestaurant,
    availableDeliveryRules,
    isASAP: contextIsASAP,
    isInitialOrder: contextIsInitialOrder,
    shouldShowDisclaimers: contaxShouldShowDisclaimers,
  } = useContext(FutureOrderContext);

  const onSelectDate = customOptions?.onSelectDate || contaxtOnSelectDate;
  const onSelectTime = customOptions?.onSelectTime || contaxtOnSelectTime;
  const shouldShowDisclaimers = customOptions?.shouldShowDisclaimers !== undefined ? customOptions?.shouldShowDisclaimers : contaxShouldShowDisclaimers;
  const currentOrderDateAndTime = customOptions?.currentOrderDateAndTime || contaxtOrderDateAndTime;
  const isInitialOrder = customOptions?.isInitialOrder !== undefined ? customOptions?.isInitialOrder : contextIsInitialOrder;
  const isASAP = customOptions?.isASAP !== undefined ? customOptions?.isASAP : contextIsASAP;
  const futureOrderAvailableDatesAndTimes = customOptions?.futureOrderAvailableDatesAndTimes || contextFutureOrderAvailableDatesAndTimes;
  const isEmptyCurrentFutureOrder = !currentOrderDateAndTime?.orderDate || !currentOrderDateAndTime?.orderTime;

  const shouldShowTimePlaceholder = useMemo(() => {
    return futureOnly && !isCheckout && isInitialOrder;
  }, [isInitialOrder, futureOnly, isCheckout]);
  const {t} = getLocalizationService();
  const deliveryTimeRange = t('delivered_in_xxx', getAsapDeliveryTimeRanges(currentRestaurant?.asapDeliveryTimeInMinutes || 0));
  const menuPlacement = openToTop ? 'top' : 'bottom';

  const deliveryTime = useMemo(() => {
    if (isASAP) {
      return {timeKey: deliveryTimeRange, timeKeyLabel: deliveryTimeRange};
    }
    if (shouldShowTimePlaceholder) {
      return {timeKey: t('select_time'), timeKeyLabel: t('select_time')};
    }

    if (currentOrderDateAndTime?.orderTime?.timeKey === t('asap')) {
      return {timeKey: deliveryTimeRange, timeKeyLabel: deliveryTimeRange};
    }

    return {
      timeKey: currentOrderDateAndTime?.orderTime?.timeKey,
      timeKeyLabel: currentOrderDateAndTime?.orderTime?.timeKeyLabel,
    };
  }, [
    isASAP,
    deliveryTimeRange,
    shouldShowTimePlaceholder,
    currentOrderDateAndTime,
    t,
  ]);

  useEffect(() => {
    if (futureOnly && setIsFutureOrderTimesNotSelected && deliveryTime?.timeKey === t('select_time')) {
      setIsFutureOrderTimesNotSelected(true);
    }
  }, [deliveryTime, futureOnly, setIsFutureOrderTimesNotSelected, t]);

  if (
    !isFutureOrderEnabled ||
    isEmptyCurrentFutureOrder ||
    !futureOrderAvailableDatesAndTimesAvailable ||
    !currentOrderDateAndTime
  ) {
    return null;
  }

  const enableShoppingCartSubmit = () => {
    if (setIsFutureOrderTimesNotSelected) {
      setIsFutureOrderTimesNotSelected(false);
    }
  };

  const handleSelectTimeAnalytics = (date: MutatedFutureOrderAvailableDatesAndTimes, time: MutatedFutureOrderAvailableDatesAndTimes['times'][number], ruleId?: number) => {
    if (!ruleId) {
      return;
    }
    
    const rule = availableDeliveryRules?.Future?.find(deliveryRule => deliveryRule.ruleId === ruleId);
    trackEvent('nlxOrderType_select', {
      selected_orderType: (time.timeKey === t('asap') || time.timeKey === 'ASAP') ? 'asap' : 'future',
      original_orderType: isASAP ? 'asap' : 'future',
      pageSource: isCheckout ? 'checkout' : 'menu',
      futureOrderTime: getFormatedDateForAnalytics(date, time),
      delivery_price: rule?.deliveryFee?.toString(),
    });
  };

  const handleSelectDate = (orderDate: MutatedFutureOrderAvailableDatesAndTimes) => {
    enableShoppingCartSubmit();
    onSelectDate(orderDate);

    if (!customOptions?.disuseAnalytics) {
      handleSelectTimeAnalytics(orderDate, orderDate.times[0], orderDate.ruleId);
    }
  };

  const handleSelectTime = (timeProps: {
    timeKey: string;
    timeRange: string;
    timeKeyLabel: string;
    ruleId?: number | undefined;
  }) => {
    enableShoppingCartSubmit();
    onSelectTime({props: timeProps, deliveryTimeRange});
    if (!customOptions?.disuseAnalytics) {
      handleSelectTimeAnalytics(currentOrderDateAndTime.orderDate, timeProps, timeProps.ruleId);
    }
  };

  return (
    <>
      {shouldShowHeader && (
        <HeaderWrapper>
          <ClockIcon />
          <HeaderLabel isCheckout={isCheckout}>{t('choose_delivery_time')}</HeaderLabel>
        </HeaderWrapper>
      )}

      <Container>
        <DropdownContainer>
          <DropdownWrapper
            name="future-order-date"
            value={currentOrderDateAndTime.orderDate}
            onChange={handleSelectDate}
            iconName="date"
            options={futureOrderAvailableDatesAndTimes}
            components={{ValueContainer}}
            getOptionValue={getDateOptionValue}
            getOptionLabel={getDateOptionLabel}
            isSearchable={false}
            isCheckout={isCheckout}
            growOnSmallResolution
            menuPlacement={menuPlacement}
          />
          <DropdownWrapper
            name="future-order-time"
            value={deliveryTime || {timeKeyLabel: t('select_time')}}
            onChange={handleSelectTime}
            iconName="time"
            options={currentOrderDateAndTime.orderDate.times.map(option => {
                if (isCurrentTimeKeyAsap(option.timeKey)) {
                  return {
                    ...option,
                    timeKeyLabel: deliveryTimeRange,
                  };
                }
                return option;
              })}
            components={{ValueContainer}}
            getOptionValue={({timeKey}: {timeKey: string}) => timeKey}
            getOptionLabel={({timeKeyLabel, timeKey}: {timeKey: string; timeKeyLabel?: string}) => timeKeyLabel || timeKey}
            isSearchable={false}
            isCheckout={isCheckout}
            menuPlacement={menuPlacement}
          />
        </DropdownContainer>
      </Container>
      {shouldShowDisclaimers && !showFutureOrderValidationError && <FutureOrderDisclaimer shouldShowCancelDisclaimer={!hideCancelDisclaimer && isCheckout} />}
      {!isCheckout && showFutureOrderValidationError && (
      <ValidationWrapper>
        <ClockAlert />
        <ValidationError>{t('choose_delivery_hour_to_continue')}</ValidationError>
      </ValidationWrapper>
      )}
    </>
  );
};

const PureFutureOrder = memo(FutureOrder) as MemoExoticComponent<(props: IFutureOrder) => JSX.Element | null> & {
  Provider: Provider<IFutureOrderContext>;
  Context: Context<IFutureOrderContext>;
};

PureFutureOrder.Provider = FutureOrderContext.Provider;
PureFutureOrder.Context = FutureOrderContext;

export default PureFutureOrder;
