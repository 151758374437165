import {ReactNode} from 'react';

import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';

import {body10Normal} from '../theme/typography';

const Container = styled.div.attrs({
  role: 'alert',
})<{width?: string; floating?: boolean}>`
  ${flexCenterVertically};
  border: 1px solid ${({theme}) => theme.input.error.borderColor};
  border-radius: 2px;
  height: 35px;
  width: ${({width}) => width || '100%'};
  ${({floating}) =>
    floating &&
    `
    position: absolute;
    top: 72px;
    background: white;
    z-index: 2;
  `}
`;

const ErrorText = styled.p`
  color: ${({theme}) => theme.input.error.textColor};
  ${body10Normal};
  line-height: 16px;
  width: 100%;
  ${flipOnLTR`
    text-align: right;
    padding-right: 8px;
  `}
`;

const Arrow = styled.div`
  width: 50px;
  height: 12px;
  position: absolute;
  top: -12px;
  left: 5%;
  transform: translateX(-50%);
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: ${({theme}) => theme.colors.surface};
    transform: translateX(-50%) translateY(69%) rotate(45deg);
    top: 0;
    left: 50%;
    border: 1px solid ${({theme}) => theme.colors.error};
  }
`;

interface IErrorBox {
  children?: ReactNode;
  withArrow?: boolean;
  floating?: boolean;
  width?: string;
  className?: string;
}

const ErrorBox = ({children, withArrow = true, floating = false, width, className}: IErrorBox) => {
  if (children) {
    return (
      <Container className={className} floating={floating} width={width}>
        {withArrow && <Arrow />}
        <ErrorText>{children}</ErrorText>
      </Container>
    );
  }
  return null;
};

export default ErrorBox;
