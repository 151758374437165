import ReactDOM from 'react-dom';
import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';

import Loader from './Loader';

const CentralLoadingContainer = styled.div`
  z-index: 2001;
  position: fixed;
  width: 72px;
  height: 72px;
  top: 50%;
  direction: ltr;
  ${flipOnLTR`
    left: 50%;
    margin-left: -36px;
  `};
  margin-top: -36px;
  pointer-events: none;
`;

const OverLay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 2000;
`;

const centralLoadingElement = (
  <>
    <OverLay onClick={e => e.stopPropagation()} />
    <CentralLoadingContainer>
      <Loader />
    </CentralLoadingContainer>
  </>
);

export default function CentralLoading() {
  return ReactDOM.createPortal(centralLoadingElement, window.document.body);
}
