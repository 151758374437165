import {memo} from 'react';

import styled from 'styled-components';

import {ETAInfoBox} from '~/shared/components/ETAInfoBox';
import {media} from '~/shared/theme/media';

import Button from '../Button';

// #region styled
/* transition for border width should be disabled, its not working good in mozilla */
const ModifiedButton = styled(Button)<{isActive: boolean; singleButtonStyle?: boolean}>`
  transition: border-color 0.2s linear, background 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  ${({theme, isActive}) => `
    border: 1px solid ${theme.colors.gray800};
    border-radius: 4px;
    padding: 8px 0;

    width: calc(50% - 4px);
    
    ${isActive ? `
        border-width: 2px;
        border-color: ${theme.colors.surfaceTitle};
      ` : ''}
    
    &:hover {
      cursor: pointer;
      background: ${theme.colors.gray910};
    }
 `}
  ${media.minLargeDesktop`
    max-width: 215px;
  `}
`;

// #endregion

type OptionButtonProps = {
  title: string;
  isActive: boolean;
  imagePath: string;
  onClick: () => void;
  isAsap?: boolean;
  deliveryFee?: number;
  isCheckout: boolean;
  hideIcon?: boolean;
  deliveryFeeBeforeDiscount?: number;
};

const OptionButton = ({
  title,
  isActive,
  imagePath,
  isAsap,
  onClick,
  deliveryFee,
  isCheckout,
  hideIcon,
  deliveryFeeBeforeDiscount,
}: OptionButtonProps) => (
  <ModifiedButton
    isActive={isActive}
    data-test-id={isAsap ? 'ASAP_TOGGLER' : 'POOL_TOGGLER'}
    onClick={onClick}
  >
    <ETAInfoBox
      title={title}
      isAsap={isAsap}
      isCheckout={isCheckout}
      imagePath={imagePath}
      deliveryFee={deliveryFee}
      hideIcon={hideIcon}
      deliveryFeeBeforeDiscount={deliveryFeeBeforeDiscount}
    />
  </ModifiedButton>
);

export default memo(OptionButton);
