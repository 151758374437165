import {useEffect, useMemo} from 'react';

import {useSelector} from 'react-redux';
import Bowser from 'bowser';
import {isEmpty} from 'lodash';

import renderingForRobot from '~/shared/services/renderingForRobot';
import {log} from '~/shared/logging/sentry';
import {selectCurrentAddress, selectCurrentDeliveryMethod, selectUserData} from '~/shared/store/selectors';
import {getLocalizationService} from '~/shared/services/localisationService';

import {isAddress} from '../store/models/Address';

const useSentryBreadcrumbs = () => {
  const {currentLanguageKey} = getLocalizationService();
  const userData = useSelector(selectUserData);
  const currentAddress = useSelector(selectCurrentAddress);
  const deliveryMethod = useSelector(selectCurrentDeliveryMethod);

  const bowser = useMemo(() => Bowser.parse(navigator?.userAgent), []);
  const address = useMemo(() => {
    if (!isEmpty(currentAddress)) {
      return {
        isCompanyAddress: isAddress(currentAddress) ? currentAddress.isCompanyAddress : false,
        addressId: isAddress(currentAddress) ? currentAddress.addressId : undefined,
        cityName: currentAddress.cityName,
        streetName: currentAddress.streetName,
        houseNumber: currentAddress.houseNumber,
      };
    }
  }, [currentAddress]);

  useEffect(() => {
    log({
      loggerName: 'App state change',
      errorOrMessage: 'collected breadcrumbs',
      logLevel: 'info',
      args: {
        isLoggedIn: !!userData,
        isBot: renderingForRobot,
        companyId: userData?.companyId,
        languageKey: currentLanguageKey,
        device: bowser?.platform?.type,
        address,
        deliveryMethod,
        // eslint-disable-next-line no-restricted-properties
        path: window.location.href,
      },
    });
  }, [currentLanguageKey, address, userData, bowser, deliveryMethod]);
};

export default useSentryBreadcrumbs;
