export interface Coupon {
  code: string;
  description: string;
  discountValue: number;
  valueType: CouponValueType;

  // Only in Next Model
  id?: number;
  isActive?: boolean;
  assignmentType?: CouponAssignmentType;
  benefitPayer?: string;

  // Not in Next Model
  couponSource?: string;
  couponValueForOrder?: number;
}

export enum CouponValueType {
  AMOUNT = 'Amount',
  PERCENT = 'Percent',
}

export enum CouponAssignmentType {
  ITEMS = 'OrderItems',
  TOTAL = 'OrderTotal'
}
