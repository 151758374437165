import {useEffect} from 'react';

function preventScrolling() {
  document.body.classList.add('lock-scroll');
}

function allowScrolling() {
  document.body.classList.remove('lock-scroll');
}

const usePreventBodyScrolling = ({shouldPreventScroll}: {shouldPreventScroll: boolean}) => {
  useEffect(() => {
    if (shouldPreventScroll) {
      preventScrolling();
    } else {
      allowScrolling();
    }

    return () => {
      allowScrolling();
    };
  }, [shouldPreventScroll]);
};

export default usePreventBodyScrolling;
