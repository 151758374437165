import {useEffect} from 'react';

import styled, {ThemeProvider} from 'styled-components';
import {useSelector} from 'react-redux';

import {attachModal} from '~/shared/components/ReduxModal';
import {PopupModalBase} from '~/shared/components/Modals';
import {trackPageview} from '~/shared/services/analytics';
import {getLocalizationService} from '~/shared/services/localisationService';
import {useCheckoutTheme, Checkout} from '~/checkout';
import {selectCurrentRestaurant, selectIsFutureOrderEnabled} from '~/shared/store/selectors';

const Root = styled(PopupModalBase)`
  &&& {
    width: 504px;
    overflow: auto;
  }
`;

const CheckoutModal = () => {
  const {currentLanguageKey} = getLocalizationService();
  const theme = useCheckoutTheme();
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isRestaurantPreOrder = Number(useSelector(selectIsFutureOrderEnabled));

  useEffect(() => {
    trackPageview({
      currentLanguageKey,
      pageGroup: 'checkout',
      eventPayload: {
        restaurantData: {
          isRestaurantPreOrder,
        },
      },
    });
  }, [currentLanguageKey, currentRestaurant, isRestaurantPreOrder]);

  return (
    <ThemeProvider theme={theme}>
      <Root>
        <Checkout
          centerSubmitBtn={false}
          returnToCheckoutOnClose
          showHeader
          closeModalOnBack
          showAddressLabelPrefix
          hidePaymentSummary
          isAgeRestrictionDisclaimerNeeded
        />
      </Root>
    </ThemeProvider>
  );
};

CheckoutModal.hideCloseButton = true;

export default attachModal('checkout_modal', CheckoutModal);
