import styled, {css} from 'styled-components';

export const flexStart = css`
  display: flex;
  align-items: flex-start;
`;

export const FlexStart = styled.div`
  ${flexStart}
`;
