import {css} from 'styled-components';

const returnWithCss = (cssString: string): ReturnType<typeof css> => css`
  ${cssString}
`;

export const convertGapToMargin = (gapValue: string, isLTR: boolean): ReturnType<typeof css> | '' => {
  const gapValues = gapValue.split(' ').map(value => value.trim());

  if (gapValues.length === 1) {
    const [gap] = gapValues;
    return returnWithCss(isLTR
      ? `& > *:not(:last-child) { margin-right: ${gap}; margin-bottom: ${gap}; }`
      : `& > *:not(:last-child) { margin-left: ${gap}; margin-bottom: ${gap}; }`);
  }
  
  if (gapValues.length === 2) {
    const [verticalGap, horizontalGap] = gapValues;
    return returnWithCss(isLTR
      ? `& > *:not(:last-child) { margin-right: ${horizontalGap}; margin-bottom: ${verticalGap}; }`
      : `& > *:not(:last-child) { margin-left: ${horizontalGap}; margin-bottom: ${verticalGap}; }`);
  }
  
  return '';
};
