import {useState, memo} from 'react';

import styled, {useTheme} from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';

import {HiddenSpan} from '~/shared/components/styled';
import {getLocalizationService} from '~/shared/services/localisationService';
import {useToggle} from '~/shared/hooks';
import DiagonalHeaderView from '~/shared/components/DiagonalHeaderView';
import {setActionMadeFrom, setCurrentModal, setDirtyShoppingCart, setOrderPermit} from '~/shared/store/actions';
import {onCheckoutOpen} from '~/shared/services/shoppingCart';
import {flipOnLTR, media} from '~/shared/theme/utils';
import {attachModal} from '~/shared/components/ReduxModal';
import ErrorBox from '~/shared/components/ErrorBox';
import {
  selectCurrentRestaurant,
  selectCurrentDeliveryMethod,
  selectCurrentCoupon,
  selectDishesAdditionalDetails,
  selectUserData,
  selectShoppingCartBillingLines,
  selectDishesWithSubs,
  selectShouldOpenModal,
  selectIsDeliveringToCurrentAddress,
} from '~/shared/store/selectors';
import Button from '~/shared/components/Button';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {ModalContent, PopupModalBase} from '~/shared/components/Modals';
import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {handleRefreshToken} from '~/shared/services/auth';
import {is401Error} from '~/shared/services/apiErrorService';

const Root = styled(PopupModalBase)`
  ${media.minLargeTablet`
    &&& {
      height: 475px;
    }
  `}
`;

const OpenFieldAreaButton = styled(Button)`
  color: ${({theme}) => theme.modal.subHeader};
  text-decoration: underline;
`;

const Input = styled.input`
  border: 1px solid ${({theme}) => theme.colors.gray500};
  border-radius: 1px;
  background-color: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.checkoutErrorModal.input.color};

  &&& {
    font-size: 14px;
  }

  ${flipOnLTR`
    padding-right: 5px;
  `}

  width: 102px;
  height: 100%;

  ::placeholder {
    color: ${({theme}) => theme.checkoutErrorModal.input.color};
  }
`;

const SubmitButton = styled(Button)`
  width: 80px;
  border-radius: 1px 0 0 1px;
  background-color: ${({theme}) => theme.checkoutErrorModal.button.backgroundColor};
  border: 0;
  color: ${({theme}) => theme.colors.secondaryText};
  ${flipOnLTR`
    margin-right: 5px;
  `}
`;

const ErrorBoxContainer = styled.div`
  margin-top: 10px;
`;

const ErrorMessage = styled.div`
  margin-bottom: 10px;
`;

const InputOrButton = ({isInputFieldShown, toggleInputFieldShown, code, setCode, onSubmit}) => {
  const {t} = getLocalizationService();
  if (isInputFieldShown) {
    return (
      <div>
        <Input type="text" value={code} onChange={e => setCode(e.target.value)} placeholder={t('the_code_is')} />
        <SubmitButton onClick={onSubmit}>{t('confirmation')}</SubmitButton>
      </div>
    );
  }

  return (
    <OpenFieldAreaButton onClick={toggleInputFieldShown}>
      {t('i_have_a_code_from_customer_service')}
    </OpenFieldAreaButton>
  );
};

const CheckoutErrorMessageModal = ({
  args: {errorMessageCodes, showServiceRepresentativeLink, shouldShowAgeConfirm},
  ...rest
}) => {
  const {t} = getLocalizationService();

  const deliveryMethod = useSelector(selectCurrentDeliveryMethod);
  const restaurant = useSelector(selectCurrentRestaurant);
  const isDeliveringToCurrentAddress = useSelector(selectIsDeliveringToCurrentAddress);
  const billingLines = useSelector(selectShoppingCartBillingLines);
  const userData = useSelector(selectUserData);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const isMaxLargeMobile = useIsMaxLargeMobile();
  const dishes = useSelector(selectDishesAdditionalDetails);
  const dishesWithSubs = useSelector(selectDishesWithSubs);
  const shouldOpenModal = useSelector(selectShouldOpenModal);

  const dispatch = useDispatch();

  const [code, setCode] = useState('');
  const [showError, setShowError] = useState(false);

  const theme = useTheme();

  const [isInputFieldShown, toggleInputFieldShown] = useToggle(false);

  const onSubmit = async () => {
    if (!code) {
      console.warn('there isnt any code'); // eslint-disable-line no-console
      return;
    }

    setShowError(false);

    try {
      const permitRes = await ManagerProvider.setPermitCodeInOrder(code);

      dispatch(setOrderPermit(permitRes.data.permits));
      dispatch(
        setCurrentModal('successModal', {
          title: 'the_code_was_submitted_successfully',
          text: 'now_you_can_continue_to_checkout',
          btnText: 'continue',
          disableCloseModal: true,
          onSuccess: () =>
            onCheckoutOpen({
              permits: permitRes.data.permits,
              setCurrentModal: (name, args) => dispatch(setCurrentModal(name, args)),
              billingLines,
              userData,
              restaurant,
              deliveryMethod,
              currentCoupon,
              iseMobileDevice: isMaxLargeMobile,
              dishes,
              isDeliveringToCurrentAddress,
              dishesWithSubs,
              setActionMadeFrom: actionMadeFrom => dispatch(setActionMadeFrom(actionMadeFrom)),
              shouldShowAgeConfirm,
              shouldOpenModal,
              ...rest,
            }),
        }),
      );
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, onSubmit);
        return;
      }

      setShowError(true);
      dispatch(setDirtyShoppingCart(false));
    }
  };

  return (
    <Root>
      <DiagonalHeaderView
        title={`${t('everythings_good_just')}...`}
        backgroundColor={theme.checkoutErrorModal.backgroundColor}
        isErrorMessageModal
      >
        <HiddenSpan id="modal-title">{`${t('everythings_good_just')}...`}</HiddenSpan>
        <ModalContent>
          <div id="modal-content">
            {errorMessageCodes?.map(errorMessageCode => (
              <ErrorMessage key={errorMessageCode}>{t(errorMessageCode)}</ErrorMessage>
            ))}
          </div>

          {showServiceRepresentativeLink && (
            <InputOrButton {...{isInputFieldShown, toggleInputFieldShown, code, setCode, onSubmit}} />
          )}

          {showError && (
            <ErrorBoxContainer>
              <ErrorBox withArrow={false}>{t('the_code_you_entered_does_not_appear_in_the_system')}</ErrorBox>
            </ErrorBoxContainer>
          )}
        </ModalContent>
      </DiagonalHeaderView>
    </Root>
  );
};

attachModal('checkout_error_message_modal', memo(CheckoutErrorMessageModal));
