import {memo} from 'react';

import styled from 'styled-components';

import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import {getLocalizationService} from '~/shared/services/localisationService';
import useChat from '~/shared/hooks/useChat';
import {body14Normal} from '~/shared/theme/typography';
import {media} from '~/shared/theme/media';
import {ActionButton} from '~/shared/components/Button';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {Link} from '~/shared/router';
import {FlexColumn} from '~/shared/theme/FlexLayout';
import {Section} from '~/shared/components/OrderCompleteSections/parts';

const RestaurantButtonContainer = styled.div`
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  text-align: center;
  display: contents;
  width: 250px;
`;

const RestaurantButton = styled(ActionButton)`
  border-radius: 0;
  box-shadow: none;
  padding-top: 0;
  height: 40px;
  width: inherit;
  ${media.minLargeTablet`
    width: 250px;
`};
`;

const ContactUsContainer = styled.div`
  ${body14Normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  margin-top: 16px;
`;

const ContactUsToChat = styled(ContactUsContainer)`
  margin-top: 0;
  margin-right: 5px;
  ${flipOnLTR`
    margin-left: 5px;
  `}
`;

const ChatContainerWrapper = styled.div`
  display: flex;
  margin: 0 auto;
`;

const ToChartLink = styled(Link)`
  ${body14Normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  text-decoration: underline;
  color: ${({theme}) => theme.colors.secondary};
`;

const CancelDetailsWrapper = styled(Section)`
  margin-bottom: 8px;
  margin-top: 0;
  align-items: center;
`;

const CancelDetailsInfo = styled.div`
  ${body14Normal};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  margin-bottom: 13px;
  ${media.minLargeTablet`
    ${body14Normal};
    width: 300px;
    margin: 0 auto 20px;
`};
`;

const ScooberCanceledOrderSection = () => {
  const {t} = getLocalizationService();
  const {isAvailable, openChat} = useChat();

  return (
    // @ts-expect-error Section.js should be refactored to ts
    <CancelDetailsWrapper title={t('canceled_order')}>
      <CancelDetailsInfo>{t('scoober_canceled_title')}</CancelDetailsInfo>

      <RestaurantButtonContainer>
        <FlexColumn>
          <RestaurantButton onClick={() => navigateToDefaultStartPage()}>{t('to_restaurant_list')}</RestaurantButton>
          <ContactUsContainer>{t('scoober_canceled_have_question')}</ContactUsContainer>
          <ChatContainerWrapper>
            <ContactUsToChat>{t('scoober_canceled_contact_us')}</ContactUsToChat>
            {isAvailable && <ToChartLink onClick={openChat}>{t('our_chat')}</ToChartLink>}
          </ChatContainerWrapper>
        </FlexColumn>
      </RestaurantButtonContainer>
    </CancelDetailsWrapper>
  );
};

export default memo(ScooberCanceledOrderSection);
