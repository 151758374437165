import {groupBy, isEmpty, chunk, last} from 'lodash';

import {RestaurantsGroupTypes} from '~/shared/consts/restaurantConsts';
import {
  selectIsMinLargeDesktop,
  selectIsMinLargeMobile,
  selectIsMinLargeTablet,
} from '~/shared/store/storeModules/userDevice/userDeviceSelectors';
import store from '~/shared/store';

export const HEADER_HEIGHT = 100;
export const HEADER_HEIGHT_MOBILE = 64;
export const HEADER_HEIGHT_EXTRA_TEXT = 22;
export const CHAIN_RESTAURANTS_ROW_HEIGHT = 280;

// Describes row height and spacing by number of columns [1 col, 2 col (tablet), 2 col (largeTablet) 3 col]
export const RESTAURANTS_ROW_HEIGHT = [303, 244, 262, 262];
export const RESTAURANTS_ROW_SPACING = [16, 20, 20, 21];

export const getRowHeightCreator = () => {
  const state = store.getState();

  const isLargeMobile = selectIsMinLargeMobile(state);
  const isMinLargeTablet = selectIsMinLargeTablet(state);
  const isMinLargeDesktop = selectIsMinLargeDesktop(state);

  const index = (() => {
    if (isMinLargeDesktop) {
      return 3;
    }

    if (isMinLargeTablet) {
      return 2;
    }

    if (isLargeMobile) {
      return 1;
    }

    return 0;
  })();

  return isLastItem =>
    RESTAURANTS_ROW_HEIGHT[index] + (isLastItem ? 0 : RESTAURANTS_ROW_SPACING[index]);
};

const withHeaderHeight = (header, columns) => {
  if (!header?.length) {
    return [];
  }
  const headerHeight = columns === 1 ? HEADER_HEIGHT_MOBILE : HEADER_HEIGHT;
  return [
    {
      ...header[0],
      height: (header[0].subTitleKey && (header[0].isCuisineStrip || header[0].isCustomGroup)) ?
        headerHeight + HEADER_HEIGHT_EXTRA_TEXT : headerHeight,
    },
  ];
};

const shiftMostFittingItem = (mutablePooledItemsByStatus, statusPriorities) => {
  const mostFittingStatus = statusPriorities.find(status => mutablePooledItemsByStatus[status]);
  if (!mostFittingStatus) {
    return;
  }

  const item = mutablePooledItemsByStatus[mostFittingStatus].shift();
  if (mutablePooledItemsByStatus[mostFittingStatus].length === 0) {
    delete mutablePooledItemsByStatus[mostFittingStatus];
  }

  return item;
};

const sortPooledItemsForOneColumn = (mutablePooledItemsByStatus, rowNumber) => {
  switch (rowNumber % 3) {
    case 0:
      return [
        shiftMostFittingItem(mutablePooledItemsByStatus, [
          'not_passed_minimum_scoober',
          'not_passed_minimum',
          'passed_minimum_scoober',
          'passed_minimum',
          'no_orders_yet',
        ]),
      ].filter(Boolean);
    case 1:
      return [
        shiftMostFittingItem(mutablePooledItemsByStatus, [
          'not_passed_minimum_scoober',
          'not_passed_minimum',
          'passed_minimum_scoober',
          'passed_minimum',
          'no_orders_yet',
        ]),
      ].filter(Boolean);
    default:
      return [
        shiftMostFittingItem(mutablePooledItemsByStatus, [
          'not_passed_minimum_scoober',
          'not_passed_minimum',
          'passed_minimum_scoober',
          'passed_minimum',
          'no_orders_yet',
        ]),
      ].filter(Boolean);
  }
};

const sortPooledItemsForTwoColumns = (mutablePooledItemsByStatus, rowNumber) => {
  if (rowNumber % 2) {
    return [
      shiftMostFittingItem(mutablePooledItemsByStatus, [
        'not_passed_minimum_scoober',
        'not_passed_minimum',
        'passed_minimum_scoober',
        'passed_minimum',
        'no_orders_yet',
      ]),
      shiftMostFittingItem(mutablePooledItemsByStatus, [
        'not_passed_minimum_scoober',
        'not_passed_minimum',
        'passed_minimum_scoober',
        'passed_minimum',
        'no_orders_yet',
      ]),
    ].filter(Boolean);
  }
  return [
    shiftMostFittingItem(mutablePooledItemsByStatus, [
      'not_passed_minimum_scoober',
      'not_passed_minimum',
      'passed_minimum_scoober',
      'passed_minimum',
      'no_orders_yet',
    ]),
    shiftMostFittingItem(mutablePooledItemsByStatus, [
      'not_passed_minimum_scoober',
      'not_passed_minimum',
      'passed_minimum_scoober',
      'passed_minimum',
      'no_orders_yet',
    ]),
  ].filter(Boolean);
};

const sortPooledItemsForThreeColumns = mutablePooledItemsByStatus => {
  return [
    shiftMostFittingItem(mutablePooledItemsByStatus, [
      'not_passed_minimum_scoober',
      'not_passed_minimum',
      'passed_minimum_scoober',
      'passed_minimum',
      'no_orders_yet',
    ]),
    shiftMostFittingItem(mutablePooledItemsByStatus, [
      'not_passed_minimum_scoober',
      'not_passed_minimum',
      'passed_minimum_scoober',
      'passed_minimum',
      'no_orders_yet',
    ]),
    shiftMostFittingItem(mutablePooledItemsByStatus, [
      'not_passed_minimum_scoober',
      'not_passed_minimum',
      'passed_minimum_scoober',
      'passed_minimum',
      'no_orders_yet',
    ]),
  ].filter(Boolean);
};

const generatePooledRowByGroups = (mutablePooledItemsByStatus, numberOfColumns, groupId, rowNumber) => {
  let items;

  switch (numberOfColumns) {
    case 1:
      items = sortPooledItemsForOneColumn(mutablePooledItemsByStatus, rowNumber);
      break;
    case 2:
      items = sortPooledItemsForTwoColumns(mutablePooledItemsByStatus, rowNumber);
      break;
    default:
      items = sortPooledItemsForThreeColumns(mutablePooledItemsByStatus);
  }

  const height = getRowHeightCreator(numberOfColumns)();

  return {
    key: last(items).key,
    groupId,
    height,
    items: items.map((i, columnIndex) => ({
      restaurantItem: i.item,
      groupId,
      itemIndexInGroup: (rowNumber * numberOfColumns) + columnIndex,
    })),
  };
};

const generatePooledOrderRows = (pooledItems, numberOfColumns, groupId) => {
  if (!pooledItems || pooledItems.length === 0) {
    return [];
  }

  const results = [];
  const header = pooledItems?.filter(item => item.isGroup) || [];

  // update header count from closed pooled restaurants
  const openItems = pooledItems.filter(({item, isGroup}) => !isGroup && item.isOpenNow);
  header[0].count = openItems.length;

  const mutablePooledItemsByStatus = groupBy(
    pooledItems?.filter(item => !item.isGroup),
    ({item: {pooledOrderSum, minimumPriceForOrder, isScoober}}) => {
      if (pooledOrderSum === 0) {
        return 'no_orders_yet';
      }
      if (pooledOrderSum >= minimumPriceForOrder && isScoober) {
        return 'passed_minimum_scoober';
      }
      if (pooledOrderSum >= minimumPriceForOrder && !isScoober) {
        return 'passed_minimum';
      }
      if (pooledOrderSum < minimumPriceForOrder && isScoober) {
        return 'not_passed_minimum_scoober';
      }
      if (pooledOrderSum < minimumPriceForOrder && !isScoober) {
        return 'not_passed_minimum';
      }
    },
  );

  let rowNumber = 0;
  while (!isEmpty(mutablePooledItemsByStatus)) {
    results.push(generatePooledRowByGroups(mutablePooledItemsByStatus, numberOfColumns, groupId, rowNumber++));
  }

  // last row shouldnt receive the additional spacing
  results[results.length - 1].height = RESTAURANTS_ROW_HEIGHT[numberOfColumns - 1];

  return [...withHeaderHeight(header, numberOfColumns), ...results];
};

const generateOtherRows = (otherItems, numberOfColumns, groupId) => {
  const header = otherItems?.filter(item => item.isGroup) || [];

  const getRowHeight = getRowHeightCreator(numberOfColumns);

  return [
    ...withHeaderHeight(header, numberOfColumns),
    ...chunk(
      otherItems?.filter(item => !item.isGroup),
      numberOfColumns,
    ).map((rowItems, rowIndex, arr) => ({
      groupId,
      height: getRowHeight(arr.length - 1 === rowIndex),
      key: last(rowItems).key,
      items: rowItems.map((i, columnIndex) => ({
        restaurantItem: i.item,
        groupId,
        itemIndexInGroup: (rowIndex * numberOfColumns) + columnIndex,
      })),
    })),
  ];
};

const generateGroupRows = (otherItems, numberOfColumns, groupId) => {
  const header = otherItems?.filter(item => item.isGroup) || [];

  const getRowHeight = getRowHeightCreator(numberOfColumns);

  return [
    ...withHeaderHeight(header, numberOfColumns),
    ...chunk(
      otherItems?.filter(item => !item.isGroup),
      numberOfColumns,
    ).map((rowItems, index, arr) => ({
      groupId,
      height: getRowHeight(arr.length - 1 === index),
      key: last(rowItems).key,
      items: rowItems.map(i => i.items[0]),
    })),
  ];
};

export const sortRestaurantsList = ({items, numberOfColumns = 3}) => {
  const itemsByGroupId = groupBy(items, 'groupId');

  if (itemsByGroupId[RestaurantsGroupTypes.POOLED] || itemsByGroupId[RestaurantsGroupTypes.NOT_POOLED_RESTAURANTS]) {
    return [
      ...generateOtherRows(
        itemsByGroupId[RestaurantsGroupTypes.NOT_POOLED_RESTAURANTS],
        numberOfColumns,
        RestaurantsGroupTypes.NOT_POOLED_RESTAURANTS,
      ),
      ...generatePooledOrderRows(
        itemsByGroupId[RestaurantsGroupTypes.POOLED],
        numberOfColumns,
        RestaurantsGroupTypes.POOLED,
      ),
      ...generateOtherRows(
        itemsByGroupId[RestaurantsGroupTypes.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW],
        numberOfColumns,
        RestaurantsGroupTypes.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW,
      ),
      ...generateOtherRows(itemsByGroupId[RestaurantsGroupTypes.CLOSED], numberOfColumns, RestaurantsGroupTypes.CLOSED),
    ];
  }

  return [
    ...generateOtherRows(itemsByGroupId[RestaurantsGroupTypes.OPEN], numberOfColumns, RestaurantsGroupTypes.OPEN),
    ...generateOtherRows(
      itemsByGroupId[RestaurantsGroupTypes.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW],
      numberOfColumns,
      RestaurantsGroupTypes.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW,
    ),
    ...generateOtherRows(itemsByGroupId[RestaurantsGroupTypes.CLOSED], numberOfColumns, RestaurantsGroupTypes.CLOSED),
  ];
};

export const arrangeGroupItems = ({items, numberOfColumns = 3, groupByKey = 'groupId'}) => {
  const itemsByGroupId = groupBy(items, groupByKey);
  return generateGroupRows(itemsByGroupId[RestaurantsGroupTypes.GROUP], numberOfColumns, RestaurantsGroupTypes.GROUP);
};
