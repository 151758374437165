import {
  RestaurantsFiltersSortOptionsType,
  RestaurantsFiltersSortOptionsTypes,
  MIN_REVIEWS_TO_DISPLAY_RATING,
} from '~/shared/consts/restaurantConsts';

import {RestaurantFromSearch} from '../../models';

interface RestaurantSortOption {
  id: RestaurantsFiltersSortOptionsType;
  compare: (a: RestaurantFromSearch, b: RestaurantFromSearch) => number;
}

const restaurantSortOptions: RestaurantSortOption[] = [
  {
    id: RestaurantsFiltersSortOptionsTypes.SPECIAL,
    compare: () => 1,
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.COUPONVALUE,
    compare: (a, b) => b.discountPercent - a.discountPercent,
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.DISTANCE,
    compare: (a, b) => a.distanceFromUser - b.distanceFromUser,
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.RANKING,
    compare: (a, b) => rankingPriorityCompare(a, b),
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.MINIMUMORDER,
    compare: (a, b) => a.minimumPriceForOrder - b.minimumPriceForOrder,
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.DELIVERYFEE,
    compare: (a, b) => deliveryFeeCompare(a, b),
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.DELIVERYTIME,
    compare: (a, b) => deliveryTimeCompare(a, b),
  },
  {
    id: RestaurantsFiltersSortOptionsTypes.SPECIALPICKUP,
    compare: (a, b) => a.distanceFromUser - b.distanceFromUser,
  },
];

function compareBooleans(a: boolean, b: boolean): number {
  if (a && !b) {
    return 1;
  }
  if (b && !a) {
    return -1;
  }
  return 0;
}

function rankingPriorityCompare(a: RestaurantFromSearch, b: RestaurantFromSearch) {
  return (
    compareBooleans(b.numOfReviews > MIN_REVIEWS_TO_DISPLAY_RATING, a.numOfReviews > MIN_REVIEWS_TO_DISPLAY_RATING) ||
    b.reviewsRankDecimal - a.reviewsRankDecimal ||
    b.numOfReviews - a.numOfReviews
  );
}

function deliveryFeeCompare(a: RestaurantFromSearch, b: RestaurantFromSearch) {
  return (
    compareBooleans(Boolean(b.estimatedNumberOfOrdersInPoolOrder), Boolean(a.estimatedNumberOfOrdersInPoolOrder)) ||
    a.deliveryFeeAmount - b.deliveryFeeAmount
  );
}

function deliveryTimeCompare(a: RestaurantFromSearch, b: RestaurantFromSearch) {
  return (
    compareBooleans(Boolean(b.isOpenNow && b.isActive && !b.estimatedNumberOfOrdersInPoolOrder && b.deliveryTimeInMinutes), Boolean(a.isOpenNow && a.isActive && !a.estimatedNumberOfOrdersInPoolOrder && a.deliveryTimeInMinutes)) ||
    compareBooleans(!b.isPooledOrderRestaurant, !a.isPooledOrderRestaurant) ||
    a.deliveryTimeInMinutes - b.deliveryTimeInMinutes
  );
}

export default restaurantSortOptions;
