import {transform} from 'lodash';

import CreditCardIcon from '~/assets/images/icons/cards/creditCardIcon.svg?react';
import AmexIcon from '~/assets/images/icons/cards/amex.svg?react';
import MastercardIcon from '~/assets/images/icons/cards/mastercard.svg?react';
import VisaIcon from '~/assets/images/icons/cards/visa_icon.svg?react';
import PayPalIcon from '~/assets/images/icons/cards/paypal.svg?react';
import MoneyCardIcon from '~/assets/images/icons/cards/moneycard.svg?react';
import CashIcon from '~/assets/images/icons/cards/cash.svg?react';
import DinersIcon from '~/assets/images/icons/cards/diners.svg?react';
import TenbisCredit from '~/assets/images/icons/tenbisCredit.svg?react';
import ApplePayCard from '~/assets/images/icons/applePayCard.svg?react';
import {PaymentMethodTypes, PaymentMethod} from '~/shared/consts/paymentConsts';

import {Payment} from '../store/models';

export const paymentMethodIsCreditCard = (paymentMethod: PaymentMethod, creditCardType?: string) => {
  return creditCardType !== 'none' && paymentMethod.toLowerCase() === PaymentMethodTypes.CREDIT_CARD.toLowerCase();
};

export const paymentMethodIsMoneyCard = (paymentMethod: PaymentMethod) => {
  return paymentMethod.toLowerCase() === PaymentMethodTypes.MONEY_CARD.toLowerCase();
};

export const paymentMethodIsPayPal = (paymentMethod: PaymentMethod) => {
  return paymentMethod.toLowerCase() === PaymentMethodTypes.PAYPAL.toLowerCase();
};

export const paymentMethodIsCash = (paymentMethod: PaymentMethod) => {
  return paymentMethod.toLowerCase() === PaymentMethodTypes.CASH.toLowerCase();
};
export const paymentMethodIsApplePay = (paymentMethod: PaymentMethod) => {
  return paymentMethod.toLowerCase() === PaymentMethodTypes.APPLE_PAY.toLowerCase();
};

export const creditCardTypesEnum: Record<string, string> = {
  amex: 'Amex',
  diners: 'Diners',
  creditcardicon: 'CreditCardIcon',
  mastercard: 'Mastercard',
  visa: 'Visa',
  newcreditcard: 'NewCreditCard',
  paypal: 'PayPal',
  moneycard: 'MoneyCard',
  cash: 'Cash',
  credit: 'Credit',
  applepay: 'ApplePay',
  none: 'NewCreditCard',
  unknown: 'NewCreditCard',
};

const creditCardNameToIconNameMapping: Record<string, string> = transform(
  {
    amex: ['amex'],
    diners: ['diners'],
    creditCardIcon: ['discover', 'maestro', 'switch', 'solo', 'jcb', 'carteblanche', 'chinaunionpay', 'laser'],
    mastercard: ['isracard', 'mastercard'],
    visa: ['visa', 'leumicard', 'cal', 'creditcard'],
    newCreditCard: ['default'],
  },
  (result: typeof creditCardNameToIconNameMapping, cardNames, cardIconName) => {
    cardNames.forEach(cardName => {
      result[cardName] = cardIconName;
    });
  },
  {},
);

export const paymentCardsIconComponents = {
  [creditCardTypesEnum.amex]: AmexIcon,
  [creditCardTypesEnum.diners]: DinersIcon,
  [creditCardTypesEnum.creditcardicon]: CreditCardIcon,
  [creditCardTypesEnum.mastercard]: MastercardIcon,
  [creditCardTypesEnum.visa]: VisaIcon,
  [creditCardTypesEnum.newcreditcard]: CreditCardIcon,
  [creditCardTypesEnum.paypal]: PayPalIcon,
  [creditCardTypesEnum.moneycard]: MoneyCardIcon,
  [creditCardTypesEnum.cash]: CashIcon,
  [creditCardTypesEnum.credit]: TenbisCredit,
  [creditCardTypesEnum.applepay]: ApplePayCard,
};

export const getPaymentType = (paymentMethod: PaymentMethod, creditCardType?: string) => {
  const isCreditCard = paymentMethodIsCreditCard(paymentMethod, creditCardType);
  const isMoneyCard = paymentMethodIsMoneyCard(paymentMethod);
  const isCash = paymentMethodIsCash(paymentMethod);
  const isPaypal = paymentMethodIsPayPal(paymentMethod);
  const isApplePay = paymentMethodIsApplePay(paymentMethod);
  return {isCreditCard, isMoneyCard, isCash, isPaypal, isApplePay};
};

export const getCreditCardName = ({paymentMethod, isTenbisCredit, creditCardType}: {paymentMethod: PaymentMethod; isTenbisCredit: boolean; creditCardType?: string}) => {
  const {isCreditCard, isMoneyCard, isCash, isPaypal, isApplePay} = getPaymentType(paymentMethod, creditCardType);

  if (isTenbisCredit) {
    return PaymentMethodTypes.CREDIT;
  }
  if (isCreditCard) {
    if (creditCardType) {
      return creditCardNameToIconNameMapping[
        creditCardType.toLowerCase() as keyof typeof creditCardNameToIconNameMapping
      ];
    }
    return creditCardTypesEnum.creditcardicon;
  }

  if (isMoneyCard) {
    return PaymentMethodTypes.MONEY_CARD;
  }
  if (isCash) {
    return PaymentMethodTypes.CASH;
  }
  if (isPaypal) {
    return PaymentMethodTypes.PAYPAL;
  }
  if (isApplePay) {
    return PaymentMethodTypes.APPLE_PAY;
  }
  return PaymentMethodTypes.UNKNOWN;
};

export const getPaymentCardIconComponent = (
  paymentMethod: PaymentMethod,
  creditCardType: keyof typeof creditCardNameToIconNameMapping,
  isTenbisCredit: boolean,
) => {
  if (paymentMethod === PaymentMethodTypes.APPLE_PAY.toLowerCase()) {
    return ApplePayCard;
  }

  const cardName = getCreditCardName({paymentMethod, isTenbisCredit, creditCardType});
  const creditCardName = creditCardTypesEnum[cardName.toLowerCase() as keyof typeof creditCardTypesEnum];
  return paymentCardsIconComponents[creditCardName];
};

export const filterPayments = (paymentsList: Payment[], searchValue: string) => {
  let filteredPayments = paymentsList;
  if (searchValue) {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    filteredPayments = filteredPayments.filter(
      payment =>
        payment.userName?.toLowerCase().includes(lowerCaseSearchValue) ||
        payment.cardLastDigits?.includes(lowerCaseSearchValue),
    );
  }
  return filteredPayments;
};

const sortingAvailablePaymentsOrder = {
  [PaymentMethodTypes.CREDIT]: 1,
  [PaymentMethodTypes.CREDIT_CARD]: 2,
  [PaymentMethodTypes.PAYPAL]: 3,
  [PaymentMethodTypes.MONEY_CARD]: 4,
  [PaymentMethodTypes.CASH]: 5,
};

const checkIfCredit = (payment: Payment) => {
  if (payment.isTenbisCredit) {
    return PaymentMethodTypes.CREDIT;
  }
  return payment.paymentMethod;
};

export const sortPayments = (paymentsList: Payment[]) =>
  paymentsList.sort(
    (a: Payment, b: Payment) => {
      const APaymentMethod = checkIfCredit(a);
      const BPaymentMethod = checkIfCredit(b);

      return (
        sortingAvailablePaymentsOrder[APaymentMethod as keyof typeof sortingAvailablePaymentsOrder] -
        sortingAvailablePaymentsOrder[BPaymentMethod as keyof typeof sortingAvailablePaymentsOrder]
      );
    },
  );
