export const header72Bold = `
  font-size: 72px;
  font-weight: bold;
  line-height: 72px;
`;

export const header70Bold = `
  font-size: 70px;
  font-weight: bold;
  line-height: 74.2px;
`;

export const header64Bold = `
  font-size: 64px;
  font-weight: bold;
  line-height: 64px;
`;

export const header49Bold = `
  font-size: 49px;
  font-weight: bold;
  line-height: 52px;
`;

export const header40Bold = `
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
`;

export const header40Normal = `
  font-size: 40px;
  font-weight: normal;
`;

export const header36Normal = `
  font-size: 36px;
  font-weight: normal;
  line-height: 36px;
`;

export const header36Bold = `
  font-size: 36px;
  font-weight: bold;
  line-height: 1.1;
`;

export const header32Bold = `
  font-size: 32px;
  font-weight: bold;
`;

export const subheader30Bold = `
  font-size: 30px;
  font-weight: bold;
`;

export const subheader30Normal = `
  font-size: 30px;
  font-weight: normal;
`;

export const subheader26Bold = `
  font-size: 26px;
  font-weight: bold;
`;

export const subheader26Normal = `
  font-size: 26px;
  font-weight: normal;
`;

export const subheader25Normal = `
  font-size: 25px;
  font-weight: normal;
`;

export const subheader24Bold = `
  font-size: 24px;
  font-weight: bold;
`;

export const subheader24Normal = `
  font-size: 24px;
  font-weight: normal;
`;

export const subheader22Bold = `
  font-size: 22px;
  font-weight: bold;
`;

export const body20Bold = `
  font-size: 20px;
  font-weight: bold;
`;

export const body20Normal = `
  font-size: 20px;
  font-weight: normal;
`;

export const body18Bold = `
  font-size: 18px;
  font-weight: bold;
`;

export const body18Normal = `
  font-size: 18px;
  font-weight: normal;
`;

export const body17Bold = `
  font-size: 17px;
  font-weight: bold;
`;

export const body16Bold = `
  font-size: 16px;
  font-weight: bold;
`;

export const body16Normal = `
  font-size: 16px;
  font-weight: normal;
`;

export const body16Light = `
  font-size: 16px;
  font-weight: 300;
`;

export const body15Bold = `
  font-size: 15px;
  font-weight: bold;
`;

export const body15Normal = `
  font-size: 15px;
  font-weight: normal;
`;

export const body14Bold = `
  font-size: 14px;
  font-weight: bold;
`;

export const body14Normal = `
  font-size: 14px;
  font-weight: normal;
`;

export const body13Bold = `
  font-size: 13px;
  font-weight: bold;
`;

export const body13Normal = `
  font-size: 13px;
  font-weight: normal;
`;

export const body12Bold = `
  font-size: 12px;
  font-weight: bold;
`;

export const body12Normal = `
  font-size: 12px;
  font-weight: normal;
`;

export const body12Light = `
  font-size: 12px;
  font-weight: 300;
`;

export const body11Bold = `
  font-size: 11px;
  font-weight: bold;
`;

export const body11Normal = `
  font-size: 11px;
  font-weight: normal;
`;

export const body10Bold = `
  font-size: 10px;
  font-weight: bold;
`;

export const body10Normal = `
  font-size: 10px;
  font-weight: normal;
`;

export const body9Bold = `
  font-size: 9px;
  font-weight: bold;
`;

export const body9Normal = `
  font-size: 9px;
  font-weight: normal;
`;

// =========== JET Typography Styles =========== //

export const headingXXL = ` 
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
`;

export const headingXL = ` 
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
`;

export const headingL = ` 
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
`;

export const headingM = ` 
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
`;

export const headingS = ` 
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
`;

export const headingXS = ` 
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
`;

export const subheadingSBold = ` 
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
`;

export const subheadingSRegular = ` 
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
`;

export const bodyBold = ` 
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
`;

export const bodyRegular = ` 
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`;

export const smallBold = ` 
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
`;

export const smallRegular = ` 
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
`;

export const captionBold = ` 
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

export const captionRegular = ` 
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
`;

export const captionStrikethrough = ` 
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  text-decoration: line-through;
`;
