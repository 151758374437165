import styled, {css} from 'styled-components';

export const flexColumnCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const FlexColumnCenter = styled.div`
  ${flexColumnCenter};
`;
