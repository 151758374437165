import {memo} from 'react';

import styled from 'styled-components';

import {body12Normal} from '~/shared/theme/typography';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import {getLocalizationService} from '~/shared/services/localisationService';
import alertIcon from '~/assets/images/icons/alert-triangle.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

const Container = styled.div`
  margin-top: 15px;
  border: 1px dashed ${({theme}) => theme.colors.error};
  border-radius: 2px;
  padding: 10px;
  margin-bottom: 15px;
  ${media.minMobile`
    margin-top: 0;  
  `}
`;

const ErrorContainer = styled.div<{flexWrap?: boolean}>`
  ${({flexWrap}) => flexWrap && 'flex-wrap: wrap;'}
  ${flexCenterVertically};
  padding: 5px;
`;

const AlertIcon = styled(ImageWithAlt)`
  width: 20px;
`;

const ErrorText = styled.div`
  ${body12Normal};
  color: ${({theme}) => theme.colors.error};
  line-height: 16px;
  ${flipOnLTR`
    text-align: right;
    margin-right: 5px;
  `}
`;

const ErrorSuggestionText = styled.div`
  ${body12Normal};
  line-height: 16px;
  ${flipOnLTR`
    text-align: right;
    margin-right: 5px;
  `}
`;

const ActionLink = styled.div`
  color: ${({theme}) => theme.checkout.errors.actionLink};
  ${body12Normal};
  line-height: 20px;
  text-decoration: underline;
  cursor: pointer;
  ${flipOnLTR`
    margin-right: 5px;
  `}
`;

interface IErrorSection {
  error: string;
  errorActionSuggestion?: {
    text: string;
    action: () => void;
  };
}

const ErrorSection = ({error, errorActionSuggestion}: IErrorSection) => {
  const {t} = getLocalizationService();

  return (
    <Container>
      <ErrorContainer>
        <AlertIcon src={alertIcon} noAlt />
        <ErrorText role="alert">{error}</ErrorText>
      </ErrorContainer>
      {errorActionSuggestion && (
        <ErrorContainer flexWrap>
          <ErrorSuggestionText>{`${t(errorActionSuggestion.text)}`}</ErrorSuggestionText>
          <ActionLink onClick={errorActionSuggestion.action}>{t('right_here')}</ActionLink>
        </ErrorContainer>
      )}
    </Container>
  );
};

export default memo(ErrorSection);
