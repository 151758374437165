/* eslint-disable no-restricted-imports */
import URI from 'urijs';
import {without} from 'lodash';
import i18next from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import asyncTranslations, {BackendOptions, RequestCallback} from 'i18next-http-backend';
/* eslint-enable no-restricted-imports */

// @ts-expect-error config needs to be rewritten as ts
import {isDev, basedir} from '~/shared/config';
import {createLogger} from '~/shared/logging';
import {
  DEFAULT_CULTURE,
  DEFAULT_LANGUAGE_KEY,
  DefaultLanguage,
  SupportedLanguages,
  SupportedLanguageType,
} from '~/shared/consts/commonConsts';
import {getParsedLocaleCookies, setCurrentLanguageCookie} from '~/shared/utils/cookies';
import renderingForRobot from '~/shared/services/renderingForRobot';
import {convertLocationToRightCasing} from '~/shared/router/supportedQueryParams';

const getLanguageFromUrlPrefix = (url: string) => {
  if (!url) {
    return DefaultLanguage;
  }

  const pathname = URI.parse(url).path || '/';
  const pathNameWithoutBasedir = pathname.startsWith(basedir) ? pathname.replace(basedir, '') : pathname;

  const languageKeyFromPrefixIfExist = pathNameWithoutBasedir.split('/')[0];
  return SupportedLanguages.find(({key}) => key === languageKeyFromPrefixIfExist);
};

const getUrlWithoutLanguagePrefix = (url = '') => {
  const supportedLanguage = getLanguageFromUrlPrefix(url);
  if (!supportedLanguage) {
    return url;
  }

  const [urlWithoutQuery, query] = url.split('?');

  const newUrlWithoutQuery = without(urlWithoutQuery.split('/'), supportedLanguage.key).join('/');
  return query ? `${newUrlWithoutQuery}?${query}` : newUrlWithoutQuery;
};

export const changeLanguagePrefixToUrlOrPath = (urlOrPath: string, newLanguagePrefix = DEFAULT_LANGUAGE_KEY) => {
  const languagePrefix = newLanguagePrefix === DEFAULT_LANGUAGE_KEY ? '' : newLanguagePrefix;
  const urlOrPathWithoutLanguagePrefix = getUrlWithoutLanguagePrefix(urlOrPath);

  const parsedUrl = URI.parse(urlOrPathWithoutLanguagePrefix);
  const pathWithoutBaseDir = getUrlWithoutLanguagePrefix(parsedUrl?.path?.replace(new RegExp(`^${basedir}?`), ''));

  const languagePart = languagePrefix ? `${languagePrefix}/` : '';

  const pathPart = pathWithoutBaseDir.startsWith('/') ? pathWithoutBaseDir.substr(1) : pathWithoutBaseDir;

  const queryPart = parsedUrl.query ? `?${parsedUrl.query}` : '';

  const newPathName = `${languagePart}${pathPart}${queryPart}`;

  if (parsedUrl.protocol) {
    const portPart = parsedUrl.port ? `:${parsedUrl.port}` : '';
    return `${parsedUrl.protocol}://${parsedUrl.hostname}${portPart}${basedir}${newPathName}`;
  }

  const relativePathHasBaseDir = urlOrPath.startsWith(basedir);
  return `${relativePathHasBaseDir ? basedir : ''}${newPathName}`;
};

const logger = createLogger('i18n');
const defaultNamespace = 'translations';

export type i18nHelpersProps = {
  t: (key: string, params?: object | undefined) => string;
  tKeyExists: (key: string) => boolean;
  currentLanguageDirection: 'ltr' | 'rtl';
  currentLanguageKey: SupportedLanguageType['key'];
  culture: SupportedLanguageType['culture'];
};

let i18nInstance: ReturnType<typeof i18next.createInstance>;
let i18nHelpers: i18nHelpersProps;

let languageResourcesLoadPromise: Promise<typeof import('*.json?text')['default']>;

interface IinitLocalisationService {
  avoidRoutes?: boolean;
  initialLanguageKey?: 'he' | 'en';
}

const initLocalisationService = (
  {avoidRoutes, initialLanguageKey}: IinitLocalisationService = {avoidRoutes: false},
) => {
  i18nInstance = i18next.createInstance().use(intervalPlural);

  const {languageKey: languageKeyFromCookie} = getParsedLocaleCookies();

  // eslint-disable-next-line no-restricted-properties
  const {href: currentHref} = window.location;

  // eslint-disable-next-line no-restricted-properties
  const convertedHref = convertLocationToRightCasing(window.location);

  const languageFromPrefix = getLanguageFromUrlPrefix(currentHref);

  let isLocationReplaced = false;
  if (languageFromPrefix?.key === DEFAULT_LANGUAGE_KEY) {
    const newRoute = changeLanguagePrefixToUrlOrPath(convertedHref, DEFAULT_LANGUAGE_KEY);

    setCurrentLanguageCookie({
      culture: DefaultLanguage.culture,
      languageKey: DefaultLanguage.key,
    });

    logger.log('navigated explicitely to "/he/". redirecting to "/" instead', {
      from: convertedHref,
      replaceStateTo: newRoute,
    });
    // eslint-disable-next-line no-restricted-properties
    window.location.replace(newRoute); // we can't use the router's location since at this point the router has not been initialized yet
    isLocationReplaced = true;
  }

  if (
    !avoidRoutes &&
    !languageFromPrefix &&
    languageKeyFromCookie &&
    languageKeyFromCookie !== DEFAULT_LANGUAGE_KEY &&
    !renderingForRobot
  ) {
    const newRoute = changeLanguagePrefixToUrlOrPath(convertedHref, languageKeyFromCookie);

    logger.log('the language prefix is different from cookie language', {replaceStateTo: newRoute});
    // eslint-disable-next-line no-restricted-properties
    window.location.replace(newRoute); // we can't use the router's location since at this point the router has not been initialized yet
    isLocationReplaced = true;
  }

  if (!avoidRoutes && !isLocationReplaced && convertedHref !== currentHref) {
    // eslint-disable-next-line no-restricted-properties
    window.location.replace(convertedHref);
  }

  const {key: decidedLanguageKey} = initialLanguageKey
    ? {key: initialLanguageKey}
    : languageFromPrefix || DefaultLanguage;
  // always use the default culture until we support different ones
  // let {key: decidedLanguage, culture: currentCulture} = languageFromPrefix || DefaultLanguage;
  const currentCulture = DEFAULT_CULTURE;

  languageResourcesLoadPromise =
    decidedLanguageKey === 'en'
      ? import(
        /* webpackChunkName: "english-translations" */ /* webpackMode: "lazy" */ '~/static/texts/en/translations.json?text'
      )
      : import(
        /* webpackChunkName: "hebrew-translations" */ /* webpackMode: "lazy" */ '~/static/texts/he/translations.json?text'
      );

  i18nInstance.use(asyncTranslations).init({
    fallbackLng: DEFAULT_LANGUAGE_KEY,
    fallbackNS: defaultNamespace,
    lng: decidedLanguageKey,
    ns: defaultNamespace,
    defaultNS: defaultNamespace,
    load: 'languageOnly',
    // not needed for react as it escapes by default
    interpolation: {escapeValue: false},
    debug: isDev,
    backend: {
      async request(options: BackendOptions, url: string, payload: string | unknown, callback: RequestCallback) {
        try {
          const resources = await languageResourcesLoadPromise;
          i18nHelpers = {
            ...i18nHelpers,
            t: (key, params) => i18nInstance.t(key, params),
            tKeyExists: key => i18nInstance.exists(key),
          };
          callback(null, {status: 200, data: resources.default});
        } catch (err: any) {
          callback(null, err);
        }
      },
    },
  });

  const origGetSuffix = i18nInstance.services.pluralResolver.getSuffix;
  i18nInstance.services.pluralResolver.getSuffix = (
    code: SupportedLanguageType['key'],
    count: number,
    ...extraArgs: any[]
  ) => {
    if (code === 'he') {
      if (count > 1) {
        return '_plural';
      }
      return '';
    }
    return origGetSuffix.call(i18nInstance.services.pluralResolver, code, count, ...extraArgs);
  };

  i18nHelpers = {
    t: str => {
      // throw new Error('The Localization Service is not ready yet.');
      logger.warn('[t]: The Localization Service is not ready yet.');
      return str;
    },
    tKeyExists: () => {
      // throw new Error('The Localization Service is not ready yet.');
      logger.warn('[tKeyExists]: The Localization Service is not ready yet.');
      return false;
    },
    currentLanguageDirection: decidedLanguageKey === 'he' ? 'rtl' : 'ltr',
    currentLanguageKey: decidedLanguageKey,
    culture: currentCulture,
  };

  return i18nInstance;
};

export default initLocalisationService;

export const getLocalizationService = () => i18nHelpers;
