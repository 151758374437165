import {DeliveryMethods} from '~/shared/consts/restaurantConsts';

const DELIVERY_METHODS_VALUES = Object.values<string>(DeliveryMethods);

export const getDeliveryMethodFromAPIString = (stringValue?: string): DeliveryMethods | null => {
  if (!stringValue) {
    return null;
  }
  // API always returns delivery method name with the first letter in upper case
  // conversion changes all the letters to lower case
  const stringValueInLowerCase = stringValue.toLowerCase();

  return DELIVERY_METHODS_VALUES.includes(stringValueInLowerCase) ? stringValueInLowerCase as DeliveryMethods : null;
};
