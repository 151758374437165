import styled, {css} from 'styled-components';

export const flexCenterVertically = css`
  display: flex;
  align-items: center;
`;

export const FlexCenterVertically = styled.div`
  ${flexCenterVertically};
`;
