import {OrderSuccessData} from '~/shared/store/models';

const DONATION_RESTAURANTS: Array<OrderSuccessData['restaurant']['restaurantId']> = [
  40885, // real donation rest
  40887, // Test donation rest
  42008, // real donation rest
];

export const isDonationRestaurant = (restaurantId: OrderSuccessData['restaurant']['restaurantId']): boolean =>
  DONATION_RESTAURANTS.includes(restaurantId);
