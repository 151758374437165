import {memo} from 'react';

import styled from 'styled-components';

import {attachModal} from '~/shared/components/ReduxModal';
import {flipOnLTR, media} from '~/shared/theme/utils';
import {generatePriceLabel} from '~/shared/components/PriceLabels';
import {flexCenter, flexColumn, flexStart} from '~/shared/theme/FlexLayout';
import {body13Normal, body14Bold, body18Bold, body20Normal} from '~/shared/theme/typography';
import {getLocalizationService} from '~/shared/services/localisationService';
import {CloseButton} from '~/shared/components/Modals';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import CircleInfoIcon from '~/assets/images/icons/circle-info.svg';

const Root = styled.div`
  &&& {
    background-color: ${({theme}) => theme.colors.darkBackground};
    max-height: 100%;
    height: 100%;
    width: 100%;
  }

  min-height: 280px;
  ${flexColumn};
  justify-content: flex-start;
  direction: ${({theme: {isLTR}}) => (isLTR ? 'ltr' : 'rtl')};
  ${flipOnLTR`text-align: right;`}
  color: ${({theme}) => theme.userReport.debitTransferRequestsReceivedModal.text};
  ${media.minLargeTablet`
    &&& {
      width: 600px;
      background-color: ${({theme}) => theme.colors.surface};
      height: auto;
    }
  `}
`;

const HeaderContainer = styled.div`
  background-color: ${({theme}) => theme.colors.surface};
  ${flexCenter}
  height: 56px;
  min-height: 56px;
  box-shadow: ${({theme}) => theme.shadows.modalHeaderShadow};
`;

const RequestsContainer = styled.div`
  overflow-y: auto;
  ${media.minLargeTablet`
    max-height: 84vh;
  `}
`;

const Header = styled.div`
  ${body18Bold};
`;

const StyledCloseButton = styled(CloseButton)`
  ${flipOnLTR`
    left: 8px;
  `}
  top: 11px;
`;

const Message = styled.div`
  ${body20Normal};
  margin: 40px 40px 16px;
`;

const TransferRequest = styled.div`
  margin-top: 16px;
  border-bottom: 1px solid ${({theme}) => theme.colors.gray800};

  &:last-child {
    border-bottom: none;
  }
`;

const InfoWrapper = styled.div`
  margin: 0 40px 16px;
`;

const Inline = styled.div`
  display: flex;
`;

const Label = styled.div`
  line-height: 20px;
`;

const Value = styled.div`
  line-height: 20px;
  font-weight: bold;
  ${flipOnLTR`
    margin-right: 4px;
  `}
`;

const ApprovalDelayDisclaimer = styled.div`
  ${flexStart}
  ${body13Normal}
  line-height: 18px;
  margin-top: 32px;
`;

const StyledInfoIcon = styled(ImageWithAlt).attrs({
  src: CircleInfoIcon,
  noAlt: true,
})`
  width: 12px;
  height: 12px;
  margin-top: 2px;
  ${flipOnLTR`
    margin-left: 4px;
  `}
`;

const ButtonsWrapper = styled(Inline)`
  justify-content: center;
  margin: 0 40px 40px;
  flex-direction: column-reverse;
  ${media.minLargeTablet`
    flex-direction: row;
  `}
`;

const ApproveButton = styled.button`
  height: 48px;
  width: auto;
  ${body14Bold}
  ${media.minLargeTablet`
    width: 252px;
  `}
  border-radius: 1px 0 0 1px;
  background-color: ${({theme}) => theme.userReport.debitTransferRequestsReceivedModal.buttons.approve.background};
  color: ${({theme}) => theme.userReport.debitTransferRequestsReceivedModal.buttons.approve.text};
  ${({
    theme: {
      userReport: {
        debitTransferRequestsReceivedModal: {
          buttons: {
            cancel: {borderColor, text},
          },
        },
      },
    },
    cancel: isCancelButton,
  }) =>
    isCancelButton &&
    `
    border: 1px solid ${borderColor};
    background: transparent;
    color: ${text};
  `}
`;

const CancelButton = styled(ApproveButton)`
  ${flipOnLTR`
    margin-left: 0;
  `}
  margin-top: 16px;
  ${media.minLargeTablet`
    ${flipOnLTR`
      margin-left: 16px;
    `}
    margin-top: 0;
  `}
`;

const TextWithBoldPart = styled.div.attrs(({text, boldText}) => ({
  dangerouslySetInnerHTML: {__html: text.replace(boldText, `<b>${boldText}</b>`)},
}))``;

const DebitTransferRequestsReceived = ({args: {transfers, action}, closeModal}) => {
  const {t} = getLocalizationService();

  const onButtonClick = ({transferId, isApproved}) => {
    if (closeModal) {
      closeModal();
    }
    action({transferId, isApproved});
  };

  return (
    <Root>
      <HeaderContainer>
        <Header>{t('debit_transfer_request')}</Header>
        <StyledCloseButton onClick={closeModal}>
          <CloseButtonSVGIcon />
        </StyledCloseButton>
      </HeaderContainer>
      <RequestsContainer>
        {transfers.map(
          ({
            transferId,
            initiatingUserName,
            restaurantName,
            transferAmount,
            transferRequestDateStr,
            transactionDateStr,
          }) => (
            <TransferRequest key={transferId}>
              <Message id="modal-title">
                <Inline>
                  <TextWithBoldPart
                    text={`${t('you_received_from_xxxx_request_for_debit_transfer_in_total_of', {
                      requester: initiatingUserName,
                    })} ${generatePriceLabel({price: transferAmount})}`}
                    boldText={initiatingUserName}
                  />
                </Inline>
              </Message>

              <InfoWrapper id="modal-content">
                <Inline>
                  <Label>{t('the_restaurant_the_deal_was_made_in')}:</Label>
                  <Value>{restaurantName}</Value>
                </Inline>
                <Inline>
                  <Label>{t('transaction_date')}:</Label>
                  <Value>{transactionDateStr}</Value>
                </Inline>
                <Inline>
                  <Label>{t('request_sent_date')}:</Label>
                  <Value>{transferRequestDateStr}</Value>
                </Inline>
                <ApprovalDelayDisclaimer>
                  <StyledInfoIcon />
                  {t('debit_transfer_approval_update_delay_disclaimer')}
                </ApprovalDelayDisclaimer>
              </InfoWrapper>

              <ButtonsWrapper>
                <CancelButton onClick={() => onButtonClick({transferId, isApproved: false})} cancel>
                  {t('decline_request')}
                </CancelButton>
                <ApproveButton onClick={() => onButtonClick({transferId, isApproved: true})}>
                  {t('approve_charge')}
                </ApproveButton>
              </ButtonsWrapper>
            </TransferRequest>
          ),
        )}
      </RequestsContainer>
    </Root>
  );
};

const EnhancedDebitTransferRequestsReceived = memo(DebitTransferRequestsReceived);

EnhancedDebitTransferRequestsReceived.hideCloseButton = true;
EnhancedDebitTransferRequestsReceived.modalType = 'autoWidth';

attachModal('debitTransferRequestsReceivedModal', EnhancedDebitTransferRequestsReceived);
