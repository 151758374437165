import {memo} from 'react';

import styled from 'styled-components';

import {body10Normal} from '~/shared/theme/typography';

import {RESTRICTION_AGE} from '../consts/restaurantConsts';
import {FlexCenter} from '../theme/FlexLayout';
import {flipOnLTR} from '../theme/flipOnLTR';

const AgeRestrictionBadgeView = styled(FlexCenter)`
  background-color: ${({theme}) => theme.colors.secondary};
  border-radius: 2px;
  padding: 0 2px;
  width: 24px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AgeRestrictionText = styled.p`
  color: ${({theme}) => theme.colors.primaryText};
  ${body10Normal}
  text-align: center;
  direction: ltr;
  line-height: 13px;
`;

const BadgeContainer = styled(FlexCenter)`
  ${flipOnLTR`
      margin-right: 8px;
  `}
`;

const AgeRestrictionBadge = ({className}: {className?: string}) => {
  const ageRestrictedText = `${RESTRICTION_AGE}+`;

  return (
    <BadgeContainer className={className} data-test-id="ageRestrictionBadge">
      <AgeRestrictionBadgeView>
        <AgeRestrictionText>{ageRestrictedText}</AgeRestrictionText>
      </AgeRestrictionBadgeView>
    </BadgeContainer>
  );
};

export default memo(AgeRestrictionBadge);
