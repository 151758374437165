import styled from 'styled-components';

import {Skeleton} from '~/shared/components/Loaders';
import {flexSpace} from '~/shared/theme/FlexLayout';

const Seperator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.colors.background};
  margin: 8px 0;
`;

const Row = styled.div`
  ${flexSpace}
`;

const OrderSuccessLoader = () => {
  return (
    <div>
      <Skeleton height={32} />
      <Skeleton width={100} height={26} marginTop={30} />
      <Skeleton width={248} height={20} marginTop={8} />
      <Skeleton width={210} height={20} marginTop={8} />

      <Skeleton width={100} height={26} marginTop={30} />
      <Row>
        <Skeleton width={210} height={20} marginTop={8} />
        <Skeleton width={56} height={20} marginTop={8} />
      </Row>
      <Seperator />
      <Row>
        <Skeleton width={210} height={20} />
        <Skeleton width={56} height={20} />
      </Row>
      <Row>
        <Skeleton width={250} height={20} marginTop={8} />
        <Skeleton width={56} height={20} marginTop={8} />
      </Row>

      <Skeleton width={100} height={26} marginTop={30} />
      <Skeleton width={248} height={20} marginTop={8} />
      <Skeleton width={210} height={20} marginTop={8} />
    </div>
  );
};

export default OrderSuccessLoader;
