import {isEmpty} from 'lodash';
import {createSelector, createStructuredSelector} from 'reselect';

import {AppState} from '../../configStore';

import {UiState} from './uiReducer';

const EMPTY_OBJECT = {};

const selectUi = (state: AppState) => state.ui;

const selectUiRestaurants = createSelector(selectUi, ui => ui.restaurants);
const selectUiRestaurantsFilter = createSelector(selectUiRestaurants, restaurants => restaurants.filter);
export const selectUiMobile = createSelector(selectUi, ui => ui.mobile);

export const selectUiCuisinesFilter = createSelector(selectUiRestaurantsFilter, filter => filter.cuisines);

export const selectFilters = createStructuredSelector<AppState, UiState['restaurants']['filter']>({
  isKosher: createSelector(selectUiRestaurantsFilter, filter => filter.isKosher),
  isNotKosher: createSelector(selectUiRestaurantsFilter, filter => filter.isNotKosher),
  isVegan: createSelector(selectUiRestaurantsFilter, filter => filter.isVegan),
  isGlutenFree: createSelector(selectUiRestaurantsFilter, filter => filter.isGlutenFree),
  freeDelivery: createSelector(selectUiRestaurantsFilter, filter => filter.freeDelivery),
  fastDelivery: createSelector(selectUiRestaurantsFilter, filter => filter.fastDelivery),
  newRestaurants: createSelector(selectUiRestaurantsFilter, filter => filter.newRestaurants),
  discountCoupon: createSelector(selectUiRestaurantsFilter, filter => filter.discountCoupon),
  isEnvironmentFriendly: createSelector(selectUiRestaurantsFilter, filter => filter.isEnvironmentFriendly),
  isScoober: createSelector(selectUiRestaurantsFilter, filter => filter.isScoober),
  lastFilterTypeSelected: createSelector(selectUiRestaurantsFilter, filter => filter.lastFilterTypeSelected),
  cuisines: selectUiCuisinesFilter,
  showStores: createSelector(selectUiRestaurantsFilter, filter => filter.showStores),
});

export const selectSelectedFilters = createSelector(selectUiRestaurantsFilter, filters =>
  (isEmpty(filters) ? {} : filters),
);

export const selectSelectedFilterKeyNames = createSelector(selectSelectedFilters, filters => {
  return (Object.keys(filters) as (keyof typeof filters)[]).filter(key => typeof filters[key] === 'boolean' && !!filters[key]);
});

export const selectCurrentCuisineId = createSelector(
  selectUiCuisinesFilter,
  cuisines => Object.keys(cuisines || EMPTY_OBJECT)[0],
);

export const selectRestaurantsSortBy = createSelector(selectUiRestaurants, restaurants => restaurants.sortBy);

const selectUiAddressDropdown = createSelector(selectUi, ui => ui.addressDropdown);
export const selectAddressView = createSelector(
  selectUiAddressDropdown,
  addressDropdown => addressDropdown.addressView,
);
export const selectEditedAddress = createSelector(
  selectUiAddressDropdown,
  addressDropdown => addressDropdown.editedAddress,
);

export const selectSearchMode = createSelector(selectUi, ui => ui.searchMode);

export const selectIsActiveBannersShown = createSelector(selectUi, ui => ui.isActiveOrderBannerShown);

export const selectIsOrderTypeMenuOpen = createSelector(selectUiMobile, mobile => mobile.isOrderTypeMenuOpen);
export const selectShowSelectTimeErrorMessage = createSelector(selectUiMobile, mobile => mobile.showSelectTimeErrorMessage);

export const selectMobileMiniFeedSortEnabled = createSelector(selectUiMobile, mobile => mobile.miniFeedFilterEnabled);
