import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {Section} from '~/shared/components/OrderCompleteSections/parts';
import {flexColumn} from '~/shared/theme/FlexLayout';

import {
  OrderCompleteHeader,
  OrderCompleteSubheader,
} from './ScooberAndOrderBlock/styled';

const VoucherCardWrapper = styled(Section)`
  padding: 24px;
  margin-top: 0;
  ${flexColumn}
`;

const VoucherCardSubheader = styled(OrderCompleteSubheader)`
  font-size: 18px;
  margin-top: 25px;
  font-weight: 700;
  line-height: 22px;
`;

const VoucherCardLine = styled(OrderCompleteSubheader)<{mt?: boolean; bold?: boolean}>`
  font-size: 14px;
  line-height: 22px;
  ${({bold}) => bold && 'font-weight: 700;'}
  ${({mt}) => mt && 'margin-top: 8px;'}
`;

const VoucherCardOrderComplete = ({orderDate, orderTotal}: {
  orderDate: string;
  orderTotal: number;
}) => {
  const {t} = getLocalizationService();

  return (
    <VoucherCardWrapper>
      <OrderCompleteHeader>{t('multipass_order_success_title')}</OrderCompleteHeader>
      <VoucherCardSubheader>{t('multipass_subheader_details')}</VoucherCardSubheader>
      <VoucherCardLine mt>{t('multipass_subheader_charge_sum', {orderTotal})}</VoucherCardLine>
      <VoucherCardLine>{t('multipass_subheader_date', {orderDate})}</VoucherCardLine>
    </VoucherCardWrapper>
  );
};

export default VoucherCardOrderComplete;
