import {RestaurantFromSearch} from '~/shared/store/models';

export const singleRestaurantHasNoOrders = (res: RestaurantFromSearch) => !res.pooledOrderSum;

export const singleRestaurantsExceededMinimalPrice = (res: RestaurantFromSearch) => res.pooledOrderSum >= res.minimumPriceForOrder;

export const singleRestaurantHasOrdersAndNotExceededMinimumPrice = (res: RestaurantFromSearch) =>
  res.pooledOrderSum < res.minimumPriceForOrder &&
  res.pooledOrderSum > 0;

export const bothRestaurantsHaveActivePoolRule = (a: RestaurantFromSearch, b: RestaurantFromSearch) =>
  a.hasActivePoolRule && b.hasActivePoolRule;

export const bothRestaurantsExceededMinimalPrice = (a: RestaurantFromSearch, b: RestaurantFromSearch) => (
  singleRestaurantsExceededMinimalPrice(a) &&
  singleRestaurantsExceededMinimalPrice(b)
);

export const bothRestaurantsHaveNoOrders = (a: RestaurantFromSearch, b: RestaurantFromSearch) =>
  !a.pooledOrderSum && !b.pooledOrderSum;
