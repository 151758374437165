import {useState} from 'react';

import {Keyframes} from 'styled-components';

import {ANIMATION_SECONDS, fadeIn, fadeOut} from '../styled';

export const useAnimation = () => {
  const [keyFrame, setKeyFrame] = useState<Keyframes | null>(null);

  const activateAnimation = (changeState: () => void) => {
    setTimeout(() => {
      setKeyFrame(fadeIn);
      changeState();
      setTimeout(() => setKeyFrame(null), 1000 * ANIMATION_SECONDS);
    }, 1000 * ANIMATION_SECONDS);
    setKeyFrame(fadeOut);
  };

  return {keyFrame, activateAnimation};
};
