import {makeReducer, composeReducers} from '../../redux-toolbelt';
import {updateAddress} from '../restrictedSharedActions';

import {setCurrentAddressKey} from './currentAddressKeyActions';

type CurrentAddressKeyState = string;

export default composeReducers<CurrentAddressKeyState>(
  makeReducer(setCurrentAddressKey),
  makeReducer<CurrentAddressKeyState, ReturnType<typeof updateAddress>['payload']>(
    updateAddress,
    (currentState, {payload}) => {
      const {address, shouldUpdateAddressKey} = payload || {};
      return shouldUpdateAddressKey ? address?.addressKey : currentState;
    },
  ),
);
