import {memo} from 'react';

import {Form, Field} from 'react-final-form';

import {getLocalizationService} from '~/shared/services/localisationService';

import {
  Container,
  Inline,
  InnerFieldWrapper,
  Label,
  ModifiedTextArea,
  AmountWrapper,
  ModifiedInput,
  Link,
  ButtonsWrapper,
  Button,
  WantToSplitLabel,
  FieldError,
} from './styled';

const SendTransferForm = ({onSubmitClick, onCancelClick, validate, total}) => {
  const {t} = getLocalizationService();

  return (
    <Form
      onSubmit={onSubmitClick}
      validate={validate}
      initialValues={{total}}
      render={({handleSubmit}) => (
        // noValidate is to prevent auto validate on IE
        <form noValidate onSubmit={handleSubmit}>
          <Container>
            <Inline>
              <Field
                name="transferReceiverEmail"
                render={({input, meta}) => (
                  <InnerFieldWrapper flexStart>
                    <Label>{`* ${t('who_owes_you')}?`}</Label>
                    <ModifiedInput {...input} placeholder={t('email')} inputType="email" />
                    <FieldError>{meta.error && meta.touched && t(meta.error)}</FieldError>
                  </InnerFieldWrapper>
                )}
              />
            </Inline>
            <Inline>
              <Field
                name="amount"
                render={({input, meta}) => (
                  <InnerFieldWrapper>
                    <Label>{`* ${t('whats_the_amount')}?`}</Label>
                    <AmountWrapper>
                      <ModifiedInput {...input} inputType="number" type="number" />
                    </AmountWrapper>
                    <Inline>
                      <WantToSplitLabel>{t('want_to_split')}?</WantToSplitLabel>
                      <Link onClick={() => input.onChange(total / 2)}>
                        {t('click_to_request_50%_from_order_amount')}
                      </Link>
                    </Inline>
                    <FieldError>{meta.error && meta.touched && t(meta.error)}</FieldError>
                  </InnerFieldWrapper>
                )}
              />
            </Inline>
          </Container>

          <Field
            name="notes"
            render={({input}) => (
              <InnerFieldWrapper>
                <Label>{`${t('want_to_add_a_few_words')}?`}</Label>
                <ModifiedTextArea {...input} />
              </InnerFieldWrapper>
            )}
          />

          <ButtonsWrapper>
            <Button onClick={() => onCancelClick()} cancel>
              {t('cancel')}
            </Button>
            <Button formnovalidate="formnovalidate" type="submit">
              {t('send_the_request')}
            </Button>
          </ButtonsWrapper>
        </form>
      )}
    />
  );
};
export default memo(SendTransferForm);
