import {memo, useContext} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {ActionButton} from '~/shared/components/Button';
import {body16Bold} from '~/shared/theme/typography';

import ShoppingCart from './ShoppingCart';

const EmptyShoppingCartContainer = styled.div`
  height: 100%;
  padding: 16px;
  ${flexColumn};
`;

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`;

const PaymentActionButton = styled(ActionButton)`
  border-radius: 1px;
  box-shadow: none;

  &&& {
    width: 100%;
  }
`;

const EmptyCartText = styled.p`
  ${body16Bold}
  line-height: 24px;
  text-align: center;
  color: ${({theme}) => theme.shoppingCart.textColor};
  height: 39px;
`;

const EmptyShoppingCart = () => {
  const {t} = getLocalizationService();
  const {isMinLargeMobile} = useContext(ShoppingCart.Context);

  if (!isMinLargeMobile) {
    return null;
  }
  return (
    <EmptyShoppingCartContainer>
      <ButtonWrapper>
        <PaymentActionButton disabled aria-labelledby="empty_shoppingcart_label">
          {t('continue_to_payment')}
        </PaymentActionButton>
      </ButtonWrapper>
      <EmptyCartText id="empty_shoppingcart_label">{t('your_shopping_cart_is_empty')}</EmptyCartText>
    </EmptyShoppingCartContainer>
  );
};

export default memo(EmptyShoppingCart);
