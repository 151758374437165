// eslint-disable-next-line no-restricted-imports
import colors from '~/shared/theme/colors';

const checkoutTheme = {
  variants: {
    modal: {
      elements: {
        title: {
          display: 'none',
          marginBottom: '16px',
        },
        itemWrapper: {
          backgroundColor: colors.primaryText,
        },
        item: {
          borderBottom: `1px solid ${colors.darkBackground}`,
          padding: '16px 40px',
        },
        itemBody: {
          margin: '0',
          paddingBottom: '16px',
          paddingRight: '12px',
        },
        ItemBodyBillingLines: {
          padding: '0',
        },
        checkoutDeliveryOptions: {
          paddingTop: '16px',
        },
        checkoutDeliveryOptionsWithFutureOrder: {
          paddingTop: '8px',
        },
        addressContainer: {
          padding: '8px 0;',
          backgroundColor: colors.darkBackground,
          height: '40px',
        },
        addressDetailRow: {
          margin: '16px 0px 0 8px',
        },
        showAddressFormButton: {
          margin: '16px 8px 0 8px',
          justifyContent: 'flex-end',
        },
        tipsButtonsAndInputContainer: {
          padding: '0',
        },
        tipTitleLegend: {
          padding: '8px 0 0 0',
        },
        TipTitleLabel: {},
        tipButton: {
          height: '40px',
        },
        tipInput: {
          height: '40px',
        },
        couponLine: {
          margin: '0',
        },
        paymentsRoot: {
          margin: '16px 0',
          border: `1px solid ${colors.gray500}`,
        },
        payments: {
          padding: '8px 6px',
        },
        paymentsButtonsAndInputContainer: {
          margin: '0',
        },
        addressWrapper: {
          padding: '0',
        },
        addressFieldsWrapper: {
          margin: '16px 0',
        },
        paymentButton: {
          margin: '4px 0',
        },
        CutleryOptionCheckbox: {
          marginTop: '0',
        },
        addCouponWrapper: {
          margin: '0',
        },
      },
    },
    'page-desktop': {
      elements: {
        title: {
          marginBottom: '16px',
        },
        dish: {
          name: {
            color: colors.surfaceTitle,
          },
          price: {
            fontWeight: 'bold',
          },
        },
        dishList: {
          maxHeight: 'none',
        },
        container: {
          maxHeight: 'none',
        },
        itemWrapper: {
          backgroundColor: colors.primaryText,
          padding: '0 32px',
        },
        item: {
          borderBottom: `1px solid ${colors.darkBackground}`,
          padding: '24px 0 20px 0',
        },
        itemBody: {
          margin: '0',
        },
        ItemBodyBillingLines: {
          padding: '0',
        },
        checkoutDeliveryOptions: {
          paddingTop: '16px',
        },
        checkoutDeliveryOptionsWithFutureOrder: {
          paddingTop: '8px',
        },
        addressContainer: {
          padding: '8px 0',
          backgroundColor: colors.darkBackground,
          height: '40px',
        },
        addressDetailRow: {
          margin: '16px 0px 0 8px',
        },
        showAddressFormButton: {
          margin: '16px 8px 0 8px',
          justifyContent: 'flex-end',
        },
        tipsButtonsAndInputContainer: {
          padding: '0',
        },
        tipTitleLegend: {},
        TipTitleLabel: {
          display: 'none',
        },
        tipButton: {
          height: '40px',
        },
        tipInput: {
          height: '40px',
        },
        couponLine: {
          margin: '0',
        },
        paymentsRoot: {
          margin: '16px 0',
          border: `1px solid ${colors.gray500}`,
        },
        payments: {},
        paymentsButtonsAndInputContainer: {
          margin: '0',
        },
        addressWrapper: {
          padding: '0',
        },
        addressFieldsWrapper: {
          margin: '16px 0',
        },
        paymentButton: {
          margin: '4px 0',
        },
        CutleryOptionCheckbox: {
          marginTop: '24px',
        },
        addCouponWrapper: {
          margin: '0',
        },
      },
    },
    'page-mobile': {
      elements: {
        title: {
          display: 'inherit',
          padding: '16px 16px 8px 16px',
          lineHeight: '18px',
          marginBottom: '0',
        },
        dish: {
          name: {
            color: colors.surfaceTitle,
          },
          price: {
            fontWeight: 'bold',
          },
        },
        dishList: {
          maxHeight: 'none',
        },
        container: {
          maxHeight: 'none',
        },
        itemWrapper: {
          backgroundColor: 'none',
        },
        item: {
          borderBottom: `1px solid ${colors.darkBackground}`,
          padding: '0 0 16px 0',
          marginBottom: '10px',
        },
        itemBody: {
          margin: '0',
          paddingBottom: '4px',
          paddingRight: '12px',
        },
        ItemBodyBillingLines: {
          padding: '0 16px',
        },
        checkoutDeliveryOptions: {
          paddingTop: '16px',
        },
        checkoutDeliveryOptionsWithFutureOrder: {
          paddingTop: '8px',
        },
        addressContainer: {
          padding: '8px 0 0 0',
        },
        addressDetailRow: {
          margin: '8px 8px 0 8px',
        },
        addressLabel: {
          lineHeight: '14px',
          padding: '0px 8px',
        },
        showAddressFormButton: {
          margin: '8px 8px 0 8px',
          justifyContent: 'flex-start',
        },
        tipsButtonsAndInputContainer: {
          padding: '0 16px',
        },
        tipTitleLegend: {
          padding: '0 16px',
        },
        TipTitleLabel: {
          display: 'none',
        },
        tipButton: {
          height: '50px',
        },
        tipInput: {
          height: '50px',
        },
        couponLine: {
          margin: '8px 16px',
        },
        paymentsRoot: {
          margin: '16px 16px',
          border: 'none',
          padding: '0 16px',
        },
        payments: {
          border: 'none',
        },
        paymentsButtonsAndInputContainer: {
          margin: '0 16px',
        },
        addressWrapper: {
          padding: '0 8px',
        },
        addressFieldsWrapper: {
          margin: '16px',
        },
        paymentButton: {
          margin: '4px 0',
        },
        CutleryOptionCheckbox: {
          marginTop: '24px',
        },
        addCouponWrapper: {
          margin: '0 16px',
        },
      },
    },
  },
};

export default checkoutTheme;
