import styled, {css} from 'styled-components';

import {flipOnLTR, media} from '~/shared/theme/utils';
import {clickOnEnterProps} from '~/shared/utils/general';

import {subheader30Normal} from '../theme/typography';

const disabledCss = css`
  &&& {
    color: ${({theme}) => theme.actionButton.disabled.color};
    background-color: ${({theme}) => theme.actionButton.disabled.backgroundColor};
    cursor: no-drop;
  }
`;

interface IButton {
  type?: 'button' | 'submit';
  disabled?: boolean;
  isDisabled?: boolean;
  isExpandable?: boolean;
  isOpen?: boolean;
  tabIndex?: number;
  testId?: string;
}

const Button = styled.button.attrs(({type, disabled, isDisabled, isExpandable, isOpen, tabIndex, testId}: IButton) => ({
  ...clickOnEnterProps,
  ...((disabled || isDisabled) && {'aria-disabled': String(!!(disabled || isDisabled))}),
  ...(isExpandable && {'aria-expanded': String(!!isOpen)}),
  ...(testId && {'data-test-id': String(testId)}),
  tabIndex: tabIndex || 0,
  type: type || 'button',
}))`
  padding: 0;
  margin: 0;
  ${({isDisabled}) => isDisabled && disabledCss}

  &:disabled {
    ${disabledCss}
  }
`;

const StyledButton = styled(Button)`
  ${subheader30Normal};
  line-height: 1.4;
  color: ${props =>
    (props.ghost === true ? props.theme.actionButton.enabled.backgroundColor : props.theme.actionButton.enabled.color)};
  background-color: ${props =>
    (props.ghost === true ? 'transparent' : props.theme.actionButton.enabled.backgroundColor)};
  cursor: pointer;
  display: inline-block;
  text-align: center;
  border-radius: 1px;
  width: 90%;
  padding: 5px 20px;
  margin: 0 auto;
  ${media.minTablet`
    width: 300px;
    align-self: center;
  `}

  &:hover {
    background-color: ${props =>
    (props.ghost === true
      ? props.theme.actionButton.enabled.backgroundColor
      : props.theme.actionButton.enabled.hoverBg)};
    color: ${props => (props.ghost ? 'white' : 'none')};
  }

  &:disabled {
    border: 0;
  }
`;

export const ActionButton = styled(StyledButton)`
  align-self: center;
  height: 50px;
  color: ${({theme, reverseColors}) =>
    (reverseColors ? theme.actionButton.enabled.backgroundColor : theme.actionButton.enabled.color)};
  background-color: ${({theme, reverseColors}) =>
    (reverseColors ? theme.actionButton.enabled.color : theme.actionButton.enabled.backgroundColor)};
  padding: 0 20px;
  border-radius: 3px;
  box-shadow: ${({theme}) => theme.shadows.shadow2};
  z-index: 1;
  font-weight: bold;
  transition: opacity 0.25s;
  font-size: ${({theme}) => theme.actionButton.fontSize};
  letter-spacing: 0.5px;
  width: ${({full}) => full && '100%'};
  ${media.minTablet`
    width: ${({full}) => full && '100%'};
    padding: 0px 32px; 
  `}
  ${flipOnLTR`
    margin-right: 8px;
  `}

  &:first-child {
    margin: 0;
  }

  &:hover {
    background-color: ${({theme, reverseColors}) => !reverseColors && theme.actionButton.enabled.hoverBg};
  }

  &:active {
    background-color: ${({theme, reverseColors}) => !reverseColors && theme.actionButton.enabled.hoverBg};
  }
`;

export const PrimaryActionButton = styled(StyledButton)`
  height: 48px;
  width: 198px;
  ${media.minTablet`
    width: 198px;
  `}
  color: ${p => (p.ghost ? p.theme.actionButton.invert.borderColor : p.theme.actionButton.enabled.color)};
  ${p => (p.ghost ? `border: 1px solid ${p.theme.actionButton.invert.borderColor};` : null)}
  font-size: ${({theme}) => theme.actionButton.fontSize};
  ${media.maxMobile`
    width: 250px;
    margin-bottom: 10px;
  `}

  &:hover {
    background-color: ${p => (p.ghost ? 'inherit' : p.theme.actionButton.enabled.hoverBg)};
    color: ${p => (p.ghost ? p.theme.actionButton.invert.color : p.theme.actionButton.enabled.color)};
    border: 1px solid ${p => p.theme.actionButton.invert.color};
  }
`;

export default Button;
