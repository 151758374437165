import {memo} from 'react';

import styled from 'styled-components';

import AgeRestrictionDisclaimer from '~/shared/components/AgeRestrictionDisclaimer';
import {BillingLineWithDiscountsData} from '~/shared/store/models/ShoppingCart/IShoppingCart';
import OrderPayment from '~/shared/store/models/Order/OrderPayment';
import {OrderSuccessData} from '~/shared/store/models';

import _BillingLinesDetails from './BillingLinesDetails';
import PaymentsDetails from './PaymentsDetails';
import LoyaltyProgram from './LoyaltyProgram';
import {defaultPaddingCss} from './orderSummaryStyle';

const BillingLinesDetails = styled(_BillingLinesDetails)`
  ${defaultPaddingCss}
`;

const Payments = ({
  billingLines,
  showAgeRestrictionDisclaimer,
  payments,
  loyaltyProgramPoints,
  isBarCodeOrder,
  isVoucherCard,
  totalSaved,
  isBenefitsHighlightEnabled,
  orderCoupon,
}: {
  billingLines?: BillingLineWithDiscountsData[];
  showAgeRestrictionDisclaimer: boolean;
  payments?: OrderPayment[];
  loyaltyProgramPoints: number;
  isBarCodeOrder: boolean;
  isVoucherCard: boolean;
  totalSaved: number;
  isBenefitsHighlightEnabled: boolean;
  orderCoupon: OrderSuccessData['orderCoupon'];
}) => {
  return (
    <>
      {!isVoucherCard && (
        <>
          <BillingLinesDetails
            isBarCodeOrder={isBarCodeOrder}
            billingLines={billingLines}
            totalSaved={totalSaved}
            isBenefitsHighlightEnabled={isBenefitsHighlightEnabled}
            // @ts-expect-error BillingLinesDetails should be refactored to TS
            orderCoupon={orderCoupon}
          />
          {showAgeRestrictionDisclaimer && <AgeRestrictionDisclaimer />}
        </>
      )}
      <PaymentsDetails payments={payments} />
      <LoyaltyProgram points={loyaltyProgramPoints} />
    </>
  );
};

export default memo(Payments);
