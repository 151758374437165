import {sumBy} from 'lodash';
import {createSelector} from 'reselect';

import {EMPTY_ARRAY, EMPTY_OBJECT} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';
import {Dish, MenuCategory} from '../../models';

const selectMenu = (state: AppState) => state.menu;

export const selectCurrentMenu = selectMenu;

const selectDishAssignedUsersBase = createSelector(selectMenu, menu => menu.dishAssignedUsers || EMPTY_OBJECT);
export const selectDishAssignedUsers = createSelector(selectDishAssignedUsersBase, ({loading, data}) =>
  (loading || !data ? EMPTY_ARRAY : data.users),
);
export const selectDishAssignedUsersLoaded = createSelector(
  selectDishAssignedUsersBase,
  dishAssignedUsers => dishAssignedUsers?.loaded,
);

const selectMenuCategories = createSelector(selectMenu, menu => menu.categories);

export const selectCurrentRestaurantDishesByDishId = createSelector(selectMenuCategories, categories => {
  const categoriesList = categories.data?.categoriesList;

  if (!categoriesList) {
    const isCategoriesLoaded = categories.data;
    return isCategoriesLoaded ? EMPTY_OBJECT : null;
  }

  return categoriesList
    .map(category => category.dishList)
    .reduce((allDishes, categoryDishes) => allDishes.concat(categoryDishes), [])
    .reduce<Record<number, Dish>>((dishesObject, dish) => {
      dishesObject[dish.dishId] = dish;
      return dishesObject;
    }, {});
});

export const selectDishesWithSubs = createSelector(selectCurrentRestaurantDishesByDishId, allDishesById =>
  Object.entries(allDishesById || {}).reduce<DishWithSub>((allDishes, [dishId, dish]) => {
    allDishes[dishId] = {
      ...dish,
      choicesMap: (dish.choices || []).reduce<DishChoicesMap>((allChoices, choice) => {
        allChoices[choice.choiceId] = {
          ...choice,
          subsMap: (choice.subs || []).reduce<DishSubsMap>((allSubs, sub) => {
            allSubs[sub.subId] = sub;
            return allSubs;
          }, {}),
        };
        return allChoices;
      }, {}),
    };

    return allDishes;
  }, {}),
);
export type DishWithSub = Record<string, Dish & {choicesMap: DishChoicesMap}>;
type DishChoicesMap = Record<number, DishChoice & {subsMap: DishSubsMap}>;
type DishSubsMap = Record<number, DishSub>;
type DishSub = DishChoice['subs'][number];
type DishChoice = Dish['choices'][number];

export const selectCategoriesListLoading = createSelector(selectMenuCategories, categories => categories.loading);
export const selectCategoriesListLoaded = createSelector(selectMenuCategories, categories => categories.loaded);

export const selectCategoriesListOfCurrentRestaurant = createSelector(
  selectMenuCategories,
  state => state.menu.lastCategoriesIdRequested,
  (categories, lastCategoriesIdRequested) => {
    if (!categories || !categories.data) {
      return [];
    }

    if (categories.loading || String(lastCategoriesIdRequested) !== String(categories.data.resId)) {
      return [];
    }

    return categories.data.categoriesList;
  },
);

export const selectMenuReOrderHistoryData = createSelector(selectMenu, menu => menu.orderHistory.data);

export const selectLast4ordersWithExistingDishes = createSelector(
  selectMenuReOrderHistoryData,
  selectCurrentRestaurantDishesByDishId,
  (orderHistoryData, dishesById) => {
    return orderHistoryData?.reduce<OrderWithExistingDishes[]>((allOrders, currentOrder) => {
      const hasMissingDishes = !currentOrder.dishes.every(({dishId}) => dishesById?.[dishId]);
      if (hasMissingDishes) {
        // eslint-disable-next-line no-console
        console.warn('some dishes were not found in the restaurant dish list', {currentOrder});
        return allOrders;
      }

      const newCurrentOrder: OrderWithExistingDishes = {
        ...currentOrder,
        dishes: currentOrder.dishes.map(dishInOrder => ({
          ...dishInOrder,
          ...(dishesById?.[dishInOrder.dishId] || ({} as Dish)),
        })),
        numberOfDishes: sumBy(currentOrder.dishes, 'quantity'),
      };
      return [...allOrders, newCurrentOrder];
    }, []);
  },
);
type OrderWithExistingDishes = OrderHistoryOrder & {dishes: Dish[]; numberOfDishes: number};
type OrderHistoryOrder = NonNullable<ReturnType<typeof selectMenuReOrderHistoryData>>[number];

const selectMenuSearch = createSelector(selectMenu, menu => menu.menuSearch);
export const selectMenuSearchTerm = createSelector(selectMenuSearch, menuSearch => menuSearch.searchTerm || '');

export const selectFilteredCategoriesListData = createSelector(
  selectCategoriesListOfCurrentRestaurant,
  selectMenuSearchTerm,
  (currentCategoriesList, searchString: string) => {
    if (!searchString) {
      return currentCategoriesList;
    }

    const lowerCaseSearch = searchString.toLowerCase();

    return currentCategoriesList.reduce((all: MenuCategory[], category) => {
      if (
        category.categoryName?.toLocaleLowerCase().includes(lowerCaseSearch) ||
        category.categoryDesc?.toLocaleLowerCase().includes(lowerCaseSearch)
      ) {
        return [...all, category];
      }

      const filteredDishList = category.dishList.filter(
        (dish: Dish) =>
          dish.name.toLocaleLowerCase().includes(lowerCaseSearch) ||
          dish.description.toLocaleLowerCase().includes(lowerCaseSearch),
      );

      if (!filteredDishList.length) {
        return all;
      }

      return [
        ...all,
        {
          ...category,
          dishList: filteredDishList,
        },
      ];
    }, []);
  },
);
