import {forwardRef, memo, useMemo, useState} from 'react';

import styled, {css} from 'styled-components';
import {flatMap} from 'lodash';
import {Form} from 'react-final-form';

import {createLogger} from '~/shared/logging';
import {media} from '~/shared/theme/utils';
import _FormField from '~/shared/components/FormField';
import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import {getLocalizationService} from '~/shared/services/localisationService';
import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {flexColumn, flexSpace} from '~/shared/theme/FlexLayout';
import {COMMENT_MAX_LENGTH, APARTMENT_MAX_LENGTH} from '~/shared/consts/addressConsts';
import useCheckoutAddress from '~/common/hooks/useCheckoutAddress';

const logger = createLogger('checkoutAddress');

const fieldSections = [
  {
    name: 'commentSection',
    fields: [
      {
        name: 'comments',
        placeholderKey: 'address_comments',
        fullWidth: true,
        inputProps: {maxLength: COMMENT_MAX_LENGTH},
      },
    ],
  },
  {
    name: 'centerSections',
    showIf: ({initialValues}) => {
      const missingPhoneNumber = !initialValues.phone01;
      return missingPhoneNumber;
    },
    fields: [
      {
        name: 'phone01',
        placeholderKey: 'phone_number',
        fullWidth: true,
        required: true,
      },
    ],
  },
  {
    name: 'bottomSections',
    fields: [
      {
        name: 'apartmentNumber',
        placeholderKey: 'apartment',
        label_short: 'apartment_short',
        inputProps: {maxLength: APARTMENT_MAX_LENGTH},
      },
      {
        name: 'floor',
        placeholderKey: 'floor',
        label_short: 'floor_short',
        inputProps: {maxLength: 3},
      },
      {
        name: 'entrance',
        placeholderKey: 'entrance',
        label_short: 'entrance_short',
        inputProps: {maxLength: 3},
      },
    ],
  },
];

const allFields = flatMap(fieldSections, ({fields}) => fields);

const FieldsWrapper = styled.div`
  ${flexSpace};
  flex: 1;
  margin: ${({theme}) => theme.checkout.elements.addressFieldsWrapper.margin};
`;

const FormField = styled(_FormField)`
  height: 50px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  border-radius: 2px;
  flex: ${({fullWidth}) => (fullWidth ? 1 : '0 0 104px')};
  margin-bottom: 6px;
  ${media.minMobile`
    flex: ${({fullWidth}) => (fullWidth ? 1 : '0 0 124px')};
  `}

  ${({required}) =>
    required &&
    css`
      > div[role='alert'] {
        position: absolute;
        bottom: -17px;
      }
    `}
`;

const CommentWrapper = styled.div`
  line-height: 150%;
`;

const ShowAddressFormButton = styled.button`
  display: flex;
  justify-content: ${({theme}) => theme.checkout.elements.showAddressFormButton.justifyContent};
  padding: 0;
  line-height: 14px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  margin: ${({theme}) => theme.checkout.elements.showAddressFormButton.margin};
  ${media.minLargeTablet`
    margin: 0;
  `}
  min-width: 140px;
`;

const AddressWrapper = styled.div`
  ${flexColumn};
  padding: ${({theme}) => theme.checkout.elements.addressWrapper.padding};
`;

const AddressDetailsRow = styled.div`
  ${flexSpace};
  margin: ${({theme}) => theme.checkout.elements.addressDetailRow.margin};
`;

const AddressDetailsColumn = styled.div`
  display: flex;
`;

const Address = styled.div`
  line-height: 14px;
`;

const SkeletonItem = styled(Skeleton)`
  margin: 16px 8px 0 8px;
`;

const LoaderComponent = () => {
  return <SkeletonItem width={200} height={18} />;
};

const AddressLabelWrapper = ({currentAddress, currentAddressKey}) => {
  const {t} = getLocalizationService();

  const arrayOfAddressValues = useMemo(
    () =>
      allFields.reduce((all, item) => {
        const valueOfCurrentField = currentAddress[item.name];

        if (item.name === 'comments' || !valueOfCurrentField) {
          return all;
        }
        return all.concat(`${t(item.label)} ${valueOfCurrentField}`);
      }, []),
    [currentAddress, t],
  );

  if (!currentAddress) {
    logger.error('No currentAddress was found', {currentAddressKey});
    return null;
  }

  const addressString = arrayOfAddressValues.join(', ');

  return (
    <SkeletonLoader shouldShowLoader={!currentAddress} LoaderComponent={LoaderComponent}>
      {addressString && <Address>{addressString}</Address>}
    </SkeletonLoader>
  );
};

const AddressForm = forwardRef(({initialValues, onSubmit, t}, addressFormRef) => {
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({handleSubmit}) => {
        return (
          <form onChange={handleSubmit} ref={addressFormRef}>
            <>
              {fieldSections
                .filter(({showIf}) => {
                  return showIf ? showIf({initialValues}) : true;
                })
                .map(({name, fields}) => (
                  <FieldsWrapper key={name}>
                    {fields.map(fieldProps => (
                      <FormField key={fieldProps.name} {...fieldProps} placeholder={t(fieldProps.placeholderKey)} />
                    ))}
                  </FieldsWrapper>
                ))}
            </>
          </form>
        );
      }}
    />
  );
});

const CheckoutAddress = ({addressFormValuesRef}, ref) => {
  const {t} = getLocalizationService();
  const isMaxLargeMobile = useIsMaxLargeMobile();
  const {currentAddressKey, currentAddress = {}, isAddressWithoutId} = useCheckoutAddress();
  const [isEditable, setIsEditable] = useState(false);

  return isEditable || isAddressWithoutId ? (
    <AddressForm
      ref={ref}
      initialValues={currentAddress}
      onSubmit={formValues => {
        addressFormValuesRef.current = formValues;
      }}
      t={t}
    />
  ) : (
    <AddressWrapper>
      <AddressDetailsRow>
        <AddressDetailsColumn>
          <AddressLabelWrapper currentAddress={currentAddress} currentAddressKey={currentAddressKey} />
        </AddressDetailsColumn>

        <AddressDetailsColumn>
          {!isMaxLargeMobile && !currentAddress?.isCompanyAddress && (
            <ShowAddressFormButton
              onClick={() => {
                setIsEditable(true);
              }}
            >
              {t('edit_address_information')}
            </ShowAddressFormButton>
          )}
        </AddressDetailsColumn>
      </AddressDetailsRow>

      {currentAddress?.comments && (
        <AddressDetailsRow>
          <CommentWrapper>{currentAddress.comments}</CommentWrapper>
        </AddressDetailsRow>
      )}

      {isMaxLargeMobile && !currentAddress?.isCompanyAddress && (
        <ShowAddressFormButton onClick={() => setIsEditable(true)}>
          {t('edit_address_information')}
        </ShowAddressFormButton>
      )}
    </AddressWrapper>
  );
};

export default memo(forwardRef(CheckoutAddress));
