import {useSelector} from 'react-redux';
import {useTheme} from 'styled-components';

import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {createLogger} from '~/shared/logging';
import {selectCurrentModal} from '~/shared/store/selectors';

import checkoutTheme from '../theme/theme';

const logger = createLogger('CouponsPage');

const CHECKOUT_THEME_VARIANTS = {
  MOBILE: 'page-mobile',
  MODAL: 'modal',
  FULL_PAGE: 'page-desktop',
};

export default function useCheckoutTheme() {
  const theme = useTheme();
  const currentModal = useSelector(selectCurrentModal);
  const isMaxLargeMobile = useIsMaxLargeMobile();

  const themeVariantName = isMaxLargeMobile
    ? CHECKOUT_THEME_VARIANTS.MOBILE
    : currentModal?.name === 'checkout_modal'
      ? CHECKOUT_THEME_VARIANTS.MODAL
      : CHECKOUT_THEME_VARIANTS.FULL_PAGE;

  const variantTheme = checkoutTheme.variants[themeVariantName];

  if (!variantTheme) {
    logger.error('checkout variant not found in theme', themeVariantName);
    return;
  }

  return {
    ...theme,
    checkout: {
      ...theme.checkout,
      ...variantTheme,
    },
  };
}
