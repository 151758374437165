import {ClosedReasonsMap, DeliveryMessage} from '~/shared/services/getRestaurantClosedMessage/types';

export const RESTAURANT_CURRENTLY_CLOSED_DELIVERY: DeliveryMessage = {
  menuCover: 'the_restaurant_doesnt_take_orders_at_the_moment',
  restListBox: 'the_restaurant_doesnt_take_orders_at_the_moment',
};

export const RESTAURANT_CURRENTLY_CLOSED_PICKUP: DeliveryMessage = {
  menuCover: 'the_restaurant_doesnt_take_orders_at_the_moment',
  restListBox: 'the_restaurant_is_closed_for_pickup',
};

export const SITTING_ONLY_DELIVERY: DeliveryMessage = {
  menuCover: 'no_delivery_service_sitting',
  restListBox: 'no_delivery_service_sitting',
};

export const SITTING_ONLY_PICKUP: DeliveryMessage = {
  menuCover: 'no_delivery_service_sitting',
  restListBox: 'the_restaurant_is_closed_for_pickup',
};

export const WITHOUT_DELIVERY_WITH_PICKUP: DeliveryMessage = {
  menuCover: 'no_delivery_service_only_pickup',
  restListBox: 'no_delivery_service_only_pickup',
};

export const DELIVERY_IS_CLOSED_TODAY: DeliveryMessage = {
  menuCover: 'delivery_service_is_closed_today',
  restListBox: 'delivery_service_is_closed_today',
};

export const DELIVERY_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT: DeliveryMessage = {
  menuCover: 'delivery_service_is_not_available_at_the_moment',
  restListBox: 'delivery_service_is_not_available_at_the_moment',
};

export const CLOSED_REASONS_MAP: ClosedReasonsMap = {
  scoober: {
    RestaurantIsBusy: {
      menuCover: 'delivery_service_is_temporarily_closed_brb',
      restListBox: 'delivery_service_is_temporarily_closed_3',
    },
    RestaurantClosedToday: DELIVERY_IS_CLOSED_TODAY,
    NoMessengers: DELIVERY_IS_CLOSED_TODAY,
    default: DELIVERY_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT,
  },
  ownDelivery: {
    RestaurantIsBusy: {
      menuCover: 'delivery_service_is_temporarily_closed_brb',
      restListBox: 'delivery_service_is_temporarily_closed',
    },
    NoMessengers: {
      menuCover: 'delivery_service_is_closed_today',
      restListBox: 'delivery_service_is_closed_today',
    },
    default: DELIVERY_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT,
  },
};
