/* eslint-disable no-console */
import {useCallback, useEffect, useMemo, useState} from 'react';

import styled from 'styled-components';

import {SkeletonLoader} from '~/shared/components/Loaders';
import MultipassCardView, {MultipassCardSkeleton} from '~/shared/components/MultipassCardView';
import {trackEvent} from '~/shared/services/analytics';
import {handleRefreshToken} from '~/shared/services/auth';
import {api} from '~/shared/services/bffService';
import {IVoucherCardRes} from '~/shared/services/bffService/api/interfaces/responses';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectOrderSuccessData, selectOrderSummaryData} from '~/shared/store/selectors';
import {FlexColumnCenterHorizontally} from '~/shared/theme/FlexLayout';
import {body18Bold} from '~/shared/theme/typography';
import {is401Error} from '~/shared/services/apiErrorService';

const Root = styled(FlexColumnCenterHorizontally)`
  width: 100%;
`;

const Title = styled.div`
  width: 100%;
  margin-bottom: 16px;
  ${body18Bold}
`;

const Description = styled.div`
  margin-top: 16px;

  a {
    text-decoration: underline;
  }
`;

const RestaurantMultipassCard = ({allowInit = true, noTitle = false, orderData}: {allowInit?: boolean; noTitle?: boolean; orderData: ReturnType<typeof selectOrderSuccessData> | ReturnType<typeof selectOrderSummaryData>}) => {
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<IVoucherCardRes | null>(null);

  // TODO move order status to enum
  const isOrderCanceled = orderData?.orderStatus === 'Canceled';
  const isLoading = !data && !isError && !isOrderCanceled;
  const restaurantId = orderData?.restaurant.restaurantId;

  const {t} = getLocalizationService();

  const fetchMultipassCard = useCallback(async () => {
    if (!restaurantId || !orderData || isOrderCanceled) {
      return;
    }

    try {
      setIsError(false);
      setData(null);

      const voucherCard = await api.fetchVoucherCardByOrderId({orderId: orderData.orderId});

      setData(voucherCard);
    } catch (error) {
      if (is401Error(error)) {
        await handleRefreshToken(error, fetchMultipassCard);
        return;
      }
      
      trackEvent('multipassFetchFailed', {orderId: orderData.orderId});

      setData(null);
      setIsError(true);

      console.error('Error fetching multipass card for restaurant', {restaurantId, error});
    }
  }, [orderData, restaurantId, isOrderCanceled]);

  useEffect(() => {
    if (!allowInit) {
      return;
    }

    fetchMultipassCard();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowInit]);

  const descriptionWithLinksOpenInNewWindow = useMemo(() => {
    if (!data?.description.includes('<a href')) {
      return data?.description;
    }

    return data.description.replaceAll('<a href', '<a target="_blank" href');
  }, [data]);

  if (!restaurantId) {
    console.error('Multipass component didnt render because restaurantId is not provided from orderSuccessData');
    return null;
  }

  const onTryAgainClick = () => {
    trackEvent('multipassFetchRetryClick', {orderId: orderData.orderId});
    fetchMultipassCard();
  };

  return (
    <Root>
      {!noTitle && <Title>{t('card_info')}</Title>}
      <SkeletonLoader
        shouldShowLoader={isLoading}
        LoaderComponent={MultipassCardSkeleton}
      >
        <MultipassCardView
          isFailure={isError}
          isCanceled={isOrderCanceled}
          onTryAgain={onTryAgainClick}
          {...data}
        />
      </SkeletonLoader>
      {descriptionWithLinksOpenInNewWindow && (
        // eslint-disable-next-line react/no-danger
        <Description dangerouslySetInnerHTML={{__html: descriptionWithLinksOpenInNewWindow}} />
      )}
    </Root>
  );

};

export default RestaurantMultipassCard;
