import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/flipOnLTR';

const InfoContainer = styled.div<{fontBold?: boolean}>`
  margin-right: 5px;
  display: flex;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  align-items: center;
  font-weight: ${({fontBold}) => (fontBold ? 'bold' : 'normal')};
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  ${flipOnLTR`
    margin-left: auto;
  `}
`;

const InfoWrapper = ({children, className}: {
  children?: React.ReactNode;
  className?: string;
} = {}) => {
  if (!children) {
    return null;
  }
  return <InfoContainer className={className}>{children}</InfoContainer>;
};

export default InfoWrapper;
