import {useState, useEffect} from 'react';

export default async function sanitizeHtml(html: string) {
  const sanitizeHtmlLib = await import(/* webpackChunkName: "sanitize-html" */ /* webpackMode: "lazy" */ 'sanitize-html');
  return sanitizeHtmlLib.default(html);
}

export function useAsyncSanitizeHtml(html: string) {
  const [sanitizedHtml, setSanitizedHtml] = useState('');

  useEffect(() => {
    if (!html) {
      return;
    }

    sanitizeHtml(html)
      .then(result => setSanitizedHtml(result));
  }, [html]);

  return sanitizedHtml;
}
