import {memo} from 'react';

import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import PaymentMethodIconAndDetails from '~/shared/components/PaymentMethodIconAndDetails';
import RadioButton from '~/shared/components/RadioButton';
import whitePlusIconUrl from '~/assets/images/icons/white_plus.svg';
import {getLocalizationService} from '~/shared/services/localisationService';
import {body12Normal, body15Bold} from '~/shared/theme/typography';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import {ModifiedCreditCard} from './types';

const Root = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;

const Header = styled.div`
  ${body15Bold};
  color: ${({theme}) => theme.checkout.otl.textColor};
  line-height: 22px;
  ${flipOnLTR`
    text-align: right;
  `}
`;

const List = styled.div`
  margin-top: 16px;
  margin-bottom: 10px;
  max-height: 400px;
  overflow: auto;
`;

const AddCreditCardButtonWrapper = styled.div`
  ${flexCenterVertically};
  cursor: pointer;
  ${flipOnLTR`
    margin-right: 4px;
  `}
`;

const PlusIcon = styled(ImageWithAlt).attrs(({theme}) => ({
  src: whitePlusIconUrl,
  color: theme.checkout.otl.addCreditCardColor,
}))`
  height: 10px;
  width: 10px;
`;

const AddCreditCardText = styled.div`
  color: ${({theme}) => theme.checkout.otl.addCreditCardColor};
  ${body12Normal};
  line-height: 16px;
  ${flipOnLTR`
    margin-right: 6px;
  `}
`;

const CreditCardRadioButton = styled(RadioButton)`
  padding: 5px 0;
`;

type OtlCreditCardListProps = {
  creditCardList: ModifiedCreditCard[];
  selectedCreditCard: number | null;
  setSelectedCreditCard: (creditCardId: number | null) => void;
  addCreditCard: () => void;
};

const OtlCreditCardList = ({
  creditCardList,
  selectedCreditCard,
  setSelectedCreditCard,
  addCreditCard,
}: OtlCreditCardListProps) => {
  const {t} = getLocalizationService();
  return (
    <Root>
      <Header>{t('select_the_credit_card_you_wish_to_assign')}</Header>
      <List>
        {creditCardList.map(creditCard => (
          <CreditCardRadioButton
            value={creditCard.creditcardId}
            onChange={() => setSelectedCreditCard(creditCard.creditcardId)}
            isChecked={selectedCreditCard === creditCard.creditcardId}
            key={creditCard.creditcardId}
            id={`payment-card-${creditCard.creditcardId}`}
          >
            {/* @ts-expect-error rewrite PaymentMethodIconAndDetails to ts */}
            <PaymentMethodIconAndDetails payment={creditCard} isCheckoutPaymentMethodLine={false} />
          </CreditCardRadioButton>
        ))}
      </List>
      <AddCreditCardButtonWrapper onClick={addCreditCard}>
        <PlusIcon alt={t('add_a_credit_card')} />
        <AddCreditCardText>{t('add_a_credit_card')}</AddCreditCardText>
      </AddCreditCardButtonWrapper>
    </Root>
  );
};

export default memo(OtlCreditCardList);
