import {memo} from 'react';

import {times} from 'lodash';
import styled from 'styled-components';

import {Skeleton} from '~/shared/components/Loaders';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

const Root = styled.div`
  height: 572px;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;

  && {
    background-color: ${({theme}) => theme.colors.primaryText};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 150px;
`;

const Image = styled.div`
  width: 272px;
  height: 100%;
`;

const HeaderDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  ${flipOnLTR`
    margin-right: 15px;
  `}
`;

const Top = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Liner = styled.div`
  width: ${({width}) => (width ? `${width}px` : '100%')};
  height: ${({height}) => (height ? `${height}px` : '1px')};
  background-color: ${({theme}) => theme.colors.gray500};
  ${({marginTop}) => marginTop && `margin-top: ${marginTop}px;`}
  ${({marginBottom}) => marginBottom && `margin-bottom: ${marginBottom}px;`}
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;

  &:first-child {
    margin-top: 0;
  }
`;

const Checkbox = styled.div`
  width: 12px;
  height: 12px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  ${flipOnLTR`margin-left: 8px;`}
`;

const Radio = styled(Checkbox)`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;

const Submit = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CHECKBOXES_LABELS_WIDTH = [126, 159, 106];

const Loader = () => (
  <Root>
    <Header>
      <Image>
        <Skeleton />
      </Image>
      <HeaderDetails>
        <Top id="modal-title">
          <Skeleton width={320} height={24} marginBottom={16} />
          <Skeleton width={260} height={20} />
        </Top>
        <Bottom>
          <Skeleton width={160} height={36} />
          <Skeleton width={85} height={36} />
        </Bottom>
      </HeaderDetails>
    </Header>
    <Liner marginTop={36} marginBottom={16} height={2} />
    <Body>
      <Skeleton width={211} height={24} />
      <Liner marginTop={10} marginBottom={12} />
      <Container>
        {times(3, i => (
          <div key={i}>
            {CHECKBOXES_LABELS_WIDTH.map(width => (
              <Field key={width}>
                <Checkbox />
                <div>
                  <Skeleton width={width} height={20} />
                </div>
              </Field>
            ))}
          </div>
        ))}
      </Container>
      <Skeleton width={211} height={24} marginTop={32} />
      <Liner marginTop={7} marginBottom={11} />
      <Field>
        <Radio />
        <div>
          <Skeleton width={126} height={20} />
        </div>
      </Field>
      <Skeleton width={264} height={16} marginTop={34} />
      <Submit>
        <Skeleton width={346} height={40} marginTop={28} />
      </Submit>
    </Body>
  </Root>
);

export default memo(Loader);
