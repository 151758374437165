import {Fragment} from 'react';

import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import {Dispatch} from 'redux';

import {createLogger} from '~/shared/logging';
import {selectOrderError, selectUserData} from '~/shared/store/selectors';
import {getCurrentLocation, pushRoute} from '~/shared/router';
import {routeNames} from '~/shared/routes';
import actions from '~/shared/store/actions';
import {flexColumn} from '~/shared/theme/FlexLayout';
import OtlSectionsContainer from '~/shared/components/Otl/OtlSectionsContainer';
import {useOnPageInit} from '~/shared/managers/initManager/initManager';
import store from '~/shared/store';

const ModalRoot = styled.div`
  background-color: ${({theme}) => theme.loginModal.backgroundColor};
  border-radius: 2px;
  overflow: hidden;
  ${flexColumn}
`;

const logger = createLogger('OtlPageComponent');

const pushRouteToCheckout = () => {
  pushRoute('/checkout');
};

const handleOnClose = (dispatch: Dispatch, isModal: boolean, closeModal?: () => void, returnToModal?: () => void) => () => {
  if (!isModal) {
    pushRouteToCheckout();
  } else {
    const {routeName} = getCurrentLocation();
    if (closeModal && routeName === routeNames.checkout) {
      closeModal();
    } else {
      if (returnToModal) {
        dispatch(actions.setCurrentModal(returnToModal));
      }
    }
  }
};

const OtlPageComponent = ({closeModal, args}: {
  closeModal?: () => void;
  args?: Record<string, () => void>;
}) => {
  const orderError = useSelector(selectOrderError);
  const dispatch = useDispatch();

  const isModal = !!closeModal;

  useOnPageInit(() => {
    const userData = selectUserData(store.getState());
    if (!userData) {
      if (isModal) {
        closeModal();
        logger.error('Modal closed, no userData');
      } else {
        pushRoute(`/signin?returnUrl=${encodeURIComponent('/otl')}`);
        logger.warn('Redirecting to login and onSuccess should redirect back to the otl page');
      }
    }
  });

  const RootElement = closeModal ? ModalRoot : Fragment;

  return (
    <RootElement>
      <OtlSectionsContainer
        orderCardId={orderError?.data.submitOrderFailurePaymentId}
        onClose={handleOnClose(dispatch, isModal, closeModal, args?.returnToModal)}
        onOtlSuccess={handleOnClose(dispatch, isModal, closeModal, args?.returnToModal)}
      />
    </RootElement>
  );
};

export default OtlPageComponent;
