import {memo} from 'react';

import {useSelector} from 'react-redux';

import Input from '~/shared/components/Input';
import {selectA11yContrast} from '~/shared/store/selectors';

const CommonInput = props => {
  const isContrastActive = useSelector(selectA11yContrast);

  return <Input {...props} isContrastActive={isContrastActive} />;
};

export default memo(CommonInput);
