export enum PooledOrderTagStates {
  NO_MINIMUM = 'noMinimum',
  UNDER_MINIMUM_WITH_ORDERS = 'underMinimumWithOrders',
  UNDER_MINIMUM_WITHOUT_ORDERS = 'underMinimumWithoutOrders',
  OVER_MINIMUM = 'overMinimum',
}

export type PooledOrderTagState = EnumValueType<typeof PooledOrderTagStates>;

export const getPooledOrderTagState = (minimumForPooledOrder: number, pooledOrderSum: number, estimatedNumberOfOrdersInPoolOrder: number): PooledOrderTagState => {
  if (minimumForPooledOrder === 0 && estimatedNumberOfOrdersInPoolOrder === 0) {
    return PooledOrderTagStates.NO_MINIMUM;
  }
  
  if (pooledOrderSum >= minimumForPooledOrder) {
    return PooledOrderTagStates.OVER_MINIMUM;
  }
  
  if (estimatedNumberOfOrdersInPoolOrder > 0) {
    return PooledOrderTagStates.UNDER_MINIMUM_WITH_ORDERS;
  }

  return PooledOrderTagStates.UNDER_MINIMUM_WITHOUT_ORDERS;
};
