import {useCallback, useEffect, useState} from 'react';

import {POST_MESSAGE_TRUSTED_ORIGINS} from '~/shared/consts/creditCardConsts';
import {ThreeDSMessage} from '~/common/types/checkout';
import {THREE_DS_EVENTS_MAP} from '~/common/hooks/useCheckoutSubmission/trackThreeDsEvent';
import {Payment} from '~/shared/store/models';

const is3dsMessage = (message: MessageEvent): message is MessageEvent<ThreeDSMessage> => !!message.data &&
  typeof message.data === 'object' &&
  'threeDsSuccess' in message.data;

const useThreeDsVerification = ({
  setThreeDSChallengeUrl,
  setIsSubmitting,
  handleOrderSubmissionRequests,
  trackThreeDsEventHandler,
}: {
  setThreeDSChallengeUrl: React.Dispatch<string>;
  setIsSubmitting: React.Dispatch<boolean>;
  handleOrderSubmissionRequests: (args?: {
    is3dsSubmission?: boolean;
    threeDsResponse?: ThreeDSMessage;
  }) => Promise<void>;
  trackThreeDsEventHandler: (
    event: keyof typeof THREE_DS_EVENTS_MAP,
    params: {
      customError?: string;
      threeDsResponse?: ThreeDSMessage;
      orderPayments?: Payment[];
    },
  ) => void;
}) => {
  const [isThreeDSFailed, setIsThreeDSFailed] = useState(false);

  const handle3dsResult = useCallback(async (e: MessageEvent) => {
    if (!is3dsMessage(e) || !POST_MESSAGE_TRUSTED_ORIGINS.includes(e.origin)) {
      return;
    }
    setThreeDSChallengeUrl('');
    const {threeDsSuccess} = e.data;
    if (!threeDsSuccess) {
      trackThreeDsEventHandler('failure', {
        threeDsResponse: e.data,
      });
      setIsThreeDSFailed(true);
      setIsSubmitting(false);
      return;
    }

    trackThreeDsEventHandler('success', {
      threeDsResponse: e.data,
    });
    setIsSubmitting(true);
    await handleOrderSubmissionRequests({is3dsSubmission: true, threeDsResponse: e.data});
  }, [
    handleOrderSubmissionRequests,
    setIsThreeDSFailed,
    setIsSubmitting,
    setThreeDSChallengeUrl,
    trackThreeDsEventHandler,
  ]);

  useEffect(() => {
    window.addEventListener('message', handle3dsResult);

    return () => {
      window.removeEventListener('message', handle3dsResult);
    };
  }, [handle3dsResult]);

  const resetIsThreeDSFailed = useCallback(() => setIsThreeDSFailed(false), []);

  return {
    isThreeDSFailed,
    resetIsThreeDSFailed,
  };
};

export default useThreeDsVerification;
