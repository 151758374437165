import {Coupon, CouponValueType, Dish} from '../store/models';

export const getDishPriceWithDiscount = (
  price: Dish['dishPrice'] | Dish['pricePerMeasurementUnit'],
  couponData: Pick<Coupon, 'valueType' | 'discountValue'> | null,
) => {
  if (!price || !couponData || couponData.valueType !== CouponValueType.PERCENT) {
    return null;
  }

  return {price, priceAfterDiscount: price - ((price / 100) * couponData.discountValue)};
};
