import {memo} from 'react';

import styled from 'styled-components';

import {body14Normal, body16Bold, body20Bold} from '~/shared/theme/typography';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {flexColumn} from '~/shared/theme/FlexLayout';

const Root = styled.div`
  ${flexColumn};
  ${({withBottomPadding}) => withBottomPadding && 'margin-bottom: 15px'};
`;

const SectionTitle = styled.h5`
  ${body16Bold};
  color: ${({theme}) => theme.menu.details.infoColor};
  line-height: 24px;
  padding: 15px 0;
  ${flipOnLTR`
    text-align: right;
  `}
  ${media.minDesktop`
    ${body20Bold};
    background-color: #f0f0f0;
    padding: 15px;
  `};
`;

const SectionContent = styled.div`
  ${flexColumn};
  color: ${({theme}) => theme.menu.details.nameColor};
  ${body14Normal};
  line-height: 20px;
  ${media.minLargeTablet`
    margin-top: 15px;
  `}
  ${flipOnLTR`
    text-align: right;
  `}
`;

const BoldSectionContent = styled(SectionContent)`
  font-weight: bold;
`;

const AboutSectionBase = ({title, content, Component, boldContent, withBottomPadding}) => (
  <Root withBottomPadding={withBottomPadding}>
    <SectionTitle id="modal-title">{title}</SectionTitle>
    {Boolean(Component) && Component}
    <SectionContent>{content}</SectionContent>
    {boldContent && <BoldSectionContent>{boldContent}</BoldSectionContent>}
  </Root>
);

export default memo(AboutSectionBase);
