import {isEqual} from 'lodash';

import {HOUR, MINUTE} from '~/shared/consts/commonConsts';
import {PERSISTENT_STORE_LOCAL_STORAGE_KEY} from '~/shared/consts/localStorageConsts';
import {DefaultRestaurantFilters} from '~/shared/consts/restaurantConsts';

import {AppState} from '../configStore';

export const PERSIST_FILTERS_DATA_FOR = 20 * MINUTE;
export const PERSIST_CURRENT_ADDRESS_VALID_FOR = 2 * HOUR;

const persistStoreConfig: PersistStoreConfig = {
  localStorageKey: PERSISTENT_STORE_LOCAL_STORAGE_KEY,
  scopes: {
    filter: {
      path: ['ui', 'restaurants', 'filter'],
      validFor: PERSIST_FILTERS_DATA_FOR,
      mergeWithInitialState: ({scopeInitialState, savedData}) =>
        (isEqual(scopeInitialState, DefaultRestaurantFilters) ? savedData : scopeInitialState),
    },
    sortBy: {
      path: ['ui', 'restaurants', 'sortBy'],
      validFor: PERSIST_FILTERS_DATA_FOR,
    },
    mobileMiniFeedSort: {
      path: ['ui', 'mobile', 'miniFeedFilterEnabled'],
      validFor: PERSIST_FILTERS_DATA_FOR,
    },
    localAddress: {
      path: ['localAddress'],
    },
    userReportOrderDetails: {
      path: ['userReportOrderDetails'],
      mergeWithInitialState: ({savedData}) => savedData || {},
    },
    currentAddressKey: {
      path: ['currentAddressKey'],
      validFor: PERSIST_CURRENT_ADDRESS_VALID_FOR,
    },
    currentDeliveryMethod: {
      path: ['currentDeliveryMethod'],
      validFor: PERSIST_CURRENT_ADDRESS_VALID_FOR,
    },
    cuisines: {
      path: ['cuisines', 'data'],
    },
    order: {
      path: ['order', 'initialOrder'],
    },
  },
};
interface PersistStoreConfig {
  localStorageKey: typeof PERSISTENT_STORE_LOCAL_STORAGE_KEY;
  scopes: Record<string, PersistStoreScope>;
}

interface PersistStoreScope {
  path: string[];
  validFor?: number;
  mergeWithInitialState?: (payload: {
    scopeInitialState: unknown;
    savedData: unknown;
    fullState: AppState;
    persistentStoreSavedScopes: PersistentStoreSavedScopes;
  }) => unknown;
}

export type PersistentStoreSavedScopes = Record<string, PersistStoreSavedScope>;

interface PersistStoreSavedScope {
  timeStamp: string;
  data: unknown;
}

export default persistStoreConfig;
