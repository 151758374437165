import {pick} from 'lodash';
import moment from 'moment-timezone';

import {RESTAURANT_LIST_VALID_FOR, DeliveryMethods, RestaurantsListContext} from '~/shared/consts/restaurantConsts';
import {createLogger} from '~/shared/logging';
import {selectAllAddresses, selectUserData} from '~/shared/store/selectors';
import {RestaurantsLastFetchInfo, RestaurantsState} from '~/shared/store/storeModules/restaurants/restaurantsReducer';
import {addressPartsFromKey, findAddressByAddressKey} from '~/shared/utils/address';

import {RestaurantGroupRestaurants, RestaurantList} from '../../models';
import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {
  selectRestaurantsCityLastFetchInfo,
  selectLastSearchRestaurantsPromise,
  selectRestaurantsGroupLastFetchInfo,
  selectRestaurantsMainLastFetchInfo,
} from './restaurantsSelectors';

const logger = createLogger('restaurants actions');

export const setSearchRestaurantsLastFetchInfo = makeActionCreator<
  'setSearchRestaurantsLastFetchInfo',
  RestaurantsLastFetchInfo
>('setSearchRestaurantsLastFetchInfo');

export const setRestaurantsByGroupIdLastFetchInfo = makeActionCreator<
  'setRestaurantsByGroupIdLastFetchInfo',
  {fetchedFor: RestaurantsLastFetchInfo['fetchedFor']}
>('setRestaurantsByGroupIdLastFetchInfo');

export const setLastSearchRestaurantsPromise = makeActionCreator<
  'setLastSearchRestaurantsPromise',
  RestaurantsState['lastSearchRestaurantsPromise']
>('setLastSearchRestaurantsPromise');

export const searchRestaurantsIfNeeded = makeThunkAsyncActionCreator<
  SearchRestaurantsIfNeededPayload,
  RestaurantsState['main']['list']['data']
>(
  'searchRestaurantsIfNeeded',
  async ({addressKey, deliveryMethod, force, waitFor = Promise.resolve()}, {store, apiService}) => {
    async function searchRestaurants() {
      await waitFor;
      const state = store.getState();
      const allAddresses = selectAllAddresses(state);
      const address = findAddressByAddressKey(allAddresses, addressKey);
      const addressKeyDetails = addressPartsFromKey(addressKey);
      return apiService.searchRestaurants({
        deliveryMethod,
        ...pick(address, 'addressId', 'cityId', 'floor', 'houseNumber', 'latitude', 'longitude', 'streetId'),
        ...pick(addressKeyDetails, 'addressId', 'cityId', 'streetId', 'houseNumber'),
      });
    }

    const state = store.getState();
    const user = selectUserData(state);
    const lastSearchRestaurantsPromise: ReturnType<typeof searchRestaurants> =
      selectLastSearchRestaurantsPromise(state);

    const {fetchedFor: lastFetchedFor, fetchedTime: lastFetchedTime} = selectRestaurantsMainLastFetchInfo(state);

    const newFetchedFor = `main,${addressKey},${deliveryMethod},${user?.userId}`;
    const newFetchTime = moment().format();

    const needToSearchRestaurants =
      force ||
      lastFetchedFor !== newFetchedFor ||
      moment(lastFetchedTime).add(RESTAURANT_LIST_VALID_FOR).isBefore(newFetchTime);

    let result = null;
    if (needToSearchRestaurants) {
      logger.verbose('searching restaurants', {addressKey, deliveryMethod, newFetchedFor, newFetchTime, force});
      store.dispatch(setSearchRestaurantsLastFetchInfo({fetchedFor: newFetchedFor, fetchedTime: newFetchTime}));

      const searchRestaurantsRes = searchRestaurants();
      store.dispatch(setLastSearchRestaurantsPromise(searchRestaurantsRes));
      result = (await searchRestaurantsRes).data;
    } else {
      logger.verbose('No searchRestaurants is needed', {force, newFetchedFor, lastFetchedFor});
    }

    return result || (await lastSearchRestaurantsPromise).data;
  },
);
interface SearchRestaurantsIfNeededPayload {
  addressKey: string;
  deliveryMethod: string;
  force?: boolean;
  waitFor?: Promise<unknown>;
}

let lastGetRestaurantsByGroupPromise: Promise<RestaurantGroupRestaurants>;
export const getRestaurantsByGroupIdIfNeeded = makeThunkAsyncActionCreator<
  GetRestaurantsByGroupIdIfNeededPayload,
  RestaurantsState['group']['list']['data']
>('getRestaurantsByGroupIdIfNeeded', async ({groupId, longitude, latitude, force}, {store, apiService}) => {
  async function getRestaurantsByGroupId() {
    const restaurantsResponse = await apiService.getRestaurantsByGroupId({
      groupId,
      longitude,
      latitude,
    });
    return restaurantsResponse.data;
  }

  const state = store.getState();

  if (!groupId) {
    throw new Error('No groupId provided to getRestaurantsByGroupIdIfNeeded');
  }

  const {fetchedFor: lastFetchedFor} = selectRestaurantsGroupLastFetchInfo(state);
  const newFetchedFor = `groupPage,${groupId},${longitude},${latitude}`;

  const needToGetRestaurantsByGroupId = force || lastFetchedFor !== newFetchedFor;
  if (needToGetRestaurantsByGroupId) {
    logger.verbose('fetching restaurants by group id', {groupId, newFetchedFor, force});
    store.dispatch(setRestaurantsByGroupIdLastFetchInfo({fetchedFor: newFetchedFor}));

    if (lastGetRestaurantsByGroupPromise) {
      await lastGetRestaurantsByGroupPromise;
    }

    lastGetRestaurantsByGroupPromise = getRestaurantsByGroupId();
  } else {
    logger.verbose('No getRestaurantsByGroupId is needed', {force, newFetchedFor, lastFetchedFor});
  }

  return lastGetRestaurantsByGroupPromise;
});
interface GetRestaurantsByGroupIdIfNeededPayload {
  groupId: string;
  longitude: number;
  latitude: number;
  force: boolean;
}

export const setSearchCityRestaurantsLastFetchInfo = makeActionCreator('setSearchCityRestaurantsLastFetchInfo');

let searchCityRestaurantsLastFetchInfoPromise: Promise<RestaurantList>;
export const searchCityRestaurantsIfNeeded = makeThunkAsyncActionCreator<
  SearchCityRestaurantsIfNeededPayload,
  RestaurantsState['city']['list']['data']
>('searchCityRestaurantsIfNeeded', async ({addressKey, force}, {store, apiService}) => {
  async function searchCityRestaurants() {
    const addressKeyDetails = addressPartsFromKey(addressKey);
    const restaurantsResponse = await apiService.searchRestaurants({
      ...addressKeyDetails,
      deliveryMethod: DeliveryMethods.DELIVERY,
    });
    return restaurantsResponse.data;
  }

  const state = store.getState();

  if (!addressKey) {
    throw new Error('No addressKey provided to searchCityRestaurantsIfNeeded');
  }

  const {fetchedFor: lastFetchedFor} = selectRestaurantsCityLastFetchInfo(state);
  const newFetchedFor = `cityPage,${addressKey}`;

  const needToSearchCityRestaurants = force || lastFetchedFor !== newFetchedFor;
  if (needToSearchCityRestaurants) {
    logger.verbose('searching city restaurants', {addressKey, newFetchedFor, force});
    store.dispatch(setSearchCityRestaurantsLastFetchInfo({fetchedFor: newFetchedFor}));

    if (searchCityRestaurantsLastFetchInfoPromise) {
      await searchCityRestaurantsLastFetchInfoPromise;
    }

    searchCityRestaurantsLastFetchInfoPromise = searchCityRestaurants();
  } else {
    logger.verbose('No searchCityRestaurantsIfNeeded is needed', {force, newFetchedFor, lastFetchedFor});
  }

  return searchCityRestaurantsLastFetchInfoPromise;
});
interface SearchCityRestaurantsIfNeededPayload {
  addressKey: string;
  force: boolean;
}

export const setRestaurantListContext = makeActionCreator<'setRestaurantListContext', RestaurantsListContext>(
  'setRestaurantListContext',
);

export const setCurrentCollectionName = makeActionCreator<
  'setCurrentCollectionName',
  RestaurantsState['currentCollectionName']
>('setCurrentCollectionName');
