const pie = {
  orange10: '#FFEAD4',
  orange30: '#ff8000',
  orange30Opacity50: '#ff800080',
  charcoal80: '#242E30',
  mozzarella30: '#DBD9D7',
  yellow: '#F6C243',
  contentBrand: '#F36805',
  lightOrange: '#FFE2C2',
  supportPositive2: '#E5FAEF',
  contentPositive: '#017A39',
  backgroundInteractive: '#262626',
  supportNeutral: '#EFEDEA',
  borderInverse: '#575655',
};

const colors = {
  primary: pie.orange30,
  primaryText: '#ffffff',

  secondary: '#0a3847',
  secondaryText: '#ffffff',

  background: '#f0f0f0',
  lightBackground: '#fafafa',
  lightBackground2: '#fcfcfc',
  darkBackground: '#f8f5f2',

  surface: '#ffffff',
  surfaceTitle: '#1574f5',
  surfacePrimaryAction: '#1574f5',
  surfacePrimaryActionDark: '#0953BD',
  surfacePrimaryActionLight: '#cfd8e4',
  surfacePrimaryActionText: '#ffffff',

  charcoal80: '#242E30',

  text: '#666666',
  text2: '#0A3847',

  gray000: '#000000',
  gray100: '#333333',
  gray200: '#666666',
  gray300: '#979797',
  gray500: '#D7D7D7',
  gray600: '#D8D8D8',
  gray700: '#E0DDD9',
  gray800: '#EBEBEB',
  gray900: '#F8F5F2',
  gray910: '#F5F5F5',
  gray920: '#F5F3F1',
  gray930: '#E4E4E4',
  gray950: '#A5A6A9',

  plate1: {color: '#fff', background: '#00b4db'},
  plate2: {color: '#00777f', background: '#dffdff'},
  plate3: {color: '#0371b5', background: '#f1f9ff'},
  plate4: {color: '#a15100', background: '#ffe8ca'},
  plate5: {color: '#1B7853', background: '#D9F6EB'},
  plate6: {color: '#ffc003', background: '#ffc003'},
  plate7: {color: '#cfd8e4', background: '#cfd8e4'},
  plate8: {color: '#275C7E', background: '#275C7E'},

  success: '#10d365',
  successDark: '#0ca74f',
  error: '#ff4246',
  info: '#00b4db',
  contrast: '#ff0',

  boxShadow: '#00000003',
  boxShadowLight: '#3f44610c',

  feedbackInfoRegular: '#205A6D',
  disclaimerSurface: '#FFECB3',
  ...pie,
};

export default colors;
