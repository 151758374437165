import {useEffect} from 'react';

import styled from 'styled-components';

import {attachModal} from '~/shared/components/ReduxModal';
import {PopupModalBase} from '~/shared/components/Modals';
import {FlexCenter} from '~/shared/theme/FlexLayout';
import {SimpleSpinnerLoader} from '~/shared/components/Loaders';
import {POST_MESSAGE_TRUSTED_ORIGINS} from '~/shared/consts/creditCardConsts';
import {media} from '~/shared/theme/media';

const StyledPopupBase = styled(PopupModalBase)<{height?: number}>`
  &&& {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    background-color: ${({theme}) => theme.colors.secondaryText};
  }

  ${media.minTablet`
    &&& {
      width: 540px;
      height: 600px;
    }
  `}
`;

const IFrameContainer = styled(FlexCenter)`
  position: relative;
  min-height: 600px;
`;

const StyledIframe = styled.iframe`
  border: none;
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
`;

const ThreeDSVerificationModal = ({args, closeModal}: {closeModal: (keepLoader?: boolean) => void; args: {url: string}}) => {
  useEffect(() => {
    const messageListener = (e: MessageEvent) => {
      if (!('threeDsSuccess' in e.data) || !POST_MESSAGE_TRUSTED_ORIGINS.includes(e.origin)) {
        return;
      }

      closeModal(true);
    };

    window.addEventListener('message', messageListener);

    return () => {
      window.removeEventListener('message', messageListener);
    };
  }, [closeModal]);

  return (
    <StyledPopupBase>
      <IFrameContainer id="modal-title">
        <SimpleSpinnerLoader />
        <StyledIframe
          title="3dsVerificationIFrame"
          src={args.url}
        />
      </IFrameContainer>
    </StyledPopupBase>
  );
};

ThreeDSVerificationModal.hideCloseButton = true;

attachModal('ThreeDSVerificationModal', ThreeDSVerificationModal);
