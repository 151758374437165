export const getErrorByActionSuggestion = ({actionSuggestionType, openCreditCard, openOtlSettings}) => {
  switch (actionSuggestionType) {
    case 'InsertNewCreditCard':
      return {
        text: 'you_can_add_a_credit_card',
        action: openCreditCard,
      };
    case 'OtlRegistration':
      return {
        text: 'you_can_assign_your_creditcard_to_your_ten_bis_card',
        action: openOtlSettings,
      };
    case 'SetOtlDebitSingleTransactionLimitRequired':
    case 'SetOtlDebitDailyLimitRequired':
    case 'SetOtlDebitMonthlyLimitRequired':
      return {
        text: 'you_can_update_your_ten_bis_card_limits',
        action: openOtlSettings,
      };
    default:
      return null;
  }
};
