import {useContext, useMemo} from 'react';

import {flatMap} from 'lodash';
import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {body18Bold} from '~/shared/theme/typography';
import {isDishAgeRestricted} from '~/shared/utils/ageRestriction';
import {getDishTotalPrice} from '~/shared/utils/billingLinesCalculation';
import {selectUser} from '~/shared/store/selectors';
import {baseTheme} from '~/shared/theme';

import ShoppingCart from '../ShoppingCart';

import DishItem from './DishItem';

const padding = 64;

interface IGetMaxHeight {
  isMaxHeight: boolean;
  isAgeRestricted?: boolean;
  isFutureOrderEnabled?: boolean;
  numberOfBillingLines: number;
  isMinLargeTablet: boolean;
}

const getMaxHeight = ({isMaxHeight, isAgeRestricted, isFutureOrderEnabled, numberOfBillingLines, isMinLargeTablet}: IGetMaxHeight) => {
  let extraSpace = isFutureOrderEnabled ? baseTheme.shoppingCart.futureOrderHeight : 0;
  extraSpace += isAgeRestricted ? baseTheme.shoppingCart.ageRestrictionDisclaimerHeight : 0;
  if (numberOfBillingLines > 2) {
    extraSpace += (numberOfBillingLines - 2) * baseTheme.shoppingCart.billingLines.lineHeight;
  }
  return !isMaxHeight
    ? 'none'
    : `calc(100vh - ${
      baseTheme.subHeader.height +
      baseTheme.header.height +
        (isMinLargeTablet ? baseTheme.menu.categories.height.desktop : baseTheme.menu.categories.height.mobile) +
        baseTheme.shoppingCart.paymentButtonHeight +
        baseTheme.shoppingCart.billingLines.minHeight +
        extraSpace +
        padding
    }px)`;
};

const ListContainer = styled.div<{maxHeight: string}>`
  padding: 16px;
  overflow: auto;
  max-height: ${({maxHeight}) => maxHeight};
`;

const Title = styled.h3`
  ${body18Bold};
  line-height: 18px;
  margin-bottom: 16px;
`;

type DishListProps = {
  hideRemoveLastDish?: boolean;
  showTitle?: boolean;
  isFutureOrderEnabled?: boolean;
  isAgeRestricted?: boolean;
  numberOfBillingLines: number;
  className?: string;
  shouldShowAgeConfirm?: boolean;
};

const DishList = ({
  showTitle,
  isFutureOrderEnabled,
  isAgeRestricted,
  numberOfBillingLines,
  className,
  shouldShowAgeConfirm,
}: DishListProps) => {
  const {t} = getLocalizationService();
  const {assignedUsers, dishes, dishesWithSubs, isMaxHeight, isMinLargeTablet} = useContext(ShoppingCart.Context);
  const currentUser = useSelector(selectUser);
  const showOwnerName = useMemo(() => {
    return dishes?.some(({assignedUserId}) => assignedUserId !== currentUser?.data?.userId);
  }, [currentUser?.data?.userId, dishes]);

  const maxHeight = getMaxHeight({isMaxHeight, isAgeRestricted, isFutureOrderEnabled, numberOfBillingLines, isMinLargeTablet});

  return (
    <ListContainer
      maxHeight={maxHeight}
      className={className}
    >
      {!showTitle && <Title>{t('your_cart')}</Title>}
      {dishes?.map(({dishId, quantity, dishName, assignedUserId, shoppingCartDishId, choices, categoryId, dishImageUrl}, index) => {
        const assignedUserInDish = assignedUsers.find(user => user.id === assignedUserId);
        const assignedUser = assignedUsers.find(user => user.isSelected);
        const dishOwnerUser = assignedUserInDish || assignedUser || null;
        const dishOwnerName = dishOwnerUser && dishOwnerUser.name;

        const subChoices = flatMap(choices, ({choiceId, subsChosen}) =>
          subsChosen.map(
            ({subId}) => dishesWithSubs?.[dishId]?.choicesMap?.[choiceId]?.subsMap?.[subId]?.name?.trim?.() || '',
          ),
        );

        const ageRestricted = isDishAgeRestricted(dishesWithSubs, dishes[index]);
        const dishTotalPrice = getDishTotalPrice(dishesWithSubs?.[dishId] || {}, choices, quantity);
        const dishSinglePrice = getDishTotalPrice(dishesWithSubs?.[dishId] || {}, choices, 1);

        return (
          <DishItem
            key={shoppingCartDishId}
            dishId={dishId}
            dishImageUrl={dishImageUrl}
            quantity={quantity}
            dishName={dishName}
            dishPrice={dishTotalPrice}
            ownerName={dishOwnerName && dishOwnerName}
            shoppingCartDishId={shoppingCartDishId}
            subChoices={subChoices}
            dishIndex={index}
            categoryId={categoryId}
            dishSinglePrice={dishSinglePrice}
            ageRestricted={ageRestricted}
            showOwnerName={showOwnerName}
            shouldShowAgeConfirm={shouldShowAgeConfirm}
          />
        );
      })}
    </ListContainer>
  );
};

export default DishList;
