import {memo} from 'react';

import styled from 'styled-components';

import {PrimaryActionButton} from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/utils';
import {body14Bold, body18Bold} from '~/shared/theme/typography';

const Root = styled.div`
  background: ${({theme}) => theme.colors.surface};
  width: 100%;
  ${media.minLargeTablet`
    &&& {
      background: ${({theme}) => theme.colors.surface};
      width: 600px;
      height: 219px;
    }
  `}
`;

const Header = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 80px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
`;

const Title = styled.h3`
  ${body18Bold};
`;

const Body = styled.div`
  ${body14Bold};
  text-align: center;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled(PrimaryActionButton)`
  ${body14Bold};
  width: 252px;
  height: 50px;
  margin-top: 24px;
`;

const DishNotFound = ({onClose}: {onClose: () => void}) => {
  const {t} = getLocalizationService();

  return (
    <Root>
      <Header>
        <Title id="modal-title">{t('dish_unavailable')}</Title>
      </Header>
      <Body id="modal-content">
        {t('the_dish_is_unavailable_at_this_time')}
        <CloseButton onClick={onClose}>{t('back_to_the_restaurant')}</CloseButton>
      </Body>
    </Root>
  );
};

export default memo(DishNotFound);
