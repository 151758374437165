import styled from 'styled-components';

import {SkeletonLoader, Skeleton} from '~/shared/components/Loaders';
import {getLocalizationService} from '~/shared/services/localisationService';
import {FlexCenterVertically, FlexStart} from '~/shared/theme/FlexLayout';
import {flipOnLTR, media} from '~/shared/theme/utils';

const Wrapper = styled(FlexCenterVertically)`
  margin: 0 16px;
  ${media.minLargeTablet`
    margin:0;
    min-height: 40px;
    padding: 0 8px;
    background-color: ${({theme}) => theme.colors.lightBackground};
  `}
`;

const Bold = styled.div`
  font-weight: bold;
  ${flipOnLTR`
    margin-left: 5px;
  `}
`;

const LoaderComponent = () => <Skeleton width={240} height={18} />;

const CheckoutAddressLabel = ({
  isLoading,
  isPickupMode,
  restaurantName,
  restaurantAddress,
  currentAddress,
  showAddressLabelPrefix,
}) => {
  const {t} = getLocalizationService();

  if (isPickupMode) {
    return (
      <Wrapper>
        <SkeletonLoader shouldShowLoader={isLoading} LoaderComponent={LoaderComponent}>
          <FlexStart>
            <Bold>{restaurantName}</Bold>
            {restaurantAddress}
          </FlexStart>
        </SkeletonLoader>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <SkeletonLoader shouldShowLoader={isLoading} LoaderComponent={LoaderComponent}>
        <FlexStart>
          {showAddressLabelPrefix && <Bold>{t('delivery_to')}</Bold>}
          {`${currentAddress?.streetName} ${currentAddress?.houseNumber}, ${currentAddress?.cityName}`}
        </FlexStart>
      </SkeletonLoader>
    </Wrapper>
  );
};

export default CheckoutAddressLabel;
