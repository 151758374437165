import {memo, useMemo} from 'react';

import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions';
import {DELIVERY_RULES, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {selectCurrentRestaurant} from '~/shared/store/storeModules/currentRestaurant/currentRestaurantSelectors';
import {getLocalizationService} from '~/shared/services/localisationService';
import useWebpWithFallback from '~/shared/hooks/useWebpWithFallback';
import {getAsapDeliveryTimeRanges, getEstimatedPooledDeliveryTimeRangeString} from '~/shared/utils/restaurants';
import {ETAInfoBox} from '~/shared/components/ETAInfoBox';
import {
  selectCurrentDeliveryMethod,
  selectCurrentOrderDateAndTime,
  selectCurrentRestaurantOrderRule,
  selectCurrentRestaurantShoppingCartDeliveryType, selectOrderDeliveryRule,
} from '~/shared/store/selectors';
import {getDateOptionLabel} from '~/shared/components/FutureOrder/FutureOrder';
import {isDeliveryRuleModel} from '~/shared/utils/restaurant';
import {useIsMinLargeMobile} from '~/shared/hooks/deviceInfo';

const COLON_SYMBOL = ':';

const SingleRuleComponentWrapper = styled.div<{noPadding?: boolean}>`
  ${({noPadding}) => (!noPadding ? 'padding: 16px 16px 0 16px;' : '')}
`;

const ETAInfoBoxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Title = styled.div<{bold?: boolean}>`
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
  color: ${({theme}) => theme.colors.charcoal80};
  font-weight: ${({bold}) => (bold ? 700 : 400)};
`;

const SingleRuleETABox = ({
  isCheckout,
  isTempClosed,
  hideTitle,
  inline,
  isClosed,
  etaBoxText,
}: {
  isCheckout?: boolean;
  isTempClosed?: boolean;
  hideTitle?: boolean;
  inline?: boolean;
  isClosed?: boolean;
  etaBoxText?: string;
}) => {
  const currentDeliveryRule = useSelector(selectOrderDeliveryRule);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const currentDeliveryMethod = useSelector(selectCurrentDeliveryMethod);
  const {t} = getLocalizationService();
  const currentOrderDateAndTime = useSelector(selectCurrentOrderDateAndTime);
  const shoppingCartHeaderType = useSelector(selectCurrentRestaurantShoppingCartDeliveryType);
  const isAsapAndFuture = shoppingCartHeaderType === SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_FUTURE;
  const isSDFO = shoppingCartHeaderType === SHOPPING_CART_DELIVERY_TYPE.SDFO;
  const currentRestaurantRule = useSelector(selectCurrentRestaurantOrderRule);
  const isMinLargeMobile = useIsMinLargeMobile();
  const isAsap = isDeliveryRuleModel(currentRestaurantRule) &&
    currentRestaurantRule.type === DELIVERY_RULES.ASAP;
  const isPickup = currentDeliveryMethod === DeliveryMethods.PICKUP;

  const imageType = (() => {
    if (isPickup) {
      return 'pickup';
    }
    if (currentDeliveryRule !== DELIVERY_RULES.ASAP && !isClosed) {
      return 'pooled';
    }

    return 'asap';
  })();

  const imagePath = useWebpWithFallback(`asap_and_pooled/${imageType}.svg`) as string;

  const {deliveryFee} = useMemo(() => {
    return {
      deliveryFee: isDeliveryRuleModel(currentRestaurantRule) ? currentRestaurantRule.deliveryFee : 0,
    };
  }, [currentRestaurantRule]);

  if (!currentRestaurant) {
    return null;
  }

  const {asapDeliveryTimeInMinutes, poolDeliveryTime} = currentRestaurant;
  const deliveryFeeBeforeDiscount =
    isDeliveryRuleModel(currentRestaurantRule) ? currentRestaurantRule?.deliveryFeeBeforeDiscount : undefined;

  const title = (() => {
    if (isPickup) {
      if (isClosed) {
        return '';
      }
      return t('pickup');
    }

    if (currentDeliveryRule === DELIVERY_RULES.POOL) {
      return t('delivered_between_xxx', {
        estimatedPoolDeliveryTime: poolDeliveryTime
          ? getEstimatedPooledDeliveryTimeRangeString(poolDeliveryTime)
          : '',
      });
    }

    if (currentDeliveryRule === DELIVERY_RULES.ASAP || !currentOrderDateAndTime?.orderDate) {
      return t('delivered_in_xxx', getAsapDeliveryTimeRanges(asapDeliveryTimeInMinutes));
    }

    if (currentDeliveryRule === DELIVERY_RULES.FUTURE) {
      if (isSDFO) {
        if (currentOrderDateAndTime?.orderTime) {
          return currentOrderDateAndTime.orderTime.timeKeyLabel;
        }
      }
      return `${getDateOptionLabel({formatedDate: currentOrderDateAndTime?.orderDate?.formatedDate})}, ${currentOrderDateAndTime?.orderTime?.timeKeyLabel || currentOrderDateAndTime?.orderTime?.timeKey}`;
    }

    return '';
  })();

  return (
    <SingleRuleComponentWrapper noPadding={isMinLargeMobile || isCheckout || isAsapAndFuture || isSDFO || inline}>
      {!hideTitle && <Title bold={isCheckout}>{t('time_to_deliver')}{!isCheckout && COLON_SYMBOL}</Title>}

      <ETAInfoBoxContainer>
        <ETAInfoBox
          inlineRow={inline}
          title={etaBoxText || title}
          imagePath={imagePath}
          deliveryFee={etaBoxText ? undefined : deliveryFee}
          isCheckout={isCheckout}
          isAsap={isAsap}
          isPickup={isPickup}
          deliveryFeeBeforeDiscount={etaBoxText ? undefined : deliveryFeeBeforeDiscount}
          isClosed={isClosed}
          isTempClosed={isTempClosed}
        />
      </ETAInfoBoxContainer>
    </SingleRuleComponentWrapper>
  );
};

export default memo(SingleRuleETABox);
