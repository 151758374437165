import {makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {AutoCompleteState} from './autoCompleteReducer';

export const fetchCityNames = makeThunkAsyncActionCreator<number, CityNames>(
  'fetchCityNames',
  async (query, {apiService}) => {
    const cityNameSuggestions = await apiService.cityNameAutoComplete({q: query});
    return {[query]: cityNameSuggestions.data};
  },
);
type CityNames = AutoCompleteState['cityName']['data'];

export const fetchStreetNames = makeThunkAsyncActionCreator<{searchValue: string; parentId: string}, StreetNames>(
  'fetchStreetNames',
  async ({searchValue = '', parentId}, {apiService}) => {
    const streetNameSuggestions = await apiService.streetNameAutoComplete({
      q: searchValue,
      parentId,
    });
    return {[parentId]: streetNameSuggestions.data};
  },
);
type StreetNames = AutoCompleteState['streetName']['data'];
