import {createSelector} from 'reselect';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

export const selectSeoCityDescriptionRoot = (state: AppState) => state.seoCityDescriptionFromCdn;

export const selectSeoCityDescriptionData = createSelector(
  selectSeoCityDescriptionRoot,
  seoCityDescriptionFromCdn => seoCityDescriptionFromCdn.data || EMPTY_OBJECT,
);

export const selectSeoCityDescription = createSelector(
  selectSeoCityDescriptionRoot,
  seoCityDescriptionFromCdn => seoCityDescriptionFromCdn.data?.description,
);
export const selectSeoMetaCityDescription = createSelector(
  selectSeoCityDescriptionRoot,
  seoCityDescriptionFromCdn => seoCityDescriptionFromCdn.data?.metaDescription,
);
export const selectSeoCityDescriptionLoaded = createSelector(
  selectSeoCityDescriptionRoot,
  seoCityDescriptionFromCdn => seoCityDescriptionFromCdn.loaded,
);
export const selectSeoCityDescriptionLoading = createSelector(
  selectSeoCityDescriptionRoot,
  seoCityDescriptionFromCdn => seoCityDescriptionFromCdn.loading,
);
