export enum ViewModes {
  SIGN_UP_MODE = 'signUp',
  SIGN_IN_MODE = 'loginOrRegister',
}

export enum LoginProvider {
  GOOGLE = 'Google',
  FACEBOOK = 'Facebook',
  TENBIS = 'Tenbis'
}

export enum LoginOrRegisterScreenKey {
  LOGIN = 'login',
  SIGN_UP = 'signUp',
  PROVIDER = 'provider'
}

export enum AuthFlowStep {
  INITIAL,
  PASSWORD,
  OTP
}

export const LOGIN_SCREEN_TYPES = {
  email: 'EMAIL_STEP',
  password: 'PASSWORD_STEP',
  verification: 'VERIFICATION_STEP',
};
