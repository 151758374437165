import {createSelector} from 'reselect';

import {DeliveryMethod, DeliveryMethods} from '~/shared/consts/restaurantConsts';

import {AppState} from '../../configStore';

const selectCurrentDeliveryMethodRoot = (state: AppState) => state.currentDeliveryMethod;

export const selectCurrentDeliveryMethod = createSelector(
  selectCurrentDeliveryMethodRoot,
  currentDeliveryMethod => (currentDeliveryMethod?.toLowerCase() as DeliveryMethod) || DeliveryMethods.DELIVERY,
);
