import {ChangeEvent} from 'react';

import styled from 'styled-components';

import Checkbox from '~/shared/components/Checkbox';
import Input from '~/common/components/Input';
import {flexSpace} from '~/shared/theme/FlexLayout';

const Root = styled.div`
  ${flexSpace};
`;

type OtlRuleLineProps = {
  ruleName: string;
  inputType: string;
  value: {
    isActive: boolean;
    value: string;
  };
  onChange: (payload: {isActive: boolean; value: string}) => void;
};

const OtlRuleLine = ({ruleName, inputType, value: formValueObject, onChange}: OtlRuleLineProps) => {
  return (
    <Root>
      <Checkbox
        value={formValueObject.isActive}
        onValueChange={checked => onChange({...formValueObject, isActive: checked})}
        label={ruleName}
        id={`checkbox_${ruleName}`}
        name={ruleName}
        outlined
      />
      <Input
        inputType={inputType}
        disabled={!formValueObject.isActive}
        onChange={(e: ChangeEvent<HTMLInputElement>) => onChange({...formValueObject, value: e.target.value})}
        value={formValueObject.isActive ? formValueObject.value : ''}
        fontSize={12}
        height={30}
      />
    </Root>
  );
};

export default OtlRuleLine;
