import {
  Context,
  Dispatch,
  memo,
  MemoExoticComponent,
  Provider,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import {Form} from 'react-final-form';

import useFormAutoFocus from '~/shared/hooks/useFormAutoFocus';
import FormField from '~/shared/components/FormField';
import {
  FieldWrapper,
  FormWrapper,
  HeaderText,
  LoginFormFieldsWrapper,
  SubmitButton,
  Email,
  BlueLinkButton,
  LinkButtonsWrapper,
  ReSendCodeIndication,
  ResendResError,
} from '~/shared/components/loginStyled';
import useCanResendCode from '~/shared/utils/useCanResendCode';
import {getLocalizationService} from '~/shared/services/localisationService';

import {VerificationFormContext, VerificationFormContextProps} from './context';

const formValidation = (values: {authenticationCode?: string}) => {
  if (values?.authenticationCode?.length !== 5) {
    return {authenticationCode: 'activation_code_must_be_exact_5_digits'};
  }

  return undefined;
};

interface IVerificationForm {
  onReSendCode: () => void;
  onSubmit: ({authenticationCode}: {authenticationCode?: string}) => void;
  email?: string;
  headerText: ReactNode;
  resErrors: string | null;
  setResErrors: Dispatch<SetStateAction<string | null>>;
  toUpdatePhoneNumberScreen?: () => void;
}

const VerificationForm = ({
  onReSendCode,
  onSubmit,
  email,
  headerText,
  resErrors: resErrorFromServer,
  setResErrors,
  toUpdatePhoneNumberScreen,
}: IVerificationForm) => {
  const {t} = getLocalizationService();
  const [isTriedReSendingCode, setIsTriedReSendingCode] = useState<boolean | null>(false);
  const {isResendButtonDisabled, setIsResendButtonDisabled} = useCanResendCode();

  const resetCode = () => {
    if (isResendButtonDisabled) {
      return;
    }

    setIsTriedReSendingCode(true);
    onReSendCode();
    setIsResendButtonDisabled(true);
  };

  const {userError, isRequireCaptcha} = useContext(VerificationFormContext);
  const resErrorDescription = useMemo(() => userError?.message || resErrorFromServer, [resErrorFromServer, userError]);
  const formWrapRef = useFormAutoFocus();
  return (
    <div>
      <Form
        onSubmit={onSubmit}
        validateOnBlur
        validate={formValidation}
        render={({handleSubmit}) => (
          <FormWrapper onSubmit={handleSubmit} ref={formWrapRef}>
            <Email>{email}</Email>
            <HeaderText>{headerText}</HeaderText>
            <LoginFormFieldsWrapper>
              <FieldWrapper>
                {/* @ts-expect-error TODO: refactor FormField to TS*/}
                <FormField
                  {...{
                    name: 'authenticationCode',
                    placeholder: t('insert_the_code'),
                    type: 'text',
                    required: true,
                    className: 'white-border-on-contrast',
                    shouldUseMetaModified: true,
                    inputProps: {
                      onKeyUp: () => {
                        if (!resErrorDescription) {
                          return;
                        }
                        setIsTriedReSendingCode(null);
                        setResErrors(null);
                      },
                    },
                  }}
                />
              </FieldWrapper>
              {/* @ts-expect-error TODO: refactor FormField to TS*/}
              {isRequireCaptcha && <FormField name="reCaptchaResponse" type="recaptcha" />}
            </LoginFormFieldsWrapper>
            <SubmitButton type="submit">{t('accept')}</SubmitButton>
          </FormWrapper>
        )}
      />
      <LinkButtonsWrapper>
        {toUpdatePhoneNumberScreen && (
          <BlueLinkButton onClick={toUpdatePhoneNumberScreen}>{t('update_phone_number')}</BlueLinkButton>
        )}
        <BlueLinkButton onClick={resetCode} isDisabled={isResendButtonDisabled}>
          {t('i_did_not_receive_send_again')}
        </BlueLinkButton>
        {resErrorDescription && <ResendResError>{resErrorDescription}</ResendResError>}
        {isResendButtonDisabled && isTriedReSendingCode && !resErrorDescription && (
          <ReSendCodeIndication>{t('we_sent_you_a_new_code')}</ReSendCodeIndication>
        )}
      </LinkButtonsWrapper>
    </div>
  );
};

const PureVerificationForm = memo(VerificationForm) as MemoExoticComponent<
  (props: IVerificationForm) => JSX.Element | null
> & {
  Provider: Provider<VerificationFormContextProps>;
  Context: Context<VerificationFormContextProps>;
};

PureVerificationForm.Context = VerificationFormContext;
PureVerificationForm.Provider = VerificationFormContext.Provider;

export default PureVerificationForm;
