import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';

import {sentryDns, releaseName, environment} from '~/shared/config';

import {remoteLoggingIgnoreList} from './consoleIgnoreList';

const NETWORK_ERROR = 'Network Error';

// standard ignoreErrors and denyUrls from https://gist.github.com/impressiver/5092952
const standardIgnores = {
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    'Can\'t find variable: ZiteReader',
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
  ],
  denyUrls: [
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
};

const denyUrls = [
  ...standardIgnores.denyUrls,
  /^https:\/\/dc\.services\.visualstudio\.com/,
  /^https:\/\/bam\..*\.nr-data\.net/,
  /^https:\/\/script\.crazyegg\.com/,
  /^https:\/\/stats\.g\.doubleclick\.net/,
  /^https:\/\/www\.google-analytics\.com/,
  /^https:\/\/.*\.appsflyer\.com/,
  /^https:\/\/.*\.hotjar\.io/,
  /^https:\/\/.*\.hotjar\.com/,
  /^https:\/\/ekr\.zdassets\.com/,
  /^https:\/\/.*\.intercom\.com/,
  /js-agent\.newrelic\.com/i,
  /maps\.googleapis\.com/i,
  /chrome-extension:/i,
  /utag/,
];

const ignoreErrors = [...standardIgnores.ignoreErrors];

export function init() {
  Sentry.init({
    dsn: sentryDns,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [],
      }),
    ],
    release: releaseName,
    environment,
    tracesSampleRate: 0.05,
    beforeBreadcrumb(event, hint) {
      const ignoreEvent =
        (hint?.xhr && denyUrls.some(i => i.test(event.data?.url || hint.xhr.responseURL))) ||
        (event?.message && remoteLoggingIgnoreList.some(i => i.test(event.message)));

      if (ignoreEvent) {
        return null;
      }

      return event;
    },
    ignoreErrors,
    denyUrls,
  });
}

export function log({loggerName, logLevel, errorOrMessage, args}) {
  Sentry.addBreadcrumb({
    category: loggerName,
    message: (errorOrMessage || '').toString(),
    level: logLevel,
    data: {
      args: JSON.stringify(args),
    },
  });
}

export function logError({error: rawError, loggerName, args}) {
  // manually throw the error to create stack trace for IE11
  // https://docs.newrelic.com/docs/browser/new-relic-browser/browser-agent-spa-api/noticeerror-browser-agent-api/#browser-limits

  const isNetworkError = args?.[0]?.message?.includes(NETWORK_ERROR);
  // escape error `משהו השתבש` only in GetOrderSuccessApi
  const isSomethingWrongInOrderSuccess =
    (args?.[0]?.url?.includes('GetOrderSuccess') && args?.[0]?.message?.includes('משהו השתבש')) ||
    (args?.[0]?.e?.url?.includes('GetOrderSuccess') && args?.[0]?.e?.message?.includes('משהו השתבש'));

  if (isNetworkError) {
    return;
  }

  if (isSomethingWrongInOrderSuccess) {
    return;
  }

  try {
    throw rawError;
  } catch (error) {
    log({errorOrMessage: rawError, loggerName, logLevel: 'error', args: JSON.stringify(args)});
    Sentry.withScope(scope => {
      if ('fingerprint' in (args?.[0] || {})) {
        scope.setFingerprint(args[0].fingerprint);
      }
      Sentry.captureException(error);
    });
  }
}
