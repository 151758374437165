import {memo, useEffect, useRef, useState} from 'react';

import Draggable, {DraggableData, DraggableEvent} from 'react-draggable';
import styled from 'styled-components';

import {usePreventBodyScrolling} from '~/shared/hooks';
import {flexColumn} from '~/shared/theme/FlexLayout';

import ClickOutsideDiv from '../ClickOutsideDiv';

const Root = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  background: ${({theme}) => theme.modal.overlayBackgroundColor};
  width: 100%;
  height: 100%;
  z-index: ${({theme}) => theme.zIndex.bottomSheet};
`;

const Container = styled.div<{isOpen: boolean}>`
  position: absolute;
  right: 0;
  left: 0;
  top: auto;
  bottom: ${({isOpen}) => (isOpen ? 0 : '-100%')};
  width: 100%;
  max-height: 80%;
  background: ${({theme}) => theme.colors.surface};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  transition: bottom 0.3s ease-in-out;
`;

const DraggableBadge = styled.div`
  border-radius: 9999px;
  width: 32px;
  height: 4px;
  background: ${({theme}) => theme.colors.gray500};
  margin: 8px auto;
`;

const StyledClickOutsideDiv = styled(ClickOutsideDiv)`
  ${flexColumn}
`;

const Body = styled.div.attrs({className: 'non-draggable'})`
  ${flexColumn}
  flex: 1;
`;

const useAnimationTrigger = ({isOpen, onClose}: {isOpen: boolean; onClose: (e?: Event) => void}) => {
  const [animationTrigger, setAnimationTrigger] = useState<boolean>(false);

  useEffect(() => {
    setAnimationTrigger(isOpen);
  }, [isOpen]);

  const animatedOnClose = (_: Event) => {
    setAnimationTrigger(false);
    setTimeout(onClose, 200);
  };

  return {animationTrigger, setAnimationTrigger, animatedOnClose};
};

const BottomSheet = (
  {children, isOpen, onClose}:
  {children: React.ReactNode; isOpen: boolean; onClose: (e?: Event) => void},
) => {
  const containerRef = useRef<HTMLDivElement>(null);

  usePreventBodyScrolling({shouldPreventScroll: isOpen});

  const {animationTrigger, animatedOnClose} = useAnimationTrigger({isOpen, onClose});

  const onDrop = (_: DraggableEvent, {y}: DraggableData) => {
    const moreThenHalfWereDragged = y > (containerRef.current?.clientHeight || 0) * 0.5;
    if (moreThenHalfWereDragged) {
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Root>
      <Draggable axis="y" onStop={onDrop} position={{x: 0, y: 0}} bounds={{top: 0}} cancel=".non-draggable">
        <Container isOpen={animationTrigger} ref={containerRef} data-test-id="bottomSheet">
          <StyledClickOutsideDiv onClickOutside={animatedOnClose}>
            <DraggableBadge />
            <Body>{children}</Body>
          </StyledClickOutsideDiv>
        </Container>
      </Draggable>
    </Root>
  );
};

export default memo(BottomSheet);
