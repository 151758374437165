import {getSizesFromScreenWidth} from '~/shared/utils/general';
import {getUserDevice} from '~/shared/utils/userAgent';

import {makeReducer} from '../../redux-toolbelt';

import {setCurrentWindowWidth, setUserDevice} from './userDeviceActions';

export type UserDeviceState = ReturnType<typeof getSizesFromScreenWidth> & ReturnType<typeof getUserDevice>;

export default makeReducer<UserDeviceState, any>(
  {
    [setUserDevice.TYPE]: (currentState, {payload: newUserDevice}: ReturnType<typeof setUserDevice>) => {
      return {
        ...currentState,
        ...newUserDevice,
      };
    },
    [setCurrentWindowWidth.TYPE]: (
      currentState,
      {payload: currentWidth}: ReturnType<typeof setCurrentWindowWidth>,
    ) => ({
      ...currentState,
      ...getSizesFromScreenWidth(currentWidth),
    }),
  },
  {defaultState: {}},
);
