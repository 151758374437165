import {useEffect, useRef, useState} from 'react';

const useFormAutoFocus = (onFocus?: (element: HTMLElement) => void) => {
  const formRef = useRef<HTMLFormElement>(null);
  const [node, setNode] = useState<HTMLFormElement>();
  
  useEffect(() => {
    if (formRef.current) {
      setNode(formRef.current);
    }
  }, []);

  useEffect(() => {
    if (!node) {
      return;
    }
    const {elements} = node;
    if (elements && elements.length > 0) {
      const nodeElements = Array.from(elements) as HTMLElement[];
      const firstFocusableElement = nodeElements.find(element => {
        return typeof element.focus === 'function' && (element.tagName === 'INPUT' || element.tagName === 'SELECT');
      });
      if (firstFocusableElement) {
        firstFocusableElement.focus();
        if (onFocus) {
          onFocus(node);
        }
      }
    }
  }, [node, onFocus]);

  return formRef;
};

export default useFormAutoFocus;
