import {PaymentMethod, PaymentMethodTypes} from '~/shared/consts/paymentConsts';

export default interface Payment<PM extends PaymentMethodTypes = PaymentMethod> {
  paymentMethod: PM;
  creditCardType: string;
  cardId: number;
  cardToken: string;
  userId: number;
  userName: string;
  cardLastDigits: string;
  sum: number;
  assigned: boolean;
  remarks: string | null;
  expirationDate: string | null;
  paymentId?: number;
  isTenbisCredit: boolean;
  cardSuffix?: string;
  prepaidBalance: number | null;
}

export const isPayment = (obj: unknown): obj is Payment =>
  !!obj &&
  typeof obj === 'object' &&
  'cardId' in obj &&
  'paymentMethod' in obj;

export const PAYMENTS_MOCK = {
  cash: {
    paymentMethod: 'Cash',
    creditCardType: 'none',
    cardId: 0,
    cardToken: '0',
    userId: 0,
    userName: null,
    cardLastDigits: '',
    sum: 0,
    assigned: false,
    remarks: null,
    expirationDate: null,
    applePayPaymentData: null,
    isTenbisCredit: false,
    prepaidBalance: 0,
  },
  creditCard1: {
    paymentMethod: 'Creditcard',
    creditCardType: 'visa',
    cardId: 3559806,
    cardToken: '',
    userId: 3982875,
    userName: '',
    cardLastDigits: '7041',
    sum: 0,
    assigned: false,
    remarks: null,
    expirationDate: '10/2028',
    isTenbisCredit: false,
    prepaidBalance: 0,
  },
  creditCard2: {
    paymentMethod: 'Creditcard',
    creditCardType: 'visa',
    cardId: 3551805,
    cardToken: '',
    userId: 3982875,
    userName: '',
    cardLastDigits: '7051',
    sum: 0,
    assigned: false,
    remarks: null,
    expirationDate: '10/2028',
    isTenbisCredit: false,
    prepaidBalance: 0,
  },
  moneyCard10BisCreditWithBalance: {
    paymentMethod: 'Moneycard',
    creditCardType: 'none',
    cardId: 3219099,
    cardToken: '',
    userId: 3982879,
    userName: 'Sergey G',
    cardLastDigits: '2784',
    sum: 0,
    assigned: false,
    remarks: null,
    expirationDate: null,
    isTenbisCredit: true,
    prepaidBalance: 50,
  },
  moneyCard10BisCreditNoBalance: {
    paymentMethod: 'Moneycard',
    creditCardType: 'none',
    cardId: 3219091,
    cardToken: '',
    userId: 3982875,
    userName: 'Sg',
    cardLastDigits: '2784',
    sum: 0,
    assigned: false,
    remarks: null,
    expirationDate: null,
    isTenbisCredit: true,
    prepaidBalance: 0,
  },
  moneyCard: {
    paymentMethod: 'Moneycard',
    creditCardType: 'none',
    cardId: 2218889,
    cardToken: '',
    userId: 3982875,
    userName: 'Sg',
    cardLastDigits: '6344',
    sum: 169,
    assigned: false,
    remarks: null,
    expirationDate: null,
    isTenbisCredit: false,
    prepaidBalance: 0,
  },
};
