import {memo} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import Checkbox from '~/shared/components/Checkbox';
import actions from '~/shared/store/actions';

const StyledCheckBox = styled(Checkbox)`
  margin-top: ${({theme}) => theme.checkout.elements.CutleryOptionCheckbox.marginTop};
`;

const CutleryOption = ({value}) => {
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const setOrderDontWantCutlery = props => dispatch(actions.setOrderDontWantCutlery(props));

  return (
    <StyledCheckBox
      id="dont_want_cutlery"
      value={value}
      onValueChange={setOrderDontWantCutlery}
      label={t('i_support_the_environment_so_i_will_pass_on_the_cutlery')}
    />
  );
};

export default memo(CutleryOption);
