import {memo} from 'react';

import styled from 'styled-components';

import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import {RestaurantFromSearch} from '~/shared/store/models';
import {getLocalizationService} from '~/shared/services/localisationService';
import WhitePooledUserIcon from '~/assets/images/icons/white-pooledUser.svg?react';

import {PooledOrderTagState, PooledOrderTagStates} from './RestaurantTagPooledOrderUtils';
import ProgressBar from './ProgressBar';

const StyledUserIcon = styled(WhitePooledUserIcon)<{$isGrey?: boolean}>`
  margin: 0 4px;

  path {
    fill: ${({theme, $isGrey}) => ($isGrey ? theme.colors.charcoal80 : theme.colors.primaryText)};
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 0;
`;

interface PooledOrderContentProps {
  estimatedNumberOfOrdersInPoolOrder: RestaurantFromSearch['estimatedNumberOfOrdersInPoolOrder'];
  minimumForPooledOrder: number;
  pooledOrderSum: number;
  tagState: PooledOrderTagState;
}

const PooledOrderContent = ({
  tagState,
  minimumForPooledOrder,
  pooledOrderSum,
  estimatedNumberOfOrdersInPoolOrder,
}: PooledOrderContentProps) => {
  const {t} = getLocalizationService();
  const isMinLargeTablet = useIsMinLargeTablet();
  return (
    <>
      {tagState === PooledOrderTagStates.NO_MINIMUM && t('no_preorder_min')}
      {tagState === PooledOrderTagStates.OVER_MINIMUM && (
        <>
          {t(isMinLargeTablet ? 'order_passed_the_min' : 'order_passed_the_min_mobile')}
          <StyledUserIcon />
          {t('people_ordered', {postProcess: 'plural', count: estimatedNumberOfOrdersInPoolOrder})}
        </>
      )}
      {tagState === PooledOrderTagStates.UNDER_MINIMUM_WITHOUT_ORDERS &&
        t('office_min', {postProcess: 'plural', count: minimumForPooledOrder})}
      {tagState === PooledOrderTagStates.UNDER_MINIMUM_WITH_ORDERS && (
        <>
          <StyledProgressBar minimumPriceForOrder={minimumForPooledOrder} pooledOrderSum={pooledOrderSum} />
          {t('remaining_amount_to_pass_min', {
            postProcess: 'plural',
            count: Math.ceil(minimumForPooledOrder - pooledOrderSum),
          })}
          <StyledUserIcon $isGrey />
          {t(isMinLargeTablet ? 'people_ordered_to_office' : 'people_ordered', {
            postProcess: 'plural',
            count: estimatedNumberOfOrdersInPoolOrder,
          })}
        </>
      )}
    </>
  );
};

export default memo(PooledOrderContent);
