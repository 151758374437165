import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import greenCheckMarkIconUrl from '~/assets/images/icons/green_check_mark.svg';
import greyCheckMarkIconUrl from '~/assets/images/icons/grey_check_mark.svg';
import {media} from '~/shared/theme/media';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {selectCurrentRestaurantFeatureList} from '~/shared/store/selectors';
import {body14Normal} from '~/shared/theme/typography';

import AboutSectionBase from './AboutSectionBase';

const Feature = styled.div`
  display: flex;
  height: 25px;
`;

const FeatureList = styled.div`
  ${flexColumn};
  height: ${({height}) => height}px;
  width: 100%;
  flex-wrap: wrap;
  ${media.maxDesktop`
    height:auto;
    max-height:220px;
  `};
`;

const FeatureText = styled.span`
  ${body14Normal};
  line-height: 14px;
  align-self: flex-start;
  ${flipOnLTR`
    padding-right: 7px;
  `}
`;

const featureCheckboxAttrs = ({value}) => ({
  src: value ? greenCheckMarkIconUrl : greyCheckMarkIconUrl,
  noAlt: true,
});
const FeatureCheckbox = styled(ImageWithAlt).attrs(featureCheckboxAttrs)`
  align-self: flex-start;
`;

const FeaturesSection = () => {
  const {t} = getLocalizationService();
  const featureList = useSelector(selectCurrentRestaurantFeatureList);

  return (
    <AboutSectionBase
      title={t('important_to_note')}
      content={
        featureList && (
          <FeatureList height={Math.ceil(featureList.length / 3) * 25}>
            {featureList.map(feature => (
              <Feature key={feature.featureName}>
                <FeatureCheckbox value={feature.enabled} />
                <FeatureText>{feature.featureName}</FeatureText>
              </Feature>
            ))}
          </FeatureList>
        )
      }

    />
  );
};

export default FeaturesSection;
