import {useRef, useCallback, useEffect, memo} from 'react';

import styled from 'styled-components';
import {Form} from 'react-final-form';

import _FormField from '~/shared/components/FormField';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {LongButton} from '~/shared/components/Modals/InfoModals';
import {getLocalizationService} from '~/shared/services/localisationService';
import {FieldWrapper, FormWrapper as _FormWrapper, FormContentRoot} from '~/shared/components/loginStyled';
import AgreeToTermsSection from '~/shared/components/AgreeToTermsSection';
import {body14Normal} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {useAutoFocus} from '~/shared/hooks';
import {onTermsOfUseClick, onPrivacyPolicyClick} from '~/common/utils/agreeToTermsUtils';
import {FormValues} from '~/shared/components/SignIn/types';

import {checkFreeEmail} from './utils/authHelpers';

const FormWrapper = styled(_FormWrapper)<{alignCenter?: boolean}>`
  ${({alignCenter}) => alignCenter && 'align-items: center;'}
`;

const FieldsContainer = styled.div`
  ${flexColumn}

  ${/* sc-selector */ FieldWrapper}:first-child {
    margin-top: 0;
  }

  ${media.minMobile`
    ${FieldWrapper} {
      width: 49%;
      ${flipOnLTR`
        margin-left: 2%;
      `};
      &:nth-child(2) {
        margin-top: 0;
      }
      &:nth-child(2n) {
        ${flipOnLTR`
          margin-left: 0;
        `};
      }
    }
  `};
`;

const Row = styled.div`
  display: flex;
`;

const SubmitButton = styled(LongButton)`
  border-radius: 0;
`;

const HeaderText = styled.div`
  ${body14Normal};
  line-height: 1.57;
  letter-spacing: normal;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
  margin-bottom: 20px;
`;

const BrandingPageTitle = styled.h1`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  margin-bottom: 12px;
  color: ${({theme}) => theme.colors.secondary};
`;

const BrandingPageSubtitle = styled.h2`
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  margin-bottom: 32px;
  color: ${({theme}) => theme.colors.secondary};
`;

const FormField = styled(_FormField).attrs({
  className: 'white-border-on-contrast sign-up-form-field',
})<{addTrailingMargin?: boolean}>`
  ${({addTrailingMargin}) =>
    addTrailingMargin &&
    `
    ${flipOnLTR`
      margin-left: 20px;
    `}
  `}
`;

type SignUpFormProps = {
  initialValues?: FormValues;
  onSubmit: (values: FormValues) => void;
  sendActivationCodeError?: string;
  existingEmailAction?: (email: string) => void;
  borderedInputs?: boolean;
  emailValidationErrorKey?: string;
  title?: string;
  subtitle?: string;
  isFixedEmail?: boolean;
};

const SignUpForm = ({
  initialValues = {fullName: '', email: '', cellPhone: '', agreeToTerms: false, wantPromotion: true},
  onSubmit,
  sendActivationCodeError,
  existingEmailAction,
  borderedInputs,
  title,
  subtitle,
  emailValidationErrorKey,
  isFixedEmail,
}: SignUpFormProps) => {
  const {t} = getLocalizationService();

  const formWrapperRef = useRef<HTMLDivElement | null>(null);
  const focusElementRef = useRef();
  const emailInUse = useRef<boolean | null>(null);

  const validateEmail = useCallback(
    async (emailAddress: string) => {
      if (!emailAddress) {
        return;
      }

      const isFree = await checkFreeEmail(emailAddress);

      if (!isFree) {
        emailInUse.current = true;
        return emailValidationErrorKey || 'you_already_registered_to_10bis_in_the_past';
      }
      emailInUse.current = false;
    },
    [emailValidationErrorKey],
  );

  useEffect(() => {
    if (initialValues?.email) {
      validateEmail(initialValues.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues.email]);

  useAutoFocus({
    domEl: focusElementRef?.current,
  });

  const validateFullName = useCallback(
    (fullName: string) => {
      if (!fullName.trim().includes(' ')) {
        return t('full_name_must_include_first_and_last_name');
      }
    },
    [t],
  );

  const validateNewUser = ({agreeToTerms}: {agreeToTerms?: boolean}) => {
    const errors: Record<string, string> = {};

    if (!agreeToTerms) {
      errors.agreeToTerms = 'accepting_the_terms_is_required';
    }

    return errors;
  };

  return (
    <div ref={formWrapperRef}>
      <Form
        onSubmit={onSubmit}
        validate={validateNewUser}
        initialValues={initialValues}
        validateOnBlur
        render={({handleSubmit, errors, touched}) => {
          return (
            <FormWrapper onSubmit={handleSubmit} noValidate alignCenter={!!title}>
              {!title && <HeaderText id="modal-title">{t('signup_header')}</HeaderText>}
              {title && (
                <>
                  <BrandingPageTitle>{title}</BrandingPageTitle>
                  {subtitle && (
                    <BrandingPageSubtitle>
                      {subtitle}
                    </BrandingPageSubtitle>
                  )}
                </>
              )}

              <FormContentRoot className="form-content-root">
                <FieldsContainer>
                  <Row>
                    <FormField
                      {...{
                        name: 'fullName',
                        placeholder: t('full_name'),
                        type: 'text',
                        validator: validateFullName,
                        required: true,
                        withBorder: !!borderedInputs,
                      }}
                    />
                  </Row>
                  <Row>
                    <FormField
                      {...{
                        name: 'email',
                        placeholder: t('email_address'),
                        type: 'email',
                        validator: validateEmail,
                        required: true,
                        withBorder: !!borderedInputs,
                        readonlyField: isFixedEmail,
                        inputProps: {
                          disabled: isFixedEmail,
                          actionButton: emailInUse.current && !!existingEmailAction &&
                            (touched?.email || isFixedEmail) && {
                              onClick: existingEmailAction,
                              text: t('login'),
                            },
                        },
                      }}
                    />
                  </Row>
                  <Row>
                    <FormField
                      {...{
                        name: 'cellPhone',
                        placeholder: t('cell_phone'),
                        type: 'phone',
                        required: true,
                        withBorder: !!borderedInputs,
                      }}
                    />
                  </Row>
                </FieldsContainer>

                <AgreeToTermsSection
                  onTermsOfUseClick={onTermsOfUseClick}
                  onPrivacyPolicyClick={onPrivacyPolicyClick}
                  errorText={
                    ((touched?.agreeToTerms && errors?.agreeToTerms) || sendActivationCodeError) &&
                    t(errors?.agreeToTerms || sendActivationCodeError)
                  }
                />

                <SubmitButton ref={focusElementRef} type="submit">
                  {t('to_confirmation')}
                </SubmitButton>
              </FormContentRoot>
            </FormWrapper>
          );
        }}
      />
    </div>
  );
};

export default memo(SignUpForm);
