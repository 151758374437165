import axios from 'axios';
import {flow, omit} from 'lodash';

import {toUpperCamelCase, toLowerCamelCase} from '../utils/general';

import {createApiError} from './apiErrorService';

const axiosInstance = axios.create({
  baseURL: '/',
  withCredentials: true,
  credentials: 'same-origin',
});

export const transformRequestConfig = config => {
  if (config.method === 'post' && config.convertParams) {
    config.params = toUpperCamelCase(config.params);
  }

  return omit(config, ['convertParams']);
};

export const transformResponseData = res => {
  if (res?.data) {
    res.data = toLowerCamelCase(res.data);
  }
  return res;
};

const handleError = error => {
  const apiError = createApiError(error);
  Promise.reject(apiError);
};

export const interceptResponseError = response => {
  if (!response?.data?.success && typeof response?.data !== 'string') {
    handleError(response);
  }
  return response;
};

export default function createInstance() {
  axiosInstance.interceptors.request.use(transformRequestConfig, handleError);
  axiosInstance.interceptors.response.use(flow(transformResponseData, interceptResponseError), handleError);
  return axiosInstance;
}
