import {useState, useEffect, useCallback, useRef, useMemo, memo} from 'react';

import styled, {css, DefaultTheme, useTheme} from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {Logo} from '~/shared/components/styled';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import whiteLogoUrl from '~/assets/images/white-logo.svg';
import {flexColumn, flexColumnCenter} from '~/shared/theme/FlexLayout';
import {header36Bold} from '~/shared/theme/typography';
import {Skeleton} from '~/shared/components/Loaders';
import ScooberBadge from '~/shared/components/RestaurantItem/ScooberBadge';

export const DiagonalDirections = {
  LEFT_UP_RIGHT_DOWN: 'left_up_right_down',
  LEFT_DOWN_RIGHT_UP: 'left_down_right_up',
};

interface IHeaderProps {
  height?: number;
  color?: string;
}

interface ICircleImageProps {
  alt: string | undefined;
  noAlt: boolean;
  size: number;
  imageUrl: string;
  shouldHideBorder?: boolean;
}
export interface IDiagonalHeaderView {
  className: string;
  stripeImage?: boolean;
  title: string;
  isSuccessModal?: boolean;
  hasLogo?: boolean;
  diagonalDirection: string;
  backgroundImage: string;
  backgroundColor: string;
  rotationAngle?: number;
  headerProps?: IHeaderProps;
  circleImageProps: ICircleImageProps;
  children: React.ReactNode;
  HeaderCover?: React.FC;
  HeaderFutureOrderCover?: React.FC;
  showHeaderCover?: boolean;
  titleId: string;
  isErrorMessageModal: boolean;
  showScooberBadge?: boolean;
}

const Root = styled.div<{stripeImage?: boolean; backgroundColor: string}>`
  height: 100%;
  width: 100%;
  border-radius: 2px;
  overflow: hidden;
  background-color: ${({backgroundColor, theme}) => backgroundColor || theme.modal.diagonalBackgroundColor};
  ${({stripeImage}) => stripeImage && 'min-height: 110vh;'}
  ${media.maxTablet`
    ${flexColumn};
    border-radius: 0;
  `};
`;

const Header = styled.div<{
  stripeImage?: boolean;
  headerProps?: IHeaderProps;
  showFutureOrderBackground?: boolean;
  backgroundImage?: string;
  circleImageProps?: ICircleImageProps;
}>`
  position: relative;
  background: ${({theme, headerProps, showFutureOrderBackground}) =>
    (showFutureOrderBackground
      ? 'linear-gradient(rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 39.58%, rgba(255,255,255,0.0001) 100% )'
      : headerProps?.color || theme.modal.diagonalHeaderColor)};
  ${({backgroundImage, showFutureOrderBackground}) => (backgroundImage && showFutureOrderBackground
    ? `
      background: linear-gradient(rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.4) 39.58%, rgba(255,255,255,0.0001) 100% ),url(${backgroundImage});
    `
    : backgroundImage && `
      background-image: url(${backgroundImage});
  `)};
  width: 100%;
  background-size: cover;
  background-position: center center;
  height: ${({stripeImage}) => (stripeImage ? 300 : 180)}px;
  ${media.minTablet`
    ${({stripeImage}) =>
    !stripeImage &&
      `
      height: 258px;
    `}
  `};
  ${flexColumnCenter};
  text-align: center;
  ${({circleImageProps}) =>
    circleImageProps &&
    `
    margin-bottom: 25px;
  `};
  ${media.minMobile`
    ${({circleImageProps}) =>
    circleImageProps &&
      `
      margin-bottom: 0;
    `};
  `};

  &&& {
    ${({headerProps}) => headerProps?.height && `height: ${headerProps?.height}px`};
  }
`;

const Stripe = styled.div<{
  backgroundColor?: string;
  stripeImage?: boolean;
  diagonalDirection: string;
  rotationAngle: number;
}>`
  background-color: ${({backgroundColor, theme}) => backgroundColor || theme.modal.diagonalBackgroundColor};
  position: absolute;
  color: ${({stripeImage}) => (stripeImage ? 'green' : ' red')};
  ${flipOnLTR`
    ${({stripeImage, diagonalDirection}) =>
    !stripeImage &&
      (diagonalDirection === DiagonalDirections.LEFT_DOWN_RIGHT_UP
        ? `
            left: 0;
            transform-origin: top left;
          `
        : `
            right: 0;
            transform-origin: top right;
          `)}
  `}

  ${({stripeImage}) =>
    (stripeImage
      ? `
    height: 100vh;
    background-image: url(${stripeImage});
    background-repeat: repeat;
    background-size: contain;
    z-index: 0;
    top: 70%;
    left: -20%;
    width: 150%;
  `
      : `
    height: 100%;
    bottom: -100%;
    width: 125%;
  `)};
  transform: ${({diagonalDirection, rotationAngle, theme}) =>
    `rotate(${
      (theme.isLTR ? -1 : 1) *
      (diagonalDirection === DiagonalDirections.LEFT_DOWN_RIGHT_UP ? -rotationAngle : rotationAngle)
    }deg)`};
`;

const getCircleImageBorder = ({
  isSuccessModal,
  shouldHideBorder,
  theme,
}: {
  isSuccessModal?: boolean;
  shouldHideBorder: boolean;
  theme: DefaultTheme;
}) => {
  if (shouldHideBorder) {
    return '0px';
  }
  if (isSuccessModal) {
    return `14px solid ${theme.modal.diagonalBorderColor}`;
  }
  return `4px solid ${theme.modal.diagonalBorderColor}`;
};

const circleImageCss = css<
  {rootWidth: number; size: number; rotationAngle: number} & {
    isSuccessModal?: boolean;
    shouldHideBorder: boolean;
    theme: DefaultTheme;
  }
>`
  position: absolute;
  left: 50%;
  bottom: ${({rootWidth, size, rotationAngle}) =>
    // eslint-disable-next-line no-mixed-operators
    0.5 * rootWidth * Math.tan((rotationAngle * Math.PI) / 180) - size * 0.5}px;
  margin-left: ${({size}) => -size / 2}px;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
  object-fit: cover;
  border-radius: 50%;
  background-color: ${({theme}) => theme.modal.diagonalBorderColor};
  box-shadow: ${({theme}) => theme.shadows.shadow2};
  border: ${getCircleImageBorder};
  z-index: 1;
`;

const circleImageAttrs = ({circleImageProps}: {circleImageProps: ICircleImageProps}) => ({
  size: circleImageProps?.size || 88,
  shouldHideBorder: circleImageProps?.shouldHideBorder || false,
});
const CircleImage = styled(ImageWithAlt).attrs(circleImageAttrs)<any>`
  ${circleImageCss}
`;

const Title = styled.div`
  position: relative;
  top: -5px;
  color: ${({theme}) => theme.userActionPage.textColor};
  font-size: 27px;
  font-weight: bold;
  width: 100%;
  padding: 0 14.5%;
  line-height: 1.1;
  text-align: center;
  ${media.minMobile`
    ${header36Bold}
  `}
`;

const DiagonalHeaderLogo = styled(Logo)`
  width: 168px;
  height: 50px;
  margin-top: -100px;
  margin-right: auto;
  margin-left: auto;
  ${media.minMobile`
    margin-right: 115px;
    margin-left: 0;
  `}
`;

const DiagonalHeaderView = ({
  className,
  headerProps,
  rotationAngle = 5,
  backgroundColor,
  backgroundImage,
  circleImageProps,
  title,
  isSuccessModal = false,
  children,
  hasLogo,
  stripeImage = false,
  diagonalDirection,
  showHeaderCover,
  HeaderCover,
  titleId,
  HeaderFutureOrderCover,
  isErrorMessageModal,
  showScooberBadge,
}: IDiagonalHeaderView) => {
  const {t} = getLocalizationService();
  const ref = useRef<HTMLDivElement>(null);
  const [innerWidth, setInnerWidth] = useState<number | null>(null);

  const showFutureOrderBackground = HeaderFutureOrderCover && !isErrorMessageModal;

  const setWindowSizeInState = useCallback(() => {
    const width = ref.current ? ref.current.getBoundingClientRect().width : null;
    setInnerWidth(width);
  }, [ref]);

  useEffect(() => {
    setWindowSizeInState();
    window.addEventListener('resize', setWindowSizeInState);
    return () => {
      window.removeEventListener('resize', setWindowSizeInState);
    };
  }, [setWindowSizeInState]);

  return (
    <Root {...{headerProps, backgroundColor, stripeImage, className, ref}}>
      <Header {...{headerProps, backgroundImage, circleImageProps, stripeImage, showFutureOrderBackground}}>
        {hasLogo && <DiagonalHeaderLogo src={whiteLogoUrl} alt={t('10bis')} />}
        {title && <Title id={titleId}>{title}</Title>}
        {showHeaderCover && HeaderCover && <HeaderCover />}
        {HeaderFutureOrderCover && <HeaderFutureOrderCover />}
        <Stripe {...{backgroundColor, stripeImage, diagonalDirection, rotationAngle}} />
        {circleImageProps && innerWidth && (
          <CircleImage
            src={circleImageProps.imageUrl}
            circleImageProps={circleImageProps}
            isSuccessModal={isSuccessModal}
            rotationAngle={rotationAngle}
            rootWidth={innerWidth}
            alt={circleImageProps.alt}
            noAlt={circleImageProps.noAlt || !circleImageProps?.alt}
          />
        )}
        {showScooberBadge && <ScooberBadge top={showScooberBadge} />}
      </Header>
      {children}
    </Root>
  );
};

export default memo(DiagonalHeaderView);

const CircleImageSkeleton = styled.div<{
  noCircleBorder?: boolean;
  rootWidth: number;
  size: number;
  rotationAngle: number;
  isSuccessModal?: boolean;
  shouldHideBorder: boolean;
}>`
  ${circleImageCss}
  ${({noCircleBorder}) =>
    noCircleBorder &&
    css`
      border-color: transparent;
    `}
`;

export const DiagonalHeaderViewSkeleton = ({
  children,
  backgroundColor,
  noCircle,
  noCircleBorder,
  circleSize = 128,
  isMinLargeMobile,
}: {
  children: React.ReactNode;
  backgroundColor: string;
  noCircle: boolean;
  noCircleBorder: boolean;
  circleSize: number;
  isMinLargeMobile: boolean;
}) => {
  const theme = useTheme();
  const [rootRef, setRootRef] = useState<HTMLDivElement | null>(null);

  const bgColor = useMemo(() => backgroundColor || theme.colors.surface, [backgroundColor, theme.colors.surface]);

  return (
    <Root backgroundColor={bgColor} ref={setRootRef}>
      <Header
        stripeImage
        headerProps={{
          height: isMinLargeMobile ? theme.menu.header.height.desktop : theme.menu.header.height.mobile,
        }}
      >
        <Skeleton />
        <Stripe diagonalDirection={DiagonalDirections.LEFT_DOWN_RIGHT_UP} rotationAngle={5} backgroundColor={bgColor} />
        {isMinLargeMobile && !noCircle && (
          <CircleImageSkeleton
            rotationAngle={5}
            rootWidth={rootRef ? rootRef.getBoundingClientRect().width : 0}
            size={circleSize}
            shouldHideBorder={noCircleBorder}
          >
            <Skeleton isCircle />
          </CircleImageSkeleton>
        )}
      </Header>
      {children}
    </Root>
  );
};
