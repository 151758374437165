/* eslint-disable max-len */
/* stylelint-disable max-line-length */
import {memo} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR, rotateOnLTR} from '~/shared/theme/utils';
import scooberTag from '~/assets/images/icons/scoober_tag.svg';
import {body14Bold} from '~/shared/theme/typography';
import {flexCenter} from '~/shared/theme/FlexLayout';

const ScooberLogoIcon = styled(ImageWithAlt).attrs({noAlt: true})``;

const ScooberWrapper = styled.div.attrs({className: 'transparent-bg-on-contrast'})`
  position: absolute;
  display: flex;
  border-radius: 100px;
  align-items: center;
  gap: 4px;
  padding: 2px;
  background: ${({theme}) => theme.colors.primaryText};
  box-shadow: ${({theme}) => theme.shadows.shadow9};
  z-index: 80;
  ${flipOnLTR`
    padding-left: 6px;
  `}
`;

const ScooberMenuWrapper = styled(ScooberWrapper)`
  bottom: 24px;
  ${flipOnLTR`
    left: 12px;
  `}
`;

const ScooberRestaurantCardWrapper = styled(ScooberWrapper)`
  bottom: 12px;
  ${flipOnLTR`
    left: 12px;
  `}
`;

const ScooberText = styled.div`
  ${body14Bold}
  color: ${({theme}) => theme.colors.charcoal80};
`;

const ScooberTextRestaurant = styled(ScooberText)`
  line-height: 1;
`;

const ScooberLogoWrapper = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({theme}) => theme.colors.contentBrand};
  ${flexCenter}
  ${rotateOnLTR}
`;

const DiagonalBadge = memo(() => {
  const {t} = getLocalizationService();
  return (
    <ScooberMenuWrapper>
      <ScooberLogoWrapper>
        <ScooberLogoIcon src={scooberTag} />
      </ScooberLogoWrapper>
      <ScooberText>{t('scoober_filter_label')}</ScooberText>
    </ScooberMenuWrapper>
  );
});

const RestaurantCardBadge = memo(() => {
  const {t} = getLocalizationService();
  return (
    <ScooberRestaurantCardWrapper>
      <ScooberLogoWrapper>
        <ScooberLogoIcon src={scooberTag} />
      </ScooberLogoWrapper>
      <ScooberTextRestaurant>{t('scoober_filter_label')}</ScooberTextRestaurant>
    </ScooberRestaurantCardWrapper>
  );
});

const ScooberBadge = ({top}: {top?: boolean}) => (top ? <DiagonalBadge /> : <RestaurantCardBadge />);

export default memo(ScooberBadge);
