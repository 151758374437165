import {combineReducers} from 'redux';

import {ApiResponse} from '~/shared/services/apiService';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

import {clearOrderData} from '../restrictedSharedActions';
import {composeReducers, makeAsyncReducer, makeReducer, StateAsyncProperty} from '../../redux-toolbelt';
import {Order as FetchedOrder, OrderPermits, OrderSuccessData} from '../../models';
import OrderSubmitError from '../../models/Order/OrderSubmitError';
import {MutatedFutureOrderAvailableDatesAndTimes} from '../../models/FutureOrderAvailableDatesAndTimes';

import {
  clearOrderViewResponse,
  submitOrder,
  setOrderRemarks,
  setOrderDontWantCutlery,
  setCurrentOrderDateAndTime,
  getOrderSuccessData,
  setOrderPermit,
  setIsReorder,
  clearCurrentOrderDateAndTime,
  setDinningRoomNoPackingRequired,
  setDeliveryRule,
  setNotesForClient,
  setDiningRoomBuilding,
  setDiningRoomFloor,
  setInitialOrder,
  setRemarksToPresent,
} from './orderActions';

export interface OrderState {
  diningRoom: {building: string; floor: string};
  remotelyFetched: StateAsyncProperty<ApiResponse<FetchedOrder>, OrderSubmitError>;
  remarks: string;
  remarksToPresent: string; // for dinning room selection we want to show different value.
  dontWantCutlery: boolean;
  dinningRoomNoPackingRequired: boolean;
  dateAndTime: {orderDate: MutatedFutureOrderAvailableDatesAndTimes; orderTime: MutatedFutureOrderAvailableDatesAndTimes['times'][number]};
  successData: StateAsyncProperty<Omit<OrderSuccessData, 'dishes'> & {dishToPresent: OrderSuccessData['dishes']}>;
  permit: OrderPermits;
  isReorder: boolean;
  deliveryRule: DELIVERY_RULES | null;
  notesForClient: string;
  initialOrder: {
    restaurantId: number | null;
    isInitialOrder: boolean;
  };
}

const defaultOrderState: OrderState = {
  diningRoom: {building: '', floor: ''},
  remotelyFetched: {loaded: false, loading: false},
  remarks: '',
  remarksToPresent: '',
  dontWantCutlery: false,
  dinningRoomNoPackingRequired: true,
  dateAndTime: {} as OrderState['dateAndTime'],
  successData: {loaded: false, loading: false},
  permit: {} as OrderPermits,
  isReorder: false,
  deliveryRule: null,
  notesForClient: '',
  initialOrder: {
    isInitialOrder: true,
    restaurantId: null,
  },
};

export default combineReducers<OrderState>({
  remotelyFetched: composeReducers(
    makeReducer(clearOrderViewResponse, () => undefined, {defaultState: defaultOrderState.remotelyFetched}),
    makeAsyncReducer(submitOrder),
  ),
  diningRoom: composeReducers(
    makeReducer(clearOrderData, () => undefined, {defaultState: defaultOrderState.diningRoom}),
    makeReducer<OrderState['diningRoom']>(
      {
        [setDiningRoomBuilding.TYPE]: (currentState, {payload}) => ({...currentState, building: payload}),
        [setDiningRoomFloor.TYPE]: (currentState, {payload}) => ({...currentState, floor: payload}),
      },
    ),
  ),
  remarks: composeReducers(
    makeReducer(clearOrderData, () => defaultOrderState.remarks, {defaultState: defaultOrderState.remarks}),
    makeReducer(setOrderRemarks, (_, {payload}) => payload || defaultOrderState.remarks),
  ),
  remarksToPresent: composeReducers(
    makeReducer(clearOrderData, () => defaultOrderState.remarks, {defaultState: defaultOrderState.remarks}),
    makeReducer(setRemarksToPresent, (_, {payload}) => payload || defaultOrderState.remarksToPresent),
  ),
  dontWantCutlery: composeReducers(
    makeReducer(clearOrderData, () => false, {defaultState: defaultOrderState.dontWantCutlery}),
    makeReducer(setOrderDontWantCutlery, state => !state),
  ),
  dinningRoomNoPackingRequired: makeReducer(setDinningRoomNoPackingRequired, {
    defaultState: defaultOrderState.dinningRoomNoPackingRequired,
  }),
  dateAndTime: composeReducers(
    makeReducer<OrderState['dateAndTime']>(setCurrentOrderDateAndTime, {
      defaultState: defaultOrderState.dateAndTime,
    }),
    makeReducer(clearCurrentOrderDateAndTime, () => defaultOrderState.dateAndTime, {
      defaultState: defaultOrderState.dateAndTime,
    }),
  ),
  successData: makeAsyncReducer(getOrderSuccessData),
  permit: composeReducers(
    makeReducer(clearOrderData, () => undefined, {defaultState: defaultOrderState.permit}),
    makeReducer(setOrderPermit),
  ),
  //  for analytics usage in OrderSuccess
  isReorder: makeReducer(
    setIsReorder,
    (_currentState, {payload: isReorder}) => {
      return isReorder;
    },
    {defaultState: defaultOrderState.isReorder},
  ),
  deliveryRule: composeReducers(
    makeReducer(clearOrderData, () => defaultOrderState.deliveryRule, {defaultState: defaultOrderState.deliveryRule}),
    makeReducer(setDeliveryRule, {defaultState: defaultOrderState.deliveryRule}),
  ),
  notesForClient: makeReducer(
    setNotesForClient,
    {
      defaultState: defaultOrderState.notesForClient,
    },
  ),
  // Initial Order is responsible for showing a "choose time" placeholder on Future order restaurants before user's time
  // choice. It also triggers a BottomMenu on mobile when forcing a user to pick a time before proceeding to checkout,
  // if the user still hasnt picked a time.

  // If the InitialOrder is true - the user has not selected a time for future delivery yet, and the placeholder will
  // appear. When user chooses a time, or making a ReOrder option, isInitialOrder changes to false and the placeholder
  // is being removed.
  // When the user changes an address, changes a restaurant or completing an order, the isInitialOrder property resets to
  // true and the placeholder will appear again
  initialOrder: composeReducers(
    makeReducer(clearOrderData, () => defaultOrderState.initialOrder, {defaultState: defaultOrderState.initialOrder}),
    makeReducer(
      setInitialOrder,
      (state, {payload}) => {
        if (!payload) {
          return defaultOrderState.initialOrder;
        }

        const restaurantId = payload.restaurantId || state.restaurantId;

        return {
          isInitialOrder: payload.isInitialOrder,
          restaurantId,
        };
      },
      {defaultState: defaultOrderState.initialOrder},
    ),
  ),
});
