import {combineReducers} from 'redux';

import {CityAutoComplete, StreetAutoComplete} from '../../models';
import {makeAsyncReducer, StateAsyncProperty} from '../../redux-toolbelt';

import {fetchCityNames, fetchStreetNames} from './autoCompleteActions';

export interface AutoCompleteState {
  cityName: StateAsyncProperty<AutoCompleteRecord<CityAutoComplete>>;
  streetName: StateAsyncProperty<AutoCompleteRecord<StreetAutoComplete>>;
}

export default combineReducers<AutoCompleteState>({
  cityName: makeAsyncReducer(fetchCityNames),
  streetName: makeAsyncReducer(fetchStreetNames),
});

type AutoCompleteRecord<T> = Record<number, T[]>;
