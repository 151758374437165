import styled from 'styled-components';

import {flexColumn, flexCenterVertically} from '~/shared/theme/FlexLayout';

import {getLocalizationService} from '../services/localisationService';
import {flipOnLTR} from '../theme/utils';
import {Link} from '../router';
import {body12Normal, body13Normal} from '../theme/typography';

import _FormField from './FormField';

const TextLink = styled(Link)`
  cursor: pointer;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${body12Normal}
  line-height: 1em;
  text-decoration: underline;
  white-space: nowrap;
  ${flipOnLTR`
    margin-left: 3px;
  `}
  ${({isCapitalized}) => isCapitalized && `
    text-transform: capitalize;
  `}
`;

const ErrorText = styled.div.attrs(() => ({
  role: 'alert',
}))`
  ${body13Normal}
  line-height: 1em;
  color: ${({theme}) => theme.colors.error};
  ${flipOnLTR`
    text-align: right;
  `}
`;

const CheckBoxArea = styled.div`
  height: 74px;
  margin-bottom: 10px;
  ${flexColumn}
`;

const FormField = styled(_FormField).attrs({
  className: 'agree-to-terms-form-field',
})`
  display: flex;
  height: auto;
  margin-bottom: 8px;
  flex: 0 1 auto;
`;

const Label = styled.label`
  white-space: nowrap;
  color: ${({theme}) => theme.colors.secondary};
  ${body12Normal}
  line-height: 16px;
  text-align: start;
  ${flipOnLTR`
    margin-left: 3px;
  `}
`;

const LabelWrapper = styled.div`
  flex-wrap: wrap;
  ${flexCenterVertically}
`;

export default function AgreeToTermsSection({
  errorText,
  onTermsOfUseClick,
  onPrivacyPolicyClick,
  isUpdateUserDetails = false,
}) {
  /**
   * wantPromotion - used by signUp (nextApi)
   * wantPromotions - used by update user details (bff)
   * isUpdateUserDetails is temp solution until both signUp will be moved to the bff.
   */
  const {t, currentLanguageKey} = getLocalizationService();

  return (
    <CheckBoxArea className="checkbox-area">
      <FormField
        {...{
          name: isUpdateUserDetails ? 'wantPromotions' : 'wantPromotion',
          type: 'checkbox',
          inputProps: {label: <Label>{t('i_want_to_recieve_deals_and_promotions')}</Label>},
        }}
      />

      <FormField
        {...{
          name: 'agreeToTerms',
          type: 'checkbox',
          shouldShowError: false,
          required: true,
          inputProps: {
            alignItems: 'start',
            label: (
              <LabelWrapper>
                <Label>{t('i_read_understood_and_agreed')}</Label>
                <TextLink currentLanguage={currentLanguageKey} onClick={onTermsOfUseClick}>
                  {t('terms_of_use')}
                </TextLink>
                <Label>{t('and')}</Label>
                <TextLink currentLanguage={currentLanguageKey} onClick={onPrivacyPolicyClick} isCapitalized>
                  {t('privacy_policy')}
                </TextLink>
              </LabelWrapper>
            ),
          },
        }}
      />
      {errorText && <ErrorText>{errorText}</ErrorText>}
    </CheckBoxArea>
  );
}
