import {css} from 'styled-components';

import {media} from '~/shared/theme/utils';

export const MOBILE_SUBHEADER_HEIGHT = 48;
export const DESKTOP_SUBHEADER_HEIGHT = 56;
export const MOBILE_HORIZONTAL_PADDING = 16;
export const DESKTOP_HORIZONTAL_PADDING = MOBILE_SUBHEADER_HEIGHT;
export const DEFAULT_HORIZONTAL_PADDING = 24;

export const paddingCss = css`
  padding-left: ${MOBILE_HORIZONTAL_PADDING}px;
  padding-right: ${MOBILE_HORIZONTAL_PADDING}px;
  ${media.minLargeTablet`
    padding-left: ${DESKTOP_HORIZONTAL_PADDING}px;
    padding-right: ${DESKTOP_HORIZONTAL_PADDING}px;
  `}
`;

export const defaultPaddingCss = css`
  padding-left: ${DEFAULT_HORIZONTAL_PADDING}px;
  padding-right: ${DEFAULT_HORIZONTAL_PADDING}px;
`;
