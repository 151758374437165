import {ReactNode} from 'react';

import styled from 'styled-components';

import {flexSpace} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {body14Bold, body14Normal} from '~/shared/theme/typography';

const DetailsLine = styled.div`
  ${flexSpace}
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
`;

const DetailLabel = styled.div<{isCapitalized?: boolean}>`
  min-width: 150px;
  ${body14Normal}
  ${flipOnLTR`
    margin-left: 16px;
  `}

  ${({isCapitalized}) =>
    isCapitalized &&
    `
    text-transform: capitalize;
  `}
`;

const DetailChildren = styled.div`
  ${body14Bold};
`;

interface OrderDetailsLineProps {
  label?: string;
  children: ReactNode;
  isCapitalizedLabel?: boolean;
}

const OrderDetailsLine = ({label, children, isCapitalizedLabel, ...props}: OrderDetailsLineProps) => {
  return (
    <DetailsLine {...props}>
      {label && <DetailLabel isCapitalized={isCapitalizedLabel}>{label}: </DetailLabel>}
      <DetailChildren>{children}</DetailChildren>
    </DetailsLine>
  );
};

export default OrderDetailsLine;
