import {useState, memo, ReactNode} from 'react';

import styled from 'styled-components';

import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {media, flipOnLTR} from '~/shared/theme/utils';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import closeIconUrl from '~/assets/images/icons/close-white.svg';
import {flexColumnCenterVertically} from '~/shared/theme/FlexLayout';
import Button from '~/shared/components/Button';

const ClickOutSideWithoutExtraProps = ({
  // @ts-expect-error needs to be rewritten with ts
  isVisible: _ignore,
  // @ts-expect-error needs to be rewritten with ts
  disableDefaultBackgroundColor: _ignore2,
  // @ts-expect-error needs to be rewritten with ts
  arrowLocation: _ignore3,
  ...rest
  // @ts-expect-error needs to be rewritten with ts
}) => <ClickOutsideDiv {...rest} />;

const LOCAL_STORAGE_NAME = 'callOuts';

const Root = styled(ClickOutSideWithoutExtraProps)`
  ${flexColumnCenterVertically};
  display: ${({isVisible}) => (isVisible ? 'flex' : 'none')};
  width: 100%;
  padding: 6px 20px;
  background-color: ${({theme, disableDefaultBackgroundColor}) =>
    !disableDefaultBackgroundColor && theme.callOut.backgroundColor};
  color: ${({theme}) => theme.callOut.color};
  border-radius: 2px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  text-align: center;
  ${media.minDesktop`
    width: auto;
    height: auto;
    padding: 20px;
  `}
`;

const CloseButton = styled(Button)`
  cursor: pointer;
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  position: absolute;
  display: flex;
  ${flipOnLTR`
    left: 15px;
  `}
`;

const CloseButtonIcon = styled(ImageWithAlt).attrs({
  src: closeIconUrl,
  noAlt: true,
})``;

type CallOutProps = {
  onRemove: () => void;
  localStorageName: string;
  children: ReactNode;
  disableDefaultBackgroundColor: boolean;
};

const CallOut = ({onRemove, children, disableDefaultBackgroundColor, localStorageName}: CallOutProps) => {
  const [isVisible, setIsVisible] = useState(() => {
    const localStorageCallOuts = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_NAME) as string);
    return !localStorageCallOuts || !localStorageCallOuts.includes(localStorageName);
  });

  const hideCallOut = () => {
    if (onRemove) {
      onRemove();
      return;
    }
    if (localStorageName) {
      const localStorageCallOuts = JSON.parse(window.localStorage.getItem(LOCAL_STORAGE_NAME) as string);
      global.window.localStorage.setItem(
        LOCAL_STORAGE_NAME,
        JSON.stringify([...(localStorageCallOuts || []), localStorageName]),
      );
    }
    setIsVisible(false);
  };

  return (
    <Root
      isVisible={isVisible}
      onClickOutside={isVisible && hideCallOut}
      disableDefaultBackgroundColor={disableDefaultBackgroundColor}
    >
      <CloseButton onClick={hideCallOut}>
        <CloseButtonIcon />
      </CloseButton>
      {children}
    </Root>
  );
};

export default memo(CallOut);
