import {createContext} from 'react';

import {DeliveryMethod} from '~/shared/consts/restaurantConsts';
import {RestaurantPropsType} from '~/shared/utils/restaurants';

export interface IRestaurantItemContextProps {
  restaurantItem: RestaurantPropsType;
  deliveryMethod: DeliveryMethod;
  groupId: string;
  isCompanyAddressAndRestaurantWithoutVoucher?: boolean;
  isCarousel?: boolean;
  isMinMobile?: boolean;
  isMinLargeDesktop?: boolean;

  isMaxMobile?: boolean;
  isMaxLargeDesktop?: boolean;
  isHighlightedCarousel?: boolean;
  isCurrentAddressIsDinningRoom?: boolean;
  isContrastMode?: boolean;
  hideOpenAndClosedLabels?: boolean;
  showTagsLogo?: boolean;
  withPickupTags?: boolean;
  userHasAddress?: boolean;
}

export const RestaurantItemContext = createContext<IRestaurantItemContextProps>({} as IRestaurantItemContextProps);
export const RestaurantItemContextProvider = RestaurantItemContext.Provider;
