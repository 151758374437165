import {ReactNode} from 'react';

import {useSelector} from 'react-redux';

import VerificationForm from '~/shared/components/VerificationForm';
import {selectUserError, selectUserErrorRequireCaptcha} from '~/shared/store/selectors';

export const VerificationFormContextProvider = ({children}: {children: ReactNode}) => {
  const userError = useSelector(selectUserError);
  const isRequireCaptcha = useSelector(selectUserErrorRequireCaptcha);

  return <VerificationForm.Provider value={{userError, isRequireCaptcha}}>{children}</VerificationForm.Provider>;
};
