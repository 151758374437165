import {memo, useCallback, useEffect, useState} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {api} from '~/shared/services/bffService';
import {getLocalizationService} from '~/shared/services/localisationService';
import {CloseButton as _CloseButton, ModalContent as _ModalContent} from '~/shared/components/Modals';
import Button from '~/shared/components/Button';
import {media} from '~/shared/theme/media';
import {body14Bold, body16Normal, body18Bold} from '~/shared/theme/typography';
import {flexCenterHorizontally, flexCenterVertically, FlexColumn} from '~/shared/theme/FlexLayout';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import closedDialogButtonUrl from '~/assets/images/dialogs/closeDialogButton.svg';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {getUserSessionToken, setUserSessionToken} from '~/shared/utils/userToken';
import {PageHeader} from '~/shared/components/header';
import VerificationForm from '~/shared/components/VerificationForm';
import {selectUserData} from '~/shared/store/selectors';
import apiService from '~/shared/services/apiService';
import {CentralLoading} from '~/shared/components/Loaders';
import {trackEvent} from '~/shared/services/analytics';
import {createLogger} from '~/shared/logging';
import {VerificationFormContextProvider} from '~/common/contextProviders/VerificationFormContextProvider';
import {handleRefreshToken, logout} from '~/shared/services/auth';
import {is401Error} from '~/shared/services/apiErrorService';

const Wrapper = styled.div<{isFullSize?: boolean}>`
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 675px;
  ${({isFullSize, theme}) =>
    isFullSize &&
    `
      background-color: ${theme.colors.surface};
      width: 100vw;
      height: 100vh;
      padding-top: 72px;
    `}
`;

const ModalContent = styled(_ModalContent)`
  width: auto;
  margin: 0;
  padding: 30px;
  ${media.minLargeTablet`
    padding: 30px 80px;
  `}
  ${flipOnLTR`
      text-align: right;
    `}
`;

const DialogHeader = styled.div`
  padding: 12px 16px;
  box-shadow: ${({theme}) => theme.shadows.modalHeaderShadow};
  ${media.minLargeTablet`    
      padding: 12px 0;
  `}
`;

const DialogHeaderText = styled.div`
  ${body18Bold};
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    width: 100%;
    margin: 0 auto;
  `}
`;

const CloseButton = styled(_CloseButton)`
  top: 8px;
  ${flipOnLTR`
    left: 8px; 
  `}
  ${media.minLargeTablet`
    ${flipOnLTR`
      left: 16px; 
    `}
  `}
`;

const ClosePopupButtonSvg = styled(ImageWithAlt).attrs({
  src: closedDialogButtonUrl,
})``;

const InformationPointsSection = styled(FlexColumn)`
  align-items: flex-start;
`;

const InfoRow = styled.div`
  ${body16Normal};
  display: flex;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const InfoText = styled.span``;

const InfoRowIndex = styled.div`
  display: flex;
  justify-content: center;
  width: 16px;
  text-align: center;
  ${flipOnLTR`
    margin-left: 6px;
  `}
`;

const ButtonsWrapper = styled.div`
  padding: 0 80px;
  ${flexCenterVertically};
  flex-direction: column;
  width: 100%;
  padding-bottom: 16px;
  ${media.minLargeTablet`
    flex-direction: row;
    ${flexCenterHorizontally};
  `}
`;

const CommonButton = styled(Button)`
  ${body14Bold};
  ${flexCenterHorizontally};
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  height: 50px;
  border-radius: 1px;
  padding: 14px 10px;
  width: 100%;
  ${media.minLargeTablet`
    ${flexCenterHorizontally};
    flex-direction: row;
    flex: 1;
    min-width: 172px;
  `}
`;

const CancelButton = styled(CommonButton)`
  background-color: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  margin-bottom: 14px;
  ${media.minLargeTablet`
    margin-bottom: 0;
    ${flipOnLTR`
      margin-left: 14px;
    `}
  `}
`;

const ContinueButton = styled(CommonButton)`
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  ${media.minLargeTablet`
    ${props => props.smaller && 'max-width: 172px;'}  
  `}
`;

const ResponseText = styled.div`
  ${body16Normal};
  text-align: center;
`;

const logger = createLogger('Delete account');

const informationPoints = [
  'delete_account_info_point_1',
  'delete_account_info_point_2',
  'delete_account_info_point_3',
  'delete_account_info_point_4',
];

type DeleteAccountWarningProps = {
  onCancel: () => void;
  onContinue?: () => void;
  isFullSize?: boolean;
};

const DeleteAccountWarning = ({onContinue, onCancel, isFullSize}: DeleteAccountWarningProps) => {
  const userData = useSelector(selectUserData);
  const [verificationFormErrors, setVerificationFormErrors] = useState<string | null>(null);
  const [apiResponse, setApiResponse] = useState<string | undefined>();
  const [isVerified, setIsVerified] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [verificationData, setVerificationData] = useState<Record<string, any>>();

  const identificationProcess = verificationData?.isPhoneMethod || userData?.cellphone ? 1 : 0;

  const {t} = getLocalizationService();

  const getUserAuthCode = useCallback(async () => {
    try {
      const {data}: Record<string, any> = await apiService.getUserAuthenticationDataAndSendAuthenticationCodeToUser({
        email: userData?.email,
      });

      const {codeAuthenticationData} = data;

      setVerificationData({
        authenticationToken: codeAuthenticationData?.authenticationToken,
        isPhoneMethod: codeAuthenticationData?.sendingMethod === 'Phone',
        lastFourPhoneDigits: codeAuthenticationData?.lastFourPhoneDigits,
      });
    } catch (error) {
      const errorType = 'get user authentication code failed';
      logger.error(errorType, {error, fingerprint: errorType});
    }
  }, [userData?.email]);

  useEffect(() => {
    if (!isVerified) {
      getUserAuthCode();
    }
  }, [isVerified, getUserAuthCode]);

  const handleRemoveAccount = async () => {
    try {
      setIsLoading(true);
      const token = await getUserSessionToken();

      if (!token) {
        setIsLoading(false);
        setIsVerified(false);
        return;
      }

      await api.removeUserAccount({
        headers: {
          'session-token': token,
        },
      });

      setApiResponse('success');
      setIsLoading(false);
      trackEvent('hasSuccessfullyDeleteAccount', {identificationProcess});
    } catch (error: any) {
      if (is401Error(error)) {
        await handleRefreshToken(error, handleRemoveAccount);
        return;
      }

      setApiResponse('failed');
      setIsLoading(false);
      trackEvent('hasFailedDeleteAccount', {identificationProcess});
      const errorType = 'delete account failed';
      setVerificationFormErrors(error?.message);
      logger.error(errorType, {error, fingerprint: errorType});
    }
  };

  const onSubmitVerification = async (entries: Record<string, any>) => {
    const {authenticationCode} = entries;

    if (!authenticationCode) {
      setVerificationFormErrors('onSubmitVerification didnt receive an authenticationCode');
      return;
    }

    setVerificationFormErrors(null);

    try {
      const params = {
        email: userData?.email,
        authenticationToken: verificationData?.authenticationToken,
        authenticationCode,
      };
      const {data: user} = await apiService.getUserV2(params);
      trackEvent('hasApproveDeleteAccount', {identificationProcess});
      setUserSessionToken(user?.sessionToken);
      setIsVerified(Boolean(user?.sessionToken));
      handleRemoveAccount();
    } catch (error: any) {
      setApiResponse('failed');
      setVerificationFormErrors(error?.message);
      const errorType = 'user verification failed';
      logger.error(errorType, {error, fingerprint: errorType});
    }
  };

  const DeleteAccountContent = () => {
    if (!isVerified) {
      const headerText = userData?.cellphone
        ? t('we_sent_you_an_sms_with_code_to_this_number_xxx', {
          phoneNumber: userData?.cellphone,
        })
        : t('we_sent_you_an_email_with_code_to_verify');
      return (
        <VerificationFormContextProvider>
          <VerificationForm
            onReSendCode={getUserAuthCode}
            onSubmit={onSubmitVerification}
            email={userData?.email}
            headerText={headerText}
            setResErrors={setVerificationFormErrors}
            resErrors={verificationFormErrors}
          />
        </VerificationFormContextProvider>
      );
    }

    if (apiResponse) {
      return (
        <ResponseText>
          {apiResponse === 'success' ? t('delete_account_success_info') : t('delete_account_error_info')}
        </ResponseText>
      );
    }

    return (
      <InformationPointsSection>
        {informationPoints.map((info, idx) => (
          <InfoRow key={info}>
            <InfoRowIndex>{idx + 1}. </InfoRowIndex>
            <InfoText>{t(info)}</InfoText>
          </InfoRow>
        ))}
      </InformationPointsSection>
    );
  };

  const isSuccess = apiResponse && apiResponse === 'success';

  useEffect(() => {
    return () => {
      if (!isSuccess) {
        return;
      }
      logout();
    };
  }, [isSuccess]);

  const onContinueClickFunc = useCallback(() => {
    if (!apiResponse) {
      trackEvent('hasContinueDeleteAccount', {identificationProcess});
      return handleRemoveAccount();
    }
    if (isSuccess && onContinue) {
      return onContinue();
    }
    return onCancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiResponse, isSuccess]);

  return (
    <>
      {isFullSize && <PageHeader />}
      <Wrapper isFullSize={isFullSize} modal-title="remove_account_modal">
        {isLoading && <CentralLoading />}
        <DialogHeader>
          <CloseButton data-id="remove-account-close-button" onClick={onCancel}>
            <ClosePopupButtonSvg alt={t('close')} />
          </CloseButton>
          <DialogHeaderText id="modal-title">
            {!apiResponse || isSuccess ? t('delete_account') : t('account_not_deleted')}
          </DialogHeaderText>
        </DialogHeader>

        <ModalContent>
          <DeleteAccountContent />
        </ModalContent>

        {isVerified && (
          <ButtonsWrapper>
            <>
              {!isSuccess && <CancelButton onClick={onCancel}>{t('cancel')}</CancelButton>}
              <ContinueButton onClick={onContinueClickFunc}>{t('continue')}</ContinueButton>
            </>
          </ButtonsWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default memo(DeleteAccountWarning);
