import {isEqual} from 'lodash';
import {combineReducers} from 'redux';

import {makeReducer, makeAsyncReducer, composeReducers, StateAsyncProperty, GenericAction} from '../../redux-toolbelt';
import {DishAssignedUsers, MenuCategories, UserOrdersHistoryForRestaurant} from '../../models';
import {submitOrder} from '../order/orderActions';

import {
  clearMenuOrderHistory,
  fetchMenuOrderHistory,
  getDishAssignedUsers,
  fetchRestaurantMenu,
  setFetchedCategories,
  setMenuLoading,
  setSearchTerm,
} from './menuActions';

export interface MenuState {
  categories: StateAsyncProperty<MenuCategories>;
  lastCategoriesIdRequested: number;
  dishAssignedUsers: StateAsyncProperty<DishAssignedUsers>;
  orderHistory: StateAsyncProperty<UserOrdersHistoryForRestaurant['orders']>;
  menuSearch: {
    searchTerm: string;
  };
}

const setEmpty = () => '';
const menuOrderHistoryDefaultState: MenuState['orderHistory'] = {loaded: false, loading: false, data: []};

export default combineReducers<MenuState>({
  categories: composeReducers(
    // TODO: add Missing Dishes reducer
    makeAsyncReducer<MenuState['categories']>(fetchRestaurantMenu, {
      defaultData: {resId: 0, categoriesList: []},
      shouldDestroyData: false,
      dataGetter: ({data}, {payload}: GenericAction<ReturnType<typeof fetchRestaurantMenu>>) =>
        (isEqual(data, payload) ? data : payload),
    }),
    makeReducer(setFetchedCategories, (state: {data: MenuCategories}, {payload}) => ({
      ...state,
      data: {
        ...state.data,
        ...payload,
      },
    })),
    makeReducer(setMenuLoading, (state: MenuState['categories'], {payload = false}) => {
      return {
        ...state,
        loading: payload,
      };
    }),
  ),
  menuSearch: combineReducers<MenuState['menuSearch']>({
    searchTerm: makeReducer({
      [setSearchTerm.TYPE]: (_state: string, {payload}: {payload: MenuState['menuSearch']['searchTerm']}) => payload,
      [fetchRestaurantMenu.success.TYPE]: setEmpty,
      [setFetchedCategories.TYPE]: setEmpty,
      [submitOrder.success.TYPE]: setEmpty,
    }),
  }),
  lastCategoriesIdRequested: makeReducer<
    MenuState['lastCategoriesIdRequested'],
    ReturnType<typeof fetchRestaurantMenu>['payload']
  >(fetchRestaurantMenu, (_state, {payload}) => payload?.restaurant.id, {
    defaultState: 0,
  }),
  dishAssignedUsers: makeAsyncReducer<MenuState['dishAssignedUsers']>(getDishAssignedUsers, {defaultData: {users: []}}),
  orderHistory: composeReducers<MenuState['orderHistory']>(
    makeReducer(clearMenuOrderHistory, () => menuOrderHistoryDefaultState, {
      defaultState: menuOrderHistoryDefaultState,
    }),
    makeAsyncReducer(fetchMenuOrderHistory),
  ),
});
