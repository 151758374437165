import {RestaurantFromGet, RestaurantFromSearch} from '~/shared/store/models';
import {DeliveryMethod, DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {DeliveryMessage} from '~/shared/services/getRestaurantClosedMessage/types';
import getClosedRestaurantMessageForDelivery from '~/shared/services/getRestaurantClosedMessage/getClosedRestaurantMessageForDelivery';
import getClosedRestaurantMessageForPickup from '~/shared/services/getRestaurantClosedMessage/getClosedRestaurantMessageForPickup';

const getClosedRestaurantMessage = ({
  restaurant,
  deliveryMethod,
}: {
  restaurant: RestaurantFromSearch | RestaurantFromGet;
  deliveryMethod: DeliveryMethod;
}): DeliveryMessage => {
  if (deliveryMethod === DeliveryMethods.DELIVERY) {
    return getClosedRestaurantMessageForDelivery(restaurant);
  }

  return getClosedRestaurantMessageForPickup(restaurant);
};

export default getClosedRestaurantMessage;
