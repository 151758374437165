const consoleIgnoreList = [
  // greeting message
  /Digging.into.our.code?/,

  // missing sourcemaps on certain 3rd party libraries
  /devtools.*sourcemap/,

  // same site cookies are not used in many services yet
  /A.cookie.associated.with.a.cross-site.resource.*was.set.without.the.*SameSite/,

  // a bug in "crazyegg" that the team that develops it is aware of
  /CE:.multiple.userscripts.installed/,

  // react devtools extension
  /react.devtools.backend.js/,

  // from redux-toolbelt add-on
  /CannotSerializeObjectNonSerializable/,

  // react deprecated methods warning
  /In.React.17.x/,

  // chrome messages
  /^\[Violation\].*.handler.took/,
  /^\[Violation\].Forced.reflow/,
];

export const remoteLoggingIgnoreList = [
  ...consoleIgnoreList,

  // i18logs are not interesting to us
  /^i18next:/,

  // ignore logger outputs to console. the logger should be tracked manually
  /^\$\[/,

  // ignore the print of the config in dev
  /^current.config..printed.only.on.dev./,

  // hot reload
  /^\[HMR\]/,

];

export default consoleIgnoreList;
