import styled from 'styled-components';

import {media, flipOnLTR} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {flexColumnCenter, flexCenter, flexColumnCenterHorizontally} from '~/shared/theme/FlexLayout';
import {body12Normal, body16Normal, body18Bold} from '~/shared/theme/typography';

const ModalRoot = styled.div`
  text-align: center;
  background-color: ${({theme}) => theme.modal.backgroundColor};
  box-shadow: ${({theme}) => theme.shadows.shadow1};
`;

export const ClosedRestaurantModalRoot = styled(ModalRoot)`
  &&& {
    width: calc(100vw - 40px);
  }

  ${media.minLargeTablet`    
    &&& {
      overflow: hidden;
      width: 513px;
    }
  `}
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
  padding: 0 0 30px;
  ${flexColumnCenterHorizontally};
`;

export const DialogHeader = styled.div`
  height: 80px;
  background-color: ${({theme}) => theme.colors.surface};
  ${flexColumnCenter};
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 25px;
  ${flexCenter};
  cursor: pointer;
  z-index: 1;
  ${flipOnLTR`
    left: 11px; 
  `}
`;

export const DialogHeaderText = styled.div`
  ${body18Bold};
  padding: 0 50px;
  margin: 0 auto;
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    width: 100%;
`}
`;

export const RestaurantExplanationText = styled.div`
  ${body16Normal};
  padding: 20px 20px 0;
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    max-width: 345px;
    margin: auto;
    padding: 20px 20px 0;
  `}
`;

export const ToAllRestaurantsWrapper = styled(Button)`
  max-width: 260px;
  width: 100%;
  min-height: 50px;
  border-radius: 1px;
  margin: 30px auto 0;
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${media.minLargeTablet`
    max-width: 345px;
  `}
`;

export const ToAllRestaurantsButton = styled.div`
  font-weight: bold;
  text-align: center;
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
`;

export const LineWrapper = styled.div`
  width: 100%;
  max-width: 265px;
  display: flex;
  margin: 30px auto 0;
  ${media.minLargeTablet`
    max-width: 345px;
  `}
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  border-top: solid 0.5px ${({theme}) => theme.colors.secondary};
  margin: 0 auto;
`;

export const OrText = styled.div`
  display: inline-flex;
  padding-left: 10px;
  padding-right: 10px;
  top: -14px;
  ${body12Normal};
  text-align: center;
  color: ${({theme}) => theme.colors.secondary};
  background-color: ${({theme}) => theme.modal.backgroundColor};
`;

export const ToRestaurantMenuButtonWrapper = styled(ToAllRestaurantsWrapper)`
  border: solid 1px ${({theme}) => theme.colors.gray500};
  margin: 30px auto 0;
  background-color: transparent;
`;

export const ToRestaurantMenuButton = styled(ToAllRestaurantsButton)`
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
`;
