export const scrollToTop = (isIos?: boolean) => {
  if (isIos) {
    setTimeout(() => {
      window.scrollTo(0, -1);
    }, 0);
    return;
  }

  window.scrollTo(0, 0);
};
