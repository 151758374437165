import {
  RESTAURANT_CURRENTLY_CLOSED_PICKUP,
  SITTING_ONLY_PICKUP,
} from '~/shared/services/getRestaurantClosedMessage/consts';

import {GetClosedRestaurantMessageForDeliveryMode, isRestaurantFromSearch} from './types';

const getClosedRestaurantMessageForPickup: GetClosedRestaurantMessageForDeliveryMode = restaurant => {
  if (restaurant) {
    const isTerminalActive = isRestaurantFromSearch(restaurant)
      ? restaurant.isTerminalActive
      : restaurant.isTerminalEnabled;

    if (isTerminalActive) {
      return SITTING_ONLY_PICKUP;
    }
  }

  return RESTAURANT_CURRENTLY_CLOSED_PICKUP;
};

export default getClosedRestaurantMessageForPickup;
