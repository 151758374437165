import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';

import {body14Normal} from '../theme/typography';

const Container = styled.div<{isGov?: boolean}>`
  margin-top: 15px;
  background-color: rgba(255, 192, 3, 0.25);
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 15px;
  ${({isGov, theme}) =>
    isGov &&
    `
    border: 1px solid ${theme.colors.gray000};
    background-color: ${theme.colors.info};
  `}
`;

const ErrorContainer = styled.div`
  align-items: center;
  padding: 5px;
`;

const ErrorText = styled.div`
  ${body14Normal};
  color: ${({theme}) => theme.colors.gray100};
  line-height: 16px;
  ${flipOnLTR`
    text-align: right;
    margin-right: 5px;
  `}
`;

const PreOrderAlert = ({texts, isGov}: {texts: string[]; isGov: boolean}) => (
  <Container isGov={isGov}>
    <ErrorContainer>
      {texts.map(text => (
        <ErrorText key={text}>{text}</ErrorText>
      ))}
    </ErrorContainer>
  </Container>
);

export default PreOrderAlert;
