// @ts-expect-error config needs to be rewritten as ts
import {menuFetchFromCdnUrl, seoCityDescriptionFetchFromCdnUrl, baseCdnUrl} from '~/shared/config';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import store from '~/shared/store';
import {createLogger} from '~/shared/logging';
import {toLowerCamelCase} from '~/shared/utils/general';
import sanitizeHtml from '~/shared/utils/sanitizeHtml';
import {MenuCategories, SeoCityDescriptionFromCdn} from '~/shared/store/models';
import actions from '~/shared/store/actions';
import {getCurrentLocation, refreshPage} from '~/shared/router';

import {makeRequest} from '../http';
import {IRestaurantMenuRes} from '../bffService/api/interfaces/responses';
import {getLocalizationService} from '../localisationService';
import {getAppType} from '../bffService/util';

import apiServiceConfig, {ApiServiceConfigFunctions, ApiServiceConfigParams} from './apiServiceConfig';
import {
  getIsMobileDeviceParams,
  getLocaleParams,
  getShoppingCartGuidParams,
  getTimestampParams,
} from './apiServiceUtils';

const NEXT_API_URL_PREFIX = 'NextApi/';
const EMPTY_SHOPPING_CART_GUID = '00000000-0000-0000-0000-000000000000';

const logger = createLogger('ApiService');

const LIMIT_TRIES = 3;
let tries = 0;

// Todo: revise after http is rewritten as ts. Response type is likely to be incorrect
const handleResponseShoppingCartGuid = (
  response: {shoppingCartGuid: string; errors?: unknown[]},
  paramsShoppingCartGuid: string | undefined,
  url: string,
) => {
  if (response.shoppingCartGuid === paramsShoppingCartGuid) {
    tries = 0;
    return;
  }

  if (response.shoppingCartGuid === EMPTY_SHOPPING_CART_GUID || paramsShoppingCartGuid === EMPTY_SHOPPING_CART_GUID) {
    if (tries === LIMIT_TRIES) {
      const {t} = getLocalizationService();

      store.dispatch(
        actions.setCurrentModal('generalErrorModal', {
          headerText: t('something_went_wrong'),
          buttons: [
            {
              label: t('refresh'),
              onClick: () => {
                refreshPage();
              },
            },
          ],
        }),
      );

      return;
    }
    tries++;
  }

  if (response.shoppingCartGuid === EMPTY_SHOPPING_CART_GUID) {
    const hasErrors = !!response.errors?.length;
    if (!hasErrors) {
      logger.error(
        [
          `A Request returned with shoppingCartGuid: ${EMPTY_SHOPPING_CART_GUID}.`,
          'Make sure this request is suppose to receive shoppingCartGuid in the first place.',
        ].join(' '),
        {url, requestShoppingCartGuid: paramsShoppingCartGuid},
      );
    }
    return;
  }

  logger.warn(`Updating shoppingCartGuidId from ${paramsShoppingCartGuid} to ${response.shoppingCartGuid}`);
  ManagerProvider.changeShoppingCartGuid({
    shoppingCartGuid: response.shoppingCartGuid,
    updateOrder: !!paramsShoppingCartGuid,
  });
};

const getCouponsRequestHeaders = (url: string) => {
  const {query} = getCurrentLocation();
  const {authorization, embedded} = query;

  if (url === 'NextApi/GetRestaurantDiscountsData' && embedded === 'true') {
    return {
      ...(authorization && {
        Authorization: `Bearer ${authorization}`,
      }),
    };
  }
};

const createApiRequest = <K extends keyof ApiServiceConfigFunctions>(
  requestConfig: ApiServiceConfigParams,
): ApiServiceConfigFunctions[K] => {
  const {
    url: userUrl,
    urlPrefix = NEXT_API_URL_PREFIX,
    method,
    useShoppingCartGuid,
    convertParams,
    timeout,
  } = requestConfig;
  const url = `${urlPrefix}${userUrl}`;

  // eslint-disable-next-line func-names
  return async function (passedParams?: Record<string, any>, {useDefaultLocale = false} = {}) {
    const state = store.getState();

    const shoppingCartGuidParams = useShoppingCartGuid
      ? getShoppingCartGuidParams(state)
      : {shoppingCartGuid: undefined};
    const localeParams = getLocaleParams(useDefaultLocale);
    const params = {
      ...shoppingCartGuidParams,
      ...localeParams,
      ...getIsMobileDeviceParams(requestConfig, state),
      ...getTimestampParams(requestConfig),
      ...passedParams,
    };

    const headers = {
      ...getAppType(state),
      ...getCouponsRequestHeaders(url),
    };

    const response = await makeRequest({
      url,
      method,
      params,
      convertParams,
      timeout,
      headers,
    });

    if (useShoppingCartGuid) {
      handleResponseShoppingCartGuid(response, params.shoppingCartGuid, url);
    }

    return response;
  };
};

const staticApiServiceFunctions = {
  facebook3rdPartyLogin: () =>
    new Promise<fb.AuthResponse & {email?: string}>((resolve, reject) => {
      // user has already connected with facebook once but didnt
      // finish the signup process and clicked on fb button again
      // then it will override the existing access,_token.
      window.FB.login((response: {
        status: string;
        authResponse: fb.AuthResponse | PromiseLike<fb.AuthResponse>;
      }) => {
        if (response.status === 'connected') {
          window.FB.api('/me', {fields: 'email'}, (fbApiResponse: {
            email?: string;
          }) => {
            resolve({...response.authResponse, ...fbApiResponse});
          });
        } else {
          reject();
        }
      });
    }),

  fetchCdnDiningRooms: async () => {
    // we can use ../Develop/.. when testing in local/dev
    const discountDiningRoomsCdn = `${baseCdnUrl}/10bis-spa-content/Production/DiscountDiningRooms.json`;
    const result = await fetch(discountDiningRoomsCdn);

    if (result.status !== 200 || !result.ok) {
      throw new Error('failed to fetch dininging rooms from cdn');
    }
    const jsonResult = await result.json();
    return jsonResult;
  },

  fetchMenuFromCdn: async ({
    restaurantId,
    currentLanguageKey,
  }: {
    restaurantId: number;
    currentLanguageKey: string;
  }): Promise<IRestaurantMenuRes | MenuCategories> => {
    if (!restaurantId || !currentLanguageKey) {
      throw new Error('restaurantId or currentLanguageKey wasnt provided to fetchMenuFromCdn');
    }

    const restaurantMenuCdnURL = `${menuFetchFromCdnUrl}${restaurantId}_${currentLanguageKey}`;
    const result = await fetch(restaurantMenuCdnURL);
    const jsonResult = await result.json();
    return toLowerCamelCase(jsonResult);
  },

  fetchSeoCityDescriptionFromCdn: async ({
    cityId,
    cuisineId,
  }: {
    cityId: string;
    cuisineId?: string;
  }): Promise<SeoCityDescriptionFromCdn | undefined> => {
    let seoDescriptionCdnUrl = `${seoCityDescriptionFetchFromCdnUrl}${cityId}`;

    if (cuisineId) {
      seoDescriptionCdnUrl += `_${cuisineId}`;
    }

    seoDescriptionCdnUrl += '.json';

    /** example for seoDescriptionCdnUrls:
     *  https://cdn.10bis.co.il/seo-for-cities-and-cuisine-types/holon.json
     *  https://cdn.10bis.co.il/seo-for-cities-and-cuisine-types/holon_pizza.json
     */

    try {
      const result = await fetch(seoDescriptionCdnUrl)
        .then(async res => {
          const json = await res.json();
          return json;
        })
        .catch(() => {
          throw new Error('unable to fetch seo city description');
        });

      if (!result) {
        return undefined;
      }

      const {Description, MetaDescription} = result;
      const sanitizedDescription = !Description ? null : await sanitizeHtml(Description);
      const sanitizedMetaDescription = !MetaDescription ? null : await sanitizeHtml(MetaDescription);

      return {
        description: sanitizedDescription?.trim(),
        metaDescription: sanitizedMetaDescription?.trim(),
      };
    } catch (error: any) {
      throw new Error(error);
    }
  },
};

const dynamicApiServiceFunctions = Object.entries(apiServiceConfig).reduce<ApiServiceConfigFunctions>(
  (res, [requestName, requestConfig]) => ({
    ...res,
    [requestName]: createApiRequest<keyof ApiServiceConfigFunctions>(requestConfig),
  }),
  {} as ApiServiceConfigFunctions,
);

const apiService = {
  ...staticApiServiceFunctions,
  ...dynamicApiServiceFunctions,
};

export default apiService;
