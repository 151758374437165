import {memo, useEffect, useState} from 'react';

import styled from 'styled-components';

import CloseIcon from '~/assets/images/icons/closeMark.svg?react';
import Logo from '~/assets/images/icons/10bis.svg?react';
import {getLocalizationService} from '~/shared/services/localisationService';
import {useIsAndroid, useIsMobileDevice} from '~/shared/hooks/deviceInfo';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {body14Normal, body16Normal} from '~/shared/theme/typography';
import {secureOpenWindow} from '~/shared/utils/general';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {checkTimePast} from '~/shared/utils/checkTimePast';
import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import {NewWidthContainer} from '~/shared/components/styled';
import Button from '~/shared/components/Button';
import {useLocation} from '~/shared/router';
import {convertGapToMargin} from '~/shared/theme/convertGapToMargin';

const Root = styled.div`
  width: 100%;
  margin: auto;
  height: 60px;
  background: ${({theme}) => theme.colors.surface};
  z-index: ${({theme}) => theme.zIndex.supportAndA11yBadge};
  box-shadow: 0 -2px 2px 0 rgba(40, 45, 72, 0.05), 0 4px 4px 0 rgba(63, 68, 97, 0.05);
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

const Content = styled(NewWidthContainer)`
  padding: 0 12px;
  ${flexCenterVertically};
  ${({theme}) => convertGapToMargin('0 6px', theme.isLTR)}
`;

const CloseButton = styled(Button)`
  display: flex;
  align-items: center;
  height: 40px;

  svg {
    transform: scale(0.8);
  }
`;

const StyledLogo = styled(Logo)`
  flex-shrink: 0;
  display: flex;
  background: ${({theme}) => theme.colors.orange30};
  border-radius: 4px;
 
  path {
    transform: scale(0.7);
    translate: 4px 4px;
  }
`;

const Title = styled.div`
  flex: 1;
  ${body14Normal}
  line-height: 16px;
  ${flipOnLTR`
    text-align: right;
  `}
`;

const PromotionButton = styled(Button)`
  padding: 4px 8px;
  height: 40px;
  background: ${({theme}) => theme.colors.surfaceTitle};
  color: ${({theme}) => theme.colors.surface};
  border-radius: 4px;
  ${body16Normal}
`;

type StoredPromotionData = {
  timeStemp: string;
  active: boolean;
};

const RENDER_ON_ROUTES_NAMES = ['home', 'restaurants'];

const AppsPromotionBanner = () => {
  const {routeName} = useLocation();
  const [showBanner, setShowBanner] = useState(false);

  const isAndroid = useIsAndroid();
  const isMobileDevice = useIsMobileDevice();

  const {t} = getLocalizationService();
  const storedExpDate = window.localStorage.getItem(LocalStorageKeys.PROMOTION_BANNER_EXP_DATE);
  const parsedExpDate: StoredPromotionData | undefined = storedExpDate && JSON.parse(storedExpDate);
  const is24hoursPast = checkTimePast({storedDate: parsedExpDate?.timeStemp || null, days: 1});

  useEffect(() => {
    if (showBanner && (!parsedExpDate || is24hoursPast)) {
      window.localStorage.setItem(LocalStorageKeys.PROMOTION_BANNER_EXP_DATE, JSON.stringify({
        timeStemp: Date.now(),
        active: true,
      }));
    }
  }, [is24hoursPast, showBanner, parsedExpDate]);

  useEffect(() => {
    if ((parsedExpDate && !parsedExpDate?.active) || !RENDER_ON_ROUTES_NAMES.includes(routeName)) {
      setShowBanner(false);
      return;
    }
    if (isMobileDevice && isAndroid && RENDER_ON_ROUTES_NAMES.includes(routeName)) {
      setShowBanner(true);
    }
  }, [isAndroid, isMobileDevice, parsedExpDate, routeName]);

  const handlePromotionClick = () => {
    secureOpenWindow('https://play.google.com/store/apps/details?id=com.tenbis.tbapp&hl=en&gl=US&pli=1', '_blank');
  };

  const handleCloseClick = () => {
    window.localStorage.setItem(LocalStorageKeys.PROMOTION_BANNER_EXP_DATE, JSON.stringify({
      timeStemp: parsedExpDate?.timeStemp,
      active: false,
    }));
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }
  
  return (
    <Root>
      <Content>
        <CloseButton onClick={handleCloseClick} aria-label={t('close')}>
          <CloseIcon className="avoid-fill-stroke yellow-first-path" />
        </CloseButton>
        <StyledLogo />
        <Title>{t('promotion_banner')}</Title>
        <PromotionButton onClick={handlePromotionClick} aria-label={t('promotion_banner')}>{t('open')}</PromotionButton>
      </Content>
    </Root>
  );
};

export default memo(AppsPromotionBanner);
