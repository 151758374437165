import actions from '~/shared/store/actions';
import {on, eventNames} from '~/shared/events';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import initManager from '~/shared/managers/initManager';
import store from '~/shared/store';
import {routeNames} from '~/shared/routes';
import {openModalOrPageFromQuery} from '~/shared/services/navigation';

import {getCurrentLocation, removeQueries, ProcessedLocation} from '../router';

const routeNamesToScrollToTop = [routeNames.signin];

async function addCouponToUserFromQuery() {
  const {query} = getCurrentLocation();
  const couponCodeInQuery = query.couponCode;
  removeQueries(['couponCode']);

  // TODO: coupon - what's coupon 0?
  if (couponCodeInQuery && couponCodeInQuery !== '0') {
    await ManagerProvider.changeCoupon({couponCode: couponCodeInQuery});
  }
}

// This function is not working good with IOS devices
// Use scrollToTop '~/shared/utils/scroll' instead
const shouldScrollToTopOnUrl = (routeName: string) => {
  const shouldScrollTop = routeNamesToScrollToTop.includes(routeName);
  if (shouldScrollTop) {
    window.scrollTo(0, 0);
  }
};

const resetPopups = () => {
  const {getState, dispatch} = store;
  const {
    currentModal,
    ui: {
      addressDropdown: {addressView},
      searchMode,
    },
  } = getState();

  if (searchMode) {
    dispatch(actions.setSearchMode(false));
  }
  if (addressView) {
    dispatch(actions.setAddressView(null));
  }
  if (currentModal) {
    dispatch(actions.setCurrentModal());
  }
};

export default function routerEpic() {
  function onAfterNavChange(currentLocation: ProcessedLocation) {
    shouldScrollToTopOnUrl(currentLocation.routeName);
  }

  on(eventNames.routeChanged, ({currentLocation}: {currentLocation: ProcessedLocation}) => {
    resetPopups();
    setTimeout(() => onAfterNavChange(currentLocation));
  });

  on(eventNames.locationChanged, () => {
    initManager.runWhenInitialized(() => {
      openModalOrPageFromQuery();
      addCouponToUserFromQuery();
    });
  });
}
