import {memo, useState, useRef, useEffect, ReactNode, KeyboardEvent} from 'react';

import {useSelector} from 'react-redux';
import styled, {css} from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {selectA11yContrast} from '~/shared/store/selectors';
import mobileBackWhiteUrl from '~/assets/images/icons/mobile_back_white.svg';
import mobileBackBlueUrl from '~/assets/images/icons/mobile_back_blue.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
 
import {flexCenter, flexCenterVertically} from '../theme/FlexLayout';
import {media} from '../theme/media';
import {body16Normal} from '../theme/typography';
import {flipOnLTR, rotateOnRTL} from '../theme/flipOnLTR';

import Button from './Button';
import {HeaderWrapper, CloseButtonSVGIcon, Title, ModalHeader} from './styled';

const CloseButton = styled(Button)`
  width: 32px;
  align-self: flex-start;
  cursor: pointer;
  height: 32px;
  border-radius: 50%;
  ${flexCenter}
  background-color: ${({withBackground, theme}) => withBackground && theme.colors.darkBackground};
  position: absolute;
  top: ${({isDual}) => (isDual ? 22 : 13)}px;
  ${flipOnLTR`left: 18px;`}
  ${rotateOnRTL};
  ${({flipDirection}) =>
    flipDirection &&
    css`
      ${flipOnLTR` 
        left: auto;
        right: 18px;
      `}
    `}
  z-index: 3;
`;

const BackButtonImage = styled(ImageWithAlt).attrs(({isContrastActive}: {isContrastActive: boolean}) => ({
  src: isContrastActive ? mobileBackWhiteUrl : mobileBackBlueUrl,
}))<{alt: string; isContrastActive: boolean}>`
  height: 17px;
  width: 17px;
  cursor: pointer;
  display: flex;
`;

const TabListContainer = styled.div.attrs({
  role: 'tablist',
})`
  ${flexCenterVertically}
  justify-content: space-around;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  ${media.minMobile`
    width: 345px;
  `};
`;

const DuelHeaderWrapper = styled(ModalHeader)`
  ${body16Normal};
  display: flex;
  justify-content: space-between;
  height: 80px;
  background-color: ${({theme}) => theme.checkout.headerBackground};
  z-index: 3;
  padding: 0 17px;
`;

const DualTitleButton = styled(Button).attrs({
  role: 'tab',
})`
  border-bottom: ${({isActive}) => (isActive ? 'solid 2px #0a3847' : 'none')};
  height: 100%;
  text-align: center;
  ${flexCenter};
  cursor: pointer;
`;

type LayoutWithHeaderProps = {
  titleKey: string;
  onClick?: (() => void) | null;
  showBackArrow?: boolean;
  titleComponent?: any;
  showHeader?: boolean;
  children?: ReactNode;
};

export const LayoutWithHeader = memo(
  ({
    titleKey,
    onClick,
    showBackArrow,
    titleComponent,
    showHeader = true,
    children,
  }: Readonly<LayoutWithHeaderProps>) => {
    const isContrastActive = useSelector(selectA11yContrast);

    const {t} = getLocalizationService();

    const HeaderTitle = titleComponent || Title;

    if (!showHeader) {
      return <>{children}</>;
    }

    return (
      <>
        <HeaderWrapper>
          <HeaderTitle id="modal-title" data-id={titleKey}>
            {t(titleKey)}
          </HeaderTitle>
        </HeaderWrapper>

        {children}

        <CloseButton onClick={onClick} aria-label={t(showBackArrow ? 'back' : 'close')} flipDirection={showBackArrow}>
          {showBackArrow ? (
            <BackButtonImage alt={t('back')} isContrastActive={isContrastActive} />
            ) : (
              <CloseButtonSVGIcon />
            )}
        </CloseButton>
      </>
    );
  },
);

const DualTitle = styled.h3<{isActive: boolean}>`
  font-weight: ${({isActive}) => (isActive ? 'bold' : 'normal')};
  font-size: 14px;
  color: ${({theme}) => theme.checkout.textColor};
  ${media.minMobile`
    font-size: 18px;
    line-height: 1em;
    margin: 0 auto;
  `};
`;

// https://devstephen.medium.com/keyboardevent-key-for-cross-browser-key-press-check-61dbad0a067a
const LEFT_ARROW_CODE = 37;
const LEFT_ARROW_KEY = 'ArrowLeft';
const RIGHT_ARROW_CODE = 39;
const RIGHT_ARROW_KEY = 'ArrowRight';

type LayoutWithDoubleHeaderProps = {
  titleKey: string;
  secondTitleKey: string;
  onClick?: () => void;
  onFirstTabClick?: () => void;
  onSecondTabClick?: () => void;
  showBackArrow?: boolean;
  firstTabActive: boolean;
  children?: ReactNode;
  hideHeader?: boolean;
};

// tab panel role.
// read more here
// https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
export const LayoutWithDoubleHeader = memo(
  ({
    titleKey,
    secondTitleKey,
    onClick,
    onFirstTabClick,
    onSecondTabClick,
    showBackArrow,
    firstTabActive,
    children,
    hideHeader,
  }: LayoutWithDoubleHeaderProps) => {
    const isContrastActive = useSelector(selectA11yContrast);

    const [isFirstTabActive, setIsFirstTabActive] = useState(firstTabActive);

    const {t} = getLocalizationService();
    const secondTitleWrapper = useRef<HTMLButtonElement | null>(null);
    const firstTitleWrapper = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
      setIsFirstTabActive(firstTabActive);
    }, [firstTabActive]);

    const handleTabPanelKeys = (e: KeyboardEvent<HTMLElement>) => {
      if (!firstTitleWrapper.current || !secondTitleWrapper.current) {
        return;
      }

      const key = e.key || e.keyCode;

      const isLeftOrRightArrowKey =
        key === LEFT_ARROW_CODE || key === LEFT_ARROW_KEY || key === RIGHT_ARROW_CODE || key === RIGHT_ARROW_KEY;
      if (!isLeftOrRightArrowKey) {
        return;
      }

      if (document.activeElement !== firstTitleWrapper.current) {
        secondTitleWrapper.current.tabIndex = -1;
        firstTitleWrapper.current.tabIndex = 0;
        firstTitleWrapper.current.focus();
      } else {
        firstTitleWrapper.current.tabIndex = -1;
        secondTitleWrapper.current.tabIndex = 0;
        secondTitleWrapper.current.focus();
      }
    };

    if (hideHeader) {
      return (
        <>
          <DuelHeaderWrapper />

          {children}

          <CloseButton onClick={onClick} aria-label={t('close')}>
            <CloseButtonSVGIcon />
          </CloseButton>
        </>
      );
    }

    return (
      <>
        <DuelHeaderWrapper>
          <TabListContainer id="modal-title" aria-label={t('login_or_register')} onKeyDown={handleTabPanelKeys}>
            <DualTitleButton
              onClick={onSecondTabClick}
              isActive={!isFirstTabActive}
              aria-selected={!isFirstTabActive}
              id="register_tab_button"
              aria-controls="register_tab_controls"
              tabIndex={!isFirstTabActive ? 0 : -1}
              ref={secondTitleWrapper}
            >
              <DualTitle isActive={!isFirstTabActive} data-id={secondTitleKey}>
                {t(secondTitleKey)}
              </DualTitle>
            </DualTitleButton>

            <DualTitleButton
              onClick={onFirstTabClick}
              isActive={isFirstTabActive}
              aria-selected={isFirstTabActive}
              id="login_tab_button"
              aria-controls="login_tab_controls"
              tabIndex={isFirstTabActive ? 0 : -1}
              ref={firstTitleWrapper}
            >
              <DualTitle isActive={isFirstTabActive} data-id={titleKey}>
                {t(titleKey)}
              </DualTitle>
            </DualTitleButton>
          </TabListContainer>
        </DuelHeaderWrapper>

        {children}

        <CloseButton
          withBackground={!showBackArrow}
          isDual
          onClick={onClick}
          aria-label={t(showBackArrow ? 'back' : 'close')}
          flipDirection={showBackArrow}
        >
          {showBackArrow ? (
            <BackButtonImage alt={t('back')} isContrastActive={isContrastActive} />
          ) : (
            <CloseButtonSVGIcon />
          )}
        </CloseButton>
      </>
    );
  },
);
