import {getLocalizationService} from '~/shared/services/localisationService';

export const TIME_RANGE_DELIMITER = ' - ';

type ValidTimeRange = `${string} - ${string}`;

export const isValidTimeRangeString = (str?: string | null): str is ValidTimeRange =>
  typeof str === 'string' && str.includes(TIME_RANGE_DELIMITER);

export const getTimeRangeLabel = (timeRange: ValidTimeRange): string => {
  const {currentLanguageDirection} = getLocalizationService();

  if (currentLanguageDirection === 'ltr') {
    return timeRange;
  }

  return timeRange.split(TIME_RANGE_DELIMITER).reverse().join(TIME_RANGE_DELIMITER);
};

export const isDuplicatedTimeRange = (timeRange: string | null): boolean => {
  if (!timeRange) {
    return false;
  }
  
  const [startTime, endTime] = timeRange.split(TIME_RANGE_DELIMITER);
  return startTime === endTime;
};
