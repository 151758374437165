import {combineReducers} from 'redux';
import {without, isEqual} from 'lodash';

import {constructCheckoutPayments, sortPaymentsByPriority} from '~/shared/utils/payments';

import * as sharedActions from '../restrictedSharedActions';
import {composeReducers, makeReducer, makeAsyncReducer, StateAsyncProperty, GenericAction} from '../../redux-toolbelt';
import {CheckoutPayment, LinkMoneycardToUserRequest, Payment} from '../../models';

import {
  addPayment,
  removePayment,
  updatePayment,
  setPayments,
  getLinkMoneycardToUserRequests,
  addApprovedPaymentCallout,
  removeApprovedPaymentCallout,
  setOrderPayments,
  getAvailablePayments,
  GetAvailablePaymentsReturnType,
  clearPayments,
  setShouldAvoidPaymentsRefetch,
  toggleDisable10BisCredit,
} from './paymentsActions';

export interface PaymentsState {
  availablePayments: StateAsyncProperty<Payment[]>;
  orderPayments: Payment[]; // Todo: seems to not be used anywhere - revise and remove if that's indeed the case
  checkoutPayments: CheckoutPayment[];
  shouldAvoidPaymentsRefetch: boolean;
  linkMoneycardRequests: StateAsyncProperty<LinkMoneycardToUserRequest[]>;
  linkMoneycardRequestsCallouts: LinkMoneycardToUserRequest[];
}

const paymentsDefaultState: PaymentsState = {
  availablePayments: {loading: false, loaded: false},
  orderPayments: [],
  checkoutPayments: [],
  shouldAvoidPaymentsRefetch: false,
  linkMoneycardRequests: {loading: false, loaded: false},
  linkMoneycardRequestsCallouts: [],
};

const extraPropsForNewCheckoutPayments = {
  assigned: true,
  editMode: false,
};
export default composeReducers<PaymentsState>(
  makeReducer<PaymentsState>(sharedActions.clearOrderData, () => undefined, {
    defaultState: paymentsDefaultState,
  }),
  combineReducers<PaymentsState>({
    availablePayments: makeAsyncReducer<PaymentsState['availablePayments']>(getAvailablePayments, {
      dataGetter: (state, {payload}: GenericAction<GetAvailablePaymentsReturnType>) => payload?.availablePayments,
      defaultData: [],
      shouldDestroyData: false,
    }),
    orderPayments: makeReducer(setOrderPayments),
    checkoutPayments: makeReducer<PaymentsState['checkoutPayments']>(
      {
        [toggleDisable10BisCredit.TYPE]: currentPayments => {
          return currentPayments.map(currentPayment => {
            if (!currentPayment.isTenbisCredit) {
              return currentPayment;
            }
            return {...currentPayment, isDisabled: !currentPayment.isDisabled};
          });
        },
        [addPayment.TYPE]: (currentPayments, {payload}: ReturnType<typeof addPayment>) => {
          const {newPayment, userData} = payload;
          if (!newPayment) {
            return currentPayments;
          }
          const existingPaymentId = currentPayments.findIndex(cp => cp.cardId === newPayment.cardId);
          if (existingPaymentId !== -1) {
            return [
              ...currentPayments.slice(0, existingPaymentId),
              newPayment,
              ...currentPayments.slice(existingPaymentId + 1),
            ];
          }

          const paymentToAdd: CheckoutPayment = {
            ...newPayment,
            ...extraPropsForNewCheckoutPayments,
            sum: 0,
          };

          const filteredPayments = (userData?.isCompanyUser)
            ? [...currentPayments]
            : currentPayments.filter(({isTenbisCredit}) => isTenbisCredit);

          const sortedNewPayments = sortPaymentsByPriority([...filteredPayments, paymentToAdd], userData);
          return sortedNewPayments;
        },
        [removePayment.TYPE]: (currentPayments, {payload: targetCardId}: ReturnType<typeof removePayment>) =>
          currentPayments.filter(({cardId}) => cardId !== targetCardId),
        [updatePayment.TYPE]: (currentPayments, {payload: updatedPayment}: ReturnType<typeof updatePayment>) =>
          currentPayments.map(payment => (payment.cardId === updatedPayment?.cardId ? updatedPayment : payment)),
        [setPayments.TYPE]: (currentPayments, {payload: newPayments}: ReturnType<typeof setPayments>) => {
          if (isEqual(currentPayments, newPayments) || !newPayments) {
            return currentPayments;
          }

          return newPayments;
        },
        [clearPayments.TYPE]: () => [],
        [getAvailablePayments.success.TYPE]: (
          currentCheckoutPayments,
          {payload}: GenericAction<GetAvailablePaymentsReturnType>,
        ) => {
          const {availablePayments, userData} = payload || {};
          const newOrderPayments = constructCheckoutPayments(
            availablePayments,
            currentCheckoutPayments,
            extraPropsForNewCheckoutPayments,
            userData,
          );
          return newOrderPayments.map(payment => ({
            ...payment,
            ...extraPropsForNewCheckoutPayments,
          }));
        },
      },
      {defaultState: []},
    ),
    shouldAvoidPaymentsRefetch: makeReducer(setShouldAvoidPaymentsRefetch, (_, {payload}) => payload),
    linkMoneycardRequests: makeAsyncReducer(getLinkMoneycardToUserRequests, {
      shouldDestroyData: false,
      defaultData: [],
    }),
    linkMoneycardRequestsCallouts: makeReducer<
      PaymentsState['linkMoneycardRequestsCallouts'],
      ReturnType<typeof addApprovedPaymentCallout>['payload']
    >(
      {
        [addApprovedPaymentCallout.TYPE]: (currentState, {payload: newApprovedMoneycard}) =>
          (newApprovedMoneycard ? [...currentState, ...newApprovedMoneycard] : currentState),
        [removeApprovedPaymentCallout.TYPE]: currentState => without(currentState, currentState[0]),
      },
      {defaultState: []},
    ),
  }),
);
