import {useCallback, useState, memo} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import actions from '~/shared/store/actions';
import {selectCurrentModal} from '~/shared/store/selectors';
import {Modal} from '~/shared/components/Modals';

import {useAutoFocus} from '../hooks';
import {useIsMaxLargeMobile} from '../hooks/deviceInfo';

const modalsComponentsMap = {};

export const attachModal = (name, component) => {
  modalsComponentsMap[name] = component;
};

const ReduxModal = ({disableEruptModals, ...otherProps}) => {
  const dispatch = useDispatch();
  const [modalRootDomEl, setModalRootDomEl] = useState();
  const containerRef = useCallback(domEl => setModalRootDomEl(domEl), []);
  const currentModal = useSelector(selectCurrentModal);
  const isMaxLargeMobile = useIsMaxLargeMobile();

  useAutoFocus({domEl: modalRootDomEl, triggerCondition: currentModal && !isMaxLargeMobile});

  const ModalsComponent = modalsComponentsMap[currentModal?.name];
  const modalOptions = currentModal?.args?.modalOptions;

  const closeModal = (...args) => {
    if (modalOptions?.closeModal) {
      modalOptions.closeModal(...args);
    }
    
    dispatch(actions.setCurrentModal(null));
    
    const {onClose} = ModalsComponent;
    if (onClose) {
      onClose();
    }
  };

  const scrollModalToBottom = () => {
    if (containerRef) {
      containerRef.scrollTop = containerRef.scrollHeight;
    }
  };

  if (!currentModal) {
    return null;
  }

  return (
    <Modal
      ref={containerRef}
      isOpen
      closeModal={closeModal}
      {...ModalsComponent}
      {...modalOptions}
      disableEruptModals={disableEruptModals}
    >
      <ModalsComponent
        scrollModalToBottom={scrollModalToBottom}
        closeModal={closeModal}
        args={currentModal?.args}
        {...otherProps}
      />
    </Modal>
  );
};

export default memo(ReduxModal);
