import {memo} from 'react';

import {Field} from 'react-final-form';
import styled, {css} from 'styled-components';

import {validators, composeValidators} from '~/shared/utils/validators';
import {getLocalizationService} from '~/shared/services/localisationService';
import getFieldInputByType from '~/shared/components/FormField/getFieldInputByType';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import rSelectStyleOverride from '~/shared/components/FormField/rSelectStyleOverride';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {body13Normal} from '~/shared/theme/typography';
import {getIsKeyboardInUse} from '~/shared/usingKeyboard/keyboardHelper';

const FieldRoot = styled.div`
  ${flexColumn};
  flex: 1;
  height: 72px;
  margin-bottom: 16px;
  ${({fullHeight}) =>
    fullHeight &&
    css`
      height: 100%;
    `}
  ${rSelectStyleOverride};
`;

const ErrorText = styled.div.attrs(() => ({
  role: 'alert',
}))`
  position: relative;
  bottom: 0;
  margin-top: 8px;
  width: 100%;
  line-height: 1em;
  ${body13Normal};
  color: ${({theme}) => theme.colors.error};
  ${flipOnLTR`
    text-align: right;
  `}
`;

const onKeyDown = e => {
  if (e.keyCode !== 13) {
    return;
  }
  e.persist();
  e?.target?.blur();
  if (!getIsKeyboardInUse()) {
    e?.target?.form?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}));
  }
};

const FormField = ({
  name,
  required,
  placeholder,
  labelledBy,
  validator,
  type,
  inputProps,
  className,
  shouldShowError = true,
  selectOnFocus,
  hidden,
  readonlyField,
  render,
  shouldUseMetaModified,
  withBorder,
  onFocus,
  ...rest
}) => {
  const {component, inputType, inputValidator, autoComplete} = getFieldInputByType(type);
  const Component = render || component;
  const {t} = getLocalizationService();

  return (
    <Field
      name={name}
      validate={!hidden && composeValidators(required ? validators.requiredValidator : null, inputValidator, validator)}
      render={({input, meta}) => {
        const hasError =
          shouldShowError &&
          !meta.active &&
          meta.error &&
          (meta.dirty || (shouldUseMetaModified ? meta.modified : meta.touched) || meta.submitFailed);

        return (
          !hidden && (
            <FieldRoot {...{fullHeight: inputType === 'textarea', className}} onFocus={onFocus}>
              <Component
                {...input}
                {...{onKeyDown, autoComplete, selectOnFocus, required, hasError}}
                aria-required={!!required}
                aria-labelledby={labelledBy}
                aria-describedby={hasError ? `${name}-error` : null}
                id={name}
                active={meta.active}
                type={inputType}
                readOnly={readonlyField}
                placeholder={placeholder}
                withBorder={withBorder}
                {...inputProps}
                {...(render ? rest : {})}
              />
              {hasError && <ErrorText aria-label={`${t(name)}`}>{t(meta.error)}</ErrorText>}
            </FieldRoot>
          )
        );
      }}
    />
  );
};

export default memo(FormField);
