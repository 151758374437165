import {memo, createElement} from 'react';

import URI from 'urijs';
import {Helmet} from 'react-helmet';

import {websiteUrl, basedir} from '~/shared/config';
import {getCurrentLocation} from '~/shared/router';
import {SupportedLanguages, DEFAULT_LANGUAGE_KEY} from '~/shared/consts/commonConsts';
import {changeLanguagePrefixToUrlOrPath, getLocalizationService} from '~/shared/services/localisationService';

const getHrefLangKey = languageKey => `hrefLang-${languageKey}`;

const MetaHeaders = memo(
  ({title, description, canonicalUrl: canonicalUrlFromArgs, canonicalPath, keywords, imageUrl, hreflangs}) => {
    const {pathname, hostname} = getCurrentLocation();
    const {currentLanguageKey} = getLocalizationService();
    const showNoIndexMetaTag = !hostname.startsWith('www.');

    const canonicalUrl =
      canonicalUrlFromArgs ||
      URI(
        `${websiteUrl}${basedir}${currentLanguageKey === DEFAULT_LANGUAGE_KEY ? '' : `${currentLanguageKey}/`}${
          canonicalPath || pathname
        }`,
      )
        .normalize()
        .toString();

    return (
      <>
        {showNoIndexMetaTag && (
          <Helmet>
            <meta name="robots" content="noindex,nofollow" />
          </Helmet>
        )}
        {title && (
          <Helmet>
            <title key="title">{title}</title>
            <meta key="meta_og_title" property="og:title" content={title} />
          </Helmet>
        )}
        {description && (
          <Helmet>
            <meta key="meta_description" name="description" content={description} />
            <meta key="meta_og_description" property="og:description" content={description} />
          </Helmet>
        )}
        {canonicalUrl && (
          <Helmet>
            <meta key="meta_og_url" property="og:url" content={canonicalUrl} />
            <link key="canonical" rel="canonical" href={canonicalUrl} />
          </Helmet>
        )}
        {keywords && (
          <Helmet>
            <meta key="meta_keywords" name="keywords" content={keywords} />
          </Helmet>
        )}
        {imageUrl && (
          <Helmet>
            <meta key="meta_og_image" property="og:image" content={imageUrl} />
            <meta key="meta_og_image_width" property="og:image:width" content="75" />
            <meta key="meta_og_image_height" property="og:image:height" content="75" />
          </Helmet>
        )}
        {hreflangs &&
          createElement(
            Helmet,
            null,
            ...Object.entries(hreflangs).map(([languageKey, hrefForLanguage]) => {
              return (
                <link key={getHrefLangKey(languageKey)} href={hrefForLanguage} rel="alternate" hrefLang={languageKey} />
              );
            }),
          )}
        {!hreflangs &&
          createElement(
            Helmet,
            null,
            ...SupportedLanguages.map(({key: languageKey}) => {
              const href = changeLanguagePrefixToUrlOrPath(canonicalUrl, languageKey);
              return <link key={getHrefLangKey(languageKey)} href={href} rel="alternate" hrefLang={languageKey} />;
            }),
          )}
      </>
    );
  },
);

export default MetaHeaders;
