import {ApiResponse} from '~/shared/services/apiService';
import {api} from '~/shared/services/bffService';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

import {Order, OrderPermits} from '../../models';
import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';
import {MutatedFutureOrderAvailableDatesAndTimes} from '../../models/FutureOrderAvailableDatesAndTimes';

import {OrderState} from './orderReducer';

export const clearOrderViewResponse = makeActionCreator<'clearOrderViewResponse', never>('clearOrderViewResponse');

export const clearCurrentOrderDateAndTime = makeActionCreator<'clearCurrentOrderDateAndTime', never>(
  'clearCurrentOrderDateAndTime',
);

export const submitOrder = makeThunkAsyncActionCreator<SubmitOrderPayload, ApiResponse<Order>>(
  'submitOrder',
  async (orderToSubmit, {apiService}) => apiService.submitOrder(orderToSubmit),
);

export const setDinningRoomNoPackingRequired = makeActionCreator<'setDinningRoomNoPackingRequired', boolean>(
  'setDinningRoomNoPackingRequired',
);

export const setNotesForClient = makeActionCreator<'setNotesForClient', string>('setNotesForClient');

export const setOrderDontWantCutlery = makeActionCreator<'dontWantCutlery', boolean>('dontWantCutlery');
export const setCurrentOrderDateAndTime = makeActionCreator<
  'setCurrentOrderDateAndTime',
  {
    orderDate: MutatedFutureOrderAvailableDatesAndTimes;
    orderTime: MutatedFutureOrderAvailableDatesAndTimes['times'][number];
  }
>('setCurrentOrderDateAndTime');
export const setOrderPermit = makeActionCreator<'setOrderPermit', OrderPermits>('setOrderPermit');

export const setOrderRemarks = makeActionCreator<'setOrderRemarks', string>('setOrderRemarks');
export const setRemarksToPresent = makeActionCreator<'setRemarksToPresent', string>('setRemarksToPresent');
export const setDiningRoomBuilding = makeActionCreator('setDiningRoomBuilding');
export const setDiningRoomFloor = makeActionCreator('setDiningRoomFloor');

export const setIsReorder = makeActionCreator('setIsReorder');

export const setDeliveryRule = makeActionCreator<'setDeliveryRule', DELIVERY_RULES>('setDeliveryRule');

export const getOrderSuccessData = makeThunkAsyncActionCreator<{orderId?: number}, OrderState['successData']['data']>(
  'getOrderSuccessData',
  async ({orderId}) => {
    if (!orderId) {
      return;
    }
    const {dishes, ...data} = await api.fetchOrderData({orderId});
    const modifiedOrderData: OrderState['successData']['data'] = {
      ...data,
      dishToPresent: dishes,
    };
    return modifiedOrderData;
  },
);

export interface SubmitOrderPayload {
  shoppingCartGuid: string;
  dontWantCutlery: boolean;
  orderRemarks: string;
}

export const setInitialOrder = makeActionCreator<'setInitialOrder', {
  isInitialOrder: boolean;
  restaurantId?: number;
}>('setInitialOrder');
