import {useState, memo} from 'react';

import styled from 'styled-components';

import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import usePreventBodyScrolling from '~/shared/hooks/usePreventBodyScrolling';
import {getLocalizationService} from '~/shared/services/localisationService';
import hamburgerButtonUrl from '~/assets/images/homepage/hamburger.svg';
import Button from '~/shared/components/Button';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

import UserStatus from '../UserStatus';

import ActionMenuContent from './ActionMenuContent';
import {ActionMenuLink} from './types';

const ActionMenuButton = styled(Button)`
  ${flexCenterVertically};
  cursor: pointer;
  color: ${({theme}) => theme.colors.primaryText};
`;

const MenuBurgerButton = styled(ImageWithAlt).attrs({
  src: hamburgerButtonUrl,
})`
  display: block;
  cursor: pointer;
  width: 20px;
  height: 20px;
  ${flipOnLTR`
    margin-right: 8px;
  `}
`;

const ActionMenu = ({actionMenuLinks, burgerMenu} : {actionMenuLinks: ActionMenuLink[]; burgerMenu: boolean}) => {
  const {t} = getLocalizationService();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenuOpen = (clickEvent: Event) => {
    if (clickEvent) {
      clickEvent.preventDefault();
    }

    setIsOpen(!isOpen);
  };

  usePreventBodyScrolling({shouldPreventScroll: !!isOpen});

  return (
    <>
      <ActionMenuButton
        isOpen={isOpen}
        onClick={toggleMenuOpen}
        aria-controls="header-action-menu-container"
        isExpandable
      >
        {!burgerMenu ? <UserStatus /> : <MenuBurgerButton alt={t('menu')}/>}
      </ActionMenuButton>
      {isOpen && <ActionMenuContent actionMenuLinks={actionMenuLinks} toggleMenuOpen={toggleMenuOpen} burgerMenu={burgerMenu} />}
    </>
  );
};

export default memo(ActionMenu);
