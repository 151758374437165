import styled from 'styled-components';
import {times} from 'lodash';

import {Skeleton} from '~/shared/components/Loaders';
import {media} from '~/shared/theme/media';
import {useIsMinLargeTablet} from '~/shared/hooks/deviceInfo';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexColumn, FlexColumn, FlexSpace, FlexStart} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {body18Bold, body18Normal} from '~/shared/theme/typography';

const Root = styled.div`
  width: 100%;
  ${flexColumn};
  flex: 1;
  color: ${({theme}) => theme.colors.secondary};
  font-weight: bold;
  ${media.minLargeTablet`
    flex: inherit;
  `}
`;

const Header = styled.header`
  ${body18Normal};
  color: ${({theme}) => theme.colors.secondary};
  font-family: TakeawaySans, sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${flipOnLTR`
    padding-right: 16px;
  `}
  ${media.minLargeTablet`
    align-items: center;
    min-height: 71px;
    line-height: 71px;
    border-bottom: 1px solid ${({theme}) => theme.colors.darkBackground};
  `}
`;

const WhiteContainer = styled.div`
  background-color: ${({theme}) => theme.colors.surface};
  padding: 16px;
  margin-top: ${({isMinLargeTablet}) => (isMinLargeTablet ? 16 : 0)}px;
  margin-bottom: 10px;
  ${media.minLargeTablet`
    margin: 0;
  `}
`;
const RestaurantNameSkeleton = styled(Skeleton)`
  width: 150px;
  height: 20px;
  display: inline-block;
  margin: 0 5px -4px;
`;

const SkeletonSpace = styled.div`
  width: 4px;
  ${media.minLargeTablet`width: 16px;`}
`;

const SkeletonSecondLine = styled(Skeleton)`
  margin-top: 4px;
`;

const ItemLine = styled.div`
  &:not(:first-child) {
    margin-top: 16px;
  }

  &:not(:last-child) {
    padding-bottom: 38px;
    border-bottom: 1px solid ${({theme}) => theme.colors.darkBackground};
  }
`;

const Title = styled.h3`
  ${body18Bold};
  line-height: 18px;
  margin-bottom: 16px;
`;

const CheckoutLoaderComponent = () => {
  const isMinLargeTablet = useIsMinLargeTablet();
  const {t} = getLocalizationService();

  return (
    <Root>
      {isMinLargeTablet && (
        <Header as="h1">
          {t('your_order_from')}
          <RestaurantNameSkeleton />
        </Header>
      )}
      <WhiteContainer isMinLargeTablet={isMinLargeTablet}>
        {times(2, index => (
          <ItemLine key={index}>
            {!isMinLargeTablet && index === 0 && <Title>{t('your_cart')}</Title>}

            <FlexSpace>
              <FlexStart>
                <Skeleton width={isMinLargeTablet ? 74 : 17} height={isMinLargeTablet ? 26 : 17} />
                <SkeletonSpace />

                <FlexColumn>
                  <Skeleton width={160} height={18} />
                  <SkeletonSecondLine width={80} height={18} />
                </FlexColumn>
              </FlexStart>
              <Skeleton width={60} height={18} />
            </FlexSpace>
          </ItemLine>
        ))}
      </WhiteContainer>
    </Root>
  );
};

export default CheckoutLoaderComponent;
