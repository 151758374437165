import {useField} from 'react-final-form';

import {getDishTotalPrice} from '~/shared/utils/billingLinesCalculation';

import {DishType, EncodedChoicesType} from '../modals/DishType';
import {decodeChoices} from '../utils/choices';

const useTotalPrice = () => {
  const {
    input: {value: dishPrice},
  } = useField<number>('dishPrice');

  const {
    input: {value: choices},
  } = useField<DishType['choices']>('choices');

  const {
    input: {value: selectedChoices},
  } = useField<EncodedChoicesType>('selectedChoices');

  const {
    input: {value: quantity},
  } = useField<number>('quantity');

  const decodedSelectedChoices = decodeChoices(selectedChoices || {});
  const totalDishPrice = getDishTotalPrice({dishPrice, choices}, decodedSelectedChoices, quantity);

  return totalDishPrice;
};

export default useTotalPrice;
