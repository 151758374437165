import {useState, useRef, useEffect, memo} from 'react';

import {useSelector, useDispatch} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {createLogger} from '~/shared/logging';
import actions from '~/shared/store/actions';
import {useRouteQuery} from '~/shared/router';
import FocusTrapDiv from '~/shared/components/FocusTrapDiv';
import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {selectA11yContrast, selectA11yOutline, selectIsActiveBannersShown} from '~/shared/store/selectors';
import {trackEvent} from '~/shared/services/analytics';

import {Root, A11yButtonOpener, A11yMenuIcon} from './styled';
import A11yMenu from './A11yMenu';

const logger = createLogger('a11yHelper');

const A11yHelper = memo(() => {
  const a11yMenuRootRef = useRef();
  const isBannersShown = useSelector(selectIsActiveBannersShown);
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const {embedded: isEmbedded} = useRouteQuery();
  const {t} = getLocalizationService();
  const isContrastActive = useSelector(selectA11yContrast);
  const isOutlineActive = useSelector(selectA11yOutline);
  const isMaxLargeMobile = useIsMaxLargeMobile();

  useEffect(() => {
    if (!isOpen || isMaxLargeMobile) {
      return;
    }

    // on a11y menu open focus on the first a11y inner menu option button (relevant for desktop only).
    try {
      a11yMenuRootRef.current.getElementsByTagName('button')[1].focus();
    } catch (e) {
      // eslint-disable-next-line no-console, quotes
      logger.error("couldn't focus on button", e);
    }
  }, [a11yMenuRootRef, isOpen, isMaxLargeMobile]);

  const toggleContrastMode = () => {
    dispatch(actions.toggleContrastMode());
    trackEvent('hasClickedAccessibilityMenu', {
      accessibilityOption: 'contrast',
      accessibilityStatus: Number(!isContrastActive),
    });
  };

  const toggleOutlineMode = () => {
    dispatch(actions.toggleOutlieMode());
    trackEvent('hasClickedAccessibilityMenu', {
      accessibilityOption: 'button',
      accessibilityStatus: Number(!isOutlineActive),
    });
  };

  const closeMenu = () => {
    setIsOpen(false);
    trackEvent('hasClosedAccessibilityMenu');
  };

  const openMenu = () => {
    setIsOpen(true);
    trackEvent('hasOpenAccessibilityMenu');
  };

  const onA11yOpenerButtonClick = () => {
    if (isMaxLargeMobile) {
      toggleContrastMode();
      return;
    }

    openMenu();
  };

  if (isEmbedded) {
    return null;
  }

  return (
    <Root isBannersShown={isBannersShown}>
      <A11yButtonOpener
        onClick={onA11yOpenerButtonClick}
        isOpen={isOpen}
        isExpandable
        aria-controls="a11y-helper-menu"
        aria-label={t('open_the_10Bis_site_accessibility_aids')}
      >
        <A11yMenuIcon alt={t('accessibility_menu')} />
      </A11yButtonOpener>
      {!isMaxLargeMobile && (
        <FocusTrapDiv>
          <A11yMenu
            id="a11y-helper-menu"
            isOpen={isOpen}
            ref={a11yMenuRootRef}
            isContrastActive={isContrastActive}
            isOutlineActive={isOutlineActive}
            toggleContrastMode={toggleContrastMode}
            toggleOutlineMode={toggleOutlineMode}
            closeMenu={closeMenu}
          />
        </FocusTrapDiv>
      )}
    </Root>
  );
});

export default A11yHelper;
