import {memo, useMemo, useEffect, useRef} from 'react';

import styled from 'styled-components';
import {isEmpty} from 'lodash';
import {useSelector} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {getErrorByActionSuggestion} from '~/shared/utils/checkout';
import {openOtlModalOrPage, openPaymentsModalOrPage} from '~/shared/services/navigation';
import {flipOnLTR} from '~/shared/theme/utils';
import {body14Normal} from '~/shared/theme/typography';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {selectOrderError} from '~/shared/store/selectors';

const Error = styled.div`
  ${flexColumn};
  flex: 1;
  border: 1px solid ${({theme}) => theme.colors.error};
  color: ${({theme}) => theme.colors.error};
  padding: 11px 8px;
  ${body14Normal};
  line-height: 14px;
`;

const ErrorMessage = styled.div``;

const ActionLink = styled.div`
  color: ${({theme}) => theme.checkout.errors.actionLink};
  text-decoration: underline;
  cursor: pointer;
  ${flipOnLTR`
    margin-right: 5px;
  `}
  display: inline;
`;

const handleScrollToError = (shouldShowError, errorMsgRef) => {
  if (shouldShowError && errorMsgRef) {
    errorMsgRef.scrollIntoView(false);
  }
};

const CheckoutError = ({orderFailure, showPaymentsRemarksError, customPaymentRemarkError, preSubmitErrors, isThreeDSFailed}) => {
  const {t} = getLocalizationService();
  const orderError = useSelector(selectOrderError);
  const formatedOrderError = useMemo(() => ({errorDesc: orderError?.message}), [orderError]);

  const paymentsRemarkAreMissingMessage = t(customPaymentRemarkError || 'attention_need_to_enter_account_details');

  const errors = useMemo(() => {
    if (isThreeDSFailed) {
      return [{errorDesc: t('3dsErrorMessage')}];
    }
    const result = formatedOrderError.errorDesc ? [formatedOrderError] : [];
    if (preSubmitErrors?.length) {
      result.push(...preSubmitErrors);
    }
    if (showPaymentsRemarksError) {
      result.push({errorDesc: paymentsRemarkAreMissingMessage});
    }

    return result;
  }, [formatedOrderError, paymentsRemarkAreMissingMessage, preSubmitErrors, showPaymentsRemarksError, isThreeDSFailed, t]);

  const errorMsgRef = useRef(null);

  useEffect(() => {
    handleScrollToError(!isEmpty(errors), errorMsgRef.current);
  }, [errors, errorMsgRef]);

  const errorActionSuggestion = useMemo(() => {
    return getErrorByActionSuggestion({
      actionSuggestionType: orderFailure?.actionSuggestionType,
      openCreditCard: () => openPaymentsModalOrPage(),
      openOtlSettings: openOtlModalOrPage,
    });
  }, [orderFailure]);

  const shouldShowError = errors.length || errorActionSuggestion;
  if (!shouldShowError) {
    return null;
  }

  return (
    <Error>
      {errors &&
        errors.map(({errorDesc}) => (
          <ErrorMessage ref={errorMsgRef} key={errorDesc}>
            {errorDesc}
          </ErrorMessage>
        ))}
      {errorActionSuggestion && (
        <ErrorMessage>
          {t(errorActionSuggestion.text)}
          <ActionLink onClick={errorActionSuggestion.action}>{t('right_here')}</ActionLink>
        </ErrorMessage>
      )}
    </Error>
  );
};

export default memo(CheckoutError);
