import {useSelector} from 'react-redux';

import BillingLinesView, {IBillingLinesView} from '~/shared/components/BillingLines';
import {selectCurrentModalName, selectIsBenefitsHighlightEnabled} from '~/shared/store/selectors';

const BillingLines = (props: Omit<IBillingLinesView, 'currentModalName'>) => {
  const currentModalName = useSelector(selectCurrentModalName);
  const isUserBenefitsHighlightEnabled = useSelector(selectIsBenefitsHighlightEnabled);
  return (
    <BillingLinesView
      currentModalName={currentModalName}
      isUserBenefitsHighlightEnabled={isUserBenefitsHighlightEnabled}
      {...props}
    />
  );
};

export default BillingLines;
