import {createSelector} from 'reselect';

import {AppState} from '../../configStore';

const selectAgeRestriction = (state: AppState) => state.ageRestriction;

export const selectActionMadeFrom = createSelector(
  selectAgeRestriction,
  ageRestriction => ageRestriction.actionMadeFrom,
);

export const selectIsAgeConfirmed = createSelector(
  selectAgeRestriction,
  ageRestriction => ageRestriction.isConfirmed,
);
