import {memo, useState} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {media} from '~/shared/theme/media';
import {body14Bold, body14Normal} from '~/shared/theme/typography';
import {Section} from '~/shared/components/OrderCompleteSections/parts';
import {defaultPaddingCss} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';

import useIsShowScooberTrackerOrLink from '../hooks/useIsShowScooberTrackerOrLink';

const StyledTrackerLinkSection = styled(Section)`
  ${defaultPaddingCss};
`;

const CopyLinkButton = styled.button`
  text-decoration: none;
  ${body14Bold};
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: ${({theme}) => theme.colors.secondary};
  align-self: center;
  margin-left: 20px;
  ${flipOnLTR`
    padding-left: 0;
    margin-left: 0;
  `}
  ${media.minLargeTablet`
    margin-right: 0;
    padding-left: 0;
    ${flipOnLTR`
      margin-left: 0;
      padding-right: 20px;
    `}  
  `}

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }
`;

const ToolTipContainer = styled.span`
  width: 65px;
  background-color: ${({theme}) => theme.colors.gray000};
  color: ${({theme}) => theme.colors.secondaryText};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 10;
  margin-top: 21px;
  margin-left: -65px;
  ${flipOnLTR`
  margin-right: -65px;
`}

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    left: 25px;
    width: 0;
    height: 0;
    border-color: transparent transparent ${({theme}) => theme.colors.gray000} transparent;
    border-style: solid;
    border-width: 10px;
  }
`;

const TrackerLinkContainer = styled.div`
  border-radius: 1px;
  border: solid 1px ${({theme}) => theme.colors.background};
  margin-top: 8px;
  display: flex;
`;

const TrackerUrl = styled.div`
  ${body14Normal};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: justify;
  width: 263px;
  color: ${({theme}) => theme.colors.text};
  word-break: break-all;
  margin-top: 8px;
  margin-bottom: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: 10px;
  ${flipOnLTR`
    margin-right: 10px;
    margin-left: 0;
    width: 235px;
  `}
  ${media.minLargeTablet`
    width: 380px;
  `}
`;

const TrackerLinkSection = () => {
  const {t} = getLocalizationService();

  const [currentTooltipMode, setCurrentTooltipMode] = useState(false);

  const {isTracking, trackerUrl} = useIsShowScooberTrackerOrLink();

  const writeToClipboard = () => {
    if (navigator.clipboard && trackerUrl) {
      navigator.clipboard.writeText(trackerUrl);
      setCurrentTooltipMode(true);
    }
  };

  if (!isTracking) {
    return null;
  }
  return (
    <StyledTrackerLinkSection title={t('share_order_status')}>
      <TrackerLinkContainer>
        <TrackerUrl>{trackerUrl}</TrackerUrl>
        <CopyLinkButton
          onClick={writeToClipboard}
          onMouseLeave={() => {
            setCurrentTooltipMode(false);
          }}
          onBlur={() => {
            setCurrentTooltipMode(false);
          }}
        >
          {t('copy_link')}

          {currentTooltipMode && <ToolTipContainer>{t('copied')}</ToolTipContainer>}
        </CopyLinkButton>
      </TrackerLinkContainer>
    </StyledTrackerLinkSection>
  );
};

export default memo(TrackerLinkSection);
