import {api} from '~/shared/services/bffService';

import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {OrderSummaryState} from './orderSummaryReducer';

export const setOrderSummaryId = makeActionCreator('setOrderSummaryId');

export const fetchOrderSummaryIfNeeded = makeThunkAsyncActionCreator<{orderId: number}, OrderSummaryState['data']>(
  'fetchOrderSummaryIfNeeded',
  async ({orderId}) => {
    const {dishes, ...data} = await api.fetchOrderData({orderId});
    return {...data, dishToPresent: dishes};
  },
);
