import {baseTheme} from '~/shared/theme';
import {getPooledGroupTimesInfo} from '~/shared/utils/pooledGroupTimesInfo';
import {RestaurantFromSearch, RestaurantList} from '~/shared/store/models';
import {Carousel} from '~/shared/store/models/Carousel';
import RestaurantSortOptions from '~/shared/store/selectors/selectorUtils/restaurantSortOptions';
import {RestaurantsFiltersSortOptionsTypes} from '~/shared/consts/restaurantConsts';

import {selectRestaurantsMainListData} from '..';
import store from '../..';

type CollectionItem = {
  restaurantItem: RestaurantFromSearch;
  key: string;
  groupId: string;
  itemIndexInGroup: number;
};

const getCarouselGroupHeight = (isMinLargeMobile: boolean, isMinLargeTablet: boolean, isHighlightInfo: boolean) => {
  if (isMinLargeTablet) {
    return baseTheme.carouselHeight[isHighlightInfo ? 'desktopHighlight' : 'desktop'];
  }

  if (isMinLargeMobile) {
    return baseTheme.carouselHeight[isHighlightInfo ? 'tabletHighlight' : 'tablet'];
  }

  return baseTheme.carouselHeight[isHighlightInfo ? 'mobileHighlight' : 'mobile'];
};

const makeCarouselGroups = (
  collectionsList: Carousel[] | undefined,
  filteredRes: RestaurantList | Record<string, never>,
  currentSort: RestaurantsFiltersSortOptionsTypes,
  isMinLargeMobile: boolean,
  isMinLargeTablet: boolean,
) => {
  if (!collectionsList) {
    return [];
  }

  const sorter = RestaurantSortOptions.find(({id}) => id === currentSort);

  return collectionsList.map((collection, carouselIndex) => {
    const {id, title, iconUrl, orderTypeViewTags, miniFeedDesc, name, highlightInfo} = collection;
    const groupId = name.toLowerCase();
    const restaurantsByCollectionOrder = collection.resIds.reduce<CollectionItem[]>((collectionItems, resId) => {
      const restaurant = filteredRes.restaurantsList.find(({restaurantId}) => restaurantId === resId);

      if (!restaurant) {
        return collectionItems;
      }

      return [...collectionItems, {
        restaurantItem: {...restaurant, orderTypeViewTags},
        key: `${id}_${restaurant.restaurantId}`,
        groupId,
        itemIndexInGroup: collectionItems.length,
      }];
    }, []);

    if (sorter) {
      restaurantsByCollectionOrder.sort((a, b) => sorter.compare(a.restaurantItem, b.restaurantItem));
    }

    return {
      carouselIndex,
      id,
      title,
      orderTypeViewTags,
      subtitle: miniFeedDesc,
      iconUrl,
      groupId,
      isCarousel: true,
      key: `${id}_carousel`,
      height: getCarouselGroupHeight(isMinLargeMobile, isMinLargeTablet, !!highlightInfo),
      items: restaurantsByCollectionOrder,
      highlightInfo,
    };
  });
};

export default makeCarouselGroups;

// why its here :|
// TODO move it to somewhere more relevant.
export const getPooledRestaurantsTimeData = () => {
  const resListData = selectRestaurantsMainListData(store.getState());
  if (!Object.keys(resListData)?.length) {
    return null;
  }

  const {poolTimeStart, poolTimeEnd, poolTimeDelivery} = resListData;
  return getPooledGroupTimesInfo(poolTimeStart, poolTimeEnd, poolTimeDelivery);
};
