import {memo, useMemo, Component, Suspense} from 'react';

import {Provider as ReduxProvider, useSelector} from 'react-redux';
import {StyleSheetManager, ThemeProvider} from 'styled-components';

import {selectCurrentModal, selectIsActiveBannersShown} from '~/shared/store/selectors';
import {getLocalizationService} from '~/shared/services/localisationService';
import {RouterProvider, useRouteQuery} from '~/shared/router';
import store from '~/shared/store';
import initManager from '~/shared/managers/initManager';
import usePreventBodyScrolling from '~/shared/hooks/usePreventBodyScrolling';
import {createTheme} from '~/shared/theme';
import GlobalCss from '~/shared/theme/globalCss';
import {ErrorPageComponent} from '~/errorPage';
import {createLogger} from '~/shared/logging';
import actions from '~/shared/store/actions';
import renderingForRobot from '~/shared/services/renderingForRobot';
import useSentryBreadcrumbs from '~/shared/hooks/useSentryBreadcrumbs';
import {useUpdateJetFm} from '~/shared/jetfm';
import {collectWebVitalsMetrics} from '~/shared/services/analytics';

import {PersistentMetaHeaders, DefaultHeaders} from './MainMetaHeaders';
import SupportChat from './SupportChat';
import Routes from './Routes';
import A11yHelper from './A11yHelper';
import AppsPromotionBanner from './AppsPromotionBanner';

import './moment';

const logger = createLogger('App');

const AppContent = memo(({unexpectedError}) => {
  const {currentLanguageKey: lng, currentLanguageDirection: direction} = getLocalizationService();
  const {shouldHideHeader} = useRouteQuery();
  const isBannersShown = useSelector(selectIsActiveBannersShown);
  const currentModal = useSelector(selectCurrentModal);

  useUpdateJetFm();

  usePreventBodyScrolling({shouldPreventScroll: !!currentModal});
  useSentryBreadcrumbs();

  const theme = useMemo(() => {
    return createTheme({direction, shouldHideHeader, lng});
  }, [direction, shouldHideHeader, lng]);

  return (
    <StyleSheetManager disableCSSOMInjection={renderingForRobot}>
      <ThemeProvider theme={theme}>
        <GlobalCss isBannersShown={isBannersShown} />
        <A11yHelper />

        <div style={{direction}}>
          <AppsPromotionBanner />
          <DefaultHeaders />
          <SupportChat />
          {unexpectedError ? <ErrorPageComponent errorDescriptionKey="unexpected_error" /> : <Routes />}
          <div id="modals" />
        </div>
      </ThemeProvider>
    </StyleSheetManager>
  );
});

class App extends Component {
  state = {
    unexpectedError: null,
  };

  static getDerivedStateFromError(error) {
    return {unexpectedError: error};
  }

  componentDidMount() {
    initManager.initApp();
    collectWebVitalsMetrics();
  }

  componentDidCatch(error, errorInfo) {
    logger.error('Unexpected error occurred', {error, errorInfo});
    store.dispatch(actions.setCurrentModal(null));
  }

  render() {
    const {unexpectedError} = this.state;

    return (
      <Suspense fallback="">
        <ReduxProvider store={store}>
          <RouterProvider>
            <PersistentMetaHeaders />
            <AppContent unexpectedError={unexpectedError} />
          </RouterProvider>
        </ReduxProvider>
      </Suspense>
    );
  }
}

export default App;
