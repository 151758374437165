/* eslint-disable class-methods-use-this */

import apiService from '~/shared/services/apiService';
import {trackEvent} from '~/shared/services/analytics';

import {createLogger} from '../../logging';
import store from '../../store';
import {
  clearCurrentOrderDateAndTime,
  restrictedSharedActions,
  setCurrentAddressKey,
  setInitialOrder,
} from '../../store/actions';
import {Address, LocalAddress} from '../../store/models';
import {selectCurrentAddress, selectCurrentAddressKey, selectUserAddresses} from '../../store/selectors';
import {addressPartsFromKey, AddressPartsFromKey, areAddressesEqual} from '../../utils/address';

import AddressManagerHelper from './AddressManagerHelper';

const logger = createLogger('AddressManager');

const AddressManager = {
  changeAddress: async (
    newAddress: Address | LocalAddress | AddressPartsFromKey | undefined,
    force: boolean | undefined,
  ) => {
    logger.verbose('trying to change address', {newAddress, force});

    const finalNewAddress = await AddressManagerHelper.getFinalNewAddress(newAddress);
    if (!finalNewAddress) {
      return;
    }

    const currentAddress = selectCurrentAddress(store.getState());
    const {addressKey: finalNewAddressKey} = finalNewAddress;
    const differentAddress = currentAddress?.addressKey !== finalNewAddressKey;
    if (!(force || differentAddress)) {
      logger.verbose('address was not changed because the provided one is like the current one', {finalNewAddressKey});
      return;
    }

    logger.verbose('changing address', {newAddress: finalNewAddress});

    if (
      currentAddress &&
      !areAddressesEqual(currentAddress, addressPartsFromKey(finalNewAddressKey), true)
    ) {
      store.dispatch(setInitialOrder({
        isInitialOrder: true,
      }));
      store.dispatch(clearCurrentOrderDateAndTime());
    }

    store.dispatch(setCurrentAddressKey(finalNewAddressKey));
    trackEvent('changeAddress', {
      currentAddressCityName: 'cityName' in finalNewAddress && finalNewAddress.cityName,
    });
    AddressManagerHelper.clearLocalAddressIfEquivalentRemoteExist(finalNewAddress);

    return finalNewAddress;
  },

  updateAddress: async (address: Address) => {
    // address includes the new addressKey.
    const {data: updatedAddressId} = await apiService.updateAddress(address);
    const state = store.getState();
    const currentAddress = selectCurrentAddress(state);
    const updatingAddress = selectUserAddresses(state).find(({addressId}) => addressId === updatedAddressId);
    const isUpdatingCurrentAddress = updatingAddress?.addressKey === selectCurrentAddressKey(state);

    store.dispatch(restrictedSharedActions.updateAddress({address, shouldUpdateAddressKey: isUpdatingCurrentAddress}));
    return {isUpdatingCurrentAddress, previousAddress: currentAddress};
  },
};

export default AddressManager;
