import {TrackGtmEventProps} from '~/shared/services/analytics/analyticsModels';

export const trackGtmEvent = ({
  name,
  eventData = {},
  pageData = {},
  restaurantData = {},
  // GA modified data overrides the default event data in GA format
  gaModifiedData = null,
  commonAnalyticsData,
}: TrackGtmEventProps) => {
  // If GA4 is still not loaded its safe to create a regular array and add events in it
  // When GA4 is loaded it will handle the added events
  window.dataLayer = window.dataLayer || [];

  const pageDataObj = {
    ...pageData,
    isLoggedIn: !!commonAnalyticsData?.userData,
    LoggedInType: pageData.LoggedInType || commonAnalyticsData?.userType,
  };

  const userDataObj = {
    companyId: commonAnalyticsData?.userData?.companyId,
    crossPlatformCustomerId: commonAnalyticsData?.userData?.crossPlatformCustomerId,
  };

  const ga4EventDataObject = {
    event: name,
    ...(gaModifiedData || eventData),
    pageData: pageDataObj,
    userData: userDataObj,
    restaurantData,
    utag_data: {
      tealium_event: 'custom_dev_none',
    },
  };
  window.dataLayer.push(ga4EventDataObject);
};
