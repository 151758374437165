import actions from '~/shared/store/actions';
import {selectUserRequiredActionsMap, selectUserData, selectCurrentModalName} from '~/shared/store/selectors';
import {openUpdateUserDetailsPageOrModal} from '~/shared/services/navigation';
import {isMoneycardActivationRequired, safeJsonParse} from '~/shared/utils/general';
import {getCurrentLocation, pushRoute} from '~/shared/router';
import {routeNames} from '~/shared/routes';
import {A11Y_LOCALSTORAGE_KEY_NAME, LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {checkTimePastWithHours} from '~/shared/utils/checkTimePast';
import {AppStore} from '~/shared/store/configStore';

const pagesToIgnoreUpdateUserDetails = [
  routeNames.updateUserDetails,
  routeNames.info,
  routeNames.unsubscribe,
  routeNames.gfoOptOut,
  routeNames.join,
];

export const invokeUserRequiredActionIfNeeded = ({store}: {store: AppStore}) => {
  const state = store.getState();
  const userActionsRequiredMap = selectUserRequiredActionsMap(state);
  const user = selectUserData(state);
  const updatePasswordRequired = user?.updatePasswordRequired;
  const storedLastRedirectDate = localStorage.getItem(LocalStorageKeys.LAST_OTL_REDIRECT_TIMESTAMP);
  const parsedLastRedirectDate = storedLastRedirectDate && safeJsonParse(storedLastRedirectDate);
  const is12hoursPast = checkTimePastWithHours({storedDate: parsedLastRedirectDate?.timeStemp || null, hours: 12});
  const isRequireOtlRedirect = user?.userEnabledFeatures.includes('RedirectToOTL');
  
  const {routeName} = getCurrentLocation();

  const shouldPreventUpdateUserDetailsAndMoneycardActivation =
    pagesToIgnoreUpdateUserDetails.includes(routeName) || selectCurrentModalName(state).includes('update_user_details');

  if (isRequireOtlRedirect && is12hoursPast) {
    localStorage.setItem(LocalStorageKeys.LAST_OTL_REDIRECT_TIMESTAMP, JSON.stringify({
      timeStemp: Date.now(),
    }));
    pushRoute('/join', {hard: true});
    return;
  }

  if (shouldPreventUpdateUserDetailsAndMoneycardActivation) {
    return;
  }

  const moneycardActivationRequired = isMoneycardActivationRequired({
    moneycardActivationRequired: !!userActionsRequiredMap.moneycardActivationRequired,
  });

  if (moneycardActivationRequired) {
    store.dispatch(actions.setCurrentModal('activateMoneycard'));
    return;
  }

  if (userActionsRequiredMap.updateUserDetailsRequired || updatePasswordRequired) {
    openUpdateUserDetailsPageOrModal();
  }

};

export const setInitialA11yValues = (store: AppStore) => {
  const a11yInitialValues = localStorage.getItem(A11Y_LOCALSTORAGE_KEY_NAME);

  if (a11yInitialValues) {
    const parsedA11yInitalValues = JSON.parse(a11yInitialValues);
    const {constrastEnabled, outlineEnabled} = parsedA11yInitalValues;

    if (constrastEnabled) {
      store.dispatch(actions.toggleContrastMode());
    }

    if (outlineEnabled) {
      store.dispatch(actions.toggleOutlieMode());
    }
  }
};
