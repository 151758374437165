import {useSelector} from 'react-redux';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {ScooberJobStatuses} from '~/shared/consts/scooberConsts';
import {selectIsTrackerUrlEmpty, selectUserLastOrderScooberJob} from '~/shared/store/selectors';

export default function useIsShowMap() {
  // custom map instead of scoober tracker.nst
  const isTrackerUrlEmpty = useSelector(selectIsTrackerUrlEmpty);
  const data = useSelector(selectUserLastOrderScooberJob);
  const {status} = data || EMPTY_OBJECT;
  const isShowMap = (isTrackerUrlEmpty || !status) && status !== ScooberJobStatuses.EatingTime;
  return isShowMap;
}
