import {SeoCityDescriptionFromCdn} from '../../models';
import {makeAsyncReducer, StateAsyncProperty} from '../../redux-toolbelt';

import {fetchSeoCityDescriptionFromCdn} from './seoCityDescriptionFromCdnActions';

export type SeoCityDescriptionFromCdnState = StateAsyncProperty<SeoCityDescriptionFromCdn>;

export default makeAsyncReducer<SeoCityDescriptionFromCdnState>(fetchSeoCityDescriptionFromCdn, {
  shouldDestroyData: false,
});
