import {uniqBy} from 'lodash';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {
  DeliveryMethod,
  DeliveryMethods,
  RestaurantsFiltersSortOptionsTypes,
  RestaurantsGroupTypes,
} from '~/shared/consts/restaurantConsts';
import {RestaurantFromSearch, RestaurantList} from '~/shared/store/models';
import {createLogger} from '~/shared/logging';
import {getPooledGroupSubtitle, GroupSubtitle} from '~/shared/services/listTitle';
import {getRestaurantStatus, RESTAURANT_STATUS_MAP} from '~/shared/utils/restaurants/restaurantStatus';
import sortOpenGroup from '~/shared/store/selectors/selectorUtils/restaurantsListSort/sortOpenGroup';

import restaurantSortOptions from './restaurantSortOptions';

const logger = createLogger('Grouped Restaurants Selector');

const cloneArray = <T>(arr: T[]) => arr && [...arr];
const makeGroup = (groupId: RestaurantsGroupTypes, items: RestaurantFromSearch[], subTitle?: GroupSubtitle) => ({
  groupId,
  items: items.map(item => ({key: `${groupId}_${item.restaurantId}`, groupId, item})),
  subTitle,
});

type RestaurantsGroup = ReturnType<typeof makeGroup>;

export default function groupedRestaurantsSelector(
  restaurantsData: RestaurantList | typeof EMPTY_OBJECT,
  deliveryMethod: DeliveryMethod,
  sortBy: RestaurantsFiltersSortOptionsTypes,
  isActivePooledOrder?: boolean,
  isOpenRestaurantsGroupSortingEnabled?: boolean,
): RestaurantsGroup[] {
  const list = cloneArray(restaurantsData?.restaurantsList) || [];
  if (list.length === 0) {
    return [];
  }
  const {poolTimeStart, poolTimeEnd, poolTimeDelivery} = restaurantsData;
  const sortOption = restaurantSortOptions.find(({id}) => id === sortBy);
  if (!sortOption) {
    logger.error('no sort option was found');
    return [];
  }

  const sortedList = list.sort((lhs, rhs) => sortOption.compare(lhs, rhs));

  type OpenAndClosedRestaurantsData = {
    openRestaurants: RestaurantFromSearch[];
    futureOrderedRestaurants: RestaurantFromSearch[];
  };

  const {openRestaurants, futureOrderedRestaurants}: OpenAndClosedRestaurantsData = sortedList.reduce(
    (openAndClosedRestaurants: OpenAndClosedRestaurantsData, restaurant: RestaurantFromSearch) => {
      const restaurantStatus = getRestaurantStatus(restaurant);
      if (restaurantStatus === RESTAURANT_STATUS_MAP.OPEN || restaurantStatus === RESTAURANT_STATUS_MAP.OPEN_WITH_FUTURE_ORDER_STARTING_TODAY) {
        return {
          ...openAndClosedRestaurants,
          openRestaurants: [...openAndClosedRestaurants.openRestaurants, restaurant],
        };
      }

      if (restaurantStatus === RESTAURANT_STATUS_MAP.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW) {
        return {
          ...openAndClosedRestaurants,
          futureOrderedRestaurants: [...openAndClosedRestaurants.futureOrderedRestaurants, restaurant],
        };
      }

      return openAndClosedRestaurants;
    },
    {openRestaurants: [], futureOrderedRestaurants: []},
  );

  const closedPooledRestaurants = sortedList.filter(
    restaurant =>
      getRestaurantStatus(restaurant) === RESTAURANT_STATUS_MAP.CLOSED && restaurant.isPooledOrderRestaurant,
  );

  const groups = [];

  const pooledSubTitle =
    deliveryMethod === DeliveryMethods.DELIVERY
      ? getPooledGroupSubtitle(poolTimeStart, poolTimeEnd, poolTimeDelivery)
      : undefined;

  const closedRestaurants = sortedList.filter(
    restaurant => getRestaurantStatus(restaurant) === RESTAURANT_STATUS_MAP.CLOSED,
  );

  const allClosetRestaurants = uniqBy([...closedPooledRestaurants, ...closedRestaurants], 'restaurantId');

  const isAtLeastOnePoolOrderExist = openRestaurants.some(({pooledOrderSum}) => pooledOrderSum > 0);

  // if the current view isn't groupBy, default sort should be applied
  groups.push(makeGroup(
    RestaurantsGroupTypes.OPEN,
    isOpenRestaurantsGroupSortingEnabled && isAtLeastOnePoolOrderExist ?
      sortOpenGroup(openRestaurants) :
      openRestaurants,
  ));
  groups.push(makeGroup(RestaurantsGroupTypes.CLOSED_WITH_FUTURE_ORDER_STARTING_TOMORROW, futureOrderedRestaurants));
  groups.push(makeGroup(RestaurantsGroupTypes.CLOSED, allClosetRestaurants, pooledSubTitle));
  return groups;
}
