import {createSelector} from 'reselect';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

const selectOrderHistory = (state: AppState) => state.ordersHistory;

export const selectOrdersHistoryLoaded = createSelector(selectOrderHistory, ordersHistory => ordersHistory.loaded);
export const selectOrdersHistoryLoading = createSelector(selectOrderHistory, ordersHistory => ordersHistory.loading);
export const selectOrdersHistoryData = createSelector(
  selectOrderHistory,
  ordersHistory => ordersHistory?.data || EMPTY_OBJECT,
);
