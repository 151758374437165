import {
  TAB_KEY_CODE,
} from '~/shared/consts/commonConsts';
import {addClass} from '~/shared/utils/general';

export const detectUsingTabForNavigation = () => {

  const addKeyboardClassOnTabPress = ({keyCode}) => {
    if (keyCode === TAB_KEY_CODE) {
      addClass(window.document.body, 'using-keyboard');
      window.removeEventListener('keydown', addKeyboardClassOnTabPress);
    }
  };
  
  window.addEventListener('keydown', addKeyboardClassOnTabPress);
};

export const getIsKeyboardInUse = () => {
  return document.body.classList.contains('using-keyboard');
};
