import {createSelector} from 'reselect';

import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

const selectOrderSummary = (state: AppState) => state.orderSummary;

export const selectOrderSummaryLoaded = createSelector(selectOrderSummary, orderSummary => orderSummary.loaded);
export const selectOrderSummaryLoading = createSelector(selectOrderSummary, orderSummary => orderSummary.loading);
export const selectOrderSummaryError = createSelector(selectOrderSummary, orderSummary => orderSummary.error);

export const selectOrderSummaryData = createSelector(
  selectOrderSummary,
  orderSummary => orderSummary?.data || EMPTY_OBJECT,
);
