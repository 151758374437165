import {memo} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {debounce} from 'lodash';

import useWebpWithFallback from '~/shared/hooks/useWebpWithFallback';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {
  selectCurrentRestaurant,
} from '~/shared/store/storeModules/currentRestaurant/currentRestaurantSelectors';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {selectOrderDeliveryRule} from '~/shared/store/storeModules/order';
import {useIsMaxLargeDesktop, useIsMaxSmallMobile} from '~/shared/hooks/deviceInfo';
import {setDeliveryRule} from '~/shared/store/storeModules/order/orderActions';
import {selectCurrentRestaurantActiveRules, selectCurrentRestaurantAvailableDeliveryRules} from '~/shared/store/selectors';
import {trackEvent} from '~/shared/services/analytics';

import {AsapPooledTogglerContext} from './context';
import AsapPooledTogglerView from './AsapPooledTogglerView';

const SWITCH_DELIVERY_RULE_DEBOUNCE_DURATION = 300;

const switchDeliveryRuleWithDebounce = debounce(
  ({nextRule, restaurantId, isCheckout}: {nextRule: DELIVERY_RULES; restaurantId: number; isCheckout?: boolean}) => {
    ManagerProvider.switchDeliveryRuleType({restaurantId, deliveryRuleType: nextRule, isCheckout});
  },
  SWITCH_DELIVERY_RULE_DEBOUNCE_DURATION,
);

const AsapPooledToggler = ({
  hideTitle,
  isCheckout,
}: {
  hideTitle?: boolean;
  isCheckout?: boolean;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const currentDeliveryRule = useSelector(selectOrderDeliveryRule);
  const currentRestaurantActiveRules = useSelector(selectCurrentRestaurantActiveRules);
  const availableDeliveryRules = useSelector(selectCurrentRestaurantAvailableDeliveryRules);

  const imageAsap = useWebpWithFallback('asap_and_pooled/asap.svg') as string;
  const imagePool = useWebpWithFallback('asap_and_pooled/pooled.svg') as string;

  const isMaxSmallMobile = useIsMaxSmallMobile();
  const isMaxLargeDesktop = useIsMaxLargeDesktop();

  if (!currentRestaurant) {
    return null;
  }

  const onRuleChange = (nextRule: DELIVERY_RULES) => {
    if (currentDeliveryRule && currentDeliveryRule !== nextRule) {
      dispatch(setDeliveryRule(nextRule));
      switchDeliveryRuleWithDebounce({restaurantId: currentRestaurant.id, nextRule, isCheckout});
      
      const isAsapSelected = nextRule === DELIVERY_RULES.ASAP;
      const selectedDeliveryFee = isAsapSelected ? availableDeliveryRules.Asap?.[0]?.deliveryFee : availableDeliveryRules.Pool?.[0]?.deliveryFee;
      trackEvent('nlxOrderType_select', {
        selected_orderType: isAsapSelected ? 'asap' : 'pooled',
        original_orderType: isAsapSelected ? 'pooled' : 'asap',
        pageSource: isCheckout ? 'checkout' : 'menu',
        delivery_price: selectedDeliveryFee,
      });
    }
  };

  return (
    <AsapPooledTogglerContext.Provider
      value={{
        isCheckout,
        onRuleChange,
        isAsap: currentDeliveryRule === DELIVERY_RULES.ASAP,
        asapDeliveryTimeInMinutes: currentRestaurant.asapDeliveryTimeInMinutes,
        poolDeliveryTime: currentRestaurant.poolDeliveryTime,
        asapDeliveryFee: currentRestaurantActiveRules.Asap?.[0]?.deliveryFee,
        asapDeliveryFeeBeforeDiscount: currentRestaurantActiveRules.Asap?.[0]?.deliveryFeeBeforeDiscount,
        pooledDeliveryFee: currentRestaurantActiveRules.Pool?.[0]?.deliveryFee,
        pooledDeliveryFeeBeforeDiscount: currentRestaurantActiveRules.Pool?.[0]?.deliveryFeeBeforeDiscount,
        hideIcons: isCheckout ? isMaxSmallMobile : isMaxLargeDesktop,
        imageAsap,
        imagePool,
        hideTitle,
      }}
    >
      <AsapPooledTogglerView />
    </AsapPooledTogglerContext.Provider>
  );
};

export default memo(AsapPooledToggler);
