import {MutableRefObject, useCallback, useMemo, useState} from 'react';

import {useSelector} from 'react-redux';

import {
  selectCurrentCoupon,
  selectCheckoutPaymentsIfExists,
  selectUserData,
  selectIsShoppingCartHasAgeRestrictionDishOrSub,
  selectShouldOpenModal,
  selectDinningRoomNoPackingRequired,
  selectCurrentRestaurant,
  selectOrderRemarks,
} from '~/shared/store/selectors';
import actions from '~/shared/store/actions';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {pushRoute, useLocation} from '~/shared/router';
import {trackEvent} from '~/shared/services/analytics';
import {getLocalizationService} from '~/shared/services/localisationService';
import {roundDecimal} from '~/shared/utils/general';
import {getCompanyDetails, timeValidation} from '~/shared/services/payments';
import store from '~/shared/store';
import {selectIsAgeConfirmed} from '~/shared/store/storeModules/ageRestriction/ageRestrictionSelectors';
import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';
import {
  Address,
  CheckoutPayment,
  IShoppingCart,
  isRemoteAddress,
  Payment,
} from '~/shared/store/models';
import {PaymentMethod} from '~/shared/consts/paymentConsts';
import {ICheckoutError, OrderFailure, PaymentRemarkConfiguration, ThreeDSMessage} from '~/common/types/checkout';
import useThreeDsVerification from '~/common/hooks/useThreeDsVerification';
import trackThreeDsEvent, {THREE_DS_EVENTS_MAP} from '~/common/hooks/useCheckoutSubmission/trackThreeDsEvent';

import useCheckoutAddress from '../useCheckoutAddress';

import {handleOrderSubmissionRequestsHandler} from './handleOrderSubmissionRequestsHandler';

const createRemarksRegex = (prefix?: string, maxLength?: number) =>
  (!prefix || !maxLength
    ? new RegExp('^.+$')
    : new RegExp(
      `^(?!${prefix.toUpperCase()}|${prefix.toLowerCase()}.*$)(.){${maxLength}}$|^${prefix.toUpperCase()}(.){${maxLength}}$|^${prefix.toLowerCase()}(.){${maxLength}}$`,
    ));

interface UseCheckoutSubmissionProps {
  addressRef: MutableRefObject<HTMLFormElement | undefined>;
  addressFormValuesRef: MutableRefObject<Partial<Address>>;
}

const useCheckoutSubmission = ({addressRef, addressFormValuesRef}: UseCheckoutSubmissionProps) => {
  const {t} = getLocalizationService();
  const {dispatch, dispatchThunk} = store;
  const userData = useSelector(selectUserData);
  const checkoutPayments = useSelector(selectCheckoutPaymentsIfExists);
  const billingLines = useSelector((state: {shoppingCart?: IShoppingCart}) => state.shoppingCart?.billingLines);
  const currentCoupon = useSelector(selectCurrentCoupon);
  const dinningRoomNoPackingRequired = useSelector(selectDinningRoomNoPackingRequired);
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const {currentAddress, isAddressWithoutId} = useCheckoutAddress();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [threeDSChallengeUrl, setThreeDSChallengeUrl] = useState<string>('');
  const [preSubmitErrors, setPreSubmitErrors] = useState<ICheckoutError[] | null>(null);
  const [validationErrors, seValidationErrors] = useState<ICheckoutError[] | null>(null);
  const [paymentsRemarksValues, setPaymentsRemarksValues] = useState<Record<string, string>>({});
  const [showPaymentsRemarksError, setPaymentsRemarksError] = useState(false);
  const orderRemarkValue = useSelector(selectOrderRemarks);

  const location = useLocation();

  const [orderFailure, setOrderFailure] = useState<OrderFailure | null>(null);
  const [orderFailurePayments, setOrderFailurePayments] = useState<Payment[]>([]);

  const trackThreeDsEventHandler = useCallback((
    event: keyof typeof THREE_DS_EVENTS_MAP,
    {customError, threeDsResponse, orderPayments}: {
      customError?: string;
      threeDsResponse?: ThreeDSMessage;
      orderPayments?: Payment[];
    } = {},
  ) => {
    trackThreeDsEvent(event, {
      currentRestaurant,
      customError,
      orderFailurePayments: orderPayments || orderFailurePayments,
      threeDsResponse,
    });
  }, [currentRestaurant, orderFailurePayments]);

  const validUserRemarks = Boolean(
    !currentRestaurant?.orderRemarks?.isVisible ||
    !currentRestaurant?.orderRemarks?.isRequired ||
    (currentRestaurant?.orderRemarks?.isRequired &&
      currentRestaurant?.orderRemarks?.isVisible &&
      orderRemarkValue !== ''),
  );

  const setOrderRemarkValue = useCallback((value: string) => {
    seValidationErrors(null);
    dispatch(actions.setOrderRemarks(value));
  }, [dispatch]);

  const isShoppingCartHasAgeRestrictionDishOrSub = useSelector(selectIsShoppingCartHasAgeRestrictionDishOrSub);
  const isAgeConfirmed = useSelector(selectIsAgeConfirmed);

  const shouldOpenModal = useSelector(selectShouldOpenModal);
  const shouldShowAgeConfirm = !isAgeConfirmed && isShoppingCartHasAgeRestrictionDishOrSub;

  const paymentMethodNames = useMemo(
    () =>
      checkoutPayments?.reduce(
        (result: PaymentMethod[], {paymentMethod, isTenbisCredit, sum}) => {
          if (sum > 0) {
            return result.concat(isTenbisCredit ? '10bis_credit' as PaymentMethod : paymentMethod);
          }
          return result;
        },
        [],
      ),
    [checkoutPayments],
  );

  const subTotalAmount = billingLines?.find(({type}: {type: string}) => type === 'SubTotal')?.amount;

  const paymentRemarkConfiguration = useMemo<PaymentRemarkConfiguration | null>(() => {
    const userHasCompanyPatch = userData?.userEnabledFeatures.includes('ShowRemarksOnPayments');
    if (userHasCompanyPatch) {
      if (!currentAddress) {
        return null;
      }
      const {startTime, endTime, prefix, maxLength, inputValidationRegex, customErrorMessage, customInputPlaceholder} =
        getCompanyDetails({
          addressCompanyId: isRemoteAddress(currentAddress) ? currentAddress.addressCompanyId : undefined,
          companyId: userData?.companyId,
        });

      const isValidTime = timeValidation({startTimeString: startTime, endTimeString: endTime});

      return isValidTime
        ? {
            prefix,
            maxLength,
            inputValidationRegex,
            customErrorMessage,
            customInputPlaceholder,
          }
        : null;
    }

    return null;
  }, [userData, currentAddress]);

  const handleOrderSubmissionRequests = useCallback(async ({is3dsSubmission, threeDsResponse}: {
    is3dsSubmission?: boolean;
    threeDsResponse?: ThreeDSMessage;
  } = {}) => {
    return handleOrderSubmissionRequestsHandler({
      is3dsSubmission,
      threeDsResponse,
      checkoutPayments,
      paymentRemarkConfiguration,
      paymentsRemarksValues,
      location,
      currentRestaurant,
      trackThreeDsEventHandler,
      setThreeDSChallengeUrl,
      setOrderFailure,
      setOrderFailurePayments,
      setIsSubmitting,
    });
  }, [
    checkoutPayments,
    paymentRemarkConfiguration,
    paymentsRemarksValues,
    location,
    currentRestaurant,
    trackThreeDsEventHandler,
  ]);

  const {isThreeDSFailed, resetIsThreeDSFailed} = useThreeDsVerification({
    setThreeDSChallengeUrl,
    setIsSubmitting,
    handleOrderSubmissionRequests,
    trackThreeDsEventHandler,
  });

  const handleTipChange = useCallback(
    ({value, isPercentage}: {value: number; isPercentage: boolean}) => {
      const tipValue = roundDecimal(
        isPercentage && subTotalAmount ? Number(subTotalAmount * (value / 100)) : Number(value),
      );
      return ManagerProvider.changeTipAmount({
        tipAmount: tipValue,
        debounce: false,
      });
    },
    [subTotalAmount],
  );

  const changeOrUpdateAddress = useCallback(async () => {
    const enhancedAddress = {
      ...(currentAddress || {}),
      ...(addressFormValuesRef.current || {}),
      comments: addressFormValuesRef.current?.comments || '',
      phone01: addressFormValuesRef.current?.phone01 || userData?.cellphone || '',
    } as Address;

    try {
      if (isAddressWithoutId) {
        const newAddressWithId: Address = await dispatchThunk(actions.insertAddress(enhancedAddress));
        // using changeAddress from ManagerProvider for the setAddressInOrder request (setAddress).
        await ManagerProvider.changeAddress({
          address: newAddressWithId,
          force: true,
          searchRestaurants: false,
        });
      } else if (addressFormValuesRef.current) {
        await ManagerProvider.updateAddress(enhancedAddress);
      }
    } catch (e: unknown) {
      setPreSubmitErrors(e as ICheckoutError[]);
    }
  }, [addressFormValuesRef, currentAddress, dispatchThunk, isAddressWithoutId, userData?.cellphone]);

  const handleCheckoutSubmit = useCallback(
    async ({shouldSkipAgeRestriction}: {shouldSkipAgeRestriction: boolean}) => {
      if (!validUserRemarks) {
        seValidationErrors([
          {
            id: 'user_remarks',
            errorDesc: t('required_field'),
          },
        ]);
        return;
      }
      dispatch(actions.setOrderRemarks(orderRemarkValue || null));

      setIsSubmitting(true);
      resetIsThreeDSFailed();
      setPreSubmitErrors(null);

      if (addressRef.current) {
        const addressFormValid = addressRef.current.checkValidity?.();

        if (addressFormValid) {
          await changeOrUpdateAddress();
        } else {
          setPreSubmitErrors([{errorDesc: t('checkout_address_component_error')}]);
          setIsSubmitting(false);
          return;
        }
      }

      trackEvent('hasSubmittedOrder', {
        paymentMethod: paymentMethodNames.join(),
        basketValue: subTotalAmount,
        voucherCode: currentCoupon?.isActive ? currentCoupon?.code : null,
        voucherValue: currentCoupon?.isActive ? currentCoupon?.couponValueForOrder : null,
      });

      if (currentRestaurant?.showPackingOption) {
        trackEvent('hasCheckedOutDiscountCampus', {
          dinningRoomNoPackingRequired,
        });
      }

      if (paymentRemarkConfiguration) {
        const {prefix, maxLength, inputValidationRegex} = paymentRemarkConfiguration;
        const paymentRemarksRegexValidate = createRemarksRegex(prefix, maxLength);
        const isInvalidRemarks = checkoutPayments.some(payment => {
          const remark = paymentsRemarksValues[`${payment.cardId}_${payment.paymentMethod}`];
          if (payment.sum && (!remark || !remark.match(inputValidationRegex || paymentRemarksRegexValidate))) {
            return true;
          }
          return false;
        });

        if (isInvalidRemarks) {
          setPaymentsRemarksError(true);
          setIsSubmitting(false);
          return;
        }

        setPaymentsRemarksError(false);
      }

      if (shouldShowAgeConfirm && !shouldSkipAgeRestriction) {
        dispatch(actions.setActionMadeFrom(ACTION_MADE_FROM_ENUM.CHECKOUT));
        if (shouldOpenModal) {
          dispatch(
            actions.setCurrentModal('age_confirm_modal', {
              onConfirm: () => {
                handleCheckoutSubmit({shouldSkipAgeRestriction: true});
              },
            }),
          );
          setIsSubmitting(false);
          return;
        }
        pushRoute('/confirm-age');
        return;
      }

      await handleOrderSubmissionRequests();
    },
    [
      validUserRemarks,
      dispatch,
      orderRemarkValue,
      addressRef,
      paymentMethodNames,
      subTotalAmount,
      currentCoupon?.isActive,
      currentCoupon?.code,
      currentCoupon?.couponValueForOrder,
      currentRestaurant?.showPackingOption,
      paymentRemarkConfiguration,
      shouldShowAgeConfirm,
      checkoutPayments,
      paymentsRemarksValues,
      t,
      changeOrUpdateAddress,
      dinningRoomNoPackingRequired,
      shouldOpenModal,
      handleOrderSubmissionRequests,
      resetIsThreeDSFailed,
    ],
  );

  interface SetPaymentsRemarksPros {
    value: string;
    cardId: number;
    paymentMethod: CheckoutPayment;
  }

  const setPaymentsRemarks = ({value, cardId, paymentMethod}: SetPaymentsRemarksPros) => {
    if (cardId >= 0 && paymentMethod) {
      setPaymentsRemarksValues({
        ...paymentsRemarksValues,
        [`${cardId}_${paymentMethod}`]: value || '',
      });

      setPaymentsRemarksError(false);
      return;
    }

    // for mobile useage - as we the same value for all payments.
    let newPaymentsRemark = {};
    checkoutPayments.forEach(payment => {
      newPaymentsRemark = {
        ...newPaymentsRemark,
        [`${payment.cardId}_${payment.paymentMethod}`]: value,
      };
    });

    setPaymentsRemarksValues(newPaymentsRemark);
    setPaymentsRemarksError(false);
  };

  return {
    isSubmitting,
    validationErrors,
    preSubmitErrors,
    orderFailure,
    subTotalAmount,
    showPaymentsRemarksError,
    paymentRemarkConfiguration,
    paymentsRemarksValues,
    handleCheckoutSubmit,
    handleTipChange,
    setPreSubmitErrors,
    setPaymentsRemarks,
    setOrderFailure,
    orderRemarkValue,
    setOrderRemarkValue,
    threeDSChallengeUrl,
    isThreeDSFailed,
  };
};

export default useCheckoutSubmission;
