/* eslint-disable no-console */

import {isDev} from '~/shared/config';

import consoleIgnoreList from './consoleIgnoreList';

export function initialPrints() {
  const filterStr = consoleIgnoreList.map(i => `-${i.toString()}`).join(' ');

  console.log('10bis Application start!');

  if (isDev) {
    console.log(`%cCONSOLE FILTERING -
    Copy the following string to the filtering line to ignore unneeded console entries:%c
    %c${filterStr}`,
    'color: blue; font-weight: bold; border: 1px solid black;',
    '',
    'color: black; font-weight: bold; border: 1px solid black; background-color: yellow;',
    );
  }
  
  console.log(`
    %c@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@   @@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@          ,@     #@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@                     @@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@*                       @@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@                           @@@@@@@@@@@@@@@
    @@@@@@@@@@@@       @ .@  @       @@@      .@@@@@@@@@@@@@
    @@@@@@@@@@        %@ /@  @     &@@@@        /@@@@@@@@@@@
    @@@@@@@@@@*       @# && #@    &@@@@@       .*@@@@@@@@@@@
    @@@@@@@@@@@@@@    @& &@ @@    @@@@@@     @@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@    @@@@@@@@   @@@@@@@     @@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@    %@@@@@@*   @@@@@@@     @@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@      @@@%      **@@@@    %@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@(     .@@@        &@@&    @@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@      @@@        @@@     @@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
    
    
    %cDigging into our code? Then come everyday!
    ------------------------------------------
    
    %cIf you're into stuff like React, Redux, Nodejs, TypeScript,
    Angular, RxJS, Nx, C#, .NET Core, Redis, and Azure cloud we want you!
    
    Submit your CV to %chttps://careers.takeaway.com/global/en%c
    and we'll get in touch soon.
    
    %cMeanwhile, what would you like to order? :)

  `,
  'color: #ff8000;',
  'color: #0000ff',
  'color: black;',
  'color: black; border-bottom: #0000ff 1px solid;',
  'color: black;',
  'color: #ff00ff;',
  );
}
