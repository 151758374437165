import {Variant} from '../config';
import {Context, Modes} from '../settings';

export const shouldTrack = (track: boolean, variant: Variant, context: Context): boolean => {
  const isModeDisabled =
    context &&
    context.mode &&
    typeof context.mode === 'function' &&
    context.mode() === Modes.Disabled;

  return !!(track && variant && variant.experimentKey && variant.experimentVariant && !isModeDisabled);
};
