import styled, {useTheme} from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';

const OuterProgressBarDiv = styled.div`
  margin-top: 2px;
  width: 26px;
  height: 14px;
  background-color: ${({theme}) => theme.colors.primaryText};
  border: 1px solid ${({theme}) => theme.colors.borderInverse};
  border-radius: 16px;
  padding: 1px;
  ${flipOnLTR`
    margin-left: 5px;
 `}
`;

const InnerBarDiv = styled(OuterProgressBarDiv)<{
  isLTR: boolean;
  progressBarPersent?: number;
}>`
  background-color: ${({theme}) => theme.colors.primaryText};
  background-image: linear-gradient(
    to ${({isLTR}) => (isLTR ? 'right' : 'left')},
    ${({theme}) => theme.colors.primary},
    ${({theme}) => theme.colors.primaryText} ${({progressBarPersent}) => progressBarPersent}%
  );
  border: none;
  box-sizing: border-box;
  width: 22px;
  height: 10px;
  border-radius: 10px;
  margin: 0;
  ${flipOnLTR`
    margin-right: 0.5px;
  `}
`;

const ProgressBar = ({
  minimumPriceForOrder,
  pooledOrderSum,
  className,
}: {
  minimumPriceForOrder: number;
  pooledOrderSum: number;
  className?: string;
}) => {
  const theme = useTheme();

  const roundedPooledOrderSum = Math.floor(pooledOrderSum);
  const progressBarPersent = () => {
    if ((roundedPooledOrderSum / minimumPriceForOrder) * 100 < 25) {
      return 40;
    }
    if ((roundedPooledOrderSum / minimumPriceForOrder) * 100 > 50) {
      return 80;
    }
    if (
      (roundedPooledOrderSum / minimumPriceForOrder) * 100 >= 25 &&
      (roundedPooledOrderSum / minimumPriceForOrder) * 100 <= 50
    ) {
      return 60;
    }
  };

  return (
    <OuterProgressBarDiv className={className}>
      <InnerBarDiv isLTR={theme.isLTR} progressBarPersent={progressBarPersent()} />
    </OuterProgressBarDiv>
  );
};

export default ProgressBar;
