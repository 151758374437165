import styled from 'styled-components';

import {InnerWidthContainer} from '~/shared/components/styled';
import {ActionButton as _ActionButton} from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';
import {paddingCss} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';
import {media} from '~/shared/theme/media';

const ButtonsContainer = styled(InnerWidthContainer)`
  background: transparent;
  text-align: center;
  width: 100%;
  ${media.minLargeTablet`
    padding: 16px 0;
    ${paddingCss};
  `}
`;

const ActionButton = styled(_ActionButton)`
  border-radius: 0;
  box-shadow: none;
  padding-top: 0;
  width: 100%;
  height: 50px;
`;

type OrderButtonsProps = {
  isBarcodeOrder: boolean;
  isVoucherCard: boolean;
  onPrint: () => void;
  onClose?: () => void;
  onReorder?: () => void;
};

const OrderSummaryButtons = ({isBarcodeOrder, onPrint, onClose, onReorder, isVoucherCard}: OrderButtonsProps) => {
  const {t} = getLocalizationService();

  if ((!onReorder && !isBarcodeOrder) || isVoucherCard) {
    return null;
  }

  return (
    <ButtonsContainer>
      {isBarcodeOrder ? (
        <ActionButton onClick={onPrint}>
          <>{t('print_coupon')}</>
        </ActionButton>
      ) : (
        <ActionButton onClick={onReorder || onClose}>
          <>{t(onReorder ? 'reorder' : 'close')}</>
        </ActionButton>
      )}
    </ButtonsContainer>
  );
};

export default OrderSummaryButtons;
