import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import {PriceLabel, PriceLabelWithStrikethrough} from '~/shared/components/PriceLabels';
import {getDishPriceWithDiscount} from '~/shared/utils/priceDiscountCalculator';

const Root = styled.div`
  display: flex;
  align-items: flex-end;
  ${flipOnLTR`
    padding-right:5px;
  `};
`;

const StyledPriceLabel = styled(PriceLabel)`
  line-height: 1;
`;

const StyledPriceLabelWithStrikethrough = styled(PriceLabelWithStrikethrough)`
  line-height: 1;
`;

type DishPriceProps = {
  price: number;
  addParentheses?: boolean;
  className?: string;
  priceDataTestId?: string;
  discountsData?: ReturnType<typeof getDishPriceWithDiscount>;
};

const DishPrice = ({price, addParentheses, className, priceDataTestId, discountsData}: DishPriceProps) => (
  <Root className={className}>
    {addParentheses && '('}
    <StyledPriceLabel
      price={discountsData ? discountsData.priceAfterDiscount : price}
      priceDataTestId={priceDataTestId}
      bold={!!discountsData}
    />
    {discountsData && <StyledPriceLabelWithStrikethrough price={discountsData.price} marginRight />}
    {addParentheses && ')'}
  </Root>
);

export default DishPrice;
