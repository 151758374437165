import moment from 'moment-timezone';

import {BillingLineType, PaymentRemarkPatch, PaymentsRemarksPatches} from '~/shared/consts/checkoutConsts';
import {BillingLineWithDiscountsData} from '~/shared/store/models/ShoppingCart/IShoppingCart';

export const getCompanyDetails = ({
  addressCompanyId,
  companyId,
}: {addressCompanyId?: number | string; companyId?: number} = {}): PaymentRemarkPatch => {
  if (!addressCompanyId && !companyId) {
    const {startTime, endTime} = PaymentsRemarksPatches.default;
    return {startTime, endTime};
  }

  const addressCompanyIds = Object.keys(PaymentsRemarksPatches.addressCompanyIds);
  const useAddressId = !!addressCompanyId && addressCompanyIds.includes(String(addressCompanyId));

  const companyIdPaymentRemark = companyId ? PaymentsRemarksPatches.companyIds[companyId] : undefined;

  const patch = useAddressId
    ? PaymentsRemarksPatches.addressCompanyIds[Number(addressCompanyId)]
    : companyIdPaymentRemark;
  const inputValidationRegex = companyIdPaymentRemark?.inputValidationRegex;
  const customErrorMessage = companyIdPaymentRemark?.customErrorMessage;
  const customInputPlaceholder = companyIdPaymentRemark?.customInputPlaceholder;

  const {startTime, endTime, prefix, maxLength} = patch || {...PaymentsRemarksPatches.default};
  return {
    startTime,
    endTime,
    prefix,
    maxLength,
    inputValidationRegex,
    customErrorMessage,
    customInputPlaceholder,
  };
};

export const timeValidation = ({startTimeString, endTimeString}: {startTimeString: string; endTimeString: string}) => {
  if (!startTimeString || !endTimeString || !startTimeString.match(/^\d/)) {
    return;
  }

  const [startHours, startMinutes] = startTimeString.split(':').map(s => Number(s));
  const [endHours, endMinutes] = endTimeString.split(':').map(s => Number(s));

  const startTime = moment().hours(startHours).minutes(startMinutes);
  const endTime = moment().hours(endHours).minutes(endMinutes);

  return moment().isBetween(startTime, endTime);
};

export const calculateBillingLinesForDisplay = (billingLines: BillingLineWithDiscountsData[] = []) => {
  const mutableBillingLines: BillingLineWithDiscountsData[] = Array.isArray(billingLines) ? [...billingLines] : [];

  const subTotal = mutableBillingLines.find(billingLine => billingLine.type === 'SubTotal');
  const total = mutableBillingLines.find(billingLine => billingLine.type === 'TotalToCharge');

  const hasOtherBillingLine = !!mutableBillingLines.find(
    billingLine => billingLine.type !== 'SubTotal' && billingLine.type !== 'TotalToCharge',
  );

  if (subTotal && total && subTotal.amount === total.amount && !hasOtherBillingLine) {
    return [total];
  }

  if (!total) {
    mutableBillingLines.push({type: BillingLineType.TotalToCharge, amount: 0, discountsData: null, priority: 100});
  }

  return mutableBillingLines.sort((a, b) => (a.priority || 99) - (b.priority || 99));
};
