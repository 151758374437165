import {isEmpty, noop} from 'lodash';
import {createSelector} from 'reselect';

import {EMPTY_ARRAY} from '~/shared/consts/commonConsts';

import {AppState} from '../../configStore';

import {UserState} from './userReducer';

export const selectUser = (state: AppState) => state.user;

export const selectUserData = createSelector(selectUser, ({loading, data}) => (loading || !data ? null : data));

export const selectUserLoaded = createSelector(selectUser, user => user.loaded);
export const selectUserLoading = createSelector(selectUser, user => user.loading);

export const selectUserError = createSelector(selectUser, user => user.error);
export const selectUserErrorRequireCaptcha = createSelector(
  selectUserError,
  userError => userError?.data?.requireCaptcha,
);

// todo new design #209775 - the userEnabledFeatures string is still unknown.
export const selectIsNewDesignEnabled = createSelector(
  noop,
  () => true,
); // createSelector(selectUserData, userData => Boolean(userData?.userEnabledFeatures?.includes('NewDesign')));

export const selectIsOrderBarcodeEnable = createSelector(selectUserData, userData => userData?.userEnabledFeatures?.includes('EnableOrderBarcode'));

export const selectIsBenefitsHighlightEnabled = createSelector(selectUserData, userData => Boolean(userData?.userEnabledFeatures?.includes('HighlightUserBenefits')));

export const selectIsGovCompanyUser = createSelector(selectUserData, userData => userData?.isGovCompany);
export const selectIsIecCompanyUser = createSelector(selectUserData, userData => userData?.isIecCompany);

export const selectUserAddresses = createSelector(
  selectUserData,
  userData => (userData && userData.addresses) || EMPTY_ARRAY,
);

export const selectUsersCompanyAddresses = createSelector(
  selectUserAddresses,
  remoteAddresses =>
    (remoteAddresses && remoteAddresses.filter(({isCompanyAddress}) => isCompanyAddress)) || EMPTY_ARRAY,
);

export const selectUsersPrivateAddresses = createSelector(
  selectUserAddresses,
  remoteAddresses =>
    (remoteAddresses && remoteAddresses.filter(({isCompanyAddress}) => !isCompanyAddress)) || EMPTY_ARRAY,
);

export const selectUserRequiredActionsMap = createSelector(selectUserData, userData => {
  const userRequiredActions: Partial<UserStateData['userRequiredActions']> = {};

  userRequiredActions.updateUserDetailsRequired =
    !isEmpty(userData?.userRequiredActions?.updateUserDetailsForm) &&
    (userData?.userRequiredActions?.updateUserDetailsRequired ||
      userData?.userRequiredActions?.updateUserDetailsForm?.isUpdatePasswordAfterResetPopup);

  userRequiredActions.moneycardActivationRequired = userData?.userRequiredActions?.moneycardActivationRequired;
  return userRequiredActions;
});

export const selectMoneycardActivationRequiredType = createSelector(
  selectUserData,
  userData => userData?.userRequiredActions?.moneycardActivationRequiredType,
);

type UserStateData = NonNullable<UserState['data']>;
