/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

export type logMessage = any[];
export interface Logger {
  logError: (...logMessage: logMessage) => void;
  logWarn: (...logMessage: logMessage) => void;
  logInfo: (...logMessage: logMessage) => void;
}

const logger: Logger = {
  logError(...data: any[]) {
    if (console && console.error) {
      console.error(...data);
    }
  },
  logWarn(...data: any[]) {
    if (console && console.warn) {
      console.warn(...data);
    }
  },
  logInfo(...data: any[]) {
    if (console && console.info) {
      console.info(...data);
    }
  },
};

function setLogger(loggerOverrides: Partial<Logger>) {
  Object.assign(logger, loggerOverrides);
}

export {logger, setLogger};
