import {memo, useMemo} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {
  creditCardTypesEnum,
  getCreditCardName,
  getPaymentType,
  paymentCardsIconComponents,
} from '~/shared/utils/paymentMethodsUtils';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {flexCenterHorizontally} from '~/shared/theme/FlexLayout';
import {body14Normal} from '~/shared/theme/typography';

const PaymentCardIconWrapper = styled.div`
  border-radius: 2px;
  margin: 8px;
  display: flex;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RemainingSumText = styled.div`
  color: ${({theme}) => theme.colors.text};
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
`;

const CardText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({horizontal}) => (horizontal ? 'row' : 'column')};
  justify-content: flex-start;
  color: ${({theme}) =>
    (theme.checkout.textColor)};
  ${body14Normal}
  line-height: 16px;
  ${({horizontal}) => (horizontal ? 'align-items: center;' : 'justify-content: center;')}
`;

const CheckoutPaymentMethodLineCardText = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${({horizontal}) => (horizontal ? 'row' : 'column')};
  justify-content: flex-start;
  color: ${({theme}) => theme.colors.text2};
  ${body14Normal}
  line-height: 20px;
  ${({horizontal}) => (horizontal ? 'align-items: center;' : 'justify-content: center;')}
  overflow: hidden;
  min-width: 0;
`;

const OwnerName = styled.div`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const CardInnerText = styled.div``;

const PaymentIconWrapper = styled.div`
  width: 100%;
  ${flexCenterHorizontally};
  max-width: 50px;
  border-radius: 2px;
`;

const CheckoutPaymentMethodLineWrapper = styled.div`
  ${({horizontal}) =>
    !horizontal &&
    `
    align-items: center;
  `}
  display: flex;
  flex: 1;
  min-width: 0;
  ${({isDisabled}) => isDisabled && 'filter: grayscale(1);'}
`;

const Wrapper = styled(CheckoutPaymentMethodLineWrapper)`
  margin-right: 4px;
  margin-left: 4px;
  cursor: ${({isDisabled}) => (isDisabled ? 'auto' : 'pointer')};
`;

const HorizontalSeperator = styled.div`
  margin: 0 2px;
`;

const PaymentMethodIconAndDetails = ({
  style,
  isDisabled,
  payment: {paymentMethod, userName, cardId, cardLastDigits, creditCardType, isTenbisCredit, prepaidBalance},
  horizontal,
  onPaymentChange,
  isCheckoutPaymentMethodLine,
}) => {
  const {isMoneyCard, isCash, isPaypal, isApplePay} = getPaymentType(paymentMethod);
  const {t} = getLocalizationService();

  const onClick = () => {
    return onPaymentChange && onPaymentChange(cardId);
  };

  const cardText = useMemo(() => {
    if (isTenbisCredit) {
      return t('tenbis_credit');
    }
    if (isCash) {
      return t('cash');
    }
    if ((isMoneyCard || isPaypal) && isCheckoutPaymentMethodLine) {
      return userName;
    }
    if (isApplePay) {
      return t('applepay');
    }
    return cardLastDigits;
  }, [isTenbisCredit, isCash, isMoneyCard, isPaypal, isCheckoutPaymentMethodLine, cardLastDigits, t, userName, isApplePay]);

  const creditCardName = getCreditCardName({paymentMethod, isTenbisCredit, creditCardType, isDisabled});
  const cardName = creditCardTypesEnum[creditCardName.toLowerCase()];

  const PaymentCardIconComponent = paymentCardsIconComponents[cardName];

  const CommonWrapper = isCheckoutPaymentMethodLine ? CheckoutPaymentMethodLineWrapper : Wrapper;

  return (
    <CommonWrapper {...{style, onClick, isDisabled}}>
      <PaymentIconWrapper>
        <PaymentCardIconWrapper>
          {PaymentCardIconComponent && <PaymentCardIconComponent className="avoid-fill-stroke" />}
        </PaymentCardIconWrapper>
      </PaymentIconWrapper>
      {!isCheckoutPaymentMethodLine && (
        <TextWrapper>
          <CardText horizontal={horizontal}>
            {isTenbisCredit && <OwnerName>{t('tenbis_credit')}</OwnerName>}
            {userName && !isTenbisCredit && (
            <>
              <OwnerName>{userName}</OwnerName>
              {horizontal && paymentMethod !== PaymentMethodTypes.PAYPAL && (
                <HorizontalSeperator>-</HorizontalSeperator>
              )}
            </>
          )}
            {!isTenbisCredit && <CardInnerText htmlFor={`payment-card-${cardId}`}>{cardText}</CardInnerText>}
          </CardText>

          {isTenbisCredit && (
            <RemainingSumText>{prepaidBalance === 0 ? t('no_balance') : t('remaining_balance_xxx', {remainingBalance: prepaidBalance})}</RemainingSumText>
          )}
        </TextWrapper>
      )}
      {isCheckoutPaymentMethodLine && (
        <TextWrapper>
          <CheckoutPaymentMethodLineCardText >{cardText}</CheckoutPaymentMethodLineCardText>
          {isTenbisCredit && (
            <RemainingSumText>{prepaidBalance === 0 ? t('no_balance') : t('remaining_balance_xxx', {remainingBalance: prepaidBalance})}</RemainingSumText>
          )}
        </TextWrapper>
      )}
    </CommonWrapper>
  );
};

export default memo(PaymentMethodIconAndDetails);
