import {restaurantSortOptions} from '~/shared/store/selectors/selectorUtils';
import {RestaurantsFiltersSortOptionsTypes, DeliveryMethods, DeliveryMethod} from '~/shared/consts/restaurantConsts';

import {hiddenFiltersOnPickup} from '../globalSelectors';

export const filterSortOptionItems = (deliveryMethod: DeliveryMethod) =>
  restaurantSortOptions
    .map(sortOption => sortOption.id)
    .filter(sortOptionId => {
      if (deliveryMethod === DeliveryMethods.PICKUP) {
        return !hiddenFiltersOnPickup.includes(sortOptionId);
      }

      return (
        sortOptionId !== RestaurantsFiltersSortOptionsTypes.SPECIALPICKUP &&
        sortOptionId !== RestaurantsFiltersSortOptionsTypes.SPECIAL // #171813 SPECIAL is filtered out from the view but still functions as the default option.
      );
    });
