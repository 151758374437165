import {useRef, useEffect} from 'react';

import {TAB_KEY_CODE} from '~/shared/consts/commonConsts';
import {getFocusableElements} from '~/shared/utils/general';

const FocusTrapDiv = ({children, className}) => {
  const focusTrapElRef = useRef();

  useEffect(() => {
    const domEl = focusTrapElRef.current;
    if (!domEl) {
      return;
    }

    let cancelSearchFocusableElements;
    const onKeydownWhenModalIsOpen = keydownEvent => {
      const {shiftKey: shiftKeyIsClicked, keyCode} = keydownEvent;
      if (keyCode !== TAB_KEY_CODE) {
        return;
      }

      const step = document.activeElement;
      cancelSearchFocusableElements = getFocusableElements({domEl, searchForFocusableChildren: true}, focusableElements => {
        const ELEMENTS = {
          first: focusableElements[0],
          last: focusableElements[focusableElements.length - 1],
        };

        if (shiftKeyIsClicked) {
          if (step === ELEMENTS.first) {
            ELEMENTS.last.focus();
            keydownEvent.preventDefault();
          }
        } else {
          if (step === ELEMENTS.last) {
            ELEMENTS.first.focus();
            keydownEvent.preventDefault();
          }
        }
      });
    };

    document.addEventListener('keydown', onKeydownWhenModalIsOpen);
    return () => {
      if (cancelSearchFocusableElements) {
        cancelSearchFocusableElements();
      }
      document.removeEventListener('keydown', onKeydownWhenModalIsOpen);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useRef.current]);

  return (
    <div ref={focusTrapElRef} className={className}>
      {children}
    </div>
  );
};

export default FocusTrapDiv;
