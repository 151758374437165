import styled, {keyframes} from 'styled-components';

import {flexCenterHorizontally} from '~/shared/theme/FlexLayout';

const Container = styled.div<RippleLoaderProps>`
  ${flexCenterHorizontally}
  position: relative;
  width: ${({size}) => size}px;
  height: ${({size}) => size}px;
`;

const RippleAnimation = keyframes`
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
`;

const FirstDiv = styled.div`
  position: absolute;
  border: 4px solid ${({theme}) => theme.colors.surface};
  opacity: 1;
  border-radius: 50%;
  animation: ${RippleAnimation} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
`;

const SecondDiv = styled(FirstDiv)`
  animation-delay: -0.5s;
`;

export interface RippleLoaderProps {
  size: number;
}

export const RippleLoader = ({size}: RippleLoaderProps) => (
  <Container size={size}>
    <FirstDiv/>
    <SecondDiv/>
  </Container>
);
