import styled from 'styled-components';

import {flipOnLTR} from '~/shared/theme/utils';
import {body18Normal} from '~/shared/theme/typography';

const ModalUnorderedList = styled.ul` 
  font-weight: normal;
  width: 100%;
`;

const ModalOrderedList = styled.ol`
  width: 100%;
  counter-reset: item;
  padding-top: 0;
  padding-bottom: 0;
  ${flipOnLTR`
    &:first-child {
      padding-right: 0;
    }
  `}
`;

const ModalListItem = styled.li`
  ${body18Normal};
  line-height: 1.42;
  word-wrap: normal;
  width: 100%;
  letter-spacing: 0.5px;
  list-style-type: none;

  &::before {
    font-weight: bold;
    content: counters(item, \".\") \" \";
    counter-increment: item;
  }
`;

const ModalList = props => {
  const {ordered, children} = props;
  if (!ordered) {
    return <ModalUnorderedList>{children}</ModalUnorderedList>;
  }
  return <ModalOrderedList>{children}</ModalOrderedList>;
};

export {
  ModalList,
  ModalListItem,
};
