const isAppInitiatedRefresh = sessionStorage.isAppInitiatedRefresh === 'true';
delete sessionStorage.isAppInitiatedRefresh;
export const getIsAppInitiatedRefresh = () => isAppInitiatedRefresh;
export const setAppInitiatedRefresh = () => {
  sessionStorage.isAppInitiatedRefresh = 'true';
};

let currentPath = null;
export const saveLocationSession = location => {
  currentPath = location.path;
};

window.addEventListener('beforeunload', () => {
  sessionStorage.leavePagePath = currentPath;
  sessionStorage.leavePageTime = new Date().getTime();
});

const appLoadTime = new Date().getTime();

export const getCurrentPathRefreshed = ({path, pathLandTime}) => {
  // we assume the path is refreshed if both:
  return (
    // 1. the time between leaving the app and landing on the page is lower then 10 seconds
    (Number(pathLandTime || 0) - appLoadTime < 10000) &&
    // 2. the path on which the user lands is the same as when the user left (or else it might be an opening of a new tab)
    path === sessionStorage.leavePagePath
  );
};
