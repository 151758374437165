import styled from 'styled-components';

import {Skeleton} from '~/shared/components/Loaders';
import {media} from '~/shared/theme/media';

const SkeletonRoot = styled.section`
  padding: 15px 15px 45px;
  display: none;
  ${media.minMobile`
    display: block;
  `}
`;

const SkeletonBody = styled(Skeleton)<{height?: number; width?: number}>``;

const ShoppingCartSkeleton = () => {
  return (
    <SkeletonRoot>
      <SkeletonBody height={55} />
    </SkeletonRoot>
  );
};

export default ShoppingCartSkeleton;
