import {useState, useEffect} from 'react';

const FIFTEEN_SECONDS_IN_MS = 15000;

const useCanResendCode = () => {
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);

  useEffect(() => {
    const timeOutHolder = setTimeout(() => {
      setIsResendButtonDisabled(false);
    }, FIFTEEN_SECONDS_IN_MS);
  
    return () => {
      clearTimeout(timeOutHolder);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResendButtonDisabled]);

  return {isResendButtonDisabled, setIsResendButtonDisabled};
};

export default useCanResendCode;
