import {memo, useState} from 'react';

import styled from 'styled-components';

import Button from '~/shared/components/Button';
import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import {MenuCategory} from '~/shared/store/models';
import {media} from '~/shared/theme/media';
import {body16Normal, body18Bold, subheader24Bold} from '~/shared/theme/typography';
import {flipOnLTR} from '~/shared/theme/utils';
import {CloseButtonSVGIcon} from '~/shared/components/styled';

const MOBILE_TITLE_HEIGHT_IN_PIXELS = 56;
const DESKTOP_MODAL_HEIGHT_IN_PIXELS = 560;

const Root = styled.div`
  && {
    top: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    background-color: ${({theme}) => theme.colors.surface};
  }

  ${media.minLargeMobile`
    && {
      border-radius: 2px;
      width: 450px;
      height: ${DESKTOP_MODAL_HEIGHT_IN_PIXELS}px;
      top: calc((100% - ${DESKTOP_MODAL_HEIGHT_IN_PIXELS}px) /2);
    }
  `}
`;

const TitleWrapper = styled.div<{isTop?: boolean}>`
  display: flex;
  align-items: center;
  width: 100%;
  height: ${MOBILE_TITLE_HEIGHT_IN_PIXELS}px;
  padding-top: 17px;
  padding-bottom: 16px;
  color: ${({theme}) => theme.colors.text2};
  ${body18Bold}
  ${flipOnLTR`
    padding-left: 20px;
    padding-right: 16px;
  `}
  ${media.minLargeMobile`
    ${subheader24Bold}
    height: 70px;
    padding-top: 24px;
    padding-bottom: 16px;
    ${flipOnLTR`
      padding-left: 28px;
      padding-right: 24px;
    `}
  `}
  ${({isTop, theme}) => !isTop && `box-shadow: ${theme.shadows.shadow7};`}
`;

const CloseButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  ${flipOnLTR`
    margin-right: auto;
  `}

  svg {
    height: 14px;
    width: 14px;

    g {
      use {
        fill: ${({theme}) => theme.colors.text2};
      }
    }
  }
`;

const Title = styled.div`
  ${media.minLargeMobile`
    line-height: 30px;
  `}
`;

const CategoryuList = styled.div`
  width: 100%;
  overflow: auto;
  overflow-y: scroll;
  height: calc(100% - ${MOBILE_TITLE_HEIGHT_IN_PIXELS}px);
  ${media.minLargeMobile`
    height: 488px;
  `}
`;
    
const Category = styled(Button)`
  height: 51px;
  width: 100%;
  text-align: start;
  padding: 14px 16px 13px;
  color: ${({theme}) => theme.colors.text2};
  border-bottom: 1px solid ${({theme}) => theme.colors.gray800};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  ${body16Normal}
  ${media.minLargeMobile`
    padding-top: 14px;
    padding-bottom: 13px;
    ${flipOnLTR`
      padding-left: 16px;
      padding-right: 24px;
    `}
  `}
  &:hover {
    background-color: ${({theme}) => theme.colors.darkBackground};
  }
`;

interface ICategoriesMenuModal {
  closeModal: () => void;
  args: {
    categories: MenuCategory[];
    onCategoryClick: (category: MenuCategory) => void;
  };
}

const CategoriesMenuModal = ({closeModal, args}: ICategoriesMenuModal) => {
  const {categories, onCategoryClick} = args;
  const {t} = getLocalizationService();
  const [isTop, setIsTop] = useState(true);

  const handleOnScroll = (e: React.UIEvent<HTMLElement>) => {
    const el = e.currentTarget;
    setIsTop(el?.scrollTop === 0);
  };

  const handleCategoryClick = async (category: MenuCategory) => {
    await closeModal();
    onCategoryClick(category);
  };

  return (
    <Root data-test-id="categoryModal">
      <TitleWrapper id="modal-title" isTop={isTop}>
        <Title data-test-id="categoryModal_Header">
          {t('categories')}
        </Title>
        <CloseButton onClick={closeModal} data-test-id="categoryModal_closeBtn">
          <CloseButtonSVGIcon />
        </CloseButton>
      </TitleWrapper>
      <CategoryuList onScroll={handleOnScroll}>
        {categories.map(category => {
          return (
            <Category onClick={() => handleCategoryClick(category)} key={category?.categoryId} data-test-id={`categoryId_${category?.categoryId}_categoryModal`}>
              {category.categoryName}
            </Category>
          );
        })}
      </CategoryuList>
    </Root>
  );
};

type StaticTypes = Partial<{
  hideCloseButton: boolean;
  modalType: string;
}>;

const EnhancedCategoriesMenuModal: React.FC<ICategoriesMenuModal> & StaticTypes =
  memo(CategoriesMenuModal);

EnhancedCategoriesMenuModal.hideCloseButton = true;
EnhancedCategoriesMenuModal.modalType = 'autoWidth';

export default attachModal('categories_menu_modal', EnhancedCategoriesMenuModal);
