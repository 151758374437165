import {createContext} from 'react';

import {OnSelectTimeProps} from '~/common/FutureOrderContextProvider';
import {DELIVERY_RULES, DeliveryMethod} from '~/shared/consts/restaurantConsts';
import {RestaurantFromGet} from '~/shared/store/models';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {ActiveRulesData} from '~/shared/utils/restaurants/getActiveDeliveryRules';

export interface IFutureOrderContext {
  onSelectDate: (orderDate: MutatedFutureOrderAvailableDatesAndTimes) => Promise<void>;
  onSelectTime: (args: OnSelectTimeProps) => Promise<void>;
  onSelectDateAndTime: (props: {
    orderDate: MutatedFutureOrderAvailableDatesAndTimes;
    orderTime: OnSelectTimeProps;
    skipRuleSwitch?: boolean;
  }) => Promise<void>;
  currentOrderDateAndTime: {
    orderDate: MutatedFutureOrderAvailableDatesAndTimes;
    orderTime: MutatedFutureOrderAvailableDatesAndTimes['times'][number];
  };
  futureOrderAvailableDatesAndTimes?: Array<MutatedFutureOrderAvailableDatesAndTimes>;
  isFutureOrderEnabled: boolean;
  futureOrderAvailableDatesAndTimesAvailable: boolean;
  isFullDisclaimer?: boolean;
  isASAP: boolean;
  currentRestaurant?: RestaurantFromGet;
  isInitialOrder?: boolean;
  shouldShowDisclaimers: boolean;
  currentDeliveryFee?: number;
  currentDeliveryRule: DELIVERY_RULES | null;
  currentDeliveryMethod: DeliveryMethod;
  availableDeliveryRules: ActiveRulesData;
  trackUserFirstDateTimeSelection: () => void;
  isBenefitsHighlightEnabled?: boolean;
}

export const FutureOrderContext = createContext<IFutureOrderContext>({} as IFutureOrderContext);
