import {memo, useEffect, useMemo, useState} from 'react';

import styled, {Keyframes} from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {createLogger} from '~/shared/logging';
import {flexCenter} from '~/shared/theme/FlexLayout';

import {ANIMATION_SECONDS} from '../styled';

import OrderSuccessIcon from './order-success.svg?react';
import {useAnimation} from './useAnimation';

const logger = createLogger('DummyTracker');

// for circumference calculation we use diameter of 95px minus stroke-width 4px x2
const TRACKER_CIRCUMFERENCE = (95 - (4 * 2)) * Math.PI;
// Containers

const TrackerContainer = styled.div`
  position: absolute;
  bottom: -${47.5 - 16}px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
`;

const TrackerBody = styled.div<{keyFrame: Keyframes | null}>`
  width: 95px;
  height: 95px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${({theme}) => theme.zIndex.languageChangeButton};
  animation-name: ${({keyFrame}) => keyFrame || 'none'};
  animation-fill-mode: forwards;
  animation-duration: ${ANIMATION_SECONDS}s;
`;

// Tracker circle progress components

const CirclesSvgContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const CirclesSvg = styled.svg`
  transform: rotate(-90deg);
`;

const InnerCircle = styled.circle`
  stroke: ${(({theme}) => theme.dummyTracker.innerCircle.strokeColor)};
`;

const ProgressCircle = styled.circle`
  stroke: ${(({theme}) => theme.dummyTracker.progressCircle.strokeColor)};
  transition: stroke-dashoffset 0.5s linear 0.6s;
`;

// Time labels components

const TimeLabels = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  user-select: none;
  z-index: ${({theme}) => theme.zIndex.languageChangeButton};
`;

const MinutesLabel = styled.span`
  font-size: 20px;
  font-weight: 700;
`;

const TimeUnitLabel = styled.span`
  line-height: 19px;
  color: ${({theme}) => theme.dummyTracker.timeUnitLabel.color};
`;

const OrderCompleteWrapper = styled.div`
  ${flexCenter}
  height: 95px;
  width: 95px;
  background-color: ${({theme}) => theme.colors.surface};
  border-radius: 50%;
`;

const OrderComplete = styled.div<{isOrderCompleted: boolean}>`
  ${flexCenter}
  height: 85px;
  width: 85px;
  background-color: ${({theme, isOrderCompleted}) => (isOrderCompleted ? theme.colors.supportPositive2 : theme.colors.darkBackground)};
  border-radius: 50%;
`;

const DummyTracker = ({minutesToRender, totalEta, isOverTwoHours, isOdTracker, isMoreThan10MinutesLeft, isOver20Minutes}: {
  minutesToRender: number | string | null;
  totalEta: number;
  isOverTwoHours: boolean;
  isOver20Minutes: boolean;
  isMoreThan10MinutesLeft: boolean;
  isOdTracker: boolean;
}) => {
  const {t} = getLocalizationService();
  const _showCircleProgress = !isOdTracker || (isOdTracker && isMoreThan10MinutesLeft);
  const [showCircleProgress, setShowCircleProgress] = useState(_showCircleProgress);
  const {keyFrame, activateAnimation} = useAnimation();

  useEffect(() => {
    if (showCircleProgress !== _showCircleProgress) {
      activateAnimation(() => setShowCircleProgress(_showCircleProgress));
    }
  }, [_showCircleProgress, activateAnimation, showCircleProgress]);

  const offset = useMemo(() => {
    if (isOverTwoHours) {
      return 0;
    }

    if (typeof minutesToRender !== 'number') {
      logger.error('incompatible type of minutesToRender received');
      return 0;
    }

    const percent = ((minutesToRender * 100) / totalEta) / 100;
    return TRACKER_CIRCUMFERENCE - (percent * TRACKER_CIRCUMFERENCE);
  }, [minutesToRender, totalEta, isOverTwoHours]);

  if (typeof minutesToRender === 'number' && minutesToRender <= 0 && !isOdTracker) {
    return null;
  }

  return (
    <TrackerContainer>
      <TrackerBody keyFrame={keyFrame}>
        <CirclesSvgContainer>
          {showCircleProgress ? (
            <CirclesSvg width={95} height={95}>
              <InnerCircle strokeWidth={4} fill="white" r={43.5} cx={47.5} cy={47.5} />
              <ProgressCircle
                strokeWidth={4}
                className="yellow-stroke"
                fill="transparent"
                r={43.5}
                cx={47.5}
                cy={47.5}
                strokeDasharray={`${TRACKER_CIRCUMFERENCE} ${TRACKER_CIRCUMFERENCE}`}
                strokeDashoffset={offset}
              />
            </CirclesSvg>
          ) : (
            <OrderCompleteWrapper>
              <OrderComplete isOrderCompleted={isOver20Minutes}>
                <OrderSuccessIcon/>
              </OrderComplete>
            </OrderCompleteWrapper>
          )}
        </CirclesSvgContainer>

        {showCircleProgress && (
          <TimeLabels>
            <MinutesLabel>{minutesToRender}</MinutesLabel>
            {!isOverTwoHours && <TimeUnitLabel>{t('dummy_tracker_minutes_time_unit')}</TimeUnitLabel>}
          </TimeLabels>
        )}
      </TrackerBody>
    </TrackerContainer>
  );
};

export default memo(DummyTracker);
