export const getSlug = name => {
  return name
    // remove spaces and tabs around the name
    .trim()
    // replace all spaces with dashes ("-")
    .replace(/ /gi, '-')
    // remove all characters that are not digits, letters, hebrew letters, or dashes ("-")
    .replace(/[^\-\d\w\u05D0-\u05EA]/gi, '')
    // lowercase
    .toLowerCase();
};
