import {OrderScooberJob, OrderData} from '~/shared/store/models';
import {api} from '~/shared/services/bffService';

import {makeThunkAsyncActionCreator} from '../../redux-toolbelt';

export const getUserLastOrderScooberJob = makeThunkAsyncActionCreator<OrderData['orderId'], OrderScooberJob>(
  'getUserLastOrderScooberJob',
  async orderId => {
    return api.fetchScooberJobV2(orderId);
  },
);
