import styled from 'styled-components';

import ArrowDown from '~/assets/images/svgIconsAsText/basic-arrow-down.svg?react';

export const StyledArrow = styled(ArrowDown)`
  path {
    fill: ${({theme, $inline}) => ($inline ? theme.colors.secondary : theme.colors.surfaceTitle)};
  }

  transform: scale(1.4);
`;
