import {IShoppingCart} from '~/shared/store/models';

import apiService from './apiService';

export default apiService;
export interface ApiResponse<T> {
  errors: Array<{errorCode: string; errorDesc: string}>;
  success: boolean;
  data: T;
  shoppingCartGuid: IShoppingCart['shoppingCartGuid'];
}

// TODO: add apierror interface and use in relevant catches
// TODO: move ApiError interface to http.interceptors when refactored
