import {memo} from 'react';

import styled from 'styled-components';

import Button from '~/shared/components/Button';
import {media} from '~/shared/theme/media';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenter} from '~/shared/theme/FlexLayout';

import ApplePayIcon from './apple_pay.svg?react';

enum ApplePayButtonVariant {
  BLACK = 'black',
  WHITE = 'white',
}

type ApplePayButtonProps = {
  variant: ApplePayButtonVariant;
  isSubmit?: boolean;
  onClick: () => void;
};

const PayButtonContainer = styled('div')<{variant: ApplePayButtonVariant}>`
  padding: ${({variant}) => (variant === ApplePayButtonVariant.BLACK ? '0' : '0 16px')};
  ${media.minLargeMobile`
    padding: 0; 
  `}
`;

const PayButton = styled(Button)<{variant: ApplePayButtonVariant}>`
  border-radius: 1px;
  ${({variant, theme}) => (variant === ApplePayButtonVariant.WHITE && `border: 1px solid ${theme.colors.gray000}`)};
  background-color: ${({variant, theme}) => (variant === ApplePayButtonVariant.BLACK ? theme.colors.gray000 : theme.colors.primaryText)};
  width: 100%;
  color: ${({variant, theme}) => (variant === ApplePayButtonVariant.BLACK ? theme.colors.primaryText : theme.colors.gray000)};
  ${flexCenter}
  gap: ${({variant}) => (variant === ApplePayButtonVariant.BLACK ? 7 : 4)}px;
  font-weight: ${({variant}) => (variant === ApplePayButtonVariant.WHITE ? 700 : 400)};
  font-size: ${({variant}) => (variant === ApplePayButtonVariant.WHITE ? 14 : 16)}px;
  padding: ${({variant}) => (variant === ApplePayButtonVariant.WHITE ? '6px 0' : '11px 0')};
  ${media.minLargeMobile`
    margin-bottom: ${({variant}) => (variant === ApplePayButtonVariant.BLACK ? 24 : 0)}px;
  `}
`;

const ApplePayIconStyled = styled(ApplePayIcon)<{variant: ApplePayButtonVariant}>`
  transform: ${({variant}) => (variant === ApplePayButtonVariant.BLACK ? 'scale(1.2)' : 'none')};
  fill: ${({variant}) => (variant === ApplePayButtonVariant.BLACK ? ApplePayButtonVariant.WHITE : ApplePayButtonVariant.BLACK)};
  position: relative;
  top: ${({theme}) => (theme.isLTR ? 1 : 0)}px;
`;

const ApplePayButton = ({variant = ApplePayButtonVariant.WHITE, onClick}: ApplePayButtonProps) => {
  const {t} = getLocalizationService();

  return (
    <PayButtonContainer variant={variant}>
      <PayButton variant={variant} onClick={onClick}>
        <span>{t('pay_with')}</span>
        <ApplePayIconStyled variant={variant} />
      </PayButton>
    </PayButtonContainer>
  );
};

export default memo(ApplePayButton);
