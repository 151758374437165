import {memo} from 'react';

import styled from 'styled-components';

import {flexCenterVertically} from '~/shared/theme/FlexLayout';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media, flipOnLTR} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {useRouteQuery, goBack} from '~/shared/router';
import {useIsMinTablet} from '~/shared/hooks/deviceInfo';
import {header36Normal} from '~/shared/theme/typography';
import CloseIconComponent from '~/assets/images/icons/close-white.svg?react';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import {
  ModalParagraphHeaderInline,
  ModalParagraphContainer,
  ModalParagraphHeader,
  ModalParagraphText,
} from './ModalParagraph';
import {ModalList, ModalListItem} from './ModalList';
import {PopupModalBase} from './styled';

export const Root = styled(PopupModalBase)`
  ${media.minLargeTablet`
    &&& {
      width: 675px;
    }
  `}
`;

const headerHeight = 80;
const ScrollContainer = styled.div`
  height: calc(100% - ${headerHeight}px);
  overflow-y: auto;
`;

const ModalHeader = styled.div`
  justify-content: ${({alignStart}) => (alignStart ? 'flex-start' : 'center')};
  ${flexCenterVertically};
  height: ${headerHeight}px;
  width: 100%;
  background-color: ${({theme, bgColor}) => bgColor || theme.loginModal.headerBackgroundColor};
  color: ${({theme}) => theme.loginModal.headerTextColor};
  font-size: ${({fontSize}) => fontSize || 25}px;
  font-weight: ${({bold}) => bold && 'bold'};
  padding: 10px 20px;
  ${media.minMobile`
    border-radius: 2px 2px 0 0;
    font-size: ${({fontSize}) => fontSize || 40}px; 
  `};
`;

const HeaderText = styled.div`
  ${flexCenterVertically};
`;

const IconWrapper = styled(ImageWithAlt)`
  width: 20px;
  ${flipOnLTR`
    margin-left: 10px;
  `};
`;

const CloseIcon = styled(CloseIconComponent)`
  text-align: center;
  width: 10px;
  cursor: pointer;
  position: absolute;

  path {
    fill: ${({theme}) => theme.colors.secondaryText};
  }

  ${flipOnLTR`
    left: 5%;
  `};
  ${media.minTablet`
    ${header36Normal};
    height: 35px;
    display: none;
  `};
`;

const ModalBody = styled.div`
  display: flex;
  padding: 15px;
  flex-flow: column;
  ${flipOnLTR`
    text-align: right;
  `}
`;

const InfoModal = memo(
  ({headerBgColor, headerFontSize, alignStart, bold, icon, header, hideCloseButton, children, className}) => {
    const onBack = () => goBack();
    const {hideHeader: hideHeaderFromQuery} = useRouteQuery();
    const {t} = getLocalizationService();
    const isMinTablet = useIsMinTablet();
    const showHeader = !hideHeaderFromQuery && (Boolean(header) && hideCloseButton);

    return (
      <Root className={className}>
        {showHeader && (
          <ModalHeader bgColor={headerBgColor} fontSize={headerFontSize} alignStart={alignStart} bold={bold}>
            <HeaderText id="modal-title">
              {icon && <IconWrapper noAlt src={icon} />}
              {header}
            </HeaderText>
            {!hideCloseButton && isMinTablet && (
              <Button onClick={onBack}>
                <CloseIcon alt={t('close')} />
              </Button>
            )}
          </ModalHeader>
        )}
        <ScrollContainer>
          <ModalBody id="modal-content">{children}</ModalBody>
        </ScrollContainer>
      </Root>
    );
  },
);

Object.assign(InfoModal, {
  Container: ModalParagraphContainer,
  Header: ModalParagraphHeader,
  InlineHeader: ModalParagraphHeaderInline,
  Text: ModalParagraphText,
  List: ModalList,
  ListItem: ModalListItem,
  modalType: 'infoModal',
});

export default InfoModal;
