export const POST_MESSAGE_TRUSTED_ORIGINS = [
  'https://www.10bis.co.il',
  'https://redirect.telepay.co.il',
  'https://staging.10bis.co.il',
  'https://10bis-spa-server-release-candidate-staging.10bis.co.il',
  'https://10bis-spa-server-release-candidate-dev.10bis.co.il',
  'https://10bis-spa-server-staging.tenbis.cloud',
  'https://tenbis-webapplication-staging.tenbis.cloud', // not sure if the rest are necessary.
  'https://10bis-spa-server-dev.azurewebsites.net',
  'https://10bis-spa-server-dev-staging.azurewebsites.net',
  'https://10bis-spa-server-staging.azurewebsites.net',
  'https://10bis-spa-server-release-candidate-staging.azurewebsites.net',
  'https://10bis-spa-server-release-candidate-dev.azurewebsites.net',
];
