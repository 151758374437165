import ManagerProvider from '~/shared/managers/ManagerProvider';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import store from '~/shared/store';
import {orderToSubmitSelector} from '~/shared/store/selectors';
import {trackEvent} from '~/shared/services/analytics';
import {resetRestaurantsFilter} from '~/shared/store/storeModules/ui/uiActions';
import {ProcessedLocation, pushRoute} from '~/shared/router';
import {is401Error} from '~/shared/services/apiErrorService';
import {handleRefreshToken} from '~/shared/services/auth';
import {CheckoutPayment, OrderSubmitError, Payment, RestaurantFromGet} from '~/shared/store/models';
import actions from '~/shared/store/actions';
import {OrderFailure, PaymentRemarkConfiguration, ThreeDSMessage} from '~/common/types/checkout';
import sanitizeHtml from '~/shared/utils/sanitizeHtml';
import {THREE_DS_EVENTS_MAP} from '~/common/hooks/useCheckoutSubmission/trackThreeDsEvent';

export const handleOrderSubmissionRequestsHandler = async ({
  is3dsSubmission,
  threeDsResponse,
  checkoutPayments,
  paymentRemarkConfiguration,
  paymentsRemarksValues,
  location,
  currentRestaurant,
  trackThreeDsEventHandler,
  setIsSubmitting,
  setOrderFailure,
  setOrderFailurePayments,
  setThreeDSChallengeUrl,
}: {
  is3dsSubmission?: boolean;
  threeDsResponse?: ThreeDSMessage;
  checkoutPayments: CheckoutPayment[];
  paymentRemarkConfiguration: PaymentRemarkConfiguration | null;
  paymentsRemarksValues: Record<string, string>;
  location: ProcessedLocation;
  currentRestaurant?: RestaurantFromGet;
  trackThreeDsEventHandler: (
    event: keyof typeof THREE_DS_EVENTS_MAP,
    params: {
      customError?: string;
      threeDsResponse?: ThreeDSMessage;
      orderPayments?: Payment[];
    },
  ) => void;
  setThreeDSChallengeUrl: React.Dispatch<string>;
  setOrderFailure: React.Dispatch<OrderFailure>;
  setOrderFailurePayments: React.Dispatch<Payment[]>;
  setIsSubmitting: React.Dispatch<boolean>;
}): Promise<void> => {
  try {
    if (is3dsSubmission) {
      const [sanitizedCavv, sanitizedEci] = await Promise.all([
        sanitizeHtml(threeDsResponse?.cavv || ''),
        sanitizeHtml(threeDsResponse?.eci || ''),
      ]);

      await ManagerProvider.changeOrderPayments({
        payments: checkoutPayments.map(payment => (payment.paymentMethod === PaymentMethodTypes.CREDIT_CARD ? {
          ...payment,
          cavv: sanitizedCavv,
          eci: sanitizedEci,
        } : payment)),
      });
    }
    if (!is3dsSubmission) {
      await ManagerProvider.updateOrderData(paymentRemarkConfiguration, paymentsRemarksValues);
    }

    const state = store.getState();
    const orderToSubmit = orderToSubmitSelector(state);

    const {data} = await ManagerProvider.submitOrder(orderToSubmit) || {};

    if (!data) {
      throw new Error('no data from submit order');
    }

    const {orderData} = data;

    trackEvent('hasOrderedFromFilterResult', {orderData});
    // clean up filters after the event was sent
    store.dispatch(resetRestaurantsFilter());
    pushRoute(`/order-success/${orderData.orderId}?firstRedirectToOrderSuccess=true`);
  } catch (error) {
    if (is401Error(error)) {
      await handleRefreshToken(error, handleOrderSubmissionRequestsHandler, {
        is3dsSubmission,
        threeDsResponse,
        checkoutPayments,
        paymentRemarkConfiguration,
        paymentsRemarksValues,
        location,
        currentRestaurant,
        trackThreeDsEventHandler,
        setIsSubmitting,
        setOrderFailure,
        setOrderFailurePayments,
        setThreeDSChallengeUrl,
      });
      return;
    }

    const {submitOrderFailurePaymentMethod, submitOrderFailurePaymentId, submitOrderFailureActionSuggestionType, threeDsChallengeUrl, orderData} = (
      error as OrderSubmitError
    )?.data || {};

    if (threeDsChallengeUrl && !is3dsSubmission) {
      setOrderFailurePayments(orderData.payments);
      trackThreeDsEventHandler('fired', {
        orderPayments: orderData.payments,
      });

      if (location.routeName === 'menu') {
        setThreeDSChallengeUrl(threeDsChallengeUrl);
        return;
      }

      store.dispatch(actions.setCurrentModal('ThreeDSVerificationModal', {url: threeDsChallengeUrl, modalOptions: {
        closeModal: (isAutoClosed: boolean) => {
          if (!isAutoClosed) {
            trackThreeDsEventHandler('failure', {
              customError: 'User canceled verification manually',
            });
            setIsSubmitting(false);
          }
          store.dispatch(actions.setCurrentModal(null));
        },
      }}));
      return;
    }

    if (submitOrderFailurePaymentMethod || submitOrderFailurePaymentId || submitOrderFailureActionSuggestionType) {
      setOrderFailure({
        cardId: submitOrderFailurePaymentId,
        paymentMethod: submitOrderFailurePaymentMethod,
        actionSuggestionType: submitOrderFailureActionSuggestionType,
        threeDsChallengeUrl,
      });
    }
    setIsSubmitting(false);
  }
};
