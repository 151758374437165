import URI from 'urijs';
import {isEmpty} from 'lodash';

import {trackEvent} from '~/shared/services/analytics';
import {createLogger} from '~/shared/logging';
import {
  selectCurrentAddressKey,
  selectCurrentModalName,
  selectCurrentRestaurant,
  selectShouldOpenModal,
} from '~/shared/store/selectors';
import {getCurrentLocation, pushRoute, removeQueries, replaceRoute} from '~/shared/router';
import actions, {setShouldAvoidPaymentsRefetch} from '~/shared/store/actions';
import store from '~/shared/store';
import {getMenuOrDishPagePath, getCityPageUrl, modalsQuery} from '~/shared/services/urlService';
import {routeNames} from '~/shared/routes';

import {Dish, User} from '../store/models';
import {ActionCreator} from '../store/redux-toolbelt-10bis/makeActionCreator';
import {SupportedQueryParamsKeys} from '../router/supportedQueryParams';

import {City} from './citiesHelpers';

const logger = createLogger('navigation');

export function navigateToHomePage({
  replace = false,
  routerOptions = {keepAllQueries: true, permanent: true, keepQueries: []},
}: NavigationDefaultPayload = {}) {
  const homePagePath = '/';

  const replaced = replace ? replaceRoute(homePagePath, routerOptions) : pushRoute(homePagePath, routerOptions);

  return replaced;
}

export const defaultStartPage = () => {
  const state = store.getState();
  const addressKeyAvailable = !!selectCurrentAddressKey(state);

  const initialPath = addressKeyAvailable ? '/restaurants/search/' : '/';
  return initialPath;
};

export function navigateToDefaultStartPage({
  replace = false,
  routerOptions = {keepAllQueries: true, permanent: true, keepQueries: []},
}: NavigationDefaultPayload = {}) {
  logger.log('navigateToDefaultStartPage', {routerOptions, replace});

  const initialPath = defaultStartPage();

  const replaced = replace ? replaceRoute(initialPath, routerOptions) : pushRoute(initialPath, routerOptions);

  return replaced;
}

export function appendQueryToPath(path: string, query: Record<string, any>, replace = false) {
  if (!path || !query) {
    return;
  }
  const queryParams = URI.buildQuery(query);
  const _path = [path, queryParams && `?${queryParams}`].filter(Boolean).join('/');
  return replace ? replaceRoute(_path) : pushRoute(_path);
}

export function navigateToMenuOrDishPage({
  restaurantId,
  restaurantName,
  restaurantSlug,
  dish,
  routerOptions,
  replace = false,
  query = {},
  toCategories = false,
}: NavigateToMenuOrDishPagePayload) {
  const {dishId, shoppingCartDishId} = dish || {};
  const queryParams = URI.buildQuery(query);

  const path = getMenuOrDishPagePath({
    restaurantId,
    restaurantName,
    restaurantSlug,
    dish: {dishId, shoppingCartDishId},
    query: queryParams,
    toCategories,
  });

  if (!path) {
    return navigateToDefaultStartPage({replace, routerOptions});
  }

  return replace ? replaceRoute(path, routerOptions) : pushRoute(path, routerOptions);
}

export function navigateToRestaurantsPage(
  {replace = false, routerOptions} = {} as {replace?: boolean; routerOptions?: RouterOptions},
) {
  const path = '/restaurants/search/';

  return replace ? replaceRoute(path, routerOptions) : pushRoute(path, routerOptions);
}

export const signInModalOrPage = (payload: {
  mode: 'signUp' | 'login';
  returnUrl?: string;
  successReturnUrl?: string;
  onSuccess?(user?: User): void;
}) => {
  const {mode, returnUrl, successReturnUrl, onSuccess} = payload;
  if (!store) {
    console.error('there is no store'); // eslint-disable-line no-console
    return;
  }

  const state = store.getState();
  const shouldOpenModal = selectShouldOpenModal(state);

  if (shouldOpenModal) {
    if (mode === 'signUp') {
      trackEvent('hasViewedRegistrationPopup');
    }
    return store.dispatch(
      actions.setCurrentModal('loginOrRegister', {
        mode,
        onSuccess,
      }),
    );
  }

  const query = URI.buildQuery({
    mode,
    returnUrl,
    successReturnUrl,
  });
  const search = query ? `?${query}` : '';

  return pushRoute(`/signin${search}`);
};

export function openModalOrPageFromQuery() {
  const state = store.getState();
  const shouldOpenModal = selectShouldOpenModal(state);

  const {query, routeParams} = getCurrentLocation();

  const pageQueries = Object.keys(modalsQuery) as Array<keyof typeof modalsQuery>;
  if (pageQueries.includes('dishId')) {
    // menu page will open it.
    return;
  }

  const availableQuery = pageQueries.find(q => query[q]);
  const actionForThisQuery = availableQuery && modalsQuery[availableQuery];

  if (!actionForThisQuery) {
    return;
  }

  removeQueries(pageQueries);

  const {getRouteOnMobile, getActionOnDesktop} = actionForThisQuery;
  if (!shouldOpenModal && getRouteOnMobile) {
    const route = getRouteOnMobile(routeParams);
    if (route) {
      pushRoute(route);
    }
  } else {
    const {action, params} = getActionOnDesktop(query);
    if (action) {
      const actionCreator: ActionCreator<any> = actions[action];
      store.dispatch(actionCreator(...(params || [])));
    }
  }
}

const ROUTES_WITHOUT_REDIRECT_ON_OPEN_DISH = [routeNames.shoppingCart, routeNames.checkout];

export function openDish(payload: {dishId: number; shoppingCartDishId: number; onClose?: () => void}) {
  const {dishId, shoppingCartDishId, onClose} = payload;

  const state = store.getState();
  const currentModalName = selectCurrentModalName(state);
  const currentRestaurant = selectCurrentRestaurant(state);

  const openDishModal = () => {
    store.dispatch(
      actions.setCurrentModal('dish_modal', {
        dishId,
        shoppingCartDishId,
        onClose,
      }),
    );
  };

  if (!currentRestaurant || currentModalName !== '') {
    return;
  }

  // dealing with checkout and shopping cart pages

  const {routeName, path} = getCurrentLocation();

  if (ROUTES_WITHOUT_REDIRECT_ON_OPEN_DISH.includes(routeName)) {
    appendQueryToPath(path, {dishId});
    openDishModal();
    return;
  }

  // adding query to menu page
  const {
    routeParams: {restaurantId, restaurantSlug},
  } = getCurrentLocation();
  navigateToMenuOrDishPage({
    restaurantId: Number(restaurantId) || currentRestaurant.id,
    restaurantName: currentRestaurant.name,
    restaurantSlug,
    query: {dishId},
  });

  openDishModal();
}

export const openOtlModalOrPage = () => {
  const state = store.getState();
  const shouldOpenModal = selectShouldOpenModal(state);

  if (shouldOpenModal) {
    store.dispatch(actions.setCurrentModal('otlModal', {returnToModal: 'checkout_modal'}));
    return;
  }

  pushRoute('/otl');
};

export const openPaymentsModalOrPage = (returnToCheckoutOnClose = false) => {
  const state = store.getState();
  const shouldOpenModal = selectShouldOpenModal(state);
  // When opening payments modal or page its assumed that the payments were fetched before
  // In this case a new payments fetch should be avoided
  store.dispatch(setShouldAvoidPaymentsRefetch(true));

  if (shouldOpenModal) {
    store.dispatch(
      actions.setCurrentModal('payments_modal', {
        returnToCheckoutOnClose,
        modalOptions: {width: '504px'},
      }),
    );
    return;
  }

  pushRoute('/payments');
};

export const openUpdateUserDetailsPageOrModal = () => {
  const state = store.getState();
  const shouldOpenModal = selectShouldOpenModal(state);

  if (shouldOpenModal) {
    store.dispatch(actions.setCurrentModal('update_user_details'));
    return;
  }

  pushRoute('/update-user-details', {keepAllQueries: false});
};

export const navigateToCityPage = (city: City) => {
  if (isEmpty(city)) {
    throw new Error('Cannot navigate to city page. missing {city} parameter');
  }
  const cityPageUrl = getCityPageUrl({city});
  pushRoute(cityPageUrl);
};

interface RouterOptions {
  keepAllQueries: boolean;
  permanent: boolean;
  keepQueries?: SupportedQueryParamsKeys;
}
interface NavigationDefaultPayload {
  routerOptions?: RouterOptions;
  replace?: boolean;
}
interface NavigateToMenuOrDishPagePayload extends NavigationDefaultPayload {
  restaurantId: number;
  restaurantName?: string | boolean;
  restaurantSlug?: string;
  dish?: Dish;
  query?: Record<string, any>;
  toCategories?: boolean;
}
