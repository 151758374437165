import {useEffect, useState} from 'react';

import {MiniFeedQueries} from '~/shared/consts/restaurantConsts';
import {useRouteQuery} from '~/shared/router';
import {SupportedQueryParamsType} from '~/shared/router/supportedQueryParams';

const resolveQueryFromPath = (query: SupportedQueryParamsType): MiniFeedQueries | null => {
  if (query.collectionName) {
    return MiniFeedQueries.COLLECTION_NAME;
  }

  if (query.cuisine) {
    return MiniFeedQueries.CUISINE;
  }

  if (query.showStores) {
    return MiniFeedQueries.SHOW_STORES;
  }

  return null;
};

const useResolvedMiniFeedQuery = () => {
  const [resolvedQuery, setResolvedQuery] = useState<MiniFeedQueries | null>(null);

  const query = useRouteQuery();

  useEffect(() => {
    if (query.showStores || query.collectionName || query.cuisine) {
      setResolvedQuery(resolveQueryFromPath(query));
      return;
    }

    setResolvedQuery(null);
  }, [query, setResolvedQuery]);

  return resolvedQuery;
};

export default useResolvedMiniFeedQuery;
