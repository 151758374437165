import {memo} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';
import {isFunction} from 'lodash';

import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media, flipOnLTR} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import {flexCenterHorizontally, flexCenterVertically, flexColumn} from '~/shared/theme/FlexLayout';
import {body14Bold, body18Bold} from '~/shared/theme/typography';
import {CloseButton} from '~/shared/components/Modals';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {setMenuLoading} from '~/shared/store/actions';
import {removeItemsFromCart, UnavailableDishId} from '~/shared/services/shoppingCart';
import {trackEvent} from '~/shared/services/analytics';

const Root = styled(ClickOutsideDiv)`
  &&& {
    background-color: ${({theme}) => theme.colors.darkBackground};
    width: 100vw;
    height: 100vh;
    max-height: 100%;
  }

  ${media.minLargeTablet`
    &&& {
      background-color: ${({theme}) => theme.colors.surface};
      box-shadow: ${({theme}) => theme.shadows.shadow1};
      border-radius: 2px;
      width: 600px;
      height: auto;
      text-align: center;     
    }
  `}
`;

const DialogHeader = styled.div`
  ${flexCenterVertically};
  ${flexCenterHorizontally};
  text-align: center;
  padding: 12px 16px 12px 16px;
  background-color: ${({theme}) => theme.colors.surface};
  box-shadow: ${({theme}) => theme.shadows.modalHeaderShadow};
  ${media.minLargeTablet`    
    padding: 12px 0 12px 0;
  `}
`;

const DialogBody = styled.div`
  ${body14Bold};
  height: calc(100% - 250px);
  margin: 0 auto;
  padding: 16px 16px 0 16px;
  color: ${({theme}) => theme.colors.secondary};
  width: 100%;
  ${flexCenterVertically};
  ${flexCenterHorizontally};
  ${media.minLargeTablet`    
    height: 100px;
  `}
`;

const DialogHeaderText = styled.div`
  ${body18Bold};
  color: ${({theme}) => theme.colors.secondary};
  ${media.minLargeTablet`
    width: 100%;
    margin: 0 auto;
`}
`;

const CloseDialogButton = styled(CloseButton)`
  top: 10px;
  ${flipOnLTR`
    left: 8px; 
  `}
  ${media.minLargeTablet`
    ${flipOnLTR`
      left: 16px; 
    `}
`}
`;

const Buttons = styled.div`
  ${flexColumn};
  flex-direction: column-reverse;
  padding: 0 40px 40px 40px;
  ${media.minLargeTablet`
    flex-direction: row;
    margin: 0 auto;
  `}
`;

const CommonButton = styled(Button)`
  width: 100%;
  min-height: 50px;
  border-radius: 1px;
  margin: 24px auto 0;
  font-weight: bold;
  text-align: center;
  ${media.minLargeTablet`
    width: 270px;
    margin: 40px auto 0;
  `}
`;

const CancelButton = styled(CommonButton)`
  background-color: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  border: 1px solid ${({theme}) => theme.colors.gray500};
  ${flipOnLTR`
    margin-left: 16px;
  `}
`;

const ConfirmButton = styled(CommonButton)`
  border: solid 1px ${({theme}) => theme.colors.gray500};
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
`;

type ButtonProps = {
  labelKey: string;
  onClick?: () => void;
};

interface IFutureOrderModalProps {
  closeModal: () => void;
  args: {
    unavailableDishesIds: UnavailableDishId[];
    titleKey: string;
    descriptionKey: string;
    buttons: {
      confirm: ButtonProps;
      cancel?: ButtonProps;
    };
  };
}

const FutureOrderMissingDishModal = ({closeModal, args}: IFutureOrderModalProps) => {
  const {unavailableDishesIds, titleKey, descriptionKey, buttons} = args;
  const {t} = getLocalizationService();
  const dispatch = useDispatch();
  const {cancel, confirm} = buttons;

  const _handleRemoveItems = async () => {
    await removeItemsFromCart(unavailableDishesIds);
    trackEvent('hasFutureOrderDishUnavailable', {linkType: 'remove items'});
    if (isFunction(confirm.onClick)) {
      confirm.onClick();
    }
    closeModal();
  };

  const handleCloseModal = () => {
    closeModal();
    dispatch(setMenuLoading(false));
  };

  const handleClose = () => {
    trackEvent('hasFutureOrderDishUnavailable', {linkType: 'close'});
    handleCloseModal();
    if (isFunction(cancel?.onClick)) {
      cancel?.onClick();
    }
  };

  const handleCancel = () => {
    trackEvent('hasFutureOrderDishUnavailable', {linkType: 'cancel'});
    handleCloseModal();
    if (isFunction(cancel?.onClick)) {
      cancel?.onClick();
    }
  };

  return (
    <Root onClickOutside={handleClose}>
      <DialogHeader>
        <CloseDialogButton onClick={handleClose}>
          <CloseButtonSVGIcon />
        </CloseDialogButton>
        <DialogHeaderText id="modal-title">{t(titleKey)}</DialogHeaderText>
      </DialogHeader>
      <DialogBody>{t(descriptionKey)}</DialogBody>
      <Buttons>
        {cancel && <CancelButton onClick={handleCancel}>{t(cancel.labelKey)}</CancelButton>}
        <ConfirmButton onClick={_handleRemoveItems}>{t(confirm.labelKey)}</ConfirmButton>
      </Buttons>
    </Root>
  );
};

type StaticTypes = Partial<{
  hideCloseButton: boolean;
  modalType: string;
}>;

const EnhancedFutureOrderMissingDishModal: React.FC<IFutureOrderModalProps> & StaticTypes =
  memo(FutureOrderMissingDishModal);

EnhancedFutureOrderMissingDishModal.hideCloseButton = true;
EnhancedFutureOrderMissingDishModal.modalType = 'autoWidth';

attachModal('futureOrderMissingDishModal', EnhancedFutureOrderMissingDishModal);
