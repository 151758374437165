import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import {PageHeader} from '~/shared/components/header';
import {PageContainer, Title} from '~/shared/components/styled';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {ActionButton} from '~/shared/components/Button';
import {Link} from '~/shared/router';
import {media} from '~/shared/theme/utils';
import visualImageUrl from '~/assets/images/400_500_visual.svg';
import {body14Bold, header64Bold} from '~/shared/theme/typography';

const Container = styled(PageContainer)`
  align-items: center;
  padding-top: ${({theme}) => theme.header.height + 50}px;
  background-color: ${({theme}) => theme.colors.surface};
  min-height: 100vh;
  ${media.minTablet`
    padding-top: ${({theme}) => theme.header.height + 120}px;
  `}
`;

const Visual = styled(ImageWithAlt).attrs({
  src: visualImageUrl,
})`
  height: 277px;
  width: 390px;
`;

const Error = styled.h1`
  ${header64Bold};
  margin-top: 35px;
  margin-right: auto;
  margin-bottom: 8px;
  margin-left: auto;
  color: ${({theme}) => theme.checkout.textColor};
`;

const DescriptionLine = styled(Title)`
  font-weight: normal;
  text-align: center;
`;

const Button = styled(ActionButton)`
  height: 40px;
  min-width: 240px;
  border-radius: 0;
  ${body14Bold};
  ${media.minTablet`
    min-width: 150px;
  `}
`;

const BackToHomepageLink = styled(Link).attrs({
  href: '/',
  hard: true,
})`
  margin-top: 32px;
  margin-right: auto;
  margin-bottom: 32px;
  margin-left: auto;
  ${media.minTablet`
    margin-right: auto;
    margin-left: auto;
  `}
`;

const ErrorPage = ({statusCode, errorDescriptionKey}) => {
  const {t} = getLocalizationService();

  const descriptionTextElement =
    (errorDescriptionKey && t(errorDescriptionKey)) ||
    t('error_page_description', {returnObjects: true})
      ?.map((line, i) => ({line, key: i}))
      ?.map(({line, key}) => <DescriptionLine key={key}>{line}</DescriptionLine>);

  return (
    <Container>
      <PageHeader isHardLogoClick />

      <Visual alt="error_page_visual" />

      {statusCode && <Error>{statusCode}</Error>}

      {descriptionTextElement}

      <BackToHomepageLink>
        <Button>{t('back_to_homepage')}</Button>
      </BackToHomepageLink>
    </Container>
  );
};

export default ErrorPage;
