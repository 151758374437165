import {MINUTE} from './commonConsts';

export enum ScooberJobStatuses {
  Confirmed = 'Confirmed',
  Cooking = 'Cooking',
  Delivering = 'Delivering',
  EatingTime = 'EatingTime',
  Canceled = 'Canceled',
}

export const SortingScooberJobStatusesOrder = {
  [ScooberJobStatuses.Canceled]: 4,
  [ScooberJobStatuses.EatingTime]: 3,
  [ScooberJobStatuses.Delivering]: 2,
  [ScooberJobStatuses.Cooking]: 1,
  [ScooberJobStatuses.Confirmed]: 0,
};

export enum ScooberBarStatuses {
  FINISHED = 'finished', // orange fill
  ON_HOLD = 'onHold', // gray fill
  LOADING = 'loading', // animation is on!
}

export const SCOOBER = 'scoober';

export const SCOOBER_INTERVAL_TIME = 2 * MINUTE;

export const SCOOBER_REDIRECT_TIME_MINUTES = 20;
