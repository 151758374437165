import styled, {css} from 'styled-components';

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const FlexColumn = styled.div`
  ${flexColumn}
`;
