import {RestaurantFromSearch} from '~/shared/store/models';
import {
  singleRestaurantHasNoOrders,
  singleRestaurantHasOrdersAndNotExceededMinimumPrice,
  singleRestaurantsExceededMinimalPrice,
} from '~/shared/store/selectors/selectorUtils/restaurantsListSort/helpers';

export const compareActivePoolRule = (a: RestaurantFromSearch, b: RestaurantFromSearch) => {
  if (a.hasActivePoolRule && !b.hasActivePoolRule) {
    return -1;
  }

  if (b.hasActivePoolRule && !a.hasActivePoolRule) {
    return 1;
  }

  return 0;
};

export const compareScooberDelivery = (a: RestaurantFromSearch, b: RestaurantFromSearch) => {
  if (a.isScoober && !b.isScoober) {
    return -1;
  }

  if (b.isScoober && !a.isScoober) {
    return 1;
  }

  return 0;
};

export const compareRestaurantsHaveOrders = (a: RestaurantFromSearch, b: RestaurantFromSearch) => {
  if (
    !singleRestaurantHasNoOrders(a) &&
    singleRestaurantHasNoOrders(b)
  ) {
    return -1;
  }

  if (
    singleRestaurantHasNoOrders(a) &&
    !singleRestaurantHasNoOrders(b)
  ) {
    return 1;
  }

  return 0;
};

export const compareRestaurantsOrderSum = (a: RestaurantFromSearch, b: RestaurantFromSearch) => {
  if (
    singleRestaurantHasOrdersAndNotExceededMinimumPrice(a) &&
    singleRestaurantsExceededMinimalPrice(b)
  ) {
    return -1;
  }

  if (
    singleRestaurantHasOrdersAndNotExceededMinimumPrice(b) &&
    singleRestaurantsExceededMinimalPrice(a)
  ) {
    return 1;
  }

  return 0;
};

export const compareRestaurantsBeforeExceedingSum = (a: RestaurantFromSearch, b: RestaurantFromSearch) => {
  if (
    singleRestaurantHasOrdersAndNotExceededMinimumPrice(a) &&
    singleRestaurantHasOrdersAndNotExceededMinimumPrice(b)
  ) {
    const resASumLeft = a.minimumPriceForOrder - a.pooledOrderSum;
    const resBSumLeft = b.minimumPriceForOrder - b.pooledOrderSum;

    return resBSumLeft - resASumLeft;
  }

  return 0;
};
