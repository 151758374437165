import {filter} from 'lodash';

import {MoneycardAndCreditcard, PaymentMethodTypes} from '~/shared/consts/paymentConsts';

import {CheckoutPayment, Payment, User} from '../store/models';

// this typeguard is a workaround for redux-toolbelt wrong dispatch type
export const isAvailablePayments = (data: unknown): data is {availablePayments: Payment[]} =>
  !!data && typeof data === 'object' && 'availablePayments' in data;

const createPaymentMethodTypePredicate =
  (methodType: EnumValueType<typeof PaymentMethodTypes>) =>
    ({paymentMethod, assigned}: Payment) =>
      paymentMethod === methodType && !assigned;

const isCheckoutPayment = (payment: unknown): payment is CheckoutPayment => {
  return Boolean(payment && typeof payment === 'object' && 'isDisabled' in payment);
};
  
const getPrioritizedPayment = ({creditCardPayment, paypalPayment, cashPayment}: {creditCardPayment?: Payment; paypalPayment?: Payment; cashPayment?: Payment}) => {
  if (creditCardPayment) {
    return creditCardPayment;
  }

  if (paypalPayment) {
    return paypalPayment;
  }

  if (cashPayment) {
    return cashPayment;
  }
};

export const sortPaymentsByPriority = (payments: CheckoutPayment[], userData?: User | null): CheckoutPayment[] => {
  const splittedCurrentPayments = {
    [PaymentMethodTypes.MONEY_CARD]: [
      ...filter(
        payments,
        payment =>
          payment.paymentMethod === PaymentMethodTypes.MONEY_CARD &&
          payment.isTenbisCredit === false &&
          payment.userId === userData?.userId,
      ),
      ...filter(
        payments,
        payment =>
          payment.paymentMethod === PaymentMethodTypes.MONEY_CARD &&
          payment.isTenbisCredit === false &&
          payment.userId !== userData?.userId,
      ),
    ],
    [PaymentMethodTypes.CREDIT_CARD]: filter(payments, {paymentMethod: PaymentMethodTypes.CREDIT_CARD}) || [],
    [PaymentMethodTypes.PAYPAL]: filter(payments, {paymentMethod: PaymentMethodTypes.PAYPAL}) || [],
    [PaymentMethodTypes.CASH]: filter(payments, {paymentMethod: PaymentMethodTypes.CASH}) || [],
    [PaymentMethodTypes.UNKNOWN]: filter(payments, {paymentMethod: PaymentMethodTypes.UNKNOWN}) || [],
    [PaymentMethodTypes.CREDIT]:
      filter(payments, {paymentMethod: PaymentMethodTypes.MONEY_CARD, isTenbisCredit: true}) || [],
    [PaymentMethodTypes.APPLE_PAY]: filter(payments, {paymentMethod: PaymentMethodTypes.APPLE_PAY}) || [],
  };

  return [
    ...splittedCurrentPayments[PaymentMethodTypes.MONEY_CARD],
    ...splittedCurrentPayments[PaymentMethodTypes.CREDIT_CARD],
    ...splittedCurrentPayments[PaymentMethodTypes.PAYPAL],
    ...splittedCurrentPayments[PaymentMethodTypes.CASH],
    ...splittedCurrentPayments[PaymentMethodTypes.CREDIT],
    ...splittedCurrentPayments[PaymentMethodTypes.APPLE_PAY],
  ];
};

const assignPaymentsInCheckout = (availablePayments: Payment[], currentCheckoutPayments: CheckoutPayment[]): CheckoutPayment[] => {
  return availablePayments.map(payment => {
    const paymentInCheckout = currentCheckoutPayments.find(
      checkoutPayment => checkoutPayment.cardId === payment.cardId && (!checkoutPayment.isDisabled || payment.isTenbisCredit),
    );

    return {
      ...payment,
      assigned: Boolean(paymentInCheckout),
      isDisabled: Boolean(paymentInCheckout?.isDisabled),
    };
  });
};

const getNonCompanyUserDefaultAvailablePayments = (
  availablePayments: Payment[],
  currentCheckoutPayments: CheckoutPayment[],
) => {
  if (currentCheckoutPayments?.length > 0) {
    return assignPaymentsInCheckout(availablePayments, currentCheckoutPayments);
  }

  const tenbisCredit = availablePayments.find(({isTenbisCredit}) => isTenbisCredit);
  const isAddTenbisCredit = tenbisCredit && tenbisCredit.prepaidBalance;

  const creditCardPayment = availablePayments.find(({paymentMethod}) => paymentMethod === PaymentMethodTypes.CREDIT_CARD);
  const paypalPayment = availablePayments.find(({paymentMethod}) => paymentMethod === PaymentMethodTypes.PAYPAL);
  const cashPayment = availablePayments.find(({paymentMethod}) => paymentMethod === PaymentMethodTypes.CASH);
  
  const prioritizedPayment = getPrioritizedPayment({creditCardPayment, paypalPayment, cashPayment});
  const newDefaultPayment: CheckoutPayment[] = [];
  if (prioritizedPayment) {
    newDefaultPayment.push({...prioritizedPayment, isDisabled: false, assigned: true});
  }
  if (isAddTenbisCredit) {
    newDefaultPayment.push({...tenbisCredit, isDisabled: true, assigned: true});
  }

  return newDefaultPayment
    .filter(isCheckoutPayment);
};

const getCompanyUserDefaultAvailablePayments = (
  availablePayments: Payment[],
  currentCheckoutPayments: CheckoutPayment[],
) => {
  if (currentCheckoutPayments?.length > 0) {
    return assignPaymentsInCheckout(availablePayments, currentCheckoutPayments);
  }

  return availablePayments.map(payment => ({
    ...payment,
    isDisabled: payment.isTenbisCredit,
    assigned: Boolean(payment.isTenbisCredit && payment.prepaidBalance) || payment.assigned,
  }));
};

const constructCompanyUserCheckoutPayment = (
  availablePayments: Payment[],
  currentCheckoutPayments: CheckoutPayment[],
  defaultAvailablePayments: CheckoutPayment[],
  assignedPayment: CheckoutPayment[],
): CheckoutPayment[] => {
  const firstCreditCard = defaultAvailablePayments
    .filter(createPaymentMethodTypePredicate(PaymentMethodTypes.CREDIT_CARD))
    .sort((a, b) => b.cardId - a.cardId)[0];
  const payPalPayment = defaultAvailablePayments.find(createPaymentMethodTypePredicate(PaymentMethodTypes.PAYPAL));
  // When switching delivery rule type between Asap and Pooled, cash option will be excluded from the current available
  // payments as there is no cash in pool orders, the cash payment should be selected from getAvailablePayments response
  const cashPayment = availablePayments.find(createPaymentMethodTypePredicate(PaymentMethodTypes.CASH));
  const hasAssignedCash = assignedPayment.some(payment => payment.paymentMethod === PaymentMethodTypes.CASH);

  const additionalCards = [firstCreditCard, payPalPayment]
    .filter(isCheckoutPayment)
    .filter(p => !wasPaymentRemovedByUser(p, currentCheckoutPayments));

  if (cashPayment && !hasAssignedCash) {
    additionalCards.push({...cashPayment, isDisabled: false});
  }

  return [
    ...(assignedPayment),
    ...(additionalCards),
  ];
};

export const constructCheckoutPayments = (
  availablePayments: Payment[] = [],
  currentCheckoutPayments: CheckoutPayment[],
  checkoutPaymentExtraProps?: Omit<CheckoutPayment, keyof Payment | 'isDisabled'>,
  userData?: User | null,
): CheckoutPayment[] => {
  const defaultAvailablePayments = (userData?.isCompanyUser ? getCompanyUserDefaultAvailablePayments : getNonCompanyUserDefaultAvailablePayments)(availablePayments, currentCheckoutPayments);
  const assignedPayment = defaultAvailablePayments.filter(({assigned}) => assigned);
  const newCheckoutPayments = userData?.isCompanyUser ? constructCompanyUserCheckoutPayment(
    availablePayments,
    currentCheckoutPayments,
    defaultAvailablePayments,
    assignedPayment,
  ) : [...assignedPayment];

  return sortPaymentsByPriority(newCheckoutPayments, userData);
};

function wasPaymentRemovedByUser(p: Payment, currentCheckoutPayments: CheckoutPayment[]) {
  return (
    !!currentCheckoutPayments.length &&
    !currentCheckoutPayments.find(cp => p.paymentMethod === cp.paymentMethod && p.cardId === cp.cardId)
  );
}

export const getMoneyCard = (payment: Payment): payment is Payment<PaymentMethodTypes.MONEY_CARD> =>
  payment.paymentMethod === PaymentMethodTypes.MONEY_CARD && !payment.isTenbisCredit;

const isCreditcard = (card: Payment): card is Payment<PaymentMethodTypes.CREDIT_CARD> =>
  card.paymentMethod === PaymentMethodTypes.CREDIT_CARD;

export const getMoneyCardAndCreditCardFromPayments = (paymentsProcessedData: MoneycardAndCreditcard, payment: Payment): MoneycardAndCreditcard => {
  if (paymentsProcessedData[PaymentMethodTypes.CREDIT_CARD] && paymentsProcessedData[PaymentMethodTypes.MONEY_CARD]) {
    return paymentsProcessedData;
  }

  if (getMoneyCard(payment) && !paymentsProcessedData[PaymentMethodTypes.MONEY_CARD]) {
    return {
      ...paymentsProcessedData,
      [PaymentMethodTypes.MONEY_CARD]: payment,
    };
  }

  return {
    ...paymentsProcessedData,
    [PaymentMethodTypes.CREDIT_CARD]: isCreditcard(payment) ? payment : null,
  };
};
