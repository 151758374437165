import {split} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';

export function getRestaurantDistance({distanceFromUser}) {
  const {t} = getLocalizationService();
  const [distanceBeforeDot] = split(distanceFromUser, '.', 2);
  const isKm = Number(distanceBeforeDot) > 1000;

  const distance = Number(distanceBeforeDot);

  if (!isKm) {
    return {
      distance: t('metre', {distance}),
      distanceNumeric: distance,
    };
  }

  const [kmDistanceBeforeDot, kmDistanceAfterDot] = split(String(Number(distanceFromUser) / 1000), '.', 2);
  return {
    distance: `${kmDistanceBeforeDot}.${kmDistanceAfterDot.substr(0, 2)} ${t('km')}`,
    distanceNumeric: Number(distanceBeforeDot),
  };
}
