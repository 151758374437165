import styled, {css} from 'styled-components';

import {body18Bold} from '~/shared/theme/typography';
import {media} from '~/shared/theme/utils';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

import {Item, ItemWithNoBottom} from '../shared';

const titleWidth = 124;

const TitlePlaceholderCss = css`
  display: ${({theme}) => theme.checkout.elements.title.display};
  ${media.minLargeTablet`
    width: ${titleWidth}px;
    margin-bottom: 0;
    ${flipOnLTR`
      margin-left: 16px;
    `}  
  `}
  ${media.maxLargeTablet`
    width: 100%;
  `}
`;

const Title = styled.div`
  ${body18Bold}
  padding: ${({theme}) => theme.checkout.elements.title.padding};
  line-height: ${({theme}) => theme.checkout.elements.title.lineHeight};
  margin-bottom: ${({theme}) => theme.checkout.elements.title.marginBottom};
  ${TitlePlaceholderCss}
`;

const FlexPlaceholder = styled.div`
  ${TitlePlaceholderCss}
`;

const Body = styled.div`
  flex: 1;
  padding: ${({theme}) => theme.checkout.elements.itemBody.padding};
`;

const CheckoutItemWrapper = styled(Item)`
  ${media.maxLargeTablet`
    ${({withTitle}) => (!withTitle && 'padding: 16px 0 0 0')}
  `}
`;

const CheckoutItem = ({title, children, withBottom = true, className, withTitle = true}) => (
  <CheckoutItemWrapper as={withBottom ? undefined : ItemWithNoBottom} withTitle={withTitle}>
    {withTitle ? <Title as="h2">{title}</Title> : <FlexPlaceholder/>}

    <Body className={className}>{children}</Body>
  </CheckoutItemWrapper>
);

export default CheckoutItem;
