import {memo, useCallback, useRef, useState, useEffect} from 'react';

import styled, {css} from 'styled-components';

import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {Fieldset, HiddenLegend, SharedCheckoutCloseButton} from '~/shared/components/styled';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {flipOnLTR, media} from '~/shared/theme/utils';
import {ENTER_KEY_CODE} from '~/shared/consts/commonConsts';
import {DiscountCouponsValueTypes} from '~/shared/consts/checkoutConsts';
import {trackEvent} from '~/shared/services/analytics';
import {HiddenStyledRadio} from '~/shared/components/RadioButton';
import couponBlueIconUrl from '~/assets/images/icons/coupon-blue.svg';
import Button from '~/shared/components/Button';
import {getLocalizationService} from '~/shared/services/localisationService';
import {getIsKeyboardInUse} from '~/shared/usingKeyboard/keyboardHelper';
import {flexColumn, flexCenterVertically, flexColumnCenterVertically} from '~/shared/theme/FlexLayout';
import {body14Bold, body16Normal, body14Normal} from '~/shared/theme/typography';

import {SectionTitle, SwitchCouponButton} from '../shared';

import CouponAutoChangedDisclaimer from './CouponAutoChangedDisclaimer';

const CouponIconCss = css`
  width: 24px;
  height: 24px;
  ${flipOnLTR`
    margin-left: 4px;
  `}
`;

const CouponIconBlue = styled(ImageWithAlt).attrs({
  noAlt: true,
  src: couponBlueIconUrl,
})`
  ${CouponIconCss}
`;

const AddCouponWrapper = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
  border: 1px solid ${({hasError, theme}) => (hasError ? theme.colors.error : theme.colors.text)};
  margin: ${({theme}) => theme.checkout.elements.addCouponWrapper.margin};
`;

const CouponInput = styled.input`
  ${body16Normal}
  ${media.minTablet`
    ${body14Normal}
  `}
  width: 100%;
  height: 100%;
  border: 0;
  outline: none;

  ::placeholder {
    color: ${({theme}) => theme.colors.text};
  }

  ${flipOnLTR`
    padding-right: 16px;
  `}
`;

const submitButtonWidth = '86px';

const SubmitButton = styled(Button)`
  width: ${submitButtonWidth};
  min-width: ${submitButtonWidth};
  height: 38px;
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  ${({disabled}) =>
    disabled &&
    `
    background-color: ${({theme}) => theme.colors.gray500};
  `}
`;

const CloseButton = styled(Button)`
  display: flex;
  width: 40px;
`;

const CloseButtonImage = styled(SharedCheckoutCloseButton)`
  width: 25px;
  height: 25px;
  position: relative;
`;

const CurrentCouponWrapper = styled.div`
  ${flexCenterVertically}
  ${({onClick}) => onClick && 'cursor: pointer;'}
  ${flipOnLTR`
    margin-right: auto;
  `}
`;

const ToCouponsListButton = styled(SwitchCouponButton)`
  ${flexCenterVertically}
  line-height: 28px;
  ${flipOnLTR`
    margin-right: auto;
  `}
`;

const CouponsList = styled(ClickOutsideDiv)`
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  position: absolute;
  width: 100%;
  background-color: ${({theme}) => theme.colors.surface};
  z-index: 150;
`;

const Label = styled.label`
  height: 40px;
  width: 100%;
  ${flexColumnCenterVertically};
  cursor: pointer;
  ${flipOnLTR`
    padding-right: 16px;
  `}
  &:hover {
    background-color: ${({theme}) => theme.colors.darkBackground};
  }

  ${({current}) =>
    current &&
    `
    background-color: ${({theme}) => theme.colors.darkBackground};
  `}
`;

const CouponError = styled.div.attrs({
  role: 'alert',
})`
  color: ${({theme}) => theme.colors.error};
  ${flipOnLTR`
    margin-right: 14px;
  `}
`;

const ListWrapper = styled.ul`
  z-index: 50;
  list-style-type: none;
  padding: 0;
  ${flexColumn};
  margin: 0 auto;
`;

const ListItem = styled.li`
  display: flex;
`;

const CustomHiddenStyledRadio = styled(HiddenStyledRadio)`
  &:checked ~ label {
    background-color: ${({theme}) => theme.colors.darkBackground};
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 16px;
  ${media.minLargeTablet`
    margin: 0;
  `}
`;

const CouponTitle = styled.div`
  ${body14Bold}
`;

const AddCouponInput = ({onBackClick, onSubmitCoupon, hasError}) => {
  const {t} = getLocalizationService();
  const [inputValue, setInputValue] = useState('');
  const autoFocusOnMount = useCallback(nodeEl => {
    if (!nodeEl) {
      return;
    }

    nodeEl.focus();
  }, []);

  return (
    <AddCouponWrapper hasError={hasError}>
      <CouponInput
        role="alert"
        aria-label={t('coupon_code')}
        placeholder={t('coupon_code')}
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        ref={autoFocusOnMount}
      />
      <CloseButton aria-label={t('remove_coupon')} onClick={onBackClick}>
        <CloseButtonImage alt={t('remove_coupon')} />
      </CloseButton>
      <SubmitButton onClick={() => onSubmitCoupon(inputValue)} disabled={!inputValue}>
        {t('add')}
      </SubmitButton>
    </AddCouponWrapper>
  );
};

const CurrentCoupon = ({currentCoupon, onChangeCouponClick}) => {
  const {t} = getLocalizationService();
  if (!currentCoupon) {
    return null;
  }

  const couponTextKey = currentCoupon.valueType === 'Percent' ? 'discount_xxx_xxx_percent' : 'discount_xxx_xxx';
  const couponText = t(couponTextKey, {
    value: currentCoupon.discountValue,
    symbol: DiscountCouponsValueTypes[currentCoupon.valueType],
  });

  return (
    <CurrentCouponWrapper>
      <CouponIconBlue alt={couponText} />
      <SwitchCouponButton onClick={onChangeCouponClick} alt={t('change_coupon')}>
        {couponText}
      </SwitchCouponButton>
    </CurrentCouponWrapper>
  );
};

const couponModeTypes = {
  ADD_COUPON: 'addCoupon',
  SELECT_COUPON: 'selectCoupon',
  INITIAL_WITH_COUPON: 'initialWithCoupon',
  INITIAl_WITHOUT_COUPON: 'initialWithoutCoupon',
};

const couponModeComponents = {
  [couponModeTypes.ADD_COUPON]: ({onSubmitCouponCode, initialMode, setCurrentMode, resetCouponAutoChanged}) => {
    const [currentCouponError, setCurrentCouponError] = useState(null);

    const onSubmitCouponInput = async couponCode => {
      const {error} = await onSubmitCouponCode(couponCode) || {};
      if (error) {
        setCurrentCouponError(error);
        return;
      }
      resetCouponAutoChanged();
      setCurrentMode(couponModeTypes.INITIAL_WITH_COUPON);
    };

    const onCancelClick = () => {
      setCurrentMode(initialMode);
    };

    return (
      <>
        <AddCouponInput hasError={Boolean(currentCouponError)} onSubmitCoupon={onSubmitCouponInput} onBackClick={onCancelClick} />
        {currentCouponError && <CouponError>{currentCouponError}</CouponError>}
      </>
    );
  },
  [couponModeTypes.SELECT_COUPON]: ({
    allCoupons,
    currentCoupon,
    onCouponSelect,
    setCurrentMode,
    initialMode,
    isRestaurantCouponAvailable,
  }) => {
    const {t, tKeyExists} = getLocalizationService();

    const firstOpenRef = useRef();
    useEffect(() => {
      firstOpenRef.current.focus();
    }, [firstOpenRef]);

    const [newSelectedCouponCode, setNewSelectedCouponCode] = useState(currentCoupon?.code);

    return (
      <CouponsList
        onClickOutside={() => {
          setCurrentMode(initialMode);
          if (newSelectedCouponCode !== currentCoupon?.code) {
            onCouponSelect(newSelectedCouponCode);
          }
        }}
        onKeyDown={({keyCode}) => {
          if (keyCode === ENTER_KEY_CODE) {
            if (!newSelectedCouponCode) {
              setCurrentMode(couponModeTypes.ADD_COUPON);
            }
            onCouponSelect(newSelectedCouponCode);
          }
        }}
      >
        <Fieldset>
          <HiddenLegend>{t('coupons_list_legend_text')}</HiddenLegend>
          <ListWrapper>
            {allCoupons
              // when restaurant coupon available,
              // dont let users to remove it by choosing the empty coupon
              .filter(({code}) => (isRestaurantCouponAvailable ? !!code : true))
              .map(({code, description, discountValue, valueType}, index) => {
                const isChecked = code === newSelectedCouponCode;
                const translationKey = valueType === 'Percent' ? 'discount_xxx_xxx_percent' : 'discount_xxx_xxx';
                const couponLabel = description
                  ? tKeyExists(description)
                    ? t(description)
                    : description
                  : t(translationKey, {value: discountValue, symbol: DiscountCouponsValueTypes[valueType]});
                return (
                  <ListItem key={code}>
                    <CustomHiddenStyledRadio
                      id={`couponsPicker_${code}`}
                      name="couponsPicker"
                      checked={isChecked}
                      isKeyboardInUse={getIsKeyboardInUse()}
                      onChange={() => setNewSelectedCouponCode(code)}
                    />
                    <Label
                      ref={index === 0 ? firstOpenRef : null}
                      htmlFor={`couponsPicker_${code}`}
                      current={isChecked}
                      onClick={() => {
                        onCouponSelect(code);
                        if (!code) {
                          setCurrentMode(couponModeTypes.INITIAl_WITHOUT_COUPON);
                          return;
                        }
                        setCurrentMode(couponModeTypes.INITIAL_WITH_COUPON);
                      }}
                    >
                      {couponLabel}
                    </Label>
                  </ListItem>
                );
              })}
            <ListItem key="add">
              <CustomHiddenStyledRadio
                id="couponsPicker_add"
                name="couponsPicker"
                isKeyboardInUse={getIsKeyboardInUse()}
                onChange={() => setNewSelectedCouponCode(null)}
              />
              <Label
                htmlFor="couponsPicker_add"
                onClick={() => {
                  setCurrentMode(couponModeTypes.ADD_COUPON);
                }}
              >
                <SectionTitle>{`+ ${t('add_new_coupon')}`}</SectionTitle>
              </Label>
            </ListItem>
          </ListWrapper>
        </Fieldset>
      </CouponsList>
    );
  },
  [couponModeTypes.INITIAl_WITHOUT_COUPON]: ({setCurrentMode, allCoupons, isCheckoutModal}) => {
    const {t} = getLocalizationService();
    const isAvailableCoupon = allCoupons?.length > 1;
    return (
      <Row>
        {isCheckoutModal && <CouponTitle>{t('checkout_payment_title')}</CouponTitle>}
        {!isAvailableCoupon && (
          <CurrentCouponWrapper
            tabIndex="0"
            onClick={() => {
              trackEvent('hasSubmittedVoucher');
              setCurrentMode(couponModeTypes.ADD_COUPON);
            }}
          >
            <CouponIconBlue />
            <SwitchCouponButton>{t('i_have_a_coupon_code')}</SwitchCouponButton>
          </CurrentCouponWrapper>
        )}
        {isAvailableCoupon && (
          <ToCouponsListButton onClick={() => setCurrentMode(couponModeTypes.SELECT_COUPON)}>
            {t('change_coupon')}
          </ToCouponsListButton>
        )}
      </Row>
    );
  },
  [couponModeTypes.INITIAL_WITH_COUPON]: ({setCurrentMode, currentCoupon, isCouponAutoChanged, isCheckoutModal}) => {
    const {t} = getLocalizationService();
    return (
      <>
        <Row>
          {isCheckoutModal && <CouponTitle>{t('checkout_payment_title')}</CouponTitle>}
          <CurrentCoupon
            onChangeCouponClick={() => setCurrentMode(couponModeTypes.SELECT_COUPON)}
            currentCoupon={currentCoupon}
          />
        </Row>
        {isCouponAutoChanged && currentCoupon && (
          <CouponAutoChangedDisclaimer
            onSelectCouponClick={() => setCurrentMode(couponModeTypes.SELECT_COUPON)}
          />
        )}
      </>
    );
  },
};

const CouponModes = ({
  currentCoupon,
  isRestaurantCouponAvailable,
  allCoupons,
  onCouponSelect,
  onSubmitCouponCode,
  isCouponAutoChanged,
  isCheckoutModal,
  resetCouponAutoChanged,
}) => {
  const initialMode = currentCoupon?.code
    ? couponModeTypes.INITIAL_WITH_COUPON
    : couponModeTypes.INITIAl_WITHOUT_COUPON;

  const [currentMode, setCurrentMode] = useState(initialMode);

  //fix initial state on checkout login flow
  useEffect(() => {
    setCurrentMode(initialMode);
  }, [initialMode, currentCoupon]);

  const ComponentByMode = couponModeComponents[currentMode];
  return (
    <>
      <ComponentByMode
        {...{
          currentCoupon,
          isRestaurantCouponAvailable,
          initialMode,
          allCoupons,
          onCouponSelect,
          onSubmitCouponCode,
          setCurrentMode,
          isCouponAutoChanged,
          isCheckoutModal,
          resetCouponAutoChanged,
        }}
      />
    </>
  );
};

export default memo(CouponModes);
