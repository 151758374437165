import './wdyr';

import {useEffect} from 'react';

import {createRoot} from 'react-dom/client';

import {onInitPromise} from './init';
import App from './app';

// eslint-disable-next-line no-restricted-imports
import '~/modals';
import '~/shared/theme/global_style.css';
import '~/shared/theme/tooltip_style.css';
import '~/shared/theme/a11y_style.css';
import 'react-virtualized/styles.css';
import '~/shared/theme/scrollbar_style.css';
import '~/shared/usingKeyboard/keyboardHelper.css';

const AppWithCallbackAfterRender = () => {
  useEffect(() => {
    const initialLoader = document.getElementById('initial-loader');
    if (initialLoader) {
      initialLoader.parentNode.removeChild(initialLoader);
    }
  }, []);

  return <App />;
};

onInitPromise.then(() => {
  const root = createRoot(document.getElementById('root'));
  root.render(<AppWithCallbackAfterRender />);
});
