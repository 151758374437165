import {createSelector} from 'reselect';

import {AppState} from '../../configStore';

export const selectUserDevice = (state: AppState) => state.userDevice;

export const selectIsMinMobile = createSelector(selectUserDevice, userDevice => userDevice?.isMinMobile);
export const selectIsMaxMobile = createSelector(selectUserDevice, userDevice => userDevice?.isMaxMobile);
export const selectIsMobileDevice = createSelector(selectUserDevice, userDevice => userDevice?.isMobileDevice);
export const selectIsTabletDevice = createSelector(selectUserDevice, userDevice => userDevice?.isTabletDevice);
export const selectIsMinLargeMobile = createSelector(selectUserDevice, userDevice => userDevice?.isMinLargeMobile);
export const selectIsMinLargeTablet = createSelector(selectUserDevice, userDevice => userDevice?.isMinLargeTablet);
export const selectIsMinTablet = createSelector(selectUserDevice, userDevice => userDevice?.isMinTablet);
export const selectIsMaxLargeMobile = createSelector(selectUserDevice, userDevice => userDevice?.isMaxLargeMobile);
export const selectIsMinDesktop = createSelector(selectUserDevice, userDevice => userDevice?.isMinDesktop);
export const selectIsMinLargeDesktop = createSelector(selectUserDevice, userDevice => userDevice?.isMinLargeDesktop);

export const selectShouldOpenModal = createSelector(selectIsMinLargeTablet, isMinLargeTablet => isMinLargeTablet);
