import {IMarketingBanner} from '../../models';
import {makeAsyncReducer, composeReducers, makeReducer, StateAsyncProperty} from '../../redux-toolbelt';

import {getMarketingBanners, clearMarketingBanners} from './marketingBannersActions';

export type MarketingBannerState = StateAsyncProperty<IMarketingBanner[]>;

const marketingBannerDefaultState: MarketingBannerState = {
  loaded: false,
  loading: true,
  data: [],
};

export default composeReducers<MarketingBannerState>(
  makeReducer(clearMarketingBanners, () => marketingBannerDefaultState, {
    defaultState: marketingBannerDefaultState,
  }),
  makeAsyncReducer(getMarketingBanners),
);
