/* stylelint-disable property-no-vendor-prefix */
import {useCallback, useState, useRef, useEffect, memo} from 'react';

import {useDispatch} from 'react-redux';
import styled, {css} from 'styled-components';
import {noop} from 'lodash';

import {updatePayment, removePayment, toggleDisable10BisCredit} from '~/shared/store/actions';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {toFixedNum} from '~/shared/utils/general';
import {PriceLabel} from '~/shared/components/PriceLabels';
import {Skeleton} from '~/shared/components/Loaders';
import PaymentMethodIconAndDetails from '~/shared/components/PaymentMethodIconAndDetails';
import {flexCenter, flexCenterVertically} from '~/shared/theme/FlexLayout';
import {useIsMinDesktop} from '~/shared/hooks/deviceInfo';
import {getLocalizationService} from '~/shared/services/localisationService';
import {body14Normal} from '~/shared/theme/typography';
import Button from '~/shared/components/Button';

const Root = styled.div`
  width: 100%;
  height: 50px;
  ${flexCenterVertically};
  justify-content: space-between;
  color: ${({theme}) => theme.colors.text};
  margin-bottom: 16px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${media.minLargeTablet`
    height: 40px;
  `}
`;

const Input = styled.input`
  &&& {
    font-size: 16px;
  }

  direction: ltr;
  height: 100%;
  width: 64px;
  padding: 12px;
  border-radius: 2px;
  border: 1px solid ${({theme}) => `${theme.colors.gray500}`};
  color: ${({theme, hasValue}) => (hasValue ? `${theme.colors.secondary}` : `${theme.colors.text}`)};
  ${media.minMobile`
    &&& {
      font-size: 14px;
    }
  `}

  &:focus,
  &:focus-visible {
    border: 2px solid ${({theme}) => `${theme.colors.surfaceTitle}`};
    color: ${({theme}) => `${theme.colors.secondary}`};
  }
`;

const InputWrapper = styled.div`
  height: 40px;
  direction: rtl;
  width: 10ch;
  min-width: 100px;
  display: flex;
  justify-content: ${({theme}) => (theme.isLTR ? 'flex-start' : 'flex-end')};
  ${media.minMobile`
    ${flipOnLTR`
      margin-right: 16px;
    `}
  `}
  ${media.minTablet`
    height: 32px;
  `}
`;

const Symbol = styled.div`
  margin-right: 8px;
  color: ${({theme}) => theme.colors.text2};
  height: 100%;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const AddAdditionalAmountButton = styled.div`
  height: 20px;
  ${flexCenter};
  cursor: pointer;
  margin: auto 10px;
  padding: 0 6px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  ${media.minDesktop`
    margin: auto 0;
    padding: 0px 3px;
  `}
`;

const ClickWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  overflow: hidden;
  height: 100%;
`;

const PaymentInfo = styled(PaymentMethodIconAndDetails).attrs(() => ({
  style: {
    minWidth: 0,
  },
  isCheckoutPaymentMethodLine: true,
}))`
  &&& {
    flex: 1;
  }

  ${media.minDesktop`
    &&& {
      flex: ${({showPaymentsRemarks}) => (showPaymentsRemarks ? 'initial' : 1)};
    }
  `}
`;

const PaymentRemarkWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 5px 0;
`;

const PaymentRemarkInput = styled(Input)`
  height: 32px;
  border-bottom: 1px solid ${({theme}) => theme.colors.gray500};
  border-radius: 0;
  text-align: center;
  padding: 0 5px;
  background: ${({theme}) => theme.colors.surface};
  margin: 0 auto;

  &&& {
    width: 10ch;
  }
`;

const SkeletonRoot = styled(Root)`
  display: flex;
  flex: 1;
  padding: 5px;
  min-height: 40px;
`;
const CardAndNameWrapper = styled.div`
  display: flex;
  flex: 1;
`;
const SkeletonName = styled(Skeleton)`
  width: 160px;
  height: 18px;
`;
const SkeletonPrice = styled(Skeleton)`
  width: 60px;
  height: 18px;
`;

const removeIconCss = css`
  content: '';
  width: 50%;
  position: absolute;
  border: 1.5px solid ${({theme}) => theme.colors.surface};
  border-radius: 2px;
`;

const addIconCss = css`
  content: '+';
  color: ${({theme}) => theme.colors.surface};
  font-size: 16px;
  margin-bottom: 1px;
  text-align: center;
  line-height: 1;
`;

const AddRemoveButton = styled.div`
  position: relative;
  background: ${({theme, isDisabled}) => (isDisabled ? theme.colors.surfacePrimaryAction : theme.colors.error)};
  cursor: pointer;
  height: 16px;
  width: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &::after {
    ${({isDisabled}) => (isDisabled ? addIconCss : removeIconCss)}
  }
`;

const EnablePaymentButton = styled(Button)`
  ${body14Normal}
  line-height: 14px;
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
`;

export const PaymentMethodLineSkeleton = () => (
  <SkeletonRoot>
    <CardAndNameWrapper>
      <SkeletonName />
    </CardAndNameWrapper>
    <SkeletonPrice />
  </SkeletonRoot>
);

const CheckoutPaymentMethodLine = ({
  payment,
  showRemoveButton,
  isMobile,
  showPaymentsRemarks,
  paymentsRemarks,
  setPaymentsRemarks,
  hasError,
  customPlaceholder,
  differenceFromOrderSum,
  totalAmount,
  onCardSumInputChange,
}) => {
  const {t} = getLocalizationService();
  const isMinDesktop = useIsMinDesktop();
  const dispatch = useDispatch();

  const inputRef = useRef();
  const oldPayment = useRef();

  const [isHover, setIsHover] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState(payment.sum.toString());

  const isActive = isHover || isFocused;

  const {sum, isDisabled, isTenbisCredit} = payment;
  const difference =
    differenceFromOrderSum >= 0 ? differenceFromOrderSum : -Math.min(Math.abs(differenceFromOrderSum), sum);
  const addOrSubtractSign = difference > 0 ? '+' : '-';
  const shouldShowDifference = difference !== 0;

  const selectInput = useCallback(() => {
    if (isDisabled || !inputRef.current) {
      return;
    }

    inputRef.current.focus();
    inputRef.current.select();
  }, [isDisabled]);

  useEffect(() => {
    if (oldPayment?.current?.isDisabled && !payment?.isDisabled) {
      selectInput();
    }

    if (oldPayment?.current?.sum !== payment?.sum) {
      setInputValue(payment.sum.toString());
    }

    oldPayment.current = payment;
  }, [payment, selectInput]);

  const addDifference = useCallback(
    e => {
      e.stopPropagation();
      dispatch(updatePayment({...payment, sum: toFixedNum(payment.sum + difference, 2)}));
    },
    [payment, difference, dispatch],
  );

  const handleRemovePayment = () => dispatch(removePayment(payment.cardId));
  const handleToggleDisable = () => dispatch(toggleDisable10BisCredit());
  
  const onRemoveClick = () => {
    if (isTenbisCredit) {
      handleToggleDisable();
      return;
    }

    handleRemovePayment(payment.cardId);
  };

  const enablePayment = e => {
    e.stopPropagation();
    dispatch(updatePayment({...payment, assigned: true, isDisabled: false}));
  };

  const onEditAmountChange = e => {
    const value = e.target.value.replace(/[^\d.]+/g, '').replace(/(\d*\.\d{0,2})(.*)/g, '$1');

    if (value.slice(-1) === '.') {
      return setInputValue(value);
    }

    setInputValue(inputValue.toString());
    onCardSumInputChange({inputValue: Math.min(Number(value), totalAmount), cardId: payment.cardId});
  };

  return (
    <Root onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} {...{isActive, hasError}}>
      {showRemoveButton && (
        <AddRemoveButton
          aria-pressed="false"
          role="button"
          isDisabled={isDisabled}
          onClick={onRemoveClick}
        />
      )}
      <ClickWrapper onClick={!isMinDesktop || !showPaymentsRemarks ? selectInput : noop}>
        <PaymentInfo {...{payment, isDisabled, showPaymentsRemarks, t}} horizontal={!isMobile} />
        {isMinDesktop && showPaymentsRemarks && !isDisabled && (
          <PaymentRemarkWrapper>
            <PaymentRemarkInput
              key={payment.name}
              value={paymentsRemarks[`${payment.cardId}_${payment.paymentMethod}`] || ''}
              onChange={({target: {value}}) =>
                setPaymentsRemarks({
                  value,
                  cardId: payment.cardId,
                  paymentMethod: payment.paymentMethod,
                })}
              placeholder={t(customPlaceholder || 'account_number')}
              title={t('account_number')}
            />
          </PaymentRemarkWrapper>
        )}

        {shouldShowDifference && !isDisabled && (
          <AddAdditionalAmountButton onClick={addDifference}>
            <PriceLabel price={Math.abs(difference)} sign={addOrSubtractSign} />
          </AddAdditionalAmountButton>
        )}

        {isDisabled && isTenbisCredit ? (
          <EnablePaymentButton onClick={handleToggleDisable}>{t('add')}</EnablePaymentButton>
        ) : (
          <InputWrapper {...{isActive, selectInput}}>
            <Input
              hasValue={inputValue !== '0'}
              aria-label={t('insert_amount_to_pay_with', {paymentMethodLabel: t(payment.paymentMethod.toLowerCase())})}
              role="alert"
              key={payment.name}
              data-test-id={`${payment.paymentMethod}-${payment.cardId}`}
              value={inputValue}
              onChange={onEditAmountChange}
              ref={inputRef}
              onFocus={e => {
                enablePayment(e);
                setIsFocused(true);
              }}
              onBlur={() => setIsFocused(false)}
              inputmode="numeric"
              pattern="[0-9]*"
              type="text"
            />
            <Symbol>₪</Symbol>
          </InputWrapper>
        )}
      </ClickWrapper>
    </Root>
  );
};

export default memo(CheckoutPaymentMethodLine);
