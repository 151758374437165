import {KeyboardEvent} from 'react';

import numeral from 'numeral';
import {camelCase} from 'lodash';
import set from 'lodash/fp/set';

import {ENTER_CODE} from '~/shared/consts/commonConsts';
import {DELIVERY_RULES, RestaurantTagType, RestaurantTagTypes} from '~/shared/consts/restaurantConsts';
import {LocalStorageKeys} from '~/shared/consts/localStorageConsts';
import {isAfterTimeInLocalStorage} from '~/shared/utils/time';
import {breakpoints} from '~/shared/theme/media';

import {getLocalizationService} from '../services/localisationService';

const FOCUSABLE_ELEMENTS_SELECTOR = [
  'a',
  'button:not([disabled]):not([tabindex="-1"])',
  'input:not([disabled]):not([type="hidden"]):not([tabindex="-1"])',
  'select:not([disabled]):not([tabindex="-1"])',
  'textarea:not([disabled]):not([tabindex="-1"])',
  'iframe:not([disabled]):not([tabindex="-1"])',
];

const API_HEBREW_ASAP_TIME_KEY = 'בהקדם';

export const getFocusableElements = (
  {domEl, searchForFocusableChildren}: {domEl: HTMLElement; searchForFocusableChildren: boolean},
  callback: (els: HTMLElement[]) => void,
) => {
  let attempts = 10;
  let currentTimeout: NodeJS.Timeout;

  function tryToGetFocusableElements() {
    try {
      const focusableElements = Array.from(
        searchForFocusableChildren
          ? domEl.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENTS_SELECTOR.join(', '))
          : [domEl],
      ).filter(elem => elem.clientHeight > 0);

      if (!focusableElements.length) {
        attempts--;
        if (attempts > 0) {
          currentTimeout = setTimeout(tryToGetFocusableElements, 200);
        }
        return;
      }

      callback(focusableElements);
      return focusableElements;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('error while focus on first focus-able element', {e});
    }
  }

  tryToGetFocusableElements();

  const cancel = () => {
    clearTimeout(currentTimeout);
  };

  return cancel;
};

const getClsRegexp = (className: string) => new RegExp(`(\\s|^)${className}(\\s|$)`);

export function hasClass(el: HTMLElement, className: string) {
  return getClsRegexp(className).test(el.className);
}

export function addClass(el: HTMLElement, className: string) {
  if (!hasClass(el, className)) {
    el.className = `${el.className} ${className}`;
  }
}

export function removeClass(el: HTMLElement, className: string) {
  el.className = el.className.replace(getClsRegexp(className), ' ').replace(/ +/g, ' ');
}

export const clickOnEnterProps = {
  onKeyDown: (e: KeyboardEvent<HTMLElement>) => {
    if (e.code === ENTER_CODE) {
      const target = e.target as HTMLElement;

      e.preventDefault();
      target.click();
    }
  },
};

export const toLowerCamelCase = <T extends string | any[] | Record<string, any>>(target: T): T => {
  if (!target) {
    return target;
  }
  if (Array.isArray(target)) {
    return target.map(toLowerCamelCase) as T;
  }
  if (typeof target === 'object') {
    return Object.entries(target).reduce(
      (result, [key, value]) => ({
        ...result,
        [camelCase(key)]: toLowerCamelCase(value),
      }),
      {} as Record<string, any>,
    ) as T;
  }

  return target;
};

const upperCamelCase = (str: string) => str.substring(0, 1).toUpperCase() + camelCase(str.substring(1));

export const toUpperCamelCase = <T extends object | any[] | Record<string, any>>(obj: T): T => {
  if (Array.isArray(obj)) {
    return obj.map(toUpperCamelCase) as T;
  }
  if (obj && typeof obj === 'object') {
    return Object.entries(obj).reduce(
      (result, [key, value]) => ({
        ...result,
        [upperCamelCase(key)]: toUpperCamelCase(value),
      }),
      {},
    ) as T;
  }
  return obj;
};

export const calcSideBarBottom = (
  sideBarReady: boolean,
  containerBottom: number,
  headerHeight: number,
  scrollTop: number,
  windowScrollHeight: number,
) => {
  const defaultBottom = 10;

  if (!sideBarReady) {
    return defaultBottom;
  }

  const scrollBarPosition = scrollTop + windowScrollHeight - headerHeight;
  const scrollBarUnderTheContainerDelta = containerBottom - scrollBarPosition;

  return scrollBarUnderTheContainerDelta > 0 ? defaultBottom : (-1 * scrollBarUnderTheContainerDelta) + 20;
};
export const scrollWindowTop = ({top = 0, disabledAnimation = false, isIos = false} = {}) => {
  const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
  const scrollParams: ScrollToOptions = {top, behavior: disabledAnimation ? 'auto' : 'smooth'};
  if (supportsNativeSmoothScroll) {
    if (isIos) {
      setTimeout(() => window.scrollTo(scrollParams), 0);
      return;
    }
    window.scrollTo(scrollParams);
  } else {
    if (isIos) {
      setTimeout(() => window.scrollTo(0, top), 0);
      return;
    }
    window.scrollTo(0, top);
  }
};

// eslint-disable-next-line no-restricted-globals
export const isNumeric = (num: any) => !isNaN(parseFloat(num)) && isFinite(num);

export const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

export const capitalFirstLetterAfterSpace = (s: string) => s.split(' ').map(capitalize).join(' ');

export const splitOnFirstSpace = (str: string) => [
  str.substring(0, str.indexOf(' ')),
  str.substring(str.indexOf(' ') + 1),
];

export const ensureEncoded = (url: string) => encodeURI(decodeURI(url));

export const safeJsonParse = (str: string) => {
  try {
    const json = JSON.parse(str);
    return json;
  } catch (e) {
    return null;
  }
};

export function wait(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const tagBackgroundColorType = (tagType: RestaurantTagType, companyId: number) => {
  if (!tagType) {
    return;
  }

  if (tagType === RestaurantTagTypes.DISCOUNT_COUPON) {
    return 'DiscountCoupon';
  }
  if (tagType === RestaurantTagTypes.PROMOTED) {
    if (companyId === 5664) {
      return 'PromotedMaccabi';
    }
    if (companyId === 4913 || companyId === 4928) {
      return 'PromotedGov';
    }
  }
};

export const validatePhoneNumber = (value: string, dictionary = 'invalid_phone_number') => {
  const {t} = getLocalizationService();
  const isNum = /^\d+$/;
  return !isNum.test(value) || value.length < 9 ? t(dictionary) : undefined;
};

export const isMoneycardActivationRequired = ({
  moneycardActivationRequired,
}: {
  moneycardActivationRequired: boolean;
}) => {
  const moneycardActivateSnoozeTime = isAfterTimeInLocalStorage({
    localStorageKey: LocalStorageKeys.MONEYCARD_ACTIVATION_SNOOZE_TIME,
  });
  return moneycardActivateSnoozeTime && moneycardActivationRequired;
};

export const toFixedNum = (num: number, precision: number) => Number(num.toFixed(precision));

export const getSizesFromScreenWidth = (currentWidth = window.innerWidth) => ({
  isMinSmallMobile: currentWidth >= breakpoints.smallMobile,
  isMaxSmallMobile: currentWidth < breakpoints.smallMobile,
  isMinMobile: currentWidth >= breakpoints.mobile,
  isMaxMobile: currentWidth < breakpoints.mobile,
  isMinLargeMobile: currentWidth >= breakpoints.largeMobile,
  isMaxLargeMobile: currentWidth < breakpoints.largeMobile,
  isMaxTablet: currentWidth < breakpoints.tablet,
  isMinTablet: currentWidth >= breakpoints.tablet,
  isMinLargeTablet: currentWidth >= breakpoints.largeTablet,
  isMaxLargeTablet: currentWidth < breakpoints.largeTablet,
  isMinDesktop: currentWidth >= breakpoints.desktop,
  isMaxDesktop: currentWidth < breakpoints.desktop,
  isMinLargeDesktop: currentWidth >= breakpoints.largeDesktop,
  isMaxLargeDesktop: currentWidth < breakpoints.largeDesktop,
});

export const secureOpenWindow = (url: string, target: string) => {
  // eslint-disable-next-line
  const ref = window.open(url, target, 'noopener');

  if (ref && ref.opener) {
    ref.opener = null;
  }
  return ref;
};

export const roundDecimal = (value: number) => Number(numeral(value).format('0.00'));

export const setIn = <T extends object>(object: T, path: any, value: any) => set(path, value, object) as T;

export const isCurrentTimeKeyAsap = (timeKey?: DELIVERY_RULES | string) => {
  if (!timeKey) {
    return false;
  }

  const timeKeyInLowerCase = timeKey.toLowerCase();
  return timeKeyInLowerCase === API_HEBREW_ASAP_TIME_KEY || timeKeyInLowerCase === DELIVERY_RULES.ASAP.toLowerCase();
};
