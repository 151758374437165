import {ReactNode, useContext} from 'react';

import styled, {css} from 'styled-components';

import {DeliveryMethods} from '~/shared/consts/restaurantConsts';
import {RestaurantFromSearch} from '~/shared/store/models';
import getClosedRestaurantMessage from '~/shared/services/getRestaurantClosedMessage';
import {getLocalizationService, i18nHelpersProps} from '~/shared/services/localisationService';
import {flexCenter} from '~/shared/theme/FlexLayout';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

import {RestaurantItemContext} from '../context';
import PooledOrderContent from '../Tags/RestaurantTagPooledOrder/PooledOrderContent';
import {PooledOrderTagState} from '../Tags/RestaurantTagPooledOrder/RestaurantTagPooledOrderUtils';

import CalenderIcon from './calender_icon.svg?react';

export enum HeaderTypes {
  CLOSED_RESTAURANT = 'closedRestaurant',
  OPEN_FOR_FUTURE_ORDER = 'openForFutureOrder',
  POOLED_ORDER_OVER_MINIMUM = 'pooledOrderOverTheMinimum',
  POOLED_ORDER = 'pooledOrder',
}

export const HEADER_STYLES = {
  [HeaderTypes.CLOSED_RESTAURANT]: css`
    background-color: ${({theme}) => theme.colors.backgroundInteractive};
    color: ${({theme}) => theme.colors.primaryText};
  `,
  [HeaderTypes.OPEN_FOR_FUTURE_ORDER]: css`
    background-color: ${({theme}) => theme.colors.backgroundInteractive};
    color: ${({theme}) => theme.colors.primaryText};
  `,
  [HeaderTypes.POOLED_ORDER]: css`
    background-color: ${({theme}) => theme.colors.supportNeutral};
    color: ${({theme}) => theme.colors.charcoal80};
  `,
  [HeaderTypes.POOLED_ORDER_OVER_MINIMUM]: css`
    background-color: ${({theme}) => theme.colors.successDark};
    color: ${({theme}) => theme.colors.primaryText};
  `,
};

const Root = styled.div`
  ${flexCenter}
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding: 0 12px;
`;

const TextContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  margin-bottom: 1px;
`;

const StyledCalenderIcon = styled(CalenderIcon)`
  ${flipOnLTR` margin-left: 4px;`}
`;

export type HeaderType = EnumValueType<typeof HeaderTypes>;

interface GetTranslationsMap {
  deliveryMethod?: DeliveryMethods;
  isCurrentAddressIsDinningRoom?: boolean;
  restaurantItem?: RestaurantFromSearch;
  estimatedNumberOfOrdersInPoolOrder?: RestaurantFromSearch['estimatedNumberOfOrdersInPoolOrder'];
  minimumForPooledOrder?: number;
  pooledOrderSum?: number;
  pooledOrderTagState?: PooledOrderTagState;
  t: i18nHelpersProps['t'];
}

const GET_HEADER_CONTENT_MAP: Record<HeaderType, (params: GetTranslationsMap) => ReactNode> = {
  [HeaderTypes.CLOSED_RESTAURANT]: ({t, deliveryMethod, restaurantItem, isCurrentAddressIsDinningRoom}) => {
    const content = (() => {
      if (isCurrentAddressIsDinningRoom) {
        return t('maximum_quota_reached');
      }
      if (restaurantItem && deliveryMethod) {
        return t(getClosedRestaurantMessage({restaurant: restaurantItem, deliveryMethod}).restListBox);
      }
      return '';
    })();

    return (
      <TextContainer>
        {content}
      </TextContainer>
    );
  },
  [HeaderTypes.OPEN_FOR_FUTURE_ORDER]: ({t}) => <><StyledCalenderIcon/> {t('open_for_future_orders')}</>,
  [HeaderTypes.POOLED_ORDER]: ({
    estimatedNumberOfOrdersInPoolOrder,
    minimumForPooledOrder,
    pooledOrderSum,
    pooledOrderTagState,
  }) => {
    if (!pooledOrderTagState) {
      return null;
    }
    return (
      <PooledOrderContent
        estimatedNumberOfOrdersInPoolOrder={estimatedNumberOfOrdersInPoolOrder || 0}
        minimumForPooledOrder={minimumForPooledOrder || 0}
        pooledOrderSum={pooledOrderSum || 0}
        tagState={pooledOrderTagState}
      />
    );
  },
  [HeaderTypes.POOLED_ORDER_OVER_MINIMUM]: ({
    estimatedNumberOfOrdersInPoolOrder,
    minimumForPooledOrder,
    pooledOrderSum,
    pooledOrderTagState,
  }) => {
    if (!pooledOrderTagState) {
      return null;
    }
    return (
      <PooledOrderContent
        estimatedNumberOfOrdersInPoolOrder={estimatedNumberOfOrdersInPoolOrder || 0}
        minimumForPooledOrder={minimumForPooledOrder || 0}
        pooledOrderSum={pooledOrderSum || 0}
        tagState={pooledOrderTagState}
      />
    );
  },
};

const Header = ({
  headerType,
  pooledOrderTagState,
}: {
  headerType: HeaderTypes;
  pooledOrderTagState?: PooledOrderTagState;
}) => {
  const {isCurrentAddressIsDinningRoom, restaurantItem, deliveryMethod} = useContext(RestaurantItemContext);
  const {t} = getLocalizationService();
  const {estimatedNumberOfOrdersInPoolOrder, minimumForPooledOrder, pooledOrderSum} = restaurantItem;

  const headerContent = GET_HEADER_CONTENT_MAP[headerType]({
    isCurrentAddressIsDinningRoom,
    restaurantItem,
    deliveryMethod,
    estimatedNumberOfOrdersInPoolOrder,
    minimumForPooledOrder,
    pooledOrderSum,
    pooledOrderTagState,
    t,
  });

  if (!headerContent) {
    return null;
  }

  return (
    <Root data-test-id="card_header">
      {headerContent}
    </Root>
  );
};

export default Header;
