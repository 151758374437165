import {Address} from '../../models';
import {makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {CompanyAddressesState} from './companyAddressesReducer';

export const getCompanyAddresses = makeThunkAsyncActionCreator<Partial<Address>, CompanyAddressesState['data']>(
  'getCompanyAddresses',
  async (location, {apiService}) => {
    const {data: companyAddresses} = await apiService.getCompanyAddresses({...location});
    return companyAddresses;
  },
);
