import {useSelector} from 'react-redux';

import {selectOrderSuccessData, selectUserData} from '~/shared/store/selectors';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import BarCodeCouponComponent from '~/common/components/CouponBarCodeComponent';
import {FoodTransferDetails, OrderDetails} from '~/shared/components/OrderCompleteSections';

import useIsFoodTransferDetailsMargin from '../hooks/useIsFoodTransferDetailsMargin';

const BarcodeOrDetailsBlock = ({isDonation}) => {
  const orderSuccessData = useSelector(selectOrderSuccessData) || EMPTY_OBJECT;
  const {restaurant, isBarcodeOrder, barcode, dishToPresent} = orderSuccessData;
  const userData = useSelector(selectUserData);
  const isFootTransferDetailsMargin = useIsFoodTransferDetailsMargin();

  const foodTransferDetailsRemarks = orderSuccessData.remarksRequired &&
    userData?.userEnabledFeatures.includes('ShowDiscountDiningRoomSelection') &&
    orderSuccessData.remarks;
  
  return isBarcodeOrder ? (
    <BarCodeCouponComponent restaurantName={restaurant.restaurantName} barCode={barcode} />
  ) : (
    <>
      <FoodTransferDetails
        {...orderSuccessData}
        hasMargin={isFootTransferDetailsMargin}
        isDonation={isDonation}
        remarks={foodTransferDetailsRemarks}
      />
      <OrderDetails dishes={dishToPresent} orderCoupon={orderSuccessData.orderCoupon} />
    </>
  );
};

export default BarcodeOrDetailsBlock;
