import styled from 'styled-components';

import {flipOnLTR, media} from '~/shared/theme/utils';
import {getLocalizationService} from '~/shared/services/localisationService';
import {body12Normal} from '~/shared/theme/typography';
import {PaymentRequestStatusTypes} from '~/shared/consts/checkoutConsts';
import {clickOnEnterProps} from '~/shared/utils/general';
import {flexSpace} from '~/shared/theme/FlexLayout';
import {trackEvent} from '~/shared/services/analytics';
import Checkbox from '~/shared/components/Checkbox';
import PaymentMethodIconAndDetails from '~/shared/components/PaymentMethodIconAndDetails';

import RadioButton from '../../RadioButton';

const Root = styled.div`
  min-height: 90%;
  background-color: ${({theme}) => theme.checkout.availablePayments.backgroundColor};
  border: 1px solid ${({theme}) => theme.colors.gray500};
  padding: 4px 15px;
  ${media.minDesktop`
    min-height: 212px;
    max-height: 380px;
    overflow: auto;
  `}
`;

const NoResultMsg = styled.div`
  ${body12Normal};
  line-height: 16px;
  text-align: center;
  color: ${({theme}) => theme.checkout.availablePayments.noResultMessage};
  margin-top: 50px;
`;

const CheckboxItem = styled.div.attrs(({isChecked}) => ({
  ...clickOnEnterProps,
  role: 'checkbox',
  'aria-checked': isChecked,
}))`
  ${flexSpace};
  height: 50px;
  border-radius: 2px;
  background-color: ${({theme}) => theme.checkout.availablePayments.paymentBackGroundColor};
  margin-bottom: 5px;
  padding-top: 5px;
  pointer-events: ${({disabled}) => (disabled ? 'none' : 'all')};
  cursor: ${({disabled}) => (disabled ? 'auto' : 'pointer')};
  ${({disabled}) =>
    !disabled &&
    `&:hover{
    box-shadow: ${({theme}) => theme.shadows.shadow2};
  }`}
  ${flipOnLTR`
    padding-right: 15px;
  `}
`;

const Status = styled.div`
  &&& {
    line-height: normal;
  }

  display: flex;
  align-items: center;
  max-width: 100px;
  ${flipOnLTR`
    margin-left: 12px;
  `}
  color: ${({theme}) => theme.checkout.paymentMethodLine.disabledPayment};
  ${media.minMobile`
    max-width: unset;
  `}
`;

const isPaymentDisabled = payment => {
  if (payment.isTenbisCredit && payment.prepaidBalance === 0) {
    return true;
  }
  if (payment.status && payment.status !== PaymentRequestStatusTypes.APPROVED) {
    return true;
  }
  return false;
};

const PaymentsList = ({paymentsList, onPaymentChange, isSinglePaymentAllowed}) => {
  const {t} = getLocalizationService();

  if (paymentsList.length === 0) {
    return (
      <Root>
        <NoResultMsg>{t('no_search_results_found')}</NoResultMsg>
      </Root>
    );
  }
  
  const InputComponent = isSinglePaymentAllowed ? RadioButton : Checkbox;

  return (
    <Root>
      {paymentsList.map(payment => {
        const disabled = isPaymentDisabled(payment);

        return (
          <CheckboxItem
            key={payment.cardId || payment.requestToken}
            onClick={e => {
              e.preventDefault();
              if (!disabled) {
                if (!payment.assigned) {
                  trackEvent('hasSelectedPaymentMethod');
                }
                return onPaymentChange(payment);
              }
            }}
            isChecked={payment.assigned}
            disabled={disabled}
          >
            <InputComponent
              id={isSinglePaymentAllowed ? `payment-card-${payment.cardId}-radio` : `payment-card-${payment.cardId}`}
              name={isSinglePaymentAllowed ? 'payment-card-option' : undefined}
              isChecked={isSinglePaymentAllowed ? payment.assigned : undefined}
              value={payment.assigned}
              isDisabled={disabled}
              label={!isSinglePaymentAllowed ? (
                <PaymentMethodIconAndDetails
                  key={payment.cardId}
                  isDisabled={disabled}
                  payment={payment}
                  onPaymentChange={onPaymentChange}
                  isCheckoutPaymentMethodLine={false}
                />
              ) : undefined}
            >
              {isSinglePaymentAllowed && (
                <PaymentMethodIconAndDetails
                  key={payment.cardId}
                  isDisabled={disabled}
                  payment={payment}
                  onPaymentChange={onPaymentChange}
                  isCheckoutPaymentMethodLine={false}
                />
              )}
            </InputComponent>

            {payment.status && payment.status === PaymentRequestStatusTypes.REQUESTED && (
              <Status>{t('waiting_for_confirmation')}</Status>
            )}
          </CheckboxItem>
        );
      })}
    </Root>
  );
};

export default PaymentsList;
