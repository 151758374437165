import {isEmpty} from 'lodash';
import styled from 'styled-components';

import {getPaymentCardIconComponent} from '~/shared/utils/paymentMethodsUtils';
import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {PriceLabel} from '~/shared/components/PriceLabels';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {FlexSpace} from '~/shared/theme/FlexLayout';

import Section from './parts/Section';
import InfoWrapper from './parts/InfoWrapper';
import {defaultPaddingCss} from './orderSummaryStyle';

const PaymentDetailsLine = styled(FlexSpace)`
  margin-bottom: 8px;
  width: 100%;
`;
const PaymentSection = styled(Section)`
  margin-top: 8px;
  ${defaultPaddingCss};
`;

const PaymentOrderSuccessCardIconWrapper = styled.div`
  border-radius: 2px;
  width: auto;
  height: auto;
  display: flex;
  ${flipOnLTR`
    margin-left: 4px;
  `}
`;
const OuterInfo = styled(InfoWrapper)`
  margin-right: 0;
`;
const PaymentsDetails = ({payments, className = ''}) => {
  const {t} = getLocalizationService();

  if (isEmpty(payments)) {
    return null;
  }

  return (
    <PaymentSection title={t('payment_details')} className={className}>
      {payments?.map(({paymentMethod, cardLastDigits, paymentValue, creditCardType, isTenbisCredit}) => {
        const PaymentCardIconComponent = getPaymentCardIconComponent(
          paymentMethod.toLowerCase(),
          creditCardType?.toLowerCase(),
          isTenbisCredit,
        );

        return (
          <PaymentDetailsLine key={cardLastDigits} data-test-id={`order_payment_${paymentMethod}`}>
            <OuterInfo>
              <PaymentOrderSuccessCardIconWrapper>
                {PaymentCardIconComponent && <PaymentCardIconComponent className="avoid-fill-stroke" />}
              </PaymentOrderSuccessCardIconWrapper>
              <InfoWrapper>
                {isTenbisCredit
                  ? t('tenbis_credit')
                  : t(paymentMethod === PaymentMethodTypes.MONEY_CARD ? 'tenbis_card' : paymentMethod.toLowerCase())}
                &nbsp;
              </InfoWrapper>
              {!isTenbisCredit &&
                `${
                  paymentMethod === PaymentMethodTypes.CASH || paymentMethod === PaymentMethodTypes.PAYPAL || paymentMethod === PaymentMethodTypes.APPLE_PAY
                    ? ''
                    : 'xxxx-'
                }${cardLastDigits}`}
            </OuterInfo>
            <PriceLabel price={paymentValue} margin={5} />
          </PaymentDetailsLine>
        );
      })}
    </PaymentSection>
  );
};

export default PaymentsDetails;
