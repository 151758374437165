import {combineReducers} from 'redux';

import {ACTION_MADE_FROM_ENUM} from '~/shared/utils/ageRestriction';
import {makeReducer} from '~/shared/store/redux-toolbelt';

import {setActionMadeFrom, setIsAgeConfirmed} from './ageRestrictionActions';

export interface AgeConfirmState {
  actionMadeFrom: ACTION_MADE_FROM_ENUM;
  isConfirmed: boolean;
}

export default combineReducers<AgeConfirmState>({
  actionMadeFrom: makeReducer(setActionMadeFrom),
  isConfirmed: makeReducer(setIsAgeConfirmed),
});
