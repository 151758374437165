import {forwardRef, useState, useCallback, useEffect, memo} from 'react';

import styled from 'styled-components';
import {useDispatch} from 'react-redux';

import {isDev} from '~/shared/config';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media, flipOnLTR} from '~/shared/theme/utils';
import {ESC_KEY_CODE} from '~/shared/consts/commonConsts';
import Button from '~/shared/components/Button';
import FocusTrapDiv from '~/shared/components/FocusTrapDiv';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import {createLogger} from '~/shared/logging';
import {flexCenter} from '~/shared/theme/FlexLayout';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';

import Portal from './Portal';

const logger = createLogger('modal');

const Root = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  ${({withoutOverlay, theme}) => (withoutOverlay ? '' : `background: ${theme.modal.overlayBackgroundColor};`)};
  z-index: 1100;
  text-align: center;
  overflow-x: hidden;
  padding: 20px;
  ${media.minTablet`
    padding: 0px;
  `}
`;

const ContainerBase = styled(ClickOutsideDiv)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  ${flipOnLTR`
    text-align: right;
  `}

  & > *:first-child {
    background: ${({bodyBackgroundColor, theme}) => bodyBackgroundColor || theme.modal.backgroundColor};
    box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
    ${media.minTablet`
      width: ${({width}) => width || '672px'};
      height: auto;
      min-height: auto;
    `}
  }
`;

const infoModal = styled(ContainerBase)`
  ${media.minMobile`
    border-radius: 2px;
    width: calc(100% - 40px);
  `}
  ${media.minLargeTablet`
    width: 950px;
  `};
`;

const autoWidth = styled(ContainerBase)`
  &&& {
    width: 100%;
    height: 100%;
  }

  ${media.minLargeMobile`
    &&& {
      width: auto;
      height: auto;
    }
  `}
`;

const autoHeight = styled(ContainerBase)`
  &&& {
    height: auto;
    min-height: auto;
  }
`;

const wideModal = styled(ContainerBase)`
  width: 750px;
`;

const modalTypes = {
  infoModal,
  wideModal,
  autoWidth,
  autoHeight,
};

export const CloseButton = styled(Button)`
  position: absolute;
  top: 24px;
  ${flipOnLTR`
    left: 24px;
  `}
  width: 34px;
  height: 34px;
  ${flexCenter};
  border-radius: 34px;
  cursor: pointer;
  z-index: 1;
  background-color: ${({theme}) => theme.colors.surface};

  &:hover {
    background-color: rgba(215, 215, 215, 0.5);
  }
`;

const Modal = forwardRef(
  (
    {
      isErupt = false,
      disableEruptModals = false,
      children,
      closeModal,
      hideCloseButton,
      width,
      children: {
        props: {
          args: {hideCloseButton: hideCloseButtonInner = null, onClickOutside: onClickOutsideInner = null} = {},
        } = {},
      } = null,
      bodyBackgroundColor,
      modalType,
      isOpen,
      onOpen,
    },
    modalRootContainerRef,
  ) => {
    const {t} = getLocalizationService();
    const [modalRootDomEl, setModalRootDomEl] = useState();
    const [modalHasContent, setModalHasContent] = useState();

    const containerRef = useCallback(
      domEl => {
        if (!modalRootContainerRef || !domEl) {
          return;
        }
        setModalRootDomEl(domEl);
        modalRootContainerRef(domEl);

        try {
          const modalContentEl = domEl.querySelector('#modal-content');
          setModalHasContent(modalContentEl);
        } catch (e) {
          // eslint-disable-next-line quotes
          logger.warn("couldn't find id #modal-content", e);
        }
      },
      [modalRootContainerRef],
    );

    const dispatch = useDispatch();

    const onEscape = useCallback(
      ({keyCode}) => {
        if (isOpen && keyCode === ESC_KEY_CODE) {
          closeModal();
        }
      },
      [isOpen, closeModal],
    );

    useEffect(() => {
      let currentTimeout;
      if (isDev && modalRootDomEl) {
        currentTimeout = setTimeout(() => {
          const modalTitleEl = modalRootDomEl.querySelector('#modal-title');

          if (!modalTitleEl) {
            logger.errorDialog('modal must have #modalTitleEl id on title element');
          }
        }, 6000);
      }
      return () => {
        clearTimeout(currentTimeout);
      };
    }, [modalRootDomEl, dispatch]);

    useEffect(() => {
      document.addEventListener('keydown', onEscape);
      return () => {
        document.removeEventListener('keydown', onEscape);
      };
    }, [onEscape]);

    useEffect(() => {
      if (isOpen && onOpen) {
        onOpen();
      }
    }, [isOpen, onOpen]);

    const Container = modalTypes[modalType] || ContainerBase;

    const onClickOutside = e => {
      // makes sure that in the rare case of several modals open, a click on one, doesn't close the other as click outside
      const isClickedInsideSomeModal = Array.from(document.querySelectorAll('[data-id="dialog-container"]')).some(m =>
        m.contains(e.target),
      );

      if (isClickedInsideSomeModal) {
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      closeModal();
    };

    if (!isOpen) {
      return null;
    }

    if (disableEruptModals && isErupt) {
      return null;
    }

    // modals with isErupt = true wont open on pages with disableEruptModals = true.
    return (
      <Portal>
        <FocusTrapDiv>
          <Root
            role="dialog"
            aria-labelledby="modal-title"
            aria-describedby={modalHasContent && 'modal-content'}
            ref={containerRef}
            data-test-id="rootModal"
          >
            <Container
              data-id="dialog-container"
              width={width}
              onClickOutside={onClickOutsideInner || onClickOutside}
              bodyBackgroundColor={bodyBackgroundColor}
            >
              {children}
              {!(hideCloseButton || hideCloseButtonInner) && (
                <CloseButton data-test-id="modalCloseButton" onClick={closeModal} aria-label={t('close')}>
                  <CloseButtonSVGIcon />
                </CloseButton>
              )}
            </Container>
          </Root>
        </FocusTrapDiv>
      </Portal>
    );
  },
);

export default memo(Modal);
