import {useMemo} from 'react';

import styled, {keyframes} from 'styled-components';

import {ScooberJobStatuses, SortingScooberJobStatusesOrder, ScooberBarStatuses} from '~/shared/consts/scooberConsts';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {body12Normal, body14Normal} from '~/shared/theme/typography';
import {Section} from '~/shared/components/OrderCompleteSections/parts';

const ScooberDetailsWrapper = styled(Section)<{
  mapDisplayed?: boolean;
  isShowScooberTracker?: boolean;
}>`
  padding: 16px 0;
  margin-top: ${({mapDisplayed}) => (mapDisplayed ? '400px' : '593px')};
  ${media.minLargeDesktop`
    margin-top: ${({mapDisplayed, isShowScooberTracker}) => (mapDisplayed ? 450 : isShowScooberTracker ? 593 : 561)}px;
  `};
`;

const ScooberStatusContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const ScooberStatusBarWrapper = styled.div`
  ${flexColumn};
  width: 75px;
  ${media.minLargeTablet`
    width: 122px;
 `}
`;

const StatusText = styled.div<{
  effect: ScooberBarStatuses;
}>`
  color: ${({theme, effect}) =>
    (effect === ScooberBarStatuses.FINISHED || effect === ScooberBarStatuses.LOADING
      ? theme.colors.primary
      : theme.colors.background)};
  ${body12Normal};
  text-align: center;
  margin-top: 5px;
  white-space: nowrap;
  ${media.minLargeTablet`
    text-align: center;
    width: auto;
 `}
  ${media.minIphoneX`
    ${body14Normal};
 `}
`;

const animationKeyFramesRTL = keyframes`
  0% {
    right: -100%;
  }

  100% {
    right: 100%;
  }
`;

const animationKeyFramesLTR = keyframes`
  0% {
    right: 100%;
  }

  100% {
    right: -100%;
  }
`;

const ScooberStatusBar = styled.div<{
  effect: ScooberBarStatuses;
  jobStatus?: ScooberJobStatuses;
}>`
  border-radius: 5px;
  height: 5px;
  background-color: ${({theme, effect, jobStatus}) =>
    (effect === ScooberBarStatuses.FINISHED ||
    (effect === ScooberBarStatuses.LOADING && jobStatus === ScooberJobStatuses.EatingTime)
      ? theme.colors.primary
      : theme.colors.background)};
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    width: ${({effect}) => (effect === ScooberBarStatuses.LOADING ? '50%' : '0')};
    height: 100%;
    background-color: ${({theme, effect}) =>
    (effect === ScooberBarStatuses.FINISHED || effect === ScooberBarStatuses.LOADING
      ? theme.colors.primary
      : theme.colors.background)};
    right: -100%;
    animation: ${({theme}) => (theme.isLTR ? animationKeyFramesLTR : animationKeyFramesRTL)} 1s
      ${({effect}) => (effect === ScooberBarStatuses.LOADING ? 'infinite' : '0')};
  }
`;

const StatusBlock = ({jobStatusIndex, effect}: {
  jobStatusIndex: number;
  effect: ScooberBarStatuses;
}) => {
  const {t} = getLocalizationService();

  const jobStatus = (Object.keys(SortingScooberJobStatusesOrder) as ScooberJobStatuses[]).find(
    k => SortingScooberJobStatusesOrder[k] === jobStatusIndex,
  );
  const isCancelledStatus = jobStatus && ScooberJobStatuses[jobStatus] === ScooberJobStatuses.Canceled;

  if (isCancelledStatus) {
    return null;
  }

  return (
    <ScooberStatusBarWrapper key={jobStatus}>
      <ScooberStatusBar key={jobStatus} effect={effect} jobStatus={jobStatus} />
      <StatusText effect={effect}>{t(jobStatus ? ScooberJobStatuses[jobStatus].toLowerCase() : '')}</StatusText>
    </ScooberStatusBarWrapper>
  );
};

const calculateEffects = (currentRelevantStatus?: ScooberJobStatuses) => {
  if (!currentRelevantStatus || currentRelevantStatus === ScooberJobStatuses.Canceled) {
    return Array(Object.keys(ScooberJobStatuses).length).fill(ScooberBarStatuses.ON_HOLD);
  }

  return (Object.keys(ScooberJobStatuses) as ScooberJobStatuses[]).map(status => {
    const currentStatusCounter = SortingScooberJobStatusesOrder[currentRelevantStatus];
    const statusCounter = SortingScooberJobStatusesOrder[status];

    if (statusCounter < currentStatusCounter) {
      return ScooberBarStatuses.FINISHED;
    }

    if (statusCounter === currentStatusCounter) {
      return ScooberBarStatuses.LOADING;
    }

    return ScooberBarStatuses.ON_HOLD;
  });
};

const ScooberStatus = ({status: currentRelevantStatus, mapDisplayed, isShowScooberTracker}: {
  status?: ScooberJobStatuses;
  mapDisplayed?: boolean;
  isShowScooberTracker: boolean;
}) => {
  const effects = useMemo(() => calculateEffects(currentRelevantStatus), [currentRelevantStatus]);

  return (
    // @ts-expect-error Section.js should be refactored to ts
    <ScooberDetailsWrapper mapDisplayed={mapDisplayed} isShowScooberTracker={isShowScooberTracker}>
      <ScooberStatusContainer>
        {effects.map((effect, index) => (
          <StatusBlock key={`scoober_status_block_${effect.toString() + index}`} jobStatusIndex={index} effect={effect} />
        ))}
      </ScooberStatusContainer>
    </ScooberDetailsWrapper>
  );
};
export default ScooberStatus;
