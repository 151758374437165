import {memo} from 'react';

import styled, {css} from 'styled-components';

import FailureIcon from '~/assets/images/icons/failure-icon.svg?react';
import CanceledVoucherIcon from '~/assets/images/icons/voucher-canceled-icon.svg?react';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexCenter, FlexCenterVertically, FlexColumn, FlexColumnCenter, FlexSpace, flexSpace} from '~/shared/theme/FlexLayout';
import {body15Bold, body14Normal, subheader26Bold, body16Bold} from '~/shared/theme/typography';
import {PriceLabel} from '~/shared/components/PriceLabels';
import ButtonBase from '~/shared/components/Button';
import {NumericFormats} from '~/shared/consts/checkoutConsts';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import {IVoucherCardRes} from '../services/bffService/api/interfaces/responses';

import {Skeleton as SkeletonLoader} from './Loaders';

const ROOT_CARD_CSS = css`
  width: 267px;
  height: 161px;
  background-color: ${({theme}) => theme.colors.gray920};
  border-radius: 9px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};
`;

const Root = styled(FlexColumn)`
  ${ROOT_CARD_CSS}
`;

const CardInformation = styled(FlexColumn)`
  ${flexSpace}
  height: 124px;
  border: 1px solid ${({theme}) => theme.colors.gray500};
  background-color: ${({theme}) => theme.colors.surface};
  border-radius: 9px 9px 0 0;
  color: ${({theme}) => theme.colors.secondary};
  padding: 12px;
`;

const FailureIconWrapper = styled(FlexCenterVertically)`
  flex: 1;
`;

const FailureMessage = styled.div`
  ${body15Bold}
  color: ${({theme}) => theme.colors.secondary};
  line-height: 19px;
`;

const BottomSectionWrapper = styled.div`
  ${flexCenter}
  flex: 1;
`;

const RetryButton = styled(ButtonBase)`
  ${body14Normal}
  color: ${({theme}) => theme.colors.surfacePrimaryAction};
  width: 100%;
  height: 100%;
`;

const BalanceWithLogoWrapper = styled(FlexSpace)``;

const Logo = styled(ImageWithAlt)`
  width: 38px;
  height: 37px;
`;

const BalanceWrapper = styled(FlexColumn)``;

const BalanceTitle = styled.div`
  ${body14Normal}
  line-height: 18px;
  margin-bottom: 8px;
`;

const Balance = styled(PriceLabel)`
  ${subheader26Bold}
  justify-content: flex-end;
`;

const CardNumber = styled.div`
  ${body16Bold}
  display: flex;
  justify-content: flex-end;
`;

const CardImage = styled(ImageWithAlt)`
  width: 252px;
  height: 17px;
`;

const Skeleton = styled(SkeletonLoader)`
  ${ROOT_CARD_CSS}
`;

const InnerWrapper = styled(FlexColumnCenter)`
  flex: 1;
`;

const MultipassCardView = ({
  isFailure,
  isCanceled,
  onTryAgain,
  cardId,
  cardPin,
  balance,
  logo,
  image,
}: Omit<Partial<IVoucherCardRes>, 'id' | 'description'> & {
  isFailure?: boolean;
  isCanceled?: boolean;
  onTryAgain: () => void;
}) => {
  const {t} = getLocalizationService();

  const handleClick = () => {
    if (onTryAgain) {
      onTryAgain();
    }
  };

  if (isFailure || isCanceled) {
    const IconComponent = isFailure ? FailureIcon : CanceledVoucherIcon;
    const message = isFailure ? t('card_info_failure') : t('order_canceled');

    return (
      <Root data-test-id="VoucherCard">
        <CardInformation>
          <InnerWrapper>
            <FailureIconWrapper>
              <IconComponent />
            </FailureIconWrapper>
            <FailureMessage>{message}</FailureMessage>
          </InnerWrapper>
        </CardInformation>
        <BottomSectionWrapper>
          {isFailure && <RetryButton onClick={handleClick}>{t('try_again_2')}</RetryButton>}
        </BottomSectionWrapper>
      </Root>
    );
  }

  return (
    <Root data-test-id="VoucherCard">
      <CardInformation>
        <BalanceWithLogoWrapper>
          <BalanceWrapper>
            <BalanceTitle>{t('balance_2')}</BalanceTitle>
            <Balance price={balance as number} formatString={NumericFormats.hideZeroDecimals} />
          </BalanceWrapper>
          <Logo src={logo} alt={t('tav_mazon')} />
        </BalanceWithLogoWrapper>

        <CardNumber>{`${cardId}-${cardPin}`}</CardNumber>

      </CardInformation>
      <BottomSectionWrapper>
        <CardImage src={image} alt={t('participating_vandors')} />
      </BottomSectionWrapper>
    </Root>
  );
};

export const MultipassCardSkeleton = () => <Skeleton />;

export default memo(MultipassCardView);
