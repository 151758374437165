import {memo, useMemo} from 'react';

// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import {selectCurrentRestaurant} from '~/shared/store/selectors';
import {getLocalizationService, i18nHelpersProps} from '~/shared/services/localisationService';
import {RestaurantFromGet} from '~/shared/store/models';
import {media} from '~/shared/theme/media';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';

import AboutSectionBase from './AboutSectionBase';

type GenerateScheduleReturnType = {dayOfWeek: string; timeRanges: string};

const Wrapper = styled.div`
  ${media.minLargeTablet`
    margin-top: 15px;
  `}
`;

const DayWrapper = styled.div`
  display: flex;
`;

const Day = styled.p`
  min-width: ${({theme}) => (theme.isLTR ? '86px' : '40px')};
  ${flipOnLTR`
    margin-left: 8px;
  `}
`;

const TimeRanges = styled.p`
`;

const generateScheduleFromApiRes = (
  currentLanguageKey: i18nHelpersProps['currentLanguageKey'],
  schedule: RestaurantFromGet['deliveryOpenTimes']): GenerateScheduleReturnType[] =>
  schedule.map(item => {
    const isRtl = currentLanguageKey === 'he';
    const dayOfWeek = moment().locale(currentLanguageKey).day(item.dayOfWeek - 1).format('dddd'); // 1 = Sun, 2 = Mon ...
    const timeRanges = item.ranges.sort((a, b) => (isRtl ? b.startTime.localeCompare(a.startTime) : a.startTime.localeCompare(b.startTime))).map(range => (isRtl ? `${range.endTime} - ${range.startTime}` : `${range.startTime} - ${range.endTime}`)).join(', ');

    return {dayOfWeek, timeRanges};
  });

const RenderSchedule = memo(({schedule}: {schedule: GenerateScheduleReturnType[]}) => {
  return (
    <Wrapper>
      {schedule.map(scheduleDay => (
        <DayWrapper key={scheduleDay.dayOfWeek}>
          <Day>{scheduleDay.dayOfWeek}</Day>
          <TimeRanges>{scheduleDay.timeRanges}</TimeRanges>
        </DayWrapper>
      ))}
    </Wrapper>
  );
});

const DeliverySection = () => {
  const {t, currentLanguageKey} = getLocalizationService();
  const restaurantData = useSelector(selectCurrentRestaurant);

  const schedule = useMemo(() => {
    if (!restaurantData?.deliveryOpenTimes?.length) {
      return null;
    }

    return generateScheduleFromApiRes(currentLanguageKey, restaurantData.deliveryOpenTimes);
  }, [currentLanguageKey, restaurantData?.deliveryOpenTimes]);
  
  if (!schedule) {
    return null;
  }

  return (
    <AboutSectionBase
      title={t('opening_times')}
      Component={<RenderSchedule schedule={schedule} />}
      content=""
      boldContent=""
      withBottomPadding={false}
    />
  );
};

export default DeliverySection;
