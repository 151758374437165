import {pickBy} from 'lodash';

import {createLogger} from '~/shared/logging';
import apiService from '~/shared/services/apiService';

import {IAddress} from '../../store/models';
import {TenBisAddressLocation} from '../../store/models/Address/TenBisAddressLocation';
import {mapAddressFromServerOrCookies} from '../../utils/address';
import {RawAddress} from '../addressHelper';
import {getExtraGeoCoderDataForAddress} from '../geocoder';

import TenBisAddressError from './TenBisAddressError';

const logger = createLogger('getTenBisAddressLocation');

export const getTenBisAddressLocation = async (rawAddress: RawAddress): Promise<TenBisAddressLocation> => {
  const extraGeoCoderData = await getExtraGeoCoderDataForAddress(rawAddress);
  if (!extraGeoCoderData) {
    logger.error('cant get extra geocoder data', {
      rawAddress,
      extraGeoCoderData,
    });
    throw new Error('cant get extra geocoder data');
  }

  let responseAddress;

  try {
    const result = await apiService.getTenBisAddressLocationHebrewOnly({
      ...rawAddress,
      ...extraGeoCoderData,
    });

    const {data} = result;
    const isBigCity = data?.isBigCity;

    responseAddress = data && {
      ...(pickBy(data, Boolean) as IAddress),
      houseNumber: data.houseNumber || (!isBigCity && '1') || '',
      isBigCity,
    };
    if (!responseAddress?.cityId || !responseAddress?.streetId) {
      throw Error;
    }

    const houseNumber = rawAddress.houseNumber || String(responseAddress.houseNumber);

    const newAddress: TenBisAddressLocation = {
      ...mapAddressFromServerOrCookies({
        ...rawAddress,
        ...responseAddress,
        houseNumber,
      }),
      isBigCity,
    };

    return newAddress;
  } catch (error) {
    logger.verbose('cant get address location from 10bis servers', {
      error,
      rawAddress,
      extraGeoCoderData,
    });
    throw new TenBisAddressError('cant get address location from 10bis servers', responseAddress);
  }
};
