import {useEffect} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import {setCurrentModal} from '~/shared/store/actions';
import {selectCurrentRestaurant, selectIsShoppingCartDirty, selectShoppingCartDishes} from '~/shared/store/selectors';

const useCheckoutEmptyState = () => {
  const dispatch = useDispatch();
  const shoppingCartDishes = useSelector(selectShoppingCartDishes);
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const isShoppingCartDirty = useSelector(selectIsShoppingCartDirty);

  useEffect(() => {
    const isEmptyShoppingCart = shoppingCartDishes && shoppingCartDishes.length === 0;
  
    if (isEmptyShoppingCart && currentRestaurant && !isShoppingCartDirty) {
      dispatch(
        setCurrentModal('checkout_empty_state_modal'),
      );
    }
  
  }, [dispatch, shoppingCartDishes, currentRestaurant, isShoppingCartDirty]);
};

export default useCheckoutEmptyState;
