/* stylelint-disable property-no-vendor-prefix */
import {createGlobalStyle, css} from 'styled-components';

import {mainFont, mainFontSize, mainFontLineHeight} from '~/shared/theme/fonts';
import {media} from '~/shared/theme/media';
import {flipOnLTR} from '~/shared/theme/utils';
import {baseTheme} from '~/shared/theme/theme'; // eslint-disable-line no-restricted-imports

import colors from './colors';

const getScrollbarWidth = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent,
    );
  if (isTablet) {
    return 0;
  }

  const scrollbox = document.createElement('div');
  scrollbox.style.overflow = 'scroll';
  scrollbox.style.width = '100px';
  scrollbox.style.height = '100px';
  scrollbox.style.visibility = 'hidden';
  document.body.appendChild(scrollbox);
  const scrollBarWidth = scrollbox.offsetWidth - scrollbox.clientWidth;

  document.body.removeChild(scrollbox);
  return Number.isNaN(scrollBarWidth) ? 7 : scrollBarWidth;
};

const scrollBarWidth = getScrollbarWidth();

const theme = baseTheme;

export const noScrollBar = css`
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
    height: 0;
    width: 0;
    background: transparent;
  }
`;

const GlobalCss = createGlobalStyle`
  html,
  body {
    margin: 0;
    padding: 0;
  }

  html,
  body,
  button,
  input,
  select,
  textarea {
    font-family: ${mainFont}, 'Segoe UI', sans-serif;
    font-size: ${mainFontSize};
    line-height: ${mainFontLineHeight};
    text-rendering: optimizeLegibility;
  }

  body {
    background: ${theme.backgroundColor};
    min-width: 320px;
    touch-action: manipulation;
  }

  body,
  .smooth-container {
    scroll-behavior: smooth;
  }

  body.lock-scroll {
    overflow: hidden;
    ${media.minTablet`
      ${flipOnLTR`
        padding-right: ${scrollBarWidth}px;

        header > div {
          padding-right: ${scrollBarWidth}px;

          :nth-child(2) {
            padding-right: ${scrollBarWidth + 8}px;
          }
        }
      `({theme})}
    `}
  }

  * {
    box-sizing: border-box;
    position: relative;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 0;
    margin-top: 0;
  }

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${theme.colors.gray000};
  }

  *::-ms-clear {
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.colors.gray300};
  }

  ::-webkit-scrollbar-track {
    background-clip: content-box;
    background-color: ${theme.colors.gray500};
    border-color: transparent;
    border-style: solid;
    border-width: 3px;
  }

  input::placeholder {
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }

  input[type='number'] {
    appearance: none;
  }

  :invalid,
  :-moz-submit-invalid,
  :-moz-ui-invalid {
    box-shadow: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  select::-ms-expand {
    display: none;
  }

  span.highlight-search-value {
    color: ${colors.secondary};
    font-weight: bold;
  }

  #modals {
    z-index: 1000;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    position: absolute;
    z-index: 5;
    background: ${theme.colors.surface};
    width: 100%;
    border: 1px solid ${theme.colors.darkBackground};
    border-radius: 3px;
    max-height: 170px;
    overflow-y: auto;
  }

  .react-autosuggest__suggestions-container--open {
    display: block;
  }

  ul.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  li.react-autosuggest__suggestion {
    font-size: 16px;
    padding: 10px;
  }

  input[type='search'],
  input[type='text'] {
    -webkit-appearance: none;
  }

  input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  input[type='week'],
  select:focus,
  textarea {
    font-size: 16px;
  }

  .rc-tooltip-hidden.dish-tooltip {
    display: none;
  }

  .dish-tooltip {
    display: none;
    max-width: 225px;
    border-radius: 5px;
    ${media.minMobile`
      display: block;
    `}
  }

  .dish-tooltip .rc-tooltip-inner {
    text-align: right;
  }

  .intercom-lightweight-app {
    z-index: ${theme.zIndex.supportAndA11yBadge} !important;
  }

  .intercom-app > div,
  .intercom-lightweight-app-launcher {
    ${media.maxLargeTablet`

      right: unset !important;
      left: 20px !important;
      ${({isBannersShown}) => isBannersShown && 'bottom: 82px !important;'}
    `}
    ${media.minLargeTablet`
      ${({isBannersShown, theme: {isLTR}}) =>
    isBannersShown &&
        isLTR &&
        `
        right: 20px !important;
        left: unset !important;
      `}
    `}
  }
`;

export default GlobalCss;
