import {memo} from 'react';

import styled from 'styled-components';

import {InfoModal} from '~/shared/components/Modals';
import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/media';

const Content = styled.div`
  box-sizing: 'content-box';
  ${media.maxMobile`
    transform: scale(0.5);
    transform-origin: right top;
  `}
`;

const StyledInfoModal = styled(InfoModal)`
  ${media.minLargeTablet`
      &&& {
        width: 950px;
        height:100vh;
      }
    `}
`;

const SingleOrderViewModal = ({args}) => {
  const {t} = getLocalizationService();

  return (
    <StyledInfoModal header={t('preview_order')}>
      <Content dangerouslySetInnerHTML={{__html: args.previewContent}} />
    </StyledInfoModal>
  );
};

const EnhanceSingleOrderViewModal = memo(SingleOrderViewModal);

EnhanceSingleOrderViewModal.modalType = 'infoModal';
attachModal('singleOrderView', EnhanceSingleOrderViewModal);
