import {useSelector} from 'react-redux';
import styled from 'styled-components';

import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {EMPTY_OBJECT} from '~/shared/consts/commonConsts';
import {Link} from '~/shared/router';
import {selectOrderSuccessData} from '~/shared/store/selectors';
import {getLocalizationService} from '~/shared/services/localisationService';
import {ShareOrder} from '~/shared/components/OrderCompleteSections';

import useIsShowMap from '../hooks/useIsShowMap';

import SuccessImageWithText from './SuccessImageWithText';
import TrackerLinkSection from './TrackerLinkSection';

const EuroWrapper = styled.div`
  margin-top: 8px;
`;

const EuroImageWithAlt = styled(ImageWithAlt)`
  width: 100%;
  max-height: 120px;
  object-fit: contain;
`;

const BottomBlock = ({isDonation}) => {
  const {t} = getLocalizationService();
  const {orderId, shareOrderEnabled, promotionBanner, isBarcodeOrder, restaurant, isPoolOrder} =
    useSelector(selectOrderSuccessData) || EMPTY_OBJECT;

  const isScoober = restaurant?.isScoober;
  const isShowMap = useIsShowMap();

  const shouldShowEuroBanner = promotionBanner?.imageUrl && promotionBanner?.linkUrl;
  const isShowOrderImage = (!isBarcodeOrder && isShowMap) || !isScoober || isPoolOrder;

  return (
    <>
      {shareOrderEnabled && <ShareOrder orderId={orderId} />}

      {shouldShowEuroBanner && (
        <EuroWrapper>
          <Link href={promotionBanner.linkUrl} target="_blank" rel="noopener" hard>
            <EuroImageWithAlt alt={promotionBanner.name || 'Euro 2021'} src={promotionBanner.imageUrl} />
          </Link>
        </EuroWrapper>
      )}

      {isShowOrderImage && <SuccessImageWithText text={isDonation ? '' : t('bonappetit')} />}

      {shareOrderEnabled && <TrackerLinkSection />}
    </>
  );
};
export default BottomBlock;
