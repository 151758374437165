import styled from 'styled-components';

import {body13Normal, body12Normal} from '~/shared/theme/typography';

import {flipOnLTR} from '../../../theme/flipOnLTR';

const Label = styled.label`
  position: absolute;
  top: calc(100% - 13px - 8px);
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 1;
  ${body13Normal};
  line-height: 13px;
  color: ${({theme, error}) => (error ? theme.colors.error : theme.colors.text)};
  ${flipOnLTR`
    right: 8px;
    text-align: right;
  `}
  ${({disabled}) =>
    disabled &&
    `
    opacity: 0.5;
  `};
  ${({float}) =>
    float &&
    `
    top: 6px;
    ${body12Normal};
  `};
`;

const FormInputLabel = ({htmlFor, error, disabled, float, required, text}) => {
  const labelProps = {htmlFor, error, disabled, float};
  return <Label {...labelProps}>{`${required ? '* ' : ''}${text}`}</Label>;
};

export default FormInputLabel;
