import {AxiosRequestConfig} from 'axios';

import {OrderData, OrderScooberJob, RestaurantFromGet} from '~/shared/store/models';
import {ActiveOrder} from '~/shared/types/ActiveOrderBannerTypes';

import {get, patch, post, apiDelete, put} from '../http';
import {validateAxiosAndReturnResponse} from '../util';

import {IRestaurantGroupsParams, IGetRestaurantMenuParams, IGetRestaurantParams, IOrderParams, IMarketingBannerParams, ILoadTenbisCredit, IUpdateUserParams, IUserConfig, IFetchBrandingPageDataParams} from './interfaces/payloads';
import {IRestaurantGroupsRes, IRestaurantMenuRes, IMarketingBannerRes, IOrderRes, IRefreshTokenRes, ILoadTenbisCreditResponse, IUpdateUserRes, IVoucherCardRes, IFetchBrandingPageDataResponse, IDiscountResponse, ApplePayMerchantSessionResponse} from './interfaces/responses';

export const updateUserDetails = async (params: IUpdateUserParams, config: IUserConfig) => {
  const response = await put<IUpdateUserRes>('v1/User/Details', params, config);
  return validateAxiosAndReturnResponse<IUpdateUserRes>(response);
};

export const removeUserAccount = (config: IUserConfig) => apiDelete('v1/User', undefined, config);

export const marketingSubscription = async (subscribe: boolean, unsubscribeToken: string) => {
  const response = await patch<string>(`v1/User/marketingSubscription/${subscribe}`, {}, {}, {headers: {unsubscribeToken}});
  return validateAxiosAndReturnResponse<string>(response);
};

export const fetchRestaurantGroups = async (params: IRestaurantGroupsParams) => {
  const response = await get<IRestaurantGroupsRes>(`v1/RestaurantGroups/${params.type}`);
  return validateAxiosAndReturnResponse<IRestaurantGroupsRes>(response);
};

export const fetchRestaurantMenu = async ({id, addressId, dateTime}: IGetRestaurantMenuParams) => {
  const options: Omit<IGetRestaurantMenuParams, 'id'> = {
    addressId,
    dateTime,
  };
  const response = await get<IRestaurantMenuRes>(`v1/Restaurants/${id}/Menu`, options);
  return validateAxiosAndReturnResponse<IRestaurantMenuRes>(response);
};

export const fetchRestaurant = async ({id, addressId, longitude, latitude}: IGetRestaurantParams) => {
  const options: Omit<IGetRestaurantParams, 'id'> = {
    addressId,
    longitude,
    latitude,
  };
  const response = await get<RestaurantFromGet>(`v1/Restaurants/${id}`, options);
  return validateAxiosAndReturnResponse<RestaurantFromGet>(response);
};

export const fetchMarketingBanners = async (params: IMarketingBannerParams, headers?: AxiosRequestConfig['headers']) => {
  const response = await get<IMarketingBannerRes>('v1/MarketingBanners', params, headers);
  return validateAxiosAndReturnResponse<IMarketingBannerRes>(response);
};

export const cancelActiveOrderByUser = (params: IOrderParams) => patch(`v1/Orders/${params.orderId}/Cancel`);

// This request doesnt require a data object, a validation of an AxiosResponse shouldnt be performed here
export const getRefreshToken = async () => post<IRefreshTokenRes>('v1/Authentication/RefreshToken');

export const fetchOrderData = async (params: IOrderParams) => {
  const response = await get<IOrderRes>(`v2/Orders/${params.orderId}`);
  return validateAxiosAndReturnResponse<IOrderRes>(response);
};

export const fetchVoucherCardByOrderId = async (params: {orderId: OrderData['orderId']}) => {
  const response = await get<IVoucherCardRes>(`v1/VoucherCards?orderId=${params.orderId}`);
  return validateAxiosAndReturnResponse<IVoucherCardRes>(response);
};

export const fetchActiveOrders = async () => {
  const response = await get<{orderBanners: ActiveOrder[]}>('v1/Orders/Banners');
  return validateAxiosAndReturnResponse<{orderBanners: ActiveOrder[]}>(response);
};

// export const fetchScooberJob = (orderId: OrderData['orderId']) => get<OrderScooberJob>(`v1/Orders/${orderId}/ScooberJob`);
export const fetchScooberJobV2 = async (orderId: OrderData['orderId']) => {
  const response = await get<OrderScooberJob>(`v2/Orders/${orderId}/ScooberJob`);
  return validateAxiosAndReturnResponse<OrderScooberJob>(response);
};

export const fetchDiscountInfoById = async ({discountId}: {discountId: OrderData['discountCoupon']['id']}) => {
  const response = await get<IDiscountResponse>(`v1/Discounts/Coupons/${discountId}`);
  return validateAxiosAndReturnResponse<IDiscountResponse>(response);
};

export const fetchBrandingPageData = async (params: IFetchBrandingPageDataParams) => {
  const response = await get<IFetchBrandingPageDataResponse>(`v1/Companies/CompanyBranding?companyBrandingContentType=${params.pageType}&token=${params.token}`);
  return validateAxiosAndReturnResponse<IFetchBrandingPageDataResponse>(response);
};

export const loadTenbisCredit = async (params: ILoadTenbisCredit) => patch<ILoadTenbisCreditResponse>('v1/Payments/LoadTenbisCredit', {}, params);

export const validateMerchant = async (url: string) => {
  const response = await get<ApplePayMerchantSessionResponse>(`v1/Payments/ApplePayMerchantSession?validationURL=${url}`);
  return validateAxiosAndReturnResponse<ApplePayMerchantSessionResponse>(response);
};
