import {memo} from 'react';

import styled from 'styled-components';

import {SimpleSpinnerLoader} from '~/shared/components/Loaders';
import SuccessIcon from '~/assets/images/icons/success.svg?react';
import FailureIcon from '~/assets/images/icons/failure.svg?react';
import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/media';
import {TenBisCreditEventTypes} from '~/shared/services/analytics/analyticsModels';

import {SubmitButton} from './Styled';
import {MessageStatuses} from './shared';

const StatusContainer = styled.div<{verify: boolean}>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: ${({theme}) => theme.colors.surface};
  ${media.minMobile`
    padding: ${({verify}) => (verify ?
    '32px 16px 16px' :
    '32px 0'
  )};
  `}
`;

const LoaderContainer = styled.div`
  > div {
    height: 72px;
    width: 72px;
    border-width: 6px;
    border-color: transparent;
    border-top-color: ${({theme}) => theme.colors.gray500};
  }
`;

const StatusText = styled.div`
  margin-top: 24px;
  font-weight: 400;
  font-size: 14px;
  color: ${({theme}) => theme.colors.text2};
`;

const VerifyTitle = styled.div`
  margin-bottom: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  color: ${({theme}) => theme.colors.text2};
`;

const Amount = styled.div`
  margin-top: 6px;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  color: ${({theme}) => theme.colors.text2};
`;

const Disclaimer = styled.div`
  margin-top: 32px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({theme}) => theme.colors.text};
`;

const ActionBlock = styled.div`
  width: 100%;
  padding: 0 16px;
  ${media.minMobile`
    padding: 0;
  `}
`;

const WhiteButton = styled(SubmitButton)`
  margin-top: 10px;
  background: ${({theme}) => theme.colors.surface};
  color: ${({theme}) => theme.colors.surfaceTitle};
  border: 1px solid ${({theme}) => theme.colors.gray500};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

const Footer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  ${media.minMobile`
    margin-bottom: 0px;
  `}
`;

const BalanceValue = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: ${({theme}) => theme.colors.text2};
`;

const StatusMessage = ({
  status,
  handleSubmit,
  handleCloseModal,
  toInitialStatus,
  amount,
}: {
  amount: number | string;
  status: MessageStatuses;
  toInitialStatus: () => void;
  handleCloseModal: (e: Event) => void;
  handleSubmit: (arg: TenBisCreditEventTypes) => void;
}) => {
  const {t} = getLocalizationService();

  return (
    <StatusContainer verify={status !== MessageStatuses.loading}>
      {status === MessageStatuses.loading && (
        <>
          <LoaderContainer>
            <SimpleSpinnerLoader />
          </LoaderContainer>
          <StatusText >{t('load_xxx_remain_to_credit', {creditAmount: amount})}</StatusText>
        </>
      )}

      {status === MessageStatuses.error && (
        <>
          <Content>
            <FailureIcon />

            <StatusText >{t('load_remain_to_credit_failed')}</StatusText>
          </Content>

          <Footer>
            <ActionBlock>
              <WhiteButton onClick={() => handleSubmit(TenBisCreditEventTypes.TRY_AGAIN)}>{t('try_again')}</WhiteButton>
            </ActionBlock>
          </Footer>
        </>
      )}

      {status === MessageStatuses.success && (
        <>
          <Content>
            <SuccessIcon />
            <StatusText>{t('load_xxx_remain_to_credit_success', {creditAmount: amount})}</StatusText>
          </Content>
          <Footer>
            <ActionBlock>
              <SubmitButton onClick={handleCloseModal}>{t('close_credit_modal')}</SubmitButton>
            </ActionBlock>
          </Footer>
        </>
      )}

      {status === MessageStatuses.verify && (
        <>
          <Content>
            <VerifyTitle>{t('confirmation_charge')}</VerifyTitle>
            <BalanceValue>{t('balance_to_add')}</BalanceValue>
            <Amount>₪{amount}</Amount>
          </Content>
          <Footer>
            <Disclaimer>{t('charge_disclaimer')}</Disclaimer>
            <ActionBlock>
              <SubmitButton onClick={() => handleSubmit(TenBisCreditEventTypes.CONFIRM)}>{t('submit_balance')}</SubmitButton>
              <WhiteButton onClick={toInitialStatus}>{t('edit_balance')}</WhiteButton>
            </ActionBlock>
          </Footer>
        </>
      )}
    </StatusContainer>
  );
};

export default memo(StatusMessage);
