import {useCallback, useMemo} from 'react';

import {CSSObject} from '@emotion/serialize';
import styled from 'styled-components';
import {isEmpty} from 'lodash';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
// eslint-disable-next-line no-restricted-imports
import {OptionTypeBase, StylesConfig} from 'react-select';

import DropDown from '~/shared/components/DropDown';
import {flipOnLTR} from '~/shared/theme/utils';
import {selectCurrentRestaurant, selectDishAssignedUsers} from '~/shared/store/selectors';
import {body16Normal} from '~/shared/theme/typography';
import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {RestaurantBusinessTypeName} from '~/shared/store/models';

const Root = styled.div`
  width: 160px;
  z-index: ${({theme}) => theme.zIndex.header};
  ${body16Normal};
  ${flipOnLTR`
    text-align: right;
  `}
`;

const selectStyles: StylesConfig<OptionTypeBase, false> = {
  menu: (base: CSSObject) => ({
    ...base,
    position: 'absolute',
    width: 160,
  }),
  menuList: (base: CSSObject) => ({
    ...base,
    maxHeight: 260,
  }),
};

type AssignedUserProps = {
  disableTabIndex?: boolean;
};

const AssignedUser = ({disableTabIndex}: AssignedUserProps) => {
  const currentRestaurant = useSelector(selectCurrentRestaurant);
  const assignedUsers = useSelector(selectDishAssignedUsers);
  const options = useMemo(() => assignedUsers.map(({id, name}) => ({value: id, label: name})), [assignedUsers]);

  const isMaxLargeMobile = useIsMaxLargeMobile();
  const menuPosition = useMemo(() => (isMaxLargeMobile ? 'fixed' : 'absolute'), [isMaxLargeMobile]);

  const {
    input: {value: assignedUserId, onChange},
  } = useField('assignedUserId');

  const value = useMemo(() => options.find(option => option.value === assignedUserId), [assignedUserId, options]);
  const onChangeValue = useCallback((option: OptionTypeBase | null) => onChange(option?.value), [onChange]);

  if (isEmpty(options) || currentRestaurant?.businessType !== RestaurantBusinessTypeName.Restaurant) {
    return <div />;
  }

  return (
    <Root data-test-id="dishAssignedUserContainer">
      <DropDown
        value={value}
        options={options}
        isDisabled={options.length === 1}
        onChange={onChangeValue}
        styles={selectStyles}
        name="assigned-users"
        tabIndex={disableTabIndex ? '-1' : '0'}
        menuPosition={menuPosition}
      />
    </Root>
  );
};

export default AssignedUser;
