import {GetClosedRestaurantMessageForDeliveryMode, isRestaurantFromSearch} from './types';
import {
  CLOSED_REASONS_MAP,
  DELIVERY_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT,
  SITTING_ONLY_DELIVERY,
  WITHOUT_DELIVERY_WITH_PICKUP,
} from './consts';

const getClosedRestaurantMessageForDelivery: GetClosedRestaurantMessageForDeliveryMode = restaurant => {
  if (!restaurant) {
    return DELIVERY_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT;
  }

  const {tempClosedReason, isScoober, isDeliveryEnabled} = restaurant;
  const isPickupEnabled = isRestaurantFromSearch(restaurant)
    ? restaurant.isPickupEnabled
    : !!restaurant.pickupRule?.isActiveNow;
  const closedReason = tempClosedReason || '';
  const scooberOrOwnDelivery = isScoober ? 'scoober' : 'ownDelivery';
  const isTerminalActive = isRestaurantFromSearch(restaurant)
    ? restaurant.isTerminalActive
    : restaurant.isTerminalEnabled;

  // No delivery, no pickup, no terminal - the restaurant is closed
  if (!isDeliveryEnabled && !isPickupEnabled && !isTerminalActive) {
    return DELIVERY_SERVICE_IS_NOT_AVAILABLE_AT_THE_MOMENT;
  }

  // No delivery, no pickup, the terminal is active - the restaurant is open for sitting
  if (!isDeliveryEnabled && !isPickupEnabled) {
    return SITTING_ONLY_DELIVERY;
  }

  // Only pickup is available
  if (!isDeliveryEnabled && isPickupEnabled) {
    return WITHOUT_DELIVERY_WITH_PICKUP;
  }

  return (
    // closedReason - enum that comes from the server.
    CLOSED_REASONS_MAP[scooberOrOwnDelivery][closedReason] ||
    // some closed restaurants doesn't have closedReason (it comes as an empty string)
    CLOSED_REASONS_MAP[scooberOrOwnDelivery].default
  );
};

export default getClosedRestaurantMessageForDelivery;
