/* eslint-disable class-methods-use-this */

import store from '../store';
import {User} from '../store/models';
import {restrictedSharedActions} from '../store/actions';
import {SignUpCredentials, UserCredentials} from '../store/storeModules/restrictedSharedActions';
import {setUserSessionToken} from '../utils/userToken';
import {trackEvent} from '../services/analytics';
import {selectCurrentAddress} from '../store/selectors';

export const updateCookiesAndToken = async (user: User) => {
  setUserSessionToken(user.sessionToken);
};

const UserManager = {
  login: async (userCredentials: UserCredentials, isAutomaticLogin: boolean) => {
    const user: User = await store.dispatchThunk(restrictedSharedActions.login(userCredentials));
    updateCookiesAndToken(user);
    trackEvent('login', {
      userData: user,
      isAutomaticLogin,
      currentAddressCityName: selectCurrentAddress(store.getState())?.cityName,
    });
    return user;
  },

  registerUser: async (
    signUpCredentials: SignUpCredentials & {authenticationCode?: string; authenticationToken?: string},
    registerCompanyUser?: boolean,
  ) => {
    const user: User = await store.dispatchThunk(
      restrictedSharedActions.registerUser({
        signUpCredentials: {
          ...signUpCredentials,
          activationCode: signUpCredentials.authenticationCode,
          activationToken: signUpCredentials.authenticationToken,
        },
        registerCompanyUser,
      }),
    );
    updateCookiesAndToken(user);
    trackEvent('register', {userData: user});
    return user;
  },
};

export default UserManager;
