import {mapAddressFromServerOrCookies} from '~/shared/utils/address';

import {Address, RestaurantFromSearch} from '../../models';
import {makeActionCreator, makeThunkAsyncActionCreator} from '../../redux-toolbelt';

import {UserState} from './userReducer';

export const clearUserErrors = makeActionCreator('clearUserErrors');

export const clearUserWithLoaded = makeActionCreator('clearUserWithLoaded');

export const getUserAddresses = makeThunkAsyncActionCreator<RestaurantFromSearch['restaurantId'], UserStateData['addresses']>(
  'getUserAddresses',
  async (restaurantId, {apiService}) => {
    const {data} = await apiService.getUserAddresses({restaurantId});
    return data.map(mapAddressFromServerOrCookies) as Address[];
  },
);

export const insertAddress = makeThunkAsyncActionCreator<Address, Address | void>(
  'insertAddress',
  async (address, {apiService}) => {
    try {
      const {data: addressIdAfterInsert} = await apiService.insertAddress(address);
      return {
        ...address,
        addressId: addressIdAfterInsert,
        addressKey: `${address.addressKey}-${addressIdAfterInsert}`,
      };
    } catch (error) {
      // ignoring error
    }
  },
);

export const removeAddress = makeThunkAsyncActionCreator<string, string | void>(
  'RemoveAddress',
  async (addressId, {apiService}) => {
    await apiService.removeAddress({addressId});
    return addressId;
  },
);

export const setMoneyCardActivationStatus = makeActionCreator<'setMoneyCardActivationStatus', boolean>(
  'setMoneyCardActivationStatus',
);

type UserStateData = NonNullable<UserState['data']>;
