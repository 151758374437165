import {useEffect, useState} from 'react';

import {isEqual, omit} from 'lodash';

import {useIsIOS} from './deviceInfo';

let store: Record<string, {deps: any[]; top: number}> = {};

const onScroll = (topY: number) => window.scrollTo(0, topY);

const useRestoreScrollPosition = (key: string, deps: any[], enabled: boolean) => {
  const [skipScroll, setSkipScroll] = useState(false);
  const isIos = useIsIOS();

  useEffect(() => {
    if (!enabled) {
      onScroll(0);
      store = omit(store, key);
    }
  }, [enabled, key]);

  useEffect(() => {
    if (!enabled) {
      return;
    }

    if (!store[key]) {
      store[key] = {deps: [], top: 0};
    }

    const prevDeps = store[key].deps;
    const prevTopY = store[key].top;

    if (!isEqual(prevDeps, deps)) {
      onScroll(0);
      store[key].deps = deps;
      store[key].top = 0;
    } else {
      if (skipScroll) {
        return;
      }

      onScroll(prevTopY);

      if (isIos && !skipScroll) {
        setSkipScroll(true);
      }
    }
  }, [deps, enabled, key, isIos, skipScroll]);

  useEffect(() => {
    if (skipScroll) {
      setSkipScroll(false);
      return;
    }

    const scroll = () => {
      if (enabled) {
        if (window.scrollY === 0) {
          return;
        }
        // need to use timeout beacuse it's sometimes updates after we the previes value and sets wrong value
        // is his place.
        setTimeout(() => {
          if (store[key]) {
            store[key].top = window.scrollY;
          }
        }, 0);
      }
    };

    document.addEventListener('scroll', scroll, true);
    return () => {
      document.removeEventListener('scroll', scroll, true);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, key]);
};

export default useRestoreScrollPosition;
