import {isEmpty} from 'lodash';

// @ts-expect-error config needs to be rewritten as ts
import {enableMenuFetchFromCdn} from '~/shared/config';
import {createLogger} from '~/shared/logging';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectIsFutureOrderEnabled, selectShoppingCartDishes, selectUserData} from '~/shared/store/selectors';
import {api} from '~/shared/services/bffService';
import transformMenuCategories, {MenuProps} from '~/shared/store/storeModules/menu/util';
import {filterMissingDishesAndSubs, shouldOpenMissingDishesModal} from '~/shared/store/storeModules/shoppingCart/util';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

import {MenuCategories, RestaurantFromGet} from '../../models';
import {makeThunkAsyncActionCreator, makeActionCreator} from '../../redux-toolbelt';

import {MenuState} from './menuReducer';

const logger = createLogger('menu actions');

export const fetchRestaurantMenu = makeThunkAsyncActionCreator<
  {restaurant: RestaurantFromGet; dateTime?: string; addressId?: number},
  MenuState['categories']['data']
>('fetchRestaurantMenu', async ({restaurant, dateTime, addressId}, {store, apiService}) => {
  const {currentLanguageKey} = getLocalizationService();
  const shoppingCartDishes = selectShoppingCartDishes(store.getState());
  const futureOrdersAvailable = selectIsFutureOrderEnabled(store.getState());

  const {id, pickupRule, deliveryRules} = restaurant;

  const state = store.getState();
  const userData = selectUserData(state);

  const isSittingOnly = !isEmpty(deliveryRules) && !pickupRule?.isActiveNow;
  const shouldFetchFromCdn =
    enableMenuFetchFromCdn &&
    !userData?.isCompanyUser &&
    ((futureOrdersAvailable && deliveryRules?.find(dr => dr.type === DELIVERY_RULES.FUTURE)?.isActiveNow) ||
      (!futureOrdersAvailable && !isSittingOnly));

  const fetchMenuFromBff = async () => {
    try {
      const menuFromBff = await api.fetchRestaurantMenu({id, dateTime, addressId});
      const unavailableDishesIds = filterMissingDishesAndSubs(menuFromBff.categories, shoppingCartDishes);

      shouldOpenMissingDishesModal(unavailableDishesIds);

      const menu = transformMenuCategories(id, menuFromBff);
      return (menu || {}) as MenuCategories;
    } catch (error) {
      const errorType = 'error while fetching fetchRestaurantMenu from bff';
      logger.error(errorType, {error, fingerprint: errorType});
    }
  };

  const fetchMenuFromCdn = async () => {
    try {
      const menuFromCdn = await apiService.fetchMenuFromCdn({restaurantId: id, currentLanguageKey});
      const unavailableDishesIds = filterMissingDishesAndSubs(
        (menuFromCdn as MenuProps).categories,
        shoppingCartDishes,
      );
      shouldOpenMissingDishesModal(unavailableDishesIds);

      const menu = transformMenuCategories(id, menuFromCdn as MenuProps);
      return (menu || {}) as MenuCategories;
    } catch (error) {
      const errorType = 'error while fetching fetchRestaurantMenu from cdn';
      logger.error(errorType, {error, fingerprint: errorType});
    }
  };

  if (shouldFetchFromCdn) {
    const dataFromCdn = await fetchMenuFromCdn();

    if (dataFromCdn) {
      return dataFromCdn;
    }
  }

  return fetchMenuFromBff();
});

export const getDishAssignedUsers = makeThunkAsyncActionCreator<never, MenuState['dishAssignedUsers']['data']>(
  'getDishAssignedUsers',
  async (_payload, {apiService}) => {
    const assignedUsers = await apiService.getDishAssignedUsers();
    return assignedUsers.data;
  },
);

export const fetchMenuOrderHistory = makeThunkAsyncActionCreator<{resId: number}, MenuState['orderHistory']['data']>(
  'fetchMenuOrderHistory',
  async (params, {apiService}) => {
    try {
      const result = await apiService.getUserOrdersHistoryForRestaurant(params);
      return result.data?.orders;
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error?.message || 'failed to fetch menu reorder history');
      } else {
        throw new Error('failed to fetch menu reorder history');
      }
    }
  },
);

export const clearMenuOrderHistory = makeActionCreator<'clearMenuOrderHistory', never>('clearMenuOrderHistory');
export const setFetchedCategories = makeActionCreator<'setFetchedCategories', MenuState['categories']>(
  'setFetchedCategories',
);
export const setMenuLoading = makeActionCreator<'setMenuLoading', boolean>('setMenuLoading');

export const setSearchTerm = makeActionCreator<'setSearchTerm', string>('setSearchTerm');
