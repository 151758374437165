import {Component} from 'react';

import ReactDOM from 'react-dom';

class Portal extends Component {
  componentDidMount() {
    this.el = document.createElement('div');
    this.el.style.cssText = 'position: fixed';
    this.root = document.getElementById('modals');

    this.root.appendChild(this.el);
    this.forceUpdate();
  }

  componentWillUnmount() {
    this.root.removeChild(this.el);
  }

  render() {
    if (this.el === undefined) {
      return null;
    }

    const {children} = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

export default Portal;
