import {createContext} from 'react';

import {RestaurantFromGet} from '~/shared/store/models';
import {PoolDeliveryTime} from '~/shared/store/models/Restaurant/RestaurantFromGet';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';

export interface IAsapPooledTogglerContext {
  isAsap: boolean;
  onRuleChange: (nextRule: DELIVERY_RULES) => void;
  asapDeliveryTimeInMinutes: number;
  poolDeliveryTime?: PoolDeliveryTime;
  asapDeliveryFee?: RestaurantFromGet['deliveryRules'][0]['deliveryFee'];
  asapDeliveryFeeBeforeDiscount?: RestaurantFromGet['deliveryRules'][0]['deliveryFeeBeforeDiscount'];
  pooledDeliveryFee?: RestaurantFromGet['deliveryRules'][0]['deliveryFee'];
  pooledDeliveryFeeBeforeDiscount?: RestaurantFromGet['deliveryRules'][0]['deliveryFeeBeforeDiscount'];
  imageAsap: string;
  imagePool: string;
  hideIcons?: boolean;
  hideTitle?: boolean;
  isCheckout?: boolean;
}

export const AsapPooledTogglerContext = createContext<IAsapPooledTogglerContext>({} as IAsapPooledTogglerContext);
