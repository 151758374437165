import {useLayoutEffect} from 'react';

import {useIsIOS, useIsSafari} from '~/shared/hooks/deviceInfo';
import {scrollToTop} from '~/shared/utils/scroll';

const useResetScrollToTopOnPageMount = () => {
  const isIOS = useIsIOS();
  const isSafari = useIsSafari();

  useLayoutEffect(() => {
    scrollToTop(isIOS && isSafari);
  }, [isIOS, isSafari]);
};

export default useResetScrollToTopOnPageMount;
