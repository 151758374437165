import styled from 'styled-components';

import {flexSpace} from '~/shared/theme/FlexLayout';

export const TipOptionsContainer = styled.div`
  border: none;
`;

export const TipsButtonsAndInputContainer = styled.ul`
  ${flexSpace};
  padding: ${({theme}) => theme.checkout.elements.tipsButtonsAndInputContainer.padding};
  margin-bottom: 0;
`;

export const ButtonsAndInputContainer = styled.div`
  ${flexSpace};
  margin: ${({theme}) => theme.checkout.elements.paymentsButtonsAndInputContainer.margin};
`;
