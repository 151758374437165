import {memo} from 'react';

import styled, {useTheme} from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import DiagonalHeaderView from '~/shared/components/DiagonalHeaderView';
import {attachModal} from '~/shared/components/ReduxModal';
import {media} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import restaurantReviewErrorModalImageUrl from '~/assets/images/reviewModalImg.png';
import {body13Bold, body13Normal, subheader26Bold} from '~/shared/theme/typography';

const RestaurantReviewErrorModalRoot = styled.div`
  border-radius: 2px;
  overflow: hidden;
  text-align: center;
  height: 100%;
  background-color: ${({theme}) => theme.modal.backgroundColor};
`;

const RestaurantReviewErrorModalTitle = styled.div`
  ${subheader26Bold};
  color: ${({theme}) => theme.modal.titleColor};
  padding: 15px 5px;
  margin-top: 50px;
  ${media.maxTablet`
    padding: 21px 20px;
  `};
`;
const RestaurantReviewErrorModalText = styled.div`
  ${body13Normal};
  line-height: 26px;
  ${media.maxTablet`
    line-height: 20px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;    
  `};
  color: ${({theme}) => theme.modal.textColorSecondary};
`;

const RefreshActionButton = styled(Button)`
  margin: 0 auto;
  width: 250px;
  border-width: 0;
  cursor: pointer;
  background-color: ${({theme}) => theme.modal.button.backgroundColor};
  color: ${({theme}) => theme.modal.button.color};
  padding: 10px 15px;
  ${body13Bold};

  &:hover {
    background-color: ${({theme}) => theme.actionButton.enabled.hoverBg};
  }

  ${media.maxTablet`
    width: 125px;
  `};
  ${media.minMobile`
    margin-bottom: 90px;
  `}
`;

const TextWrapper = styled.div`
  margin: 32px 0;
`;

const handleButtonClicked = ({closeModal}) => {
  closeModal();
};

const RestaurantReviewErrorModal = ({closeModal}) => {
  const theme = useTheme();
  const {t} = getLocalizationService();

  return (
    <RestaurantReviewErrorModalRoot>
      <DiagonalHeaderView
        backgroundImage={restaurantReviewErrorModalImageUrl}
        backgroundColor={theme.modal.backgroundColor}
      >
        <RestaurantReviewErrorModalTitle>{t('thanks')}</RestaurantReviewErrorModalTitle>
        <TextWrapper>
          <RestaurantReviewErrorModalText>{t('review_already_submitted')}</RestaurantReviewErrorModalText>
        </TextWrapper>
        <RefreshActionButton onClick={() => handleButtonClicked({closeModal})}>{t('understood')}</RefreshActionButton>
      </DiagonalHeaderView>
    </RestaurantReviewErrorModalRoot>
  );
};

attachModal('restaurant_review_error_modal', memo(RestaurantReviewErrorModal));
