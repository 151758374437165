import {createSelector} from 'reselect';

import {AppState} from '../../configStore';

const selectAutoComplete = (state: AppState) => state.autoComplete;

export const selectAutoCompleteCityName = createSelector(
  selectAutoComplete,
  autoComplete => autoComplete.cityName.data,
);

export const selectAutoCompleteStreetName = createSelector(
  selectAutoComplete,
  autoComplete => autoComplete.streetName.data,
);
