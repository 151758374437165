export const getPooledGroupSubtitle = (
  poolTimeStart: TimeSpan,
  poolTimeEnd: TimeSpan,
  poolTimeDelivery: TimeSpan,
): GroupSubtitle | undefined => {
  if (poolTimeStart.ticks === 0 && poolTimeEnd.ticks === 0 && poolTimeDelivery.ticks === 0) {
    return;
  }
  return {
    textKey: 'preorder_now_and_enjoy_free_delivery',
  };
};

export interface GroupSubtitle {
  textKey: string;
  textArgs?: {
    poolEndTimeString?: string;
    estimatedPoolDeliveryTime?: string;
  };
}
