import {sortBy} from 'lodash';

import {RestaurantFromGet} from '~/shared/store/models';
import {MutatedFutureOrderAvailableDatesAndTimes} from '~/shared/store/models/FutureOrderAvailableDatesAndTimes';
import {DELIVERY_RULES} from '~/shared/consts/restaurantConsts';
import {isCurrentTimeKeyAsap} from '~/shared/utils/general';
import {getTimeRangeLabel, isValidTimeRangeString} from '~/shared/utils/timeRangeHelper';

import {isToday} from './deliveryOptions/validators/isSDFORestaurant';

export const getMutatedFutureOrderAvailableDatesAndTimes = (restaurant?: RestaurantFromGet) => {
  const {availableFutureDatesAndTimes} = restaurant || {};
  if (!availableFutureDatesAndTimes) {
    return;
  }

  const futureDatesAndTimes = availableFutureDatesAndTimes.reduce(
    (result: Record<string, MutatedFutureOrderAvailableDatesAndTimes>, futureDate) => {
      const {date, ruleId, timesWithTimeRanges} = futureDate;
      const asapRuleId = restaurant?.deliveryRules.find(({type, isActiveNow}) => type === DELIVERY_RULES.ASAP && isActiveNow)?.id;

      const timesWithRuleId = Object.entries(timesWithTimeRanges).reduce<MutatedFutureOrderAvailableDatesAndTimes['times'][number][]>((filteredTimes, [timeKey, timeRange]) => {
        if (isCurrentTimeKeyAsap(timeKey)) {
          if (asapRuleId) {
            return [{
              // Timekey asap should be in upper case when used as API parameter
              timeKey: DELIVERY_RULES.ASAP.toUpperCase(),
              timeRange: DELIVERY_RULES.ASAP.toUpperCase(),
              timeKeyLabel: DELIVERY_RULES.ASAP,
              ruleId: asapRuleId,
            }, ...sortBy(filteredTimes, 'timeKey')];
          }
          return sortBy(filteredTimes, 'timeKey');
        }

        return [...filteredTimes, {
          timeKey,
          timeRange,
          timeKeyLabel: isValidTimeRangeString(timeRange) ?
            getTimeRangeLabel(timeRange) :
            timeRange,
          ruleId,
        }];
      }, []);
      // times is splitted into multiple chunks of the same day
      // ruleId is used ONLY for creating times object inside date as ruleId can be different for the same day
      const isExtraTimeForSameDay = result[date];

      if (isExtraTimeForSameDay) {
        result[date].times.push(...timesWithRuleId);

        result[date].times = sortBy(result[date].times, 'timeKey');

        if (asapRuleId && isToday(date)) {
          result[date].times = [
            {
              timeKey: DELIVERY_RULES.ASAP.toUpperCase(),
              timeRange: DELIVERY_RULES.ASAP.toUpperCase(),
              timeKeyLabel: DELIVERY_RULES.ASAP,
              ruleId: asapRuleId,
            },
            ...result[date].times.filter(time => time.timeKey.toUpperCase() !== DELIVERY_RULES.ASAP.toUpperCase()),
          ];
        }

        return result;
      }

      result[date] = {...futureDate, times: timesWithRuleId};
      return result;
    },
    {},
  );
  return Object.values(futureDatesAndTimes);
};
