import {logger} from './logger';
import {Restriction, RestrictionValue} from './config';
import {Context} from './settings';

export enum Op {
  And = 'and',
  Or = 'or',
  Eq = 'eq',
  Neq = 'neq',
  In = 'in',
  Nin = 'nin',
  Gt = 'gt',
  Gte = 'gte',
  Lt = 'lt',
  Lte = 'lte',
}

function verifyRestrictionObject(restriction: Restriction): void {
  const ops = Object.values(Op).filter(value => typeof restriction[value] !== 'undefined');
  if (ops.length > 1) throw new Error(`Multiple operators specified (${ops.join(', ')})`);
}

function getContextValue(
  restriction: Restriction,
  restrictionsProvidersMap: Map<string, () => string>,
): string {
  const {property} = restriction;

  if (!restrictionsProvidersMap.size) {
    throw new Error('No restriction properties found');
  }
  if (!restrictionsProvidersMap.has(property)) {
    throw new Error(`Unexpected restriction property: ${property}`);
  }

  const contextValue = restrictionsProvidersMap.get(property);

  if (typeof contextValue === 'function') {
    return contextValue();
  }

  throw new Error('No restriction properties found');
}

function isEqual(
  checkValue: RestrictionValue,
  restriction: Restriction,
  context: Context,
): boolean {
  const contextValue = getContextValue(restriction, context.restrictionsProviders);

  return checkValue.toLowerCase() === contextValue?.toLowerCase();
}

const compareVersionNumbers = (left: string, right: string): number =>
  left.localeCompare(right, undefined, {
    ignorePunctuation: true,
    numeric: true,
    sensitivity: 'base',
  });

function isGreaterThan(left: string, right: string, {property}: Restriction): boolean {
  if (property === 'AppVersion') return compareVersionNumbers(left, right) === 1;

  throw new Error(`Property is not comparable: ${property}`);
}

function comparableCheck(operator: Op, restriction: Restriction, context: Context): boolean {
  const value = getContextValue(restriction, context.restrictionsProviders);
  const checkValue = restriction[operator] as RestrictionValue;

  const equal = isEqual(checkValue, restriction, context);
  if ([Op.Eq, Op.Gte, Op.Lte].includes(operator) && equal) return true;
  if ([Op.Gt, Op.Gte].includes(operator)) return isGreaterThan(value, checkValue, restriction);
  if ([Op.Lt, Op.Lte].includes(operator)) return isGreaterThan(checkValue, value, restriction);

  return false;
}

/**
 * Todo - Rename to `isAllowed`
 */
function evaluateRestriction(restriction: Restriction, context: Context): boolean {
  try {
    verifyRestrictionObject(restriction);

    if (restriction.and) return restriction.and.every(r => evaluateRestriction(r, context));
    if (restriction.or) return restriction.or.some(r => evaluateRestriction(r, context));
    if (restriction.eq) return isEqual(restriction.eq, restriction, context);
    if (restriction.neq) return !isEqual(restriction.neq, restriction, context);
    if (restriction.in) return restriction.in.some(val => isEqual(val, restriction, context));
    if (restriction.nin) return restriction.nin.every(val => !isEqual(val, restriction, context));
    if (restriction.gt) return comparableCheck(Op.Gt, restriction, context);
    if (restriction.gte) return comparableCheck(Op.Gte, restriction, context);
    if (restriction.lt) return comparableCheck(Op.Lt, restriction, context);
    if (restriction.lte) return comparableCheck(Op.Lte, restriction, context);
    // Todo - check if this has legit use cases apart from tests
    return true;
  } catch (error: unknown) {
    const message = error instanceof Error ? error.message : 'Unknown error';
    logger.logWarn(message, null, {error});
    return false;
  }
}

export {getContextValue};
export default evaluateRestriction;
