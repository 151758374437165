import {memo} from 'react';

import styled from 'styled-components';
import {useSelector} from 'react-redux';

import {attachModal} from '~/shared/components/ReduxModal';
import {getLocalizationService} from '~/shared/services/localisationService';
import {flexColumn} from '~/shared/theme/FlexLayout';
import {body14Bold, body14Normal, body20Bold} from '~/shared/theme/typography';
import missingDishImg from '~/assets/images/missingItems.png';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import {CloseButtonSVGIcon} from '~/shared/components/styled';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {media} from '~/shared/theme/media';
import {getMenuOrDishPagePath} from '~/shared/services/urlService';
import {pushRoute} from '~/shared/router';
import {selectCurrentRestaurant} from '~/shared/store/selectors';
import {flipOnLTR} from '~/shared/theme/utils';
import {CloseButton} from '~/shared/components/Modals';

const Root = styled(ClickOutsideDiv)`
  ${flexColumn}
  align-items: center;

  && {
    width: 343px;
    height: 416px;
    background-color: ${({theme}) => theme.colors.surface};
    border-radius: 16px;
    padding: 0 24px;
  }

  ${media.minTablet`
    && {
      padding: 0 32px;
      width: 475px;
      height: 420px;
    }
  `}
`;

const Title = styled.div`
  margin-top: 44px;
  white-space: pre-line;
  text-align: center;
  ${body20Bold}
  color: ${({theme}) => theme.colors.charcoal80};
  line-height: 28px;
  ${media.minTablet`
    margin-top: 48px;
  `}
`;

const SubTitle = styled.div`
  margin-top: 8px;
  color: ${({theme}) => theme.colors.charcoal80};
  ${body14Normal}
  line-height: 20px;
`;

const StyledMissingDishImg = styled(ImageWithAlt).attrs({
  src: missingDishImg,
  noAlt: true,
})`
  margin: 24px 0;
  height: 160px;
  width: 160px;
`;

const BackToMenuButton = styled.button`
  border: 1px solid ${({theme}) => theme.colors.mozzarella30};
  color: ${({theme}) => theme.colors.charcoal80};
  ${body14Bold}
  border-radius: 100px;
  width: 295px;
  height: 48px;
  ${media.minTablet`
    width: 411px;
  `}
`;

const CloseDialogButton = styled(CloseButton)`
  top: 16px;
  ${flipOnLTR`
    left: 16px; 
  `}
`;

interface CheckoutEmptyStateModalProps {closeModal: () => void}

const CheckoutEmptyStateModal = ({closeModal}: CheckoutEmptyStateModalProps) => {
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const onModalClose = () => {
    const currentRestaurantUrl = getMenuOrDishPagePath({
      restaurantId: currentRestaurant?.id,
      restaurantName: currentRestaurant?.name,
    });
    closeModal();
    if (currentRestaurantUrl) {
      pushRoute(currentRestaurantUrl);
    }
  };
  const {t} = getLocalizationService();

  return (
    <Root onClickOutside={onModalClose}>
      <CloseDialogButton onClick={onModalClose}>
        <CloseButtonSVGIcon />
      </CloseDialogButton>
      <Title id="modal-title">{t('oops_empty_shopping_cart')}</Title>
      <SubTitle>{t('please_go_to_menu')}</SubTitle>
      <StyledMissingDishImg />
      <BackToMenuButton onClick={onModalClose}>{t('back_to_menu')}</BackToMenuButton>
    </Root>
  );
};

type StaticTypes = Partial<{
  hideCloseButton: boolean;
  modalType: string;
}>;

const EnhancedCheckoutEmptyStateModal: React.FC<CheckoutEmptyStateModalProps> & StaticTypes =
  memo(CheckoutEmptyStateModal);

EnhancedCheckoutEmptyStateModal.hideCloseButton = true;
EnhancedCheckoutEmptyStateModal.modalType = 'autoHeight';

export default attachModal('checkout_empty_state_modal', EnhancedCheckoutEmptyStateModal);
