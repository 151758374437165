import styled, {keyframes} from 'styled-components';

const loadIconsAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`;

const SimpleSpinnerLoader = styled.div`
  border: 2px solid ${({theme}) => theme.colors.primaryText};
  border-radius: 50%;
  border-top: 2px solid ${({theme}) => theme.colors.gray100};
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  animation: ${loadIconsAnimation} 3s infinite ease-in-out;
`;

export default SimpleSpinnerLoader;
