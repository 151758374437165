import {ReactNode} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';

const BadgeText = styled.span`
  height: 20px;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 12px;
  text-align: center;
  font-weight: 700;
  color: ${({theme}) => theme.tagColorsForeground.NoDeliveryFee};
  background-color: ${({theme}) => theme.tagColorsBackground.NoDeliveryFee};
  padding: 2px 4px;
  border-radius: 4px;
  line-height: 1;
`;

const FreeShippingBadge = ({children}: {children: ReactNode}) => {
  const {t} = getLocalizationService();
  return (
    <BadgeText>
      {children}
      {t('free_delivery')}
    </BadgeText>
  );
};

export default FreeShippingBadge;
