import {useSelector, useDispatch} from 'react-redux';
import {omit} from 'lodash';

import UpdateUser from '~/shared/components/UpdateUser';
import {useIsMinDesktop, useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import {createLogger} from '~/shared/logging';
import ManagerProvider from '~/shared/managers/ManagerProvider';
import {pushRoute} from '~/shared/router';
import {navigateToDefaultStartPage} from '~/shared/services/navigation';
import {setCurrentModal} from '~/shared/store/actions';
import {selectShoppingCartBillingLines, selectUserData} from '~/shared/store/selectors';
import {handleRefreshToken, logout} from '~/shared/services/auth';
import apiService from '~/shared/services/apiService';
import {getLegacySiteUrl} from '~/shared/services/urlService';
import {getUserSessionToken} from '~/shared/utils/userToken';
import {api} from '~/shared/services/bffService';
import {User} from '~/shared/store/models';
import {IUpdateUserParams} from '~/shared/services/bffService/api/interfaces/payloads';
import {getLocalizationService} from '~/shared/services/localisationService';
import {VerificationFormContextProvider} from '~/common/contextProviders/VerificationFormContextProvider';
import {onTermsOfUseClick, onPrivacyPolicyClick} from '~/common/utils/agreeToTermsUtils';
import {SendAndHandleRequestType} from '~/shared/components/UpdateUser/context';
import {is401Error} from '~/shared/services/apiErrorService';

const logger = createLogger('updateUser*');

const UpdateUserDetails = () => {
  const isMinDesktop = useIsMinDesktop();
  const isMaxLargeMobile = useIsMaxLargeMobile();
  const userData = useSelector(selectUserData);
  const shoppingCartBillingLines = useSelector(selectShoppingCartBillingLines);
  const dispatch = useDispatch();
  const {t} = getLocalizationService();
  const {hideSuccessPopup} = userData?.userRequiredActions?.updateUserDetailsForm || {};

  const login = () => {
    ManagerProvider.login().then(() => {
      if (isMinDesktop) {
        return;
      }

      if (isMaxLargeMobile && shoppingCartBillingLines?.length) {
        pushRoute('/checkout');
        return;
      }

      navigateToDefaultStartPage({routerOptions: {keepAllQueries: false, permanent: false}});
    });
  };

  interface HandleNavigationOnSuccessParams {
    redirectToOtl?: boolean;
    data?: string | null;
  }

  const sendAndHandleRequest: SendAndHandleRequestType = async ({userDetails, isResetPasswordType, setResErrors}) => {
    setResErrors(null);
    const handleNavigationOnSuccess = ({redirectToOtl, data = null}: HandleNavigationOnSuccessParams) => {
      const isRequireOtlOrRedirect = userData?.userEnabledFeatures.includes('RequireOTLUponFirstLogin') || redirectToOtl;

      if (isRequireOtlOrRedirect) {
        pushRoute('/join', {hard: true});
        return;
      }

      const shouldavigateToUrlAfterLogin = !isResetPasswordType && data;
      if (shouldavigateToUrlAfterLogin) {
        const url = data?.startsWith('/') ? data : `/${data}`;
        pushRoute(getLegacySiteUrl(url), {hard: true});
        return;
      }
      login();
    };

    try {
      let redirectToOtl: HandleNavigationOnSuccessParams['redirectToOtl'];
      let data: HandleNavigationOnSuccessParams['data'];
      const token = getUserSessionToken();

      if (!isResetPasswordType) {
        const updatedUserFields = {
          ...userDetails,
          wantPromotions: Boolean(userDetails.wantPromotions),
          defaultCompanyAddressId:
            userDetails.companyAddress && userDetails.companyAddress && typeof userDetails.companyAddress === 'string'
              ? parseInt(userDetails.companyAddress, 10)
              : undefined,
        };

        const params: IUpdateUserParams = {
          userDetails: omit(updatedUserFields, [
            'wantPromotion',
            'authenticationCode',
            'authenticationToken',
            'activationToken',
            'companyAddress',
          ]) as Partial<User>,
          authenticationCode: userDetails.authenticationCode as IUpdateUserParams['authenticationCode'],
          authenticationToken: userDetails.authenticationToken as IUpdateUserParams['authenticationToken'],
        };

        const config = {
          headers: {
            'session-token': token,
          },
        };
        const result = await api.updateUserDetails(params, config);
        redirectToOtl = result.redirectToOtl;
      } else {
        const result = await apiService.updateUserPassword(userDetails);
        const {data: responseData} = result;
        data = responseData as string;
      }

      if (hideSuccessPopup) {
        handleNavigationOnSuccess({redirectToOtl, data});
      } else {
        dispatch(
          setCurrentModal('successModal', {
            title: 'details_have_been_updated_successfully',
            text: 'your_details_have_been_updated_successfully_can_order_now',
            btnText: 'ok',
            onSuccess: () => handleNavigationOnSuccess({redirectToOtl, data}),
            disableCloseModal: !isMinDesktop,
          }),
        );
      }
    } catch (error: any) {
      if (is401Error(error)) {
        await handleRefreshToken(error, sendAndHandleRequest, {userDetails, isResetPasswordType, setResErrors});
        return;
      }

      logger.error('fail to update user', error);
      if (error.message) {
        setResErrors(error.message);
        throw new Error(error.message);
      }
      setResErrors(t('request_failed'));

      throw new Error('[BFF] failed to update user');
    }
  };

  const getActivationTokenAndSendActivationCodeToUser = async ({
    cellPhone,
    email,
    isRegistrationMode,
  }: {
    cellPhone: string;
    email: string;
    isRegistrationMode: boolean;
  }) =>
    apiService.getActivationTokenAndSendActivationCodeToUser({
      cellPhone,
      email,
      isRegistrationMode,
    });

  return (
    <VerificationFormContextProvider>
      <UpdateUser.Provider
        value={{
          userData,
          logger,
          logout,
          getActivationTokenAndSendActivationCodeToUser,
          sendAndHandleRequest,
          onTermsOfUseClick,
          onPrivacyPolicyClick,
        }}
      >
        <UpdateUser />
      </UpdateUser.Provider>
    </VerificationFormContextProvider>
  );
};

export default UpdateUserDetails;
