import {useCallback, useRef, useEffect, useState} from 'react';

import {useTheme} from 'styled-components';

/* Feature detection - https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#improving_scrolling_performance_with_passive_listeners*/
let passiveIfSupported: boolean | {passive: boolean} = false;

try {
  window.addEventListener(
    'test' as keyof WindowEventMap,
    null as unknown as EventListener,
    Object.defineProperty({}, 'passive', {
      get() {
        passiveIfSupported = {passive: true};
        return passiveIfSupported;
      },
    }),
  );
  // eslint-disable-next-line no-empty
} catch (err) {}

export type OnSlideNextType = (args: {manual: boolean}) => void;

type TouchSlideProps = {
  onSlideNext: OnSlideNextType;
  onSlidePrev: () => void;
  isDisabled: boolean;
  touchMove?: (value: number) => void;
  touchEnd?: () => void;
};

const useTouchSlide = ({onSlideNext, onSlidePrev, isDisabled = true, touchMove, touchEnd}: TouchSlideProps) => {
  const [node, setNodeRef] = useState<HTMLElement | null>(null);
  const mousePositionRef = useRef<Record<string, any>>({});

  const theme = useTheme();

  const handleTouchStart = useCallback((e: TouchEvent) => {
    mousePositionRef.current.startX = e.targetTouches[0]?.clientX;
    mousePositionRef.current.startY = e.targetTouches[0]?.clientY;
  }, []);

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      mousePositionRef.current.endX = e.targetTouches[0]?.clientX;

      if (touchMove && mousePositionRef.current.startY - 50 < e.targetTouches[0].clientY) {
        touchMove(-(e.touches[0].clientX - mousePositionRef?.current?.startX) / window.innerWidth);
      }
    },
    [touchMove],
  );

  const handleTouchEnd = useCallback(() => {
    const {startX, endX} = mousePositionRef.current;

    if (!endX) {
      return;
    }

    if (touchEnd) {
      touchEnd();
      return;
    }

    if (theme.isLTR) {
      if (startX > endX) {
        onSlideNext({manual: true});
      } else {
        onSlidePrev();
      }
    } else {
      if (startX > endX) {
        onSlidePrev();
      } else {
        onSlideNext({manual: true});
      }
    }
  }, [onSlideNext, onSlidePrev, theme.isLTR, touchEnd]);

  useEffect(() => {
    if (!node || isDisabled) {
      return;
    }

    node.addEventListener('touchstart', handleTouchStart, passiveIfSupported);
    node.addEventListener('touchmove', handleTouchMove, passiveIfSupported);
    node.addEventListener('touchend', handleTouchEnd, passiveIfSupported);

    return () => {
      node.removeEventListener('touchstart', handleTouchStart);
      node.removeEventListener('touchmove', handleTouchMove);
      node.removeEventListener('touchend', handleTouchEnd);
    };
  }, [node, isDisabled, handleTouchEnd, handleTouchStart, handleTouchMove]);

  return [node, setNodeRef];
};

export default useTouchSlide;
