/* eslint-disable max-len */
import {useState, memo, useEffect} from 'react';

import {useSelector} from 'react-redux';

import {websiteUrl, intercomAppId, isDev} from '~/shared/config';
import {useIsMaxLargeMobile} from '~/shared/hooks/deviceInfo';
import useChat from '~/shared/hooks/useChat';
import {removeQueries, useLocation} from '~/shared/router';
import {getLocalizationService} from '~/shared/services/localisationService';
import {selectCurrentRestaurant, selectUserData, selectUsersPrivateAddresses} from '~/shared/store/selectors';
import store from '~/shared/store';
import {getMenuOrDishPagePath} from '~/shared/services/urlService';

const updateIntercom = data => {
  if (!window.Intercom) {
    return;
  }

  window.Intercom('update', data);
};

const bootIntercom = (currentLanguageKey = 'he') => {
  window.Intercom('boot', {
    debug: isDev,
    api_base: 'https://api-iam.eu.intercom.io',
    app_id: intercomAppId,
    alignment: 'left',
    horizontal_padding: 20,
    vertical_padding: 20,
    language_override: currentLanguageKey,
  });
};

export const onLogout = () => {
  window.Intercom('shutdown');
  bootIntercom();
};

export const updateIntercomWithFirstDish = () => {
  if (!window.Intercom) {
    return;
  }

  const currentRestaurant = selectCurrentRestaurant(store.getState());
  if (!currentRestaurant) {
    return;
  }

  window.Intercom('trackEvent', 'itemAddedtoCart', {
    restaurant_id: currentRestaurant.id,
    restaurant_name: currentRestaurant.name,
    restaurant_link: `${websiteUrl}${getMenuOrDishPagePath({
      restaurantId: currentRestaurant.id,
      restaurantName: currentRestaurant.name,
    })}`.replaceAll('.il//', '.il/'),
    platform: 'web',
  });
};

export const updateIntercomWithOrder = (orderData = {}) => {
  if (!window.Intercom) {
    return;
  }

  window.Intercom('trackEvent', 'Purchase', {
    order_id: orderData.orderId,
    restaurant_id: orderData.restaurant.restaurantId,
    restaurant_name: orderData.restaurant.restaurantName,
    is_scoober: orderData.restaurant.isScoober,
    is_pooled: orderData.isPoolOrder,
    order_city_name: orderData.address.cityName,
    platform: 'web',
  });

  updateIntercom({
    last_order_id: orderData.orderId,
    last_order_restaurant_name: orderData.restaurant.restaurantName,
    last_order_restaurant_id: orderData.restaurant.restaurantId,
    last_order_is_scoober_restaurant: orderData.restaurant.isScoober,
    last_order_is_pooled: orderData.isPoolOrder,
    last_order_city_name: orderData.address.cityName,
  });
};

const Intercom = () => {
  const [isScriptMounted, setIsScriptMounted] = useState(false);
  const userData = useSelector(selectUserData);
  const usersPrivateAddresses = useSelector(selectUsersPrivateAddresses);
  const {currentLanguageKey} = getLocalizationService();
  const {search} = useLocation();
  const {isAvailable} = useChat(isScriptMounted);

  const isMaxLargeMobile = useIsMaxLargeMobile();

  const intercomObject = document.getElementsByClassName('intercom-lightweight-app-launcher')[0];

  useEffect(() => {
    if (isAvailable && search.toLowerCase().includes('openlivechat')) {
      removeQueries(['openlivechat']);
      window.Intercom('show');
    }
  }, [isAvailable, isScriptMounted, search]);

  useEffect(() => {
    const intercomScript = document.createElement('script');
    intercomScript.innerHTML = `
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercomAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

    setIsScriptMounted(true);
    document.head.appendChild(intercomScript);
    bootIntercom(currentLanguageKey);
  }, [currentLanguageKey]);

  useEffect(() => {
    if (!userData) {
      return;
    }

    const company = userData.companyId
      ? {
          company: {
            id: userData.companyId,
          },
        }
      : {};

    updateIntercom({
      name: `${userData.firstName} ${userData.lastName}`,
      user_id: userData.userId,
      email: userData.email,
      first_name: userData.firstName,
      last_name: userData.lastName,
      phone: userData.cellphone,
      is_admin: userData.isCompanyAdmin,
      is_first_private_order: !usersPrivateAddresses?.length,
      language_override: currentLanguageKey,
      ...company,
    });
  }, [usersPrivateAddresses, userData, currentLanguageKey]);

  if (intercomObject) {
    if (isMaxLargeMobile) {
      intercomObject.style.bottom = '32px';
      return;
    }
    intercomObject.style.bottom = '20px';
  }

  return null;
};

export default memo(Intercom);
