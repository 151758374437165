import {memo} from 'react';

import styled from 'styled-components';

import {media, flipOnLTR} from '~/shared/theme/utils';
import {generatePriceLabel} from '~/shared/components/PriceLabels';
import {body14Bold} from '~/shared/theme/typography';
import {RippleLoader, SkeletonLoader} from '~/shared/components/Loaders';
import {SkeletonButton} from '~/shared/components/Loaders/components';
import {FlexCenter} from '~/shared/theme/FlexLayout';

const SubmitButton = styled.button.attrs({
  'data-test-id': 'checkoutSubmitOrderBtn',
})`
  position: relative;
  width: 100%;
  height: 50px;
  background-color: ${({theme}) => theme.colors.surfacePrimaryAction};
  color: ${({theme}) => theme.colors.surfacePrimaryActionText};
  ${body14Bold};
  text-align: center;
  cursor: pointer;
  ${media.minLargeTablet`
    margin-bottom: 24px;
  `}

  &:disabled {
    background-color: ${({theme}) => theme.colors.gray500};
    cursor: not-allowed;
  }

  ${media.minLargeTablet`
    ${flipOnLTR`
      margin-right:  ${({centerSubmitBtn}) => centerSubmitBtn && '154px'};
      margin-left: ${({centerSubmitBtn}) => centerSubmitBtn && '24px'};
    `}
  `}
`;

const LoaderContainer = styled(FlexCenter)`
  position: absolute;
  top: 0;
  ${flipOnLTR`
    right: calc(50% - 25px);
  `}
`;

const CheckoutSubmit = ({
  onCheckoutSubmit,
  label: translatedLabel,
  isDisabled,
  centerSubmitBtn,
  totalToCharge,
  isLoading,
  isSubmitting,
}) => (
  <SkeletonLoader shouldShowLoader={isLoading} LoaderComponent={SkeletonButton}>
    <SubmitButton centerSubmitBtn={centerSubmitBtn} onClick={onCheckoutSubmit} disabled={isDisabled}>
      {isDisabled && isSubmitting ? (
        <LoaderContainer>
          <RippleLoader size={50} />
        </LoaderContainer>
      ) : `${translatedLabel} (${generatePriceLabel({price: totalToCharge})})`}
    </SubmitButton>
  </SkeletonLoader>
);

export default memo(CheckoutSubmit);
