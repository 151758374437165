import {Address, LocalAddress, RestaurantFromGet, RestaurantFromSearch} from '~/shared/store/models';
import {SHOPPING_CART_DELIVERY_TYPE} from '~/shared/utils/restaurants/deliveryOptions/types';
import isSDFORestaurant from '~/shared/utils/restaurants/deliveryOptions/validators/isSDFORestaurant';
import isAsapAndPooledRestaurant from '~/shared/utils/restaurants/deliveryOptions/validators/isAsapAndPooledRestaurant';
import isAsapAndFutureRestaurant from '~/shared/utils/restaurants/deliveryOptions/validators/isAsapAndFutureRestaurant';
import isPoolOnlyRestaurant from '~/shared/utils/restaurants/deliveryOptions/validators/isPoolOnlyRestaurant';
import isAsapOnlyRestaurant from '~/shared/utils/restaurants/deliveryOptions/validators/isAsapOnlyRestaurant';
import {isVoucherRestaurant} from '~/shared/utils/restaurants/restaurantStatus';
import isFutureOnlyRestaurant from '~/shared/utils/restaurants/deliveryOptions/validators/isFutureOnlyRestaurant';

const getCurrentRestaurantShoppingCartDeliveryType = (restaurant: RestaurantFromSearch | RestaurantFromGet, address?: Address | LocalAddress | undefined): SHOPPING_CART_DELIVERY_TYPE => {
  if (restaurant.tempClosedReason || isVoucherRestaurant(restaurant)) {
    return SHOPPING_CART_DELIVERY_TYPE.DEFAULT;
  }

  if (isAsapAndPooledRestaurant(restaurant, address)) {
    return SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_POOLED;
  }

  if (isPoolOnlyRestaurant(restaurant, address)) {
    return SHOPPING_CART_DELIVERY_TYPE.POOL_ONLY;
  }

  if (isSDFORestaurant(restaurant, address)) {
    return SHOPPING_CART_DELIVERY_TYPE.SDFO;
  }

  if (isAsapAndFutureRestaurant(restaurant, address)) {
    return SHOPPING_CART_DELIVERY_TYPE.ASAP_AND_FUTURE;
  }

  if (isFutureOnlyRestaurant(restaurant, address)) {
    return SHOPPING_CART_DELIVERY_TYPE.FUTURE;
  }

  if (isAsapOnlyRestaurant(restaurant, address)) {
    return SHOPPING_CART_DELIVERY_TYPE.ASAP_ONLY;
  }

  return SHOPPING_CART_DELIVERY_TYPE.DEFAULT;
};

export default getCurrentRestaurantShoppingCartDeliveryType;
