// businessType
export enum RestaurantBusinessTypeFromSearch {
  None = 0,
  Restaurant = 1,
  GroceryStore = 2,
  OfficeSupplies = 3,
  // VoucherCard = 4 <- TODO: not sure about that
}

export enum RestaurantBusinessTypeName {
  None = 'None',
  Restaurant = 'Restaurant',
  GroceryStore = 'GroceryStore',
  OfficeSupplies = 'OfficeSupplies',
  VoucherCard = 'VoucherCard', // Multipass
  Voucher = 'Voucher', // Used for self generated vouchers like Be (soon also shufersal)
}
