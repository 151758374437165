/* eslint-disable max-len */

import {memo} from 'react';

import styled, {css, useTheme} from 'styled-components';
import {uniqueId} from 'lodash';
import {useSelector} from 'react-redux';

import {media, flipOnLTR} from '~/shared/theme/utils';
import Tooltip from '~/shared/components/Tooltip';
import {flexCenter, flexColumn, flexCenterVertically} from '~/shared/theme/FlexLayout';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import {body18Bold, body16Normal, body20Normal, header40Normal, subheader25Normal} from '../theme/typography';
import {selectA11yContrast} from '../store/selectors';

export const ModalInnerTooltip = styled(Tooltip).attrs({
  overlayStyle: {
    zIndex: 1000,
  },
})``;

export const Fieldset = styled.fieldset`
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
  padding-block-end: 0;
  min-inline-size: min-content;
  border-width: 2px;
  border-style: groove;
  border: none;
  border-image: initial;
  padding: 0;
  margin: 0;
`;

export const PageContainer = styled.div`
  ${flexColumn};
  position: relative;
`;

export const SubheaderContainer = styled.div`
  ${flexCenterVertically};
  position: relative;
  justify-content: space-between;
  padding: 10px ${({theme}) => theme.MobileHeaderSidesPadding}px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  min-height: ${({theme}) => theme.subHeader.height}px;
  max-height: ${({theme}) => theme.subHeader.height}px;
  background-color: ${({theme}) => theme.subHeader.backgroundColor};
  z-index: 1;
`;

export const NewWidthContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  ${media.minLargeMobile`
    max-width: 570px;
  `}
  ${media.minLargeTablet`
    max-width: 1004px;
  `}
  ${media.minLargeDesktop`
    max-width: 1281px;
  `}
`;

export const WidthContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1280px;
`;

export const InnerWidthContainer = styled(NewWidthContainer)`
  position: initial;
  display: flex;
  margin: 0 auto;
  overflow: visible;
`;

export const Scroller = styled.div`
  flex: 1;
  margin-top: 170px;
  padding: 10px ${({theme}) => theme.bodyMargins} 0;
  background-color: ${({theme}) => theme.scroller.mobileBackgroundColor};
  ${media.minMobile`
    margin-top: 275;
    padding: 20px ${({theme}) => theme.bodyMargins} 0;
    background-color: ${({theme}) => theme.scroller.backgroundColor};
  `};
`;

export const Logo = styled(ImageWithAlt)<{show?: boolean}>`
  ${({show}) =>
    show === false &&
    css`
      display: none;
    `};
  width: 108px;
  height: 32px;
  cursor: pointer;
  ${media.minLargeTablet`
    display: block;
  `}
`;

export const ModalHeader = styled.div`
  ${subheader25Normal};
  ${flexCenter};
  flex-shrink: 0;
  width: 100%;
  background-color: ${({theme}) => theme.loginModal.headerBackgroundColor};
  color: ${({theme}) => theme.loginModal.headerTextColor};
  ${media.minMobile`
    border-radius: 2px 2px 0 0;
    ${header40Normal}
  `};
`;

export const HeaderWrapper = styled(ModalHeader)<{hideHeader?: boolean}>`
  display: ${({hideHeader}) => (hideHeader ? 'none' : 'flex')};
  justify-content: space-between;
  font-size: 14px;
  height: ${({theme}) => theme.sharedHeader.height.mobile}px;
  background-color: ${({theme}) => theme.checkout.headerBackground};
  z-index: 3;
  padding: 0 20px;
  box-shadow: ${({theme}) => theme.shadows.modalHeaderShadow};
  ${media.minMobile`
    height: ${({theme}) => theme.checkout.header.height}px;
    ${body16Normal};
    padding: 0 40px;
  `};
`;

export const CloseButtonSVGIcon = memo(() => {
  const isContrastActive = useSelector(selectA11yContrast);

  const {colors} = useTheme();
  const fill = isContrastActive ? colors.surface : colors.secondary;
  const maskFill = !isContrastActive ? colors.surface : colors.secondary;

  const pathId = `path_${uniqueId()}`;

  return (
    <svg width="10px" height="10px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <path
          d="M18.5417474,0.458252573 C19.2075633,1.12406847 19.1398774,2.27125528 18.3905666,3.02056603 L11.911,9.5 L18.3905666,15.979434 C19.098249,16.6871163 19.1979389,17.7496943 18.645934,18.4264717 L18.5417474,18.5417474 C17.8759315,19.2075633 16.7287447,19.1398774 15.979434,18.3905666 L15.979434,18.3905666 L9.5,11.911 L3.02056603,18.3905666 C2.27125528,19.1398774 1.12406847,19.2075633 0.458252573,18.5417474 C-0.207563322,17.8759315 -0.139877367,16.7287447 0.609433386,15.979434 L7.089,9.5 L0.609433386,3.02056603 C-0.0982489915,2.31288366 -0.19793888,1.25030566 0.354066046,0.573528265 L0.458252573,0.458252573 C1.12406847,-0.207563322 2.27125528,-0.139877367 3.02056603,0.609433386 L3.02056603,0.609433386 L9.5,7.089 L15.979434,0.609433386 C16.7287447,-0.139877367 17.8759315,-0.207563322 18.5417474,0.458252573 Z"
          id={`${pathId}`}
        />
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <mask id="mask-2" fill={maskFill}>
          <use xlinkHref={`#${pathId}`} />
        </mask>
        <use id="Mask1" fill={fill} fillRule="nonzero" xlinkHref={`#${pathId}`} />
      </g>
    </svg>
  );
});

export const MobileBackSVGIcon = () => {
  return (
    <svg width="10px" height="18px" viewBox="0 0 10 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g
        id="Page-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <g id="ic_back" transform="translate(-7.000000, -3.000000)" stroke="#1574f5">
          <g id="back" transform="translate(8.000000, 4.000000)">
            <path d="M8,0 L0,8" id="Line" strokeWidth="2" />
            <path d="M0,8 L8,16" id="Line" strokeWidth="2" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export const Wrapper = styled.div`
  ${flexColumn};
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
  ${media.minMobile`
    flex-direction:row;
    align-items: center;
  `};
`;

export const Title = styled.h2<{isActive?: boolean}>`
  ${body20Normal};
  font-weight: ${({isActive}) => (isActive ? 'bold' : 'normal')};
  color: ${({theme}) => theme.checkout.textColor};
  font-size: 26px;
  line-height: 1em;
  margin: 0 auto;
`;

export const SubPageTitle = styled(Title)`
  margin: auto;
  ${body18Bold};
`;

export const RadioButtonWrapperRight = styled.div`
  margin: 0;
  ${flipOnLTR`
    margin-left: auto;
  `};
  height: 100%;
`;

export const RadioButtonWrapperLeft = styled.div`
  margin: 0;
  ${flipOnLTR`
    margin-right: auto;
  `};
  height: 100%;
`;

export const screenReaderNotificatorCss = css`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const HiddenSpan = styled.span`
  ${screenReaderNotificatorCss}
`;

export const HiddenLegend = styled.legend`
  ${screenReaderNotificatorCss}
`;

export const Padder = styled.div`
  padding: 0 19px;
  ${media.minMobile`    
    padding: 15px 40px 40px 40px;
  `};
  ${media.maxTablet`
    height: auto;
  `}
`;

const SharedCheckoutCloseButtonSvg = ({className}: {className?: string}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" className={className}>
    <defs>
      <path
        id="a"
        d="M7.807.193c.28.28.252.763-.064 1.079L5.015 4l2.728 2.728c.287.287.337.712.133.998l-.069.081c-.28.28-.763.252-1.079-.064L4
       5.015 1.272 7.743c-.316.316-.799.344-1.08.064-.28-.28-.25-.763.065-1.079L2.985 4 .257 1.272C-.03.985-.08.56.124.274L.193.193c.28-.28.763-.252 1.079.064L4 2.985 6.728.257c.316-.316.799-.344 1.08-.064z"
      />
    </defs>
    <g fill="none" fillRule="evenodd" transform="translate(8 8)">
      <mask id="b" fill="#fff">
        <use xlinkHref="#a" />
      </mask>
      <use fill="#0A3847" fillRule="nonzero" xlinkHref="#a" />
      <g fill="#0A3847" mask="url(#b)" />
    </g>
  </svg>
);

export const SharedCheckoutCloseButton = styled(SharedCheckoutCloseButtonSvg)`
  display: flex;
  cursor: pointer;
  position: relative;
  align-self: center;

  &:hover {
    border-radius: 50%;
    box-shadow: ${({theme}) => theme.shadows.shadow2};
  }
`;
