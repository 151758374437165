import {ValueOrNull} from '../config';

export const getType = (obj: ValueOrNull): string => {
  const type = typeof obj;

  if (type !== 'object') return type;

  const lowerCaseTheFirstLetter = (str: string) => str[0].toLowerCase() + str.slice(1);

  const stringOfObj: string = Object.prototype.toString
    .call(obj)
    .replace(/^\[object (\S+)\]$/, '$1');

  const result = lowerCaseTheFirstLetter(stringOfObj);

  return result;
};

/**  Example Usage  **/
//   getType([]); // "array"
//   getType('123'); // "string"
//   getType(null); // "null"
//   getType(undefined); // "undefined"
//   getType(); // "undefined"
//   getType(function () {}); // "function"
//   getType(/123/g); // "regExp"
//   getType(new Date()); // "date"
//   getType(new Map()); // "map"
//   getType(new Set()); // "set"

export const isStringType = (val: ValueOrNull): boolean => getType(val) === 'string';
export const isBoolType = (val: ValueOrNull): boolean => getType(val) === 'boolean';
export const isIntType = (val: ValueOrNull): boolean =>
  getType(val) === 'number' && Number.isSafeInteger(val);
export const isIntegerType = isIntType;
export const isNumberType = (val: ValueOrNull): boolean => getType(val) === 'number';
export const isArrayType = (val: ValueOrNull): boolean => getType(val) === 'array';
export const isObjectType = (val: ValueOrNull): boolean => getType(val) === 'object';
