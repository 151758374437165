import {forwardRef, memo} from 'react';

import {noop} from 'lodash';

import {getLocalizationService} from '~/shared/services/localisationService';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';

import {
  MenuRoot,
  CloseButton,
  A11YClosePopupIcon,
  Title,
  StyledOptionButton,
  HoverText,
  A11yContrastIcon,
  Label,
  A11yOutlineIcon,
} from './styled';

const A11yMenu = forwardRef((props, ref) => {
  const {t} = getLocalizationService();
  const {isOpen, closeMenu, isOutlineActive, toggleOutlineMode, isContrastActive, toggleContrastMode} = props;
  
  return (
    <ClickOutsideDiv onClickOutside={isOpen ? closeMenu : noop}>
      <MenuRoot
        hide={!isOpen}
        ref={ref}
      >
        <Title>{t('accessibility')}</Title>

        <StyledOptionButton
          onClick={toggleContrastMode}
          flipColors={isContrastActive}
          isActive={isContrastActive}
          isOpen={isOpen}
          addSpace
        >
          {!isContrastActive && (<HoverText>{t('contrast_description')}</HoverText>)}
          <A11yContrastIcon isActive={isContrastActive}/>
          <Label>{t('change_contast')}</Label>
        </StyledOptionButton>

        <StyledOptionButton
          onClick={toggleOutlineMode}
          flipColors={isOutlineActive}
          isActive={isOutlineActive}
          isOpen={isOpen}
          addSpace
        >
          {!isOutlineActive && (<HoverText>{t('outline_description')}</HoverText>)}
          <A11yOutlineIcon isActive={isOutlineActive}/>
          <Label>{t('change_outline')}</Label>
        </StyledOptionButton>
        
        <CloseButton title={t('close')} onClick={closeMenu} isOpen={isOpen}>
          <A11YClosePopupIcon isContrastActive={isContrastActive} aria-label={t('close_accessibility_aids_menu')}/>
        </CloseButton>
      </MenuRoot>
    </ClickOutsideDiv>
  );
});

export default memo(A11yMenu);
