import styled from 'styled-components';

import {Link} from '~/shared/router';
import {getLocalizationService} from '~/shared/services/localisationService';
import {getLegacySiteUrl} from '~/shared/services/urlService';
import {media} from '~/shared/theme/media';

const LoyaltyProgramContainer = styled.div`
  display: block;
  margin-top: 8px;
  font-weight: bold;
  padding: 13px;
  color: ${({theme}) => theme.orderSuccess.body.headerTextColor};
  background: ${({theme}) => theme.colors.surface};
  ${media.minMobile`
   display: flex;
`};
`;

const LoyaltyProgramLink = styled(Link)`
  text-decoration: underline !important;
  margin-left: 6px;
  margin-right: 6px;
  color: ${({theme}) => theme.orderSuccess.body.linkColor};
`;

const serviceAreaUrl = getLegacySiteUrl('/Account/ServiceArea');

const LoyaltyProgram = ({points}) => {
  const {t} = getLocalizationService();
  
  if (!points) {
    return null;
  }
  return (
    <LoyaltyProgramContainer>
      <div>{t('yay_in_this_order_you_have_earned_xxx_points', {points})}</div>
      <LoyaltyProgramLink href={serviceAreaUrl} hard>{t('to_you_see_your_points')}</LoyaltyProgramLink>
    </LoyaltyProgramContainer>
  );
};

export default LoyaltyProgram;
