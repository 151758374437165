import {memo} from 'react';

import styled from 'styled-components';

import CloseIconComponent from '~/assets/images/icons/close-popup.svg?react';
import refreshTokenLogoutPicture from '~/assets/images/refreshTokenLogout.png';
import {attachModal} from '~/shared/components/ReduxModal';
import ClickOutsideDiv from '~/shared/components/ClickOutsideDiv';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import {getLocalizationService} from '~/shared/services/localisationService';
import store from '~/shared/store';
import actions from '~/shared/store/actions';
import {signInModalOrPage} from '~/shared/services/navigation';
import {removeQueries} from '~/shared/router';

const Root = styled(ClickOutsideDiv)`
  max-width: 457px;
  padding: 17px;
  border-radius: 2px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};

  &:first-child {
    overflow-y: hidden;
    background-color: ${({theme}) => theme.colors.surface};
  }
`;

const Header = styled.div`
  background-color: ${({theme}) => theme.colors.surface};
  display: flex;
  justify-content: ${({theme}) => (theme.isLTR ? 'flex-start' : 'flex-end')};
`;

const Title = styled.h3`
  white-space: pre-line;
  margin-top: 17px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  margin-bottom: 8px;
`;

const Content = styled.div`
  background-color: ${({theme}) => theme.colors.surface};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 40px;
`;

const Image = styled(ImageWithAlt)`
  width: 200px;
  height: 200px;
`;
const Button = styled.button`
  margin-top: 40px;
  margin-bottom: 15px;
  height: 48px;
  width: 230px;
  font-weight: 700;
  font-size: 14px;
  background-color: ${({theme}) => theme.actionButton.enabled.backgroundColor};
  color: ${({theme}) => theme.actionButton.enabled.color};
`;

const CloseIcon = styled(CloseIconComponent)`
  cursor: pointer;
  height: 16px;
  width: 16px;
`;

const onConnectNow = () => {
  removeQueries(['disconnectModal']);
  signInModalOrPage({mode: 'login'});
};

export const RefreshTokenModal = memo(() => {
  const {t} = getLocalizationService();

  const handleCloseModal = (e: Event) => {
    e?.preventDefault();
    removeQueries(['disconnectModal']);
    store.dispatch(actions.setCurrentModal(null));
  };

  return (
    <Root onClickOutside={handleCloseModal}>
      <Header>
        <CloseIcon alt={t('close')} onClick={handleCloseModal} />
      </Header>
      <Content>
        <Title id="modal-title">{t('for_your_safety')}</Title>
        <Text>{t('connect_again')}</Text>
        <Image alt="refresh-token-logout-picture" src={refreshTokenLogoutPicture} />
        <Button onClick={onConnectNow}>{t('connect_now')}</Button>
      </Content>
    </Root>
  );
});

attachModal('refreshTokenModal', RefreshTokenModal);
