import {useDispatch, useSelector} from 'react-redux';

import {PaymentMethodTypes} from '~/shared/consts/paymentConsts';
import {useIsSafari} from '~/shared/hooks/deviceInfo';
import {createLogger} from '~/shared/logging';
import {is401Error} from '~/shared/services/apiErrorService';
import {handleRefreshToken} from '~/shared/services/auth';
import {validateMerchant} from '~/shared/services/bffService/api';
import actions from '~/shared/store/actions';
import {ApplePayPayment} from '~/shared/store/models/Payment';
import {selectUserData} from '~/shared/store/selectors';

const logger = createLogger('applepay');

const PAYMENT_METHOD_DATA = [{
  supportedMethods: 'https://apple.com/apple-pay',
  data: {
    version: 12,
    merchantIdentifier: 'merchant.com.tenbis',
    supportedNetworks: [
      'masterCard',
      'amex',
      'visa',
    ],
    merchantCapabilities: [
      'supports3DS',
    ],
    countryCode: 'IL',
  },
}];

type UseApplePayArgs = {
  totalToCharge: number;
  isCheckoutInitialized: boolean;
  handleCheckoutSubmit: ({shouldSkipAgeRestriction}: {shouldSkipAgeRestriction: boolean}) => Promise<boolean | undefined>;
};
const useApplePay = ({totalToCharge, isCheckoutInitialized, handleCheckoutSubmit}: UseApplePayArgs) => {
  const isSafari = useIsSafari();
  const dispatch = useDispatch();
  const userData = useSelector(selectUserData);
  const showApplePayButton = window.PaymentRequest && isSafari && isCheckoutInitialized;

  const createApplePayPayment = (applePayPaymentData: ApplePayPayment['applePayPaymentData']): Array<ApplePayPayment> => {
    return [{
      paymentMethod: PaymentMethodTypes.APPLE_PAY,
      applePayPaymentData,
      userId: userData?.userId || 0,
      sum: totalToCharge,
      assigned: true,
      isTenbisCredit: false,
      remarks: '',
      userName: `${userData?.firstName} ${userData?.lastName}`,
      prepaidBalance: null,
    }];
  };

  const handleMerchantValidationEvent = async (event: MerchantValidationEvent) => {
    const response = await validateMerchant(event.validationURL);
    event.complete(response);
  };

  const onApplePayButtonClick = async () => {
    const paymentDetails = {
      total: {
        label: '10bis',
        amount: {
          value: totalToCharge.toString(),
          currency: 'ILS',
        },
      },
    };
    
    const request = new PaymentRequest(PAYMENT_METHOD_DATA, paymentDetails);

    request.onmerchantvalidation = async event => {
      try {
        await handleMerchantValidationEvent(event);
        logger.log('validation-success');
      } catch (error) {
        if (is401Error(error)) {
          await handleRefreshToken(error, () => handleMerchantValidationEvent(event));
          return;
        }
        logger.error('validation-success', error);
      }
    };

    request.onpaymentmethodchange = event => {
      const e = event as PaymentMethodChangeEvent;
      if (e.methodDetails.type !== undefined) {
        e.updateWith(paymentDetails);
      }
    };
  
    try {
      const response = await request.show();
      if (!response.details.token) {
        await response.complete('fail');
        logger.error('fail to complete payment');
        return;
      }
      const {paymentData, paymentMethod} = response.details.token;
      const applePayPayment = createApplePayPayment({
        paymentData: btoa(JSON.stringify(paymentData)),
        methodDisplayName: paymentMethod.displayName,
        methodNetwork: paymentMethod.network,
        methodType: paymentMethod.type,
      });
      dispatch(actions.setPayments(applePayPayment));
      await handleCheckoutSubmit({shouldSkipAgeRestriction: false});
      await response.complete('success');
      
    } catch (error) {
      logger.error('fail to complete payment', error);
    }
  };

  return {
    showApplePayButton,
    onApplePayButtonClick,
  };
};

export default useApplePay;
