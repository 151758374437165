import styled from 'styled-components';

import {Section} from '~/shared/components/OrderCompleteSections/parts';
import {paddingCss} from '~/shared/components/OrderCompleteSections/orderSummaryStyle';
import {getLocalizationService} from '~/shared/services/localisationService';
import {UserVoucher} from '~/shared/store/models/User/UserVouchers';
import {body14Bold, body16Normal} from '~/shared/theme/typography';

const BarCodeSection = styled(Section)`
  padding-top: 32px;
  ${paddingCss};
  background: ${({theme}) => theme.colors.surface};
`;

const BarCodeImg = styled.div.attrs(({imageUrl}: {imageUrl?: string}) => ({
  style: {backgroundImage: imageUrl && `url(${imageUrl})`},
}))<{imageUrl?: string}>`
  background-position: center;
  height: 69px;
  margin: auto;
  background-size: contain;
  background-repeat: no-repeat;
  width: 193px;
`;

const BarCodeNumber = styled.div`
  margin: auto;
  text-align: center;
  ${body16Normal};
  padding-top: 13px;
  color: ${({theme}) => theme.colors.secondary};
  background: ${({theme}) => theme.colors.surface};
`;

const BoldInfoText = styled.div`
  ${body14Bold};
`;

const InfoText = styled.div`
  max-width: 416px;
  width: auto;
  margin: 0 auto;
`;

const ExplanationText = styled.div`
  text-align: center;
  padding: 18px 0 10px 0;
  color: ${({theme}) => theme.colors.secondary};
  background: ${({theme}) => theme.colors.surface};
  width: 100%;
`;

type OrderSummaryBarcodeProps = {
  barcode: UserVoucher;
  isGov: boolean;
};

const OrderSummaryBarcode = ({barcode, isGov}: OrderSummaryBarcodeProps) => {
  const {t} = getLocalizationService();

  return (
    <BarCodeSection>
      <BarCodeImg imageUrl={barcode.barCodeImgUrl} />
      <BarCodeNumber>{barcode.barCodeNumber}</BarCodeNumber>
      <ExplanationText>
        <BoldInfoText>{t('bar_code_must_be_printed')}</BoldInfoText>
        <InfoText>{`${t('list_of_chains_is_listed')} ${isGov ? t('in_accordance_with') : ''} ${t(
          'this_order_can_not_be_canceled',
        )} ${t('voucher_is_personal_statement')}`}
        </InfoText>
      </ExplanationText>
    </BarCodeSection>
  );
};

export default OrderSummaryBarcode;
