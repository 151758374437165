import {memo, useCallback} from 'react';

import {useField} from 'react-final-form';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

import AgeRestrictionBadge from '~/shared/components/AgeRestrictionBadge';
import BaseAmountCounter from '~/shared/components/AmountCounter';
import DishPricePerWeight from '~/shared/components/DishPricePerWeight';
import {useIsMaxTablet, useIsMinTablet} from '~/shared/hooks/deviceInfo';
import {getLocalizationService} from '~/shared/services/localisationService';
import {Dish, RestaurantBusinessTypeName} from '~/shared/store/models';
import {selectCurrentRestaurant} from '~/shared/store/selectors';
import {FlexColumn, FlexSpace, FlexStart} from '~/shared/theme/FlexLayout';
import {body12Bold, body13Normal, body14Bold, body18Normal, body20Bold} from '~/shared/theme/typography';
import {media, flipOnLTR} from '~/shared/theme/utils';
import defaultImageUrl from '~/assets/images/icons/groceries-default-image.svg';
import ImageWithAlt from '~/shared/components/ImageWithAlt';

import useTotalPrice from '../../../hooks/useTotalPrice';
import BaseDishPrice from '../DishPrice';

import AssignedUser from './AssignedUser';

const Root = styled.div`
  ${media.minTablet`
		padding: 20px 20px 0 20px;
	`}
`;

const Image = styled.div<{backgroundImage?: string}>`
  display: block;
  width: 100%;
  height: 180px;
  background-image: url(${({backgroundImage}) => backgroundImage});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-color: ${({theme}) => theme.colors.darkBackground};
  ${media.minTablet`
    width: 244px;
    height: 207px;
  `};
`;

const ContentContainer = styled(FlexStart)`
  align-items: stretch;
`;

const DishInfo = styled(FlexColumn)`
  min-height: 150px;
  flex: 1;
  padding: 20px 20px 0 20px;
  ${media.minTablet`
    padding: 0;
    ${flipOnLTR`
      padding-right: 20px;
    `}
	`}
`;

const DishName = styled.h1`
  ${body20Bold};
  color: ${({theme}) => theme.menu.dishModal.textColor};
  ${flipOnLTR`
		text-align: right;
	`}
  ${media.minTablet`
    line-height: 1em;
    margin-bottom: 7px;
  `}
`;

const DishDescription = styled.div`
  ${body14Bold};
  width: 90%;
  line-height: 18px;
  margin-top: 15px;
  color: ${({theme}) => theme.menu.dishModal.textColor};
  ${flipOnLTR`
    text-align: right;
  `}
  ${media.minTablet`
    margin-top: 0;
    font-weight: normal;
  `};
`;

const ActionsContainer = styled(FlexSpace)`
  flex: 1;
  align-items: flex-end;
  padding: 15px 0;
  margin-top: 16px;
  border-top: 1px solid ${({theme}) => theme.colors.darkBackground};
  ${media.minTablet`
    padding:0;
		border-top: none;
	`}
`;

const AmountCounter = styled(BaseAmountCounter)``;

const DishPrice = styled(BaseDishPrice)`
  color: ${({theme}) => theme.colors.secondary};
  ${body18Normal};
  margin: 0;
  ${flipOnLTR`
    margin-right: 15px;
  `}

  ${media.minTablet`
    ${body20Bold};
    
    ${flipOnLTR`
      margin-right: 30px;
      margin-left: 45px;
    `}
  `}
`;

const UnderImageText = styled.p`
  ${body13Normal};
  margin-top: 5px;
  ${flipOnLTR`
    text-align: right;
  `}
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 2px;
  background-color: ${({theme}) => theme.lineColor};
  margin: 10px 0;
`;

const StyledAgeRestrictionBadge = styled(AgeRestrictionBadge)`
  margin: 0;
  position: absolute;
  z-index: 1;
  top: 16px;
  ${flipOnLTR`
    right: 16px;
  `}

  ${media.minLargeMobile`
    top: 12px;
    ${flipOnLTR`
      right: 12px;
    `}
  `}

  & p {
    ${body12Bold}
    line-height: 18px;
  }

  & div {
    height: 16px;
    width: 24px;
  }
`;

const DishNameContainer = styled.div`
  display: flex;
  ${flipOnLTR`
    margin-left: 40px;
  `}
`;

const ImageContainer = styled.div<{defaultImage?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({defaultImage}) => (defaultImage ? '46px' : '20px')};
`;

const StyledDishPricePerWeight = styled(DishPricePerWeight)`
  line-height: 18px;
  margin-top: 8px;
`;

const DefaultImageWrapper = styled(Image)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({theme}) => theme.colors.gray920};
`;

const DefaultImage = styled(ImageWithAlt)`
  height: 80px;
  width: 80px;
`;

type DishHeaderProps = {
  isEditMode: boolean;
  image?: Dish['dishImageUrl'];
  name: Dish['dishName'];
  description: Dish['dishDescription'];
  ageRestricted: Dish['ageRestricted'];
  pricePerMeasurementUnit: Dish['pricePerMeasurementUnit'];
  measurementUnitScale: Dish['measurementUnitScale'];
  measurementUnit: Dish['measurementUnit'];
};

const DishHeader = ({
  name,
  description,
  image,
  isEditMode,
  ageRestricted,
  pricePerMeasurementUnit,
  measurementUnitScale,
  measurementUnit,
}: DishHeaderProps) => {
  const isMinTablet = useIsMinTablet();
  const {t} = getLocalizationService();
  const isMaxTablet = useIsMaxTablet();
  const currentRestaurant = useSelector(selectCurrentRestaurant);

  const totalPrice = useTotalPrice();
  
  const isVoucherCard = currentRestaurant?.businessType === RestaurantBusinessTypeName.VoucherCard;
  const isIncreaseQuantityDisbled = !!currentRestaurant?.isVoucherEnabled || isVoucherCard;

  const {
    input: {value: quantity, onChange},
  } = useField('quantity');

  const increaseQuantity = useCallback(() => {
    if (!isIncreaseQuantityDisbled) {
      onChange(quantity + 1);
    }
  }, [isIncreaseQuantityDisbled, onChange, quantity]);

  const decreaseQuantity = useCallback(() => {
    if (quantity > 1) {
      onChange(quantity - 1);
    }
  }, [onChange, quantity]);

  if (isMaxTablet) {
    // mobile
    return (
      <Root>
        {image ? (
          <Image data-test-id="dishImageContainer" backgroundImage={image} >
            {ageRestricted && <StyledAgeRestrictionBadge/>}
          </Image>
        ) : (
          <DefaultImageWrapper data-test-id="dishImageContainer">
            <DefaultImage src={defaultImageUrl} noAlt/>
            {ageRestricted && <StyledAgeRestrictionBadge/>}
          </DefaultImageWrapper>
        )}
        <DishInfo>
          <FlexSpace>
            <DishNameContainer>
              <DishName data-test-id="dishNameLabel" id="modal-title">
                {name}
              </DishName>
            </DishNameContainer>
            {isEditMode && <DishPrice price={totalPrice} />}
          </FlexSpace>

          <DishDescription id="modal-content">{description}</DishDescription>
          <StyledDishPricePerWeight
            pricePerMeasurementUnit={pricePerMeasurementUnit}
            measurementUnit={measurementUnit}
            measurementUnitScale={measurementUnitScale}
          />
          <ActionsContainer>
            <AssignedUser />

            <AmountCounter
              {...{
                quantity,
                increaseQuantity,
                decreaseQuantity,
                isIncreaseQuantityDisbled,
                itemName: name,
              }}
              size="big"
              disableTabIndex
            />
          </ActionsContainer>
        </DishInfo>
      </Root>
    );
  }

  return (
    <Root>
      <ContentContainer>
        {image ? (
          <ImageContainer>
            <Image data-test-id="dishImageContainer" backgroundImage={image} >
              {ageRestricted && <StyledAgeRestrictionBadge/>}
            </Image>
            <UnderImageText>*{t('the_image_is_for_illustration_purposes_only')}</UnderImageText>
          </ImageContainer>
        ) : (
          <ImageContainer defaultImage >
            <DefaultImageWrapper data-test-id="dishImageContainer">
              <DefaultImage src={defaultImageUrl} noAlt/>
              {ageRestricted && <StyledAgeRestrictionBadge/>}
            </DefaultImageWrapper>
          </ImageContainer>
        )}
        <DishInfo>
          <FlexSpace>
            <DishNameContainer>
              <DishName data-test-id="dishNameLabel" id="modal-title">
                {name}
              </DishName>
            </DishNameContainer>
            {!isMinTablet && <DishPrice price={totalPrice} />}
          </FlexSpace>

          <DishDescription data-test-id="dishDescriptionLabel" id="modal-content">
            {description}
          </DishDescription>
          <StyledDishPricePerWeight
            pricePerMeasurementUnit={pricePerMeasurementUnit}
            measurementUnit={measurementUnit}
            measurementUnitScale={measurementUnitScale}
          />

          <ActionsContainer>
            <AssignedUser />

            <AmountCounter
              {...{
                quantity,
                increaseQuantity,
                decreaseQuantity,
                isIncreaseQuantityDisbled,
                itemName: name,
              }}
              size="big"
              disableTabIndex
            />
          </ActionsContainer>
        </DishInfo>
      </ContentContainer>

      <Line />
    </Root>
  );
};

export default memo(DishHeader);
