import {useState} from 'react';

import styled from 'styled-components';
import {isEmpty} from 'lodash';

import {useIsMinMobile} from '~/shared/hooks/deviceInfo';
import {flexSpace, flexColumn} from '~/shared/theme/FlexLayout';
import {media} from '~/shared/theme/media';
import {AddPaymentButton} from '~/shared/components/PaymentButton';
import ErrorSection from '~/shared/components/ErrorSection';
import {getLocalizationService} from '~/shared/services/localisationService';
import defaultGrayIconUrl from '~/assets/images/icons/cards/defaultGray.svg';
import MoneyCardIcon from '~/assets/images/icons/cards/moneycard.svg?react';
import PayPalButton from '~/shared/components/PayPalButton';
import Button from '~/shared/components/Button';
import {body12Normal} from '~/shared/theme/typography';

const Root = styled.div`
  width: 100%;
  ${flexColumn};
  align-items: start;
  ${media.minMobile`
		padding-top: 16px;
		flex-direction: row;
    ${flexSpace};
  `}
  margin-bottom: 20px;
`;

const Link = styled(Button)`
  cursor: pointer;
  border-radius: 1px;
  border: solid 1px;
  border-color: ${({theme}) => theme.checkout.availablePayments.borderColor};
  background-color: ${({theme, showAsMainCTA}) =>
    (showAsMainCTA ? theme.colors.surfacePrimaryAction : theme.colors.primaryText)};
  padding: 4px 8px;
  ${body12Normal};
  line-height: 16px;
  width: 120px;
  height: 40px;
  vertical-align: middle;
  display: block;
  ${media.minTablet`
    width: 150px;
  `}
  color: ${({theme}) => theme.checkout.availablePayments.linkBtnColor};
  margin-bottom: 10px;
`;

const AddPaymentsButtons = ({addCreditCard, addBillingPermission, showPaypalButton, onClose}) => {
  const {t} = getLocalizationService();
  const isMinMobile = useIsMinMobile();
  const [paypalError, setPaypalError] = useState();

  return (
    <>
      <Root>
        {!isMinMobile ? (
          <>
            <AddPaymentButton
              onClick={addCreditCard}
              label={t('add_new_credit_card')}
              icon={defaultGrayIconUrl}
              fullWidth
            />
            <AddPaymentButton
              onClick={addBillingPermission}
              label={t('add_new_billing_permission')}
              IconComponent={() => <MoneyCardIcon className="avoid-fill-stroke" />}
              fullWidth
            />
            {showPaypalButton && (
              <AddPaymentButton fullWidth showBorder={false}>
                <PayPalButton onSuccess={onClose} onError={setPaypalError} variant="large" />
              </AddPaymentButton>
            )}
          </>
        ) : (
          <>
            <Link onClick={addCreditCard}>{t('add_new_credit_card')}</Link>
            <Link onClick={addBillingPermission}>{t('add_new_billing_permission')}</Link>
            {showPaypalButton && <PayPalButton onSuccess={onClose} onError={setPaypalError} variant="small" />}
          </>
        )}
      </Root>

      {!isEmpty(paypalError) && <ErrorSection error={paypalError} />}
    </>
  );
};

export default AddPaymentsButtons;
