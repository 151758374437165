import {memo, useContext, useRef, useState} from 'react';

import styled from 'styled-components';

import {getLocalizationService} from '~/shared/services/localisationService';
import Button from '~/shared/components/Button';
import FutureOrder from '~/shared/components/FutureOrder';
import {flipOnLTR} from '~/shared/theme/flipOnLTR';
import {trackEvent} from '~/shared/services/analytics';
import {FutureOrderContext} from '~/shared/components/FutureOrder/context';

import {StyledArrow} from './Shared';
import SingleRuleETABox from './SingleRuleETABox';

const Root = styled.div``;

const ContentLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const CollapseButton = styled(Button)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Label = styled.div`
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: ${({theme}) => theme.colors.surfaceTitle};
`;

const CollapsedContent = styled.div<{hideOverflow: boolean; contentHeight?: number; isCollapsed: boolean}>`
  transition: height 0.3s ease-in-out;
  overflow: ${({hideOverflow}) => (hideOverflow ? 'hidden' : 'unset')};
  ${({isCollapsed, contentHeight}) => (isCollapsed ? `
    height: ${contentHeight}px;
  ` : `
    height: 0px;
  `)}
`;

const FutureOrderWrapper = styled.div`
  padding-top: 16px;
`;

const ArrowContainer = styled.div<{isCollapsed: boolean}>`
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.25s linear;
  transform: rotate(${({isCollapsed}) => (isCollapsed ? 180 : 0)}deg);
  ${flipOnLTR`
    margin-right: 6px;
  `}
`;

const Content = styled.div``;

const AsapAndFuture = (
  {inline, hideTitle, isCheckout}:
  {inline?: boolean;isCheckout?: boolean; hideTitle?: boolean},
) => {
  const collapseContentRef = useRef<HTMLDivElement>(null);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [hideOverflow, setHideOverflow] = useState(true);
  const [transition, setTransition] = useState(false);
  const {t} = getLocalizationService();

  const {
    isASAP,
  } = useContext(FutureOrderContext);

  const handleCollapseClick = () => {
    if (transition || inline) {
      return;
    }

    setIsCollapsed(state => {
      if (!state) {
        trackEvent('nlxOrderType_click', {
          pageSource: isCheckout ? 'checkout' : 'menu',
          original_orderType: isASAP ? 'asap' : 'future',
        });
      }
      return !state;

    });
    setTransition(true);
    setTimeout(() => {
      setHideOverflow(state => !state);
      setTransition(false);
    }, isCollapsed ? 0 : 300);
  };

  return (
    <Root>
      <ContentLine>
        <SingleRuleETABox
          inline={inline}
          isCheckout={isCheckout}
          hideTitle={hideTitle}
        />
        <CollapseButton onClick={handleCollapseClick}>
          {!inline && (
            <Label>
              {isCollapsed ? t('done') : t('schedule')}
            </Label>
          )}
          <ArrowContainer isCollapsed={isCollapsed}>
            <StyledArrow inline={inline} />
          </ArrowContainer>
        </CollapseButton>
      </ContentLine>

      <CollapsedContent
        hideOverflow={hideOverflow}
        contentHeight={collapseContentRef.current?.clientHeight}
        isCollapsed={isCollapsed}
      >
        <Content ref={collapseContentRef}>
          <FutureOrderWrapper>
            <FutureOrder isCheckout={!!isCheckout} />
          </FutureOrderWrapper>
        </Content>
      </CollapsedContent>
    </Root>
  );
};

export default memo(AsapAndFuture);
