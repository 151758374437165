/* eslint-disable max-len */
import {memo} from 'react';

import styled, {css, withTheme} from 'styled-components';

import {flexColumnCenter, flexCenter, flexColumnCenterVertically} from '~/shared/theme/FlexLayout';
import {media, flipOnLTR} from '~/shared/theme/utils';
import Button from '~/shared/components/Button';
import ImageWithAlt from '~/shared/components/ImageWithAlt';
import a11yMenuIconUrl from '~/assets/images/a11yMenuIcon.svg';
import {body18Normal, subheader24Bold} from '~/shared/theme/typography';

const A11Y_BUTTON_SIZE = 50;
const CLOSE_BUTTON_SIZE = 32;

export const A11yContrastIcon = memo(
  withTheme(({isActive, theme}) => {
    const fill = isActive ? theme.colors.secondaryText : theme.colors.secondary;

    return (
      <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1302.000000, -271.000000)" fill={fill} fillRule="nonzero">
            <g transform="translate(1198.000000, 188.000000)">
              <g transform="translate(28.000000, 59.000000)">
                <g transform="translate(76.000000, 24.000000)">
                  <path d="M25.5706667,4.46133333 C25.56,4.46133333 25.56,4.45066667 25.56,4.44 C25.5493333,4.44 25.5386667,4.44 25.5386667,4.42933333 C19.7094852,-1.39984811 10.2585148,-1.39984811 4.42933333,4.42933333 C-1.39984811,10.2585148 -1.39984811,19.7094852 4.42933333,25.5386667 C4.44,25.5386667 4.44,25.5493333 4.44,25.56 C4.45066667,25.56 4.46133333,25.56 4.46133333,25.5706667 C10.2905148,31.3998481 19.7414852,31.3998481 25.5706667,25.5706667 C31.3998481,19.7414852 31.3998481,10.2905148 25.5706667,4.46133333 Z M22.4666667,21.4 L16.0666667,21.4 C15.4775629,21.4 15,20.9224371 15,20.3333333 C15,19.7442296 15.4775629,19.2666667 16.0666667,19.2666667 L22.4666667,19.2666667 C23.0557704,19.2666667 23.5333333,19.7442296 23.5333333,20.3333333 C23.5333333,20.9224371 23.0557704,21.4 22.4666667,21.4 Z M2.19998618,15 C2.1926916,10.0154247 5.08383468,5.48134848 9.60620572,3.38511266 C14.1285768,1.28887685 19.4570148,2.01296485 23.256,5.24 L5.24,23.256 C3.27411666,20.9548343 2.1958748,18.0265564 2.19998618,15 L2.19998618,15 Z" />
                  <path d="M13.9333333,8.6 L11.8,8.6 L11.8,6.46666667 C11.8,5.87756293 11.3224371,5.4 10.7333333,5.4 C10.1442296,5.4 9.66666667,5.87756293 9.66666667,6.46666667 L9.66666667,8.6 L7.53333333,8.6 C6.9442296,8.6 6.46666667,9.07756293 6.46666667,9.66666667 C6.46666667,10.2557704 6.9442296,10.7333333 7.53333333,10.7333333 L9.66666667,10.7333333 L9.66666667,12.8666667 C9.66666667,13.4557704 10.1442296,13.9333333 10.7333333,13.9333333 C11.3224371,13.9333333 11.8,13.4557704 11.8,12.8666667 L11.8,10.7333333 L13.9333333,10.7333333 C14.5224371,10.7333333 15,10.2557704 15,9.66666667 C15,9.07756293 14.5224371,8.6 13.9333333,8.6 Z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }),
);

export const A11YClosePopupIcon = memo(
  withTheme(({isContrastActive, theme}) => {
    const fill = isContrastActive ? theme.colors.secondaryText : theme.colors.secondary;

    return (
      <svg width="24px" height="24px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="2---Icons/24x24/close-popup" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Close-Button" fill={fill} fillRule="nonzero">
            <path d="M4.66427952,5.67930921 L2.05828103,8.2946482 C1.7569117,8.59709771 1.29551845,8.62441826 1.02773036,8.35567036 C0.759942278,8.08692247 0.787165258,7.62387548 1.08853459,7.32142597 L3.69453308,4.70608698 L1.08853459,2.09074799 C0.787165258,1.78829848 0.759942278,1.32525149 1.02773036,1.05650359 C1.29551845,0.787755692 1.7569117,0.815076245 2.05828103,1.11752575 L4.66427952,3.73286474 L7.27027802,1.11752575 C7.57164735,0.815076245 8.0330406,0.787755692 8.30082869,1.05650359 C8.56861677,1.32525149 8.54139379,1.78829848 8.24002446,2.09074799 L5.63402597,4.70608698 L8.24002446,7.32142597 C8.54139379,7.62387548 8.56861677,8.08692247 8.30082869,8.35567036 C8.0330406,8.62441826 7.57164735,8.59709771 7.27027802,8.2946482 L4.66427952,5.67930921 Z" />
          </g>
        </g>
      </svg>
    );
  }),
);

export const A11yOutlineIcon = memo(
  withTheme(({isActive, theme}) => {
    const fill = isActive ? theme.colors.secondaryText : theme.colors.secondary;

    return (
      <svg width="83px" height="31px" viewBox="0 0 83 31" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1275.000000, -395.000000)">
            <g transform="translate(1198.000000, 188.000000)">
              <g transform="translate(28.000000, 184.000000)">
                <g transform="translate(49.000000, 24.000000)">
                  <path
                    d="M2.8257548,0.829404195 L74.9924215,0.829404195 C75.5447062,0.829404195 75.9924215,1.27711945 75.9924215,1.8294042 L75.9924215,23.3294042 L75.9924215,23.3294042 L1.8257548,23.3294042 L1.8257548,1.8294042 C1.8257548,1.27711945 2.27347005,0.829404195 2.8257548,0.829404195 Z"
                    stroke={fill}
                    strokeWidth="2"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="82.4090881 7.13916016 82.4090881 29.7460709 80.4090881 29.7460709 80.4090881 5.2020874"
                  />
                  <path
                    d="M2.05960032,23.1 L6.74799915,27.746 L82.4090881,27.7460709 L82.4090881,29.7460709 L6.24242147,29.7460709 L6.23722839,29.7511292 L0.820999146,24.3260651 L2.05960032,23.1 Z"
                    fill={fill}
                    fillRule="nonzero"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="75.1590881 2.04998779 76.5422974 0.618713379 81.9171753 6.66888428 80.6469727 8.328125"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="7.65908813 24.1603216 8.91868846 22.9118729 14.3249664 28.2687124 13.0662816 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="14.3257548 24.1603216 15.5853551 22.9118729 20.9916331 28.2687124 19.7329483 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="20.9924215 24.1603216 22.2520218 22.9118729 27.6582998 28.2687124 26.399615 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="27.6590881 24.1603216 28.9186885 22.9118729 34.3249664 28.2687124 33.0662816 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="34.3257548 24.1603216 35.5853551 22.9118729 40.9916331 28.2687124 39.7329483 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="40.9924215 24.1603216 42.2520218 22.9118729 47.6582998 28.2687124 46.399615 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="47.6590881 24.1603216 48.9186885 22.9118729 54.3249664 28.2687124 53.0662816 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="54.3257548 24.1603216 55.5853551 22.9118729 60.9916331 28.2687124 59.7329483 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="60.9924215 24.1603216 62.2520218 22.9118729 67.6582998 28.2687124 66.399615 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="67.6590881 24.1603216 68.9186885 22.9118729 74.3249664 28.2687124 73.0662816 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="74.3257548 24.1603216 75.5853551 22.9118729 80.9916331 28.2687124 79.7329483 29.576594"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="75.1590881 19.5484487 76.4186885 18.3 81.8249664 23.6568395 80.5662816 24.9647212"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="75.1590881 13.9484487 76.4186885 12.7 81.8249664 18.0568395 80.5662816 19.3647212"
                  />
                  <polygon
                    fill={fill}
                    fillRule="nonzero"
                    points="75 7.94844869 76.2596003 6.7 81.5812073 12.2316798 80.3843079 13.4019679"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    );
  }),
);

export const Root = styled.div`
  position: fixed;
  bottom: ${({isBannersShown}) => (isBannersShown ? '86px' : '32px')};
  right: 0;
  ${media.minTablet`
    top: 130px;
    bottom: auto;
    ${flipOnLTR`
      right: auto;
      left: 0;
    `}
  `}
  ${media.minLargeTablet`
    top: 177px;
  `}
  z-index: ${({theme}) => theme.zIndex.supportAndA11yBadge};
`;

export const Label = styled.label`
  margin-top: 8px;
`;

export const A11yButtonOpener = styled(Button).attrs({
  tabIndex: 1,
})`
  width: ${A11Y_BUTTON_SIZE}px;
  height: ${A11Y_BUTTON_SIZE}px;
  box-shadow: ${({theme}) => theme.shadows.shadow1};
  background-color: ${({theme}) => theme.colors.secondary};
  ${flexCenter};
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  ${media.minTablet`
    ${flipOnLTR`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: 50%;
      border-bottom-right-radius: 50%;
    `}
  `}
`;

export const A11yMenuIcon = styled(ImageWithAlt).attrs(() => ({
  src: a11yMenuIconUrl,
}))`
  width: 23px;
  height: 28px;
`;

export const Title = styled.h2`
  ${subheader24Bold};
  letter-spacing: 1px;
  padding: 0;
  color: ${({theme}) => theme.colors.secondary};
  text-align: center;
  margin: 16px 0;
`;

export const MenuRoot = styled.div`
  position: absolute;
  flex-direction: column;
  width: 246px;
  height: 329px;
  background-color: ${({theme}) => theme.colors.surface};
  box-shadow: ${({theme}) => theme.shadows.a11yShadow};
  margin-top: -${A11Y_BUTTON_SIZE}px;
  transition: right 1s ease, left 1s ease;
  right: ${({hide}) => (hide ? -500 : 0)}px;
  bottom: 0;
  ${media.minTablet`
    bottom: auto;
    ${flipOnLTR`
      left: ${({hide}) => (hide ? -500 : 0)}px;
      right: auto;
    `}
  `}
`;

export const StyledOptionButton = styled(Button).attrs(({isActive, isOpen}) => ({
  'aria-pressed': isActive,
  tabIndex: isOpen ? 0 : -1,
}))`
  ${body18Normal};
  width: 182px;
  height: 109px;
  border-radius: 2px;
  box-shadow: ${({theme}) => theme.shadows.a11yShadow};
  border: solid 2px ${({theme}) => theme.colors.secondary};
  margin: 0 auto;
  color: ${({theme}) => theme.colors.secondary};
  line-height: 18px;
  ${flexColumnCenter};
  ${({addSpace}) => addSpace && 'margin-bottom: 16px;'}
  ${({flipColors}) =>
    flipColors &&
    css`
      background-color: ${({theme}) => theme.colors.secondary};
      color: ${({theme}) => theme.colors.secondaryText};
    `}
`;

export const HoverText = styled.div`
  background-color: #03222b;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  color: ${({theme}) => theme.colors.secondaryText};
  text-align: center;
  ${flexColumnCenterVertically};
  cursor: pointer;
  display: none;

  /* stylelint-disable selector-type-no-unknown */
  ${StyledOptionButton}:hover &,
  ${StyledOptionButton}:focus & {
    display: flex;
    z-index: 2;
  }
  /* stylelint-enable selector-type-no-unknown */
`;

export const CloseButton = styled(Button).attrs(({isOpen}) => ({
  tabIndex: isOpen ? 0 : -1,
}))`
  z-index: 2;
  width: ${CLOSE_BUTTON_SIZE}px;
  height: ${CLOSE_BUTTON_SIZE}px;
  ${flexColumnCenter};
  position: absolute;
  top: 5px;
  ${flipOnLTR`
    left: 5px;
  `}
`;
