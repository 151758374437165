import {RestaurantBusinessTypeName} from '~/shared/store/models';

export const ITEMS_IN_ROW = {
  [RestaurantBusinessTypeName.GroceryStore]: {
    isMinLargeDesktop: 4,
    isMinLargeTablet: 3,
    isMinLargeMobile: 1,
    default: 2,
  },
  default: {
    isMinLargeDesktop: 2,
    isMinLargeTablet: 2,
    isMinLargeMobile: 1,
    default: 1,
  },
};

export const CARDS_HEIGHT = {
  [RestaurantBusinessTypeName.GroceryStore]: {
    isMinLargeDesktop: 288,
    isMinLargeTablet: 288,
    isMinLargeMobile: 288,
    default: 234,
  },
  default: {
    isMinLargeDesktop: 140,
    isMinLargeTablet: 140,
    isMinLargeMobile: 133,
    default: 133,
  },
};

export const CARDS_TOP_SPACING = {
  [RestaurantBusinessTypeName.GroceryStore]: {
    isMinLargeDesktop: 9,
    isMinLargeTablet: 8,
    isMinLargeMobile: 7,
    default: 7,
  },
  default: {
    isMinLargeDesktop: 20,
    isMinLargeTablet: 20,
    isMinLargeMobile: 10,
    default: 10,
  },
};

export const ROW_TITLE_HEIGHT = 20;

export const TITLE_TOP_PADDINGS = {
  [RestaurantBusinessTypeName.GroceryStore]: {
    isMinLargeDesktop: 0,
    isMinLargeTablet: 0,
    isMinLargeMobile: 0,
    default: 0,
  },
  default: {
    isMinLargeDesktop: 0,
    isMinLargeTablet: 0,
    isMinLargeMobile: 0,
    default: 8,
  },
};

export const TITLE_BOTTOM_PADDINGS = {
  [RestaurantBusinessTypeName.GroceryStore]: {
    isMinLargeDesktop: 16,
    isMinLargeTablet: 16,
    isMinLargeMobile: 16,
    default: 16,
  },
  default: {
    isMinLargeDesktop: 14,
    isMinLargeTablet: 14,
    isMinLargeMobile: 14,
    default: 16,
  },
};

export const ROW_MARGIN_TOP_GROCERIES = 16;
export const ROW_MARGIN_TOP_DEFAULT = 15;

export const ROW_DESCRIPTION_HEIGHT = 22;
export const DESCRIPTION_MAX_LETTERS_IN_ONE_LINE = {
  isMinLargeDesktop: 130,
  isMinLargeTablet: 100,
  isMinLargeMobile: 30,
  default: 30,
};

export const DESCRIPTION_BOX_BOTTOM_PADDING = {
  [RestaurantBusinessTypeName.GroceryStore]: {
    isMinLargeDesktop: 14,
    isMinLargeTablet: 14,
    isMinLargeMobile: 14,
    default: 14,
  },
  default: {
    isMinLargeDesktop: 14,
    isMinLargeTablet: 14,
    isMinLargeMobile: 14,
    default: 16,
  },
};

export const DESCRIPTION_MAX_LINES_MOBILE = 4;
export const DESCRIPTION_MAX_LINES_DEFAULT = 2;

export const REORDER_DEFAULT_WRAPPER_HEIGHT = 150;
export const REORDER_TABLET_TITLE_HEIGHT = 52;
export const REORDER_TABLET_BOTTOM_PADDING = 8;
export const REORDER_TABLET_CARD_BOTTOM_MARGIN = 10;

export const ROW_EMPTY_DESCRIPTION_HEIGHT = 0;
