import {PaymentMethod, PaymentMethodTypes} from '~/shared/consts/paymentConsts';

import Payment, {isPayment} from './Payment';

export const isCheckoutPayment = (obj: unknown): obj is CheckoutPayment => isPayment(obj) && 'isDisabled' in obj;

export default interface CheckoutPayment<PM extends PaymentMethodTypes = PaymentMethod> extends Payment<PM> {
  isDisabled: boolean;
  editMode?: boolean;
}
