import {useCallback, useEffect} from 'react';

import styled from 'styled-components';
import {useSelector, useDispatch} from 'react-redux';

import {getLocalizationService} from '~/shared/services/localisationService';
import {media} from '~/shared/theme/utils';
import {removeApprovedPaymentCallout} from '~/shared/store/actions';

import CallOut from './CallOut';

const CALLOUT_SHOW_TIME = 5000;

const CalloutWrapper = styled.div`
  position: absolute;
  z-index: 2000;
  top: ${({theme}) => theme.sharedHeader.height.mobile}px;
  width: 100%;
  right: 0;
  padding: 15px 20px;
  ${media.minMobile`
    top: ${({theme}) => theme.sharedHeader.height.rest}px;
  `}
`;

const CallOutBg = styled.div`
  border-radius: 2px;
  background-color: ${({theme}) => theme.colors.successDark};
`;

const CalloutInnerWrapper = styled.div`
  display: flex;
`;

const Text = styled.div`
  margin: 0 10px;
  font-weight: bold;
`;

const AttachPaymentsCallOut = () => {
  const {t} = getLocalizationService();
  const linkMoneycardRequestsCallouts = useSelector(state => state.payments.linkMoneycardRequestsCallouts);
  const dispatch = useDispatch();

  const onRemove = useCallback(() => {
    dispatch(removeApprovedPaymentCallout());
  }, [dispatch]);

  useEffect(() => {
    const removeCallOutInterval = setTimeout(() => {
      if (linkMoneycardRequestsCallouts[0]) {
        onRemove();
      }
    }, CALLOUT_SHOW_TIME);

    return () => {
      clearTimeout(removeCallOutInterval);
    };
  }, [linkMoneycardRequestsCallouts, onRemove]);

  if (!linkMoneycardRequestsCallouts[0]) {
    return null;
  }

  return (
    <CalloutWrapper>
      <CallOutBg>
        <CallOut onRemove={onRemove} disableDefaultBackgroundColor>
          <CalloutInnerWrapper>
            <Text>{t('your_request_was_approved_by_xxx', {name: linkMoneycardRequestsCallouts[0].userName})}</Text>
          </CalloutInnerWrapper>
        </CallOut>
      </CallOutBg>
    </CalloutWrapper>
  );
};

export default AttachPaymentsCallOut;
